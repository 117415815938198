import React, { useCallback, useEffect, useMemo } from "react";
import { Checkbox, Popup, Table, WithPopup } from "@abb/abb-common-ux-react";
import { AccessRights, AccessRightsDto, ManageUserRoleDto, RoleGroups, RoleTypes, TeamTypes } from "api";
import styled from "styled-components";
import { RoleSelectionType } from "../containers/ManageUserView";
import { Button } from "framework/components/Button";
import { Roles } from "framework/hooks/useUserRoles";
import { RoleGroupAccessRightsDto } from "../../../../api/models/RoleGroupAccessRightsDto";
import { SmallHeader } from "../../../../framework/components/styled/SmallHeader";
import { useTranslation } from "react-i18next";
import { primitiveSort } from "utilities/sorting";
import { UserPriceListsGrid } from "./UserPriceListsGrid";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedUser, getUserPriceLists } from "../reducers/manageUsersViewReducer";
import { manageUsersSagas } from "../sagas/manageUsersSagas";
import { useIsMobile } from "utilities/useIsMobile";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { getUserAccessRights } from "applications/common/reducers/userReducer";
import AuthorizedComponent from "framework/components/AuthorizedComponent";
import { commonUXTheme } from "styles/commonUXVariables";

interface UserRolesDialogProps {
  roleGroups: Roles[] | undefined;
  userRoles: ManageUserRoleDto[] | undefined;
  setUserRoles: React.Dispatch<React.SetStateAction<ManageUserRoleDto[] | undefined>>;
  accessRights: RoleGroupAccessRightsDto[] | undefined;
  saveChanges?: (disableNotification: boolean) => void;
  isSaveEnabled: boolean;
  isLoading: boolean;
  hasEditRights: boolean;
  isInternal: boolean;
  isDeliverItRelevantCompany: boolean;
  roleSelectionType: RoleSelectionType | undefined;
  showDefaultRolesAsSelected?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isAccessRequestView?: boolean;
  isTeamDialog?: boolean;
  teamType?: TeamTypes;
  companyId?: string;
  hideRoleGroupLabel?: boolean;
}

const TooltipText = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

const DialogContentContainer = styled.div<{ isMobile: boolean; isAccessRequestView?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isAccessRequestView ? "space-between" : "space-around")};
  min-width: 900px;
  margin-bottom: ${(props) => props.theme.sizes.lm};
  margin-left: ${(props) => (!props.isMobile && props.isAccessRequestView ? "115px" : "none")};
  margin-right: ${(props) => (!props.isMobile && props.isAccessRequestView ? "115px" : "none")};
`;

const UserRolesContainer = styled.div<{ isAccessRequestView?: boolean, isTeamDialog?: boolean }>`
  height: ${(props) => (props.isAccessRequestView ? "515px" : "100%")};
  min-height: ${(props) => (!props.isTeamDialog ? "515px" : "100%")};
  display: flex;
  flex-direction: column;
`;

const PriceListsContainer = styled.div<{ isMobile: boolean; isAccessRequestView?: boolean }>`
  min-width: 900px;
  display: flex;
  height: 100%;
  width: auto;
  margin-left: ${(props) => (!props.isMobile && props.isAccessRequestView ? "115px" : "none")};
  margin-right: ${(props) => (!props.isMobile && props.isAccessRequestView ? "115px" : "none")};
`;

const RoleGroupContainer = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.sizes.l};
`;

const StaticHeightSmallHeader = styled(SmallHeader)`
  display: inline-flex;
`;

const StyledButton = styled(Button)`
  width: 100px;
  margin-top: ${(props) => props.theme.sizes.m};
`;
const StyledAutoWidthButton = styled(Button)`
  width: auto;
  margin-top: ${(props) => props.theme.sizes.m};
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

const getDefaultRoles = (
  accessRights: AccessRightsDto,
  roleSelectionType: RoleSelectionType | undefined,
  isInternal: boolean,
  roleGroups: Roles[] | undefined,
  teamType: TeamTypes | undefined
) => {
  const roleTypesFilter: RoleTypes[] = [];
  if (roleSelectionType === RoleSelectionType.COMPANY) {
    roleTypesFilter.push(RoleTypes.Company);
    if (isInternal) {
      roleTypesFilter.push(RoleTypes.InternalCompany);
    } else {
      roleTypesFilter.push(RoleTypes.ExternalCompany);
    }
  } else if(roleSelectionType === RoleSelectionType.TEAM) {
    if(teamType === TeamTypes.BusinessUnit) {
      roleTypesFilter.push(RoleTypes.BusinessUnitTeam);
    }
    if(teamType === TeamTypes.FunctionalTeam) {
      roleTypesFilter.push(RoleTypes.FunctionalTeam);
    }
  } else {
    roleTypesFilter.push(RoleTypes.Global);
  }

  const roles = roleGroups?.flatMap((r) => r.roles);

  const defaultRoles = accessRights.roles.filter(
    (role) => (role.defaultRole || roles?.find(r => r.id === role.id)?.defaultRole) && roleTypesFilter.some((f) => f === role.roleTypeId)
  );
  return defaultRoles;
};

const getActiveRoles = (
  userRoles: ManageUserRoleDto[] | undefined,
  accessRight: AccessRightsDto,
  roleSelectionType: RoleSelectionType | undefined,
  isInternal: boolean,
  roles: Roles[] | undefined,
  teamType: TeamTypes | undefined,
  showDefaultCompanyRolesAsSelected?: boolean,
) => {
  let usersRoleAbbreviations = userRoles
    ?.filter(
      (role) =>
        role.roleAccessRights?.some(
          (roleAccessRight) => roleAccessRight.accessRight?.id === accessRight?.accessRight?.id
        )
    )
    .map((ur) => ur.abbreviation);

  const usersSubRoleIds = userRoles?.flatMap((ur) => ur.subRoleIds);

  // Add possible sub roles
  if (usersRoleAbbreviations && roles) {
    const subRoleAbbreviations = roles
      .flatMap((r) => r.roles)
      .filter(
        (r) =>
          usersSubRoleIds?.some((s) => s === r.id) &&
          r.roleAccessRights.some((rar) => rar.accessRight?.id === accessRight?.accessRight?.id) &&
          !usersRoleAbbreviations?.some((abbr) => abbr === r.abbreviation)
      )
      .map((r) => r.abbreviation);

    usersRoleAbbreviations = usersRoleAbbreviations.concat(subRoleAbbreviations);
  }

  const defaultRoles: string[] = showDefaultCompanyRolesAsSelected
    ? getDefaultRoles(accessRight, roleSelectionType, isInternal, roles, teamType).map((role) => role.abbreviation)
    : [];

  const activeRoles = usersRoleAbbreviations?.map((role) => role) ?? [];

  const ret = [...new Set([...defaultRoles, ...activeRoles])]
    .sort((a, b) => (a < b ? -1 : 1))
    .join(", ");

  return ret;
};

const getIsDisabled = (
  isDefaultRole: boolean,
  hasEditRights: boolean,
  isDeliverItRelevantCompany: boolean,
  isExternallyAssignedRole: boolean | undefined,
  isAssignedAsSubRole: boolean,
  isViewOnlyRole: boolean
) => {
  if (isDefaultRole) {
    return true;
  }
  if (!hasEditRights) {
    return true;
  }
  if (isExternallyAssignedRole) {
    return true;
  }
  if (isDeliverItRelevantCompany) {
    return true;
  }

  if (isAssignedAsSubRole) {
    return true;
  }

  if (isViewOnlyRole) {
    return true;
  }

  return false;
};

export const UserRoles = ({
  roleGroups,
  userRoles,
  setUserRoles,
  accessRights,
  saveChanges,
  isSaveEnabled,
  isLoading,
  hasEditRights,
  roleSelectionType,
  isInternal,
  isDeliverItRelevantCompany,
  showDefaultRolesAsSelected,
  setIsOpen,
  isAccessRequestView,
  companyId,
  isTeamDialog,
  teamType,
  hideRoleGroupLabel = false
}: UserRolesDialogProps): JSX.Element => {
  const getRoleGroup = (roleGroup: Roles): JSX.Element | undefined => {
    const rolesInGroup = [...roleGroup.roles].sort((a, b) =>
      primitiveSort(a.abbreviation, b.abbreviation)
    );
    if (rolesInGroup.length > 0) {
      return (
        <RoleGroupContainer key={roleGroup.id}>
          <StaticHeightSmallHeader text={!hideRoleGroupLabel ? roleGroup.friendlyName ?? "" : ""} />
          {rolesInGroup.map((role, index) => {
            const isNonDeliverItRelevantCompany =
              roleGroup.id === RoleGroups.DeliverIt &&
              !isDeliverItRelevantCompany &&
              roleSelectionType === RoleSelectionType.COMPANY;

            const isExternallyAssignedRole = userRoles?.some(
              (r) => r.id === role.id && r.externallyAssigned === true
            );

            const correspondingRole = userRoles?.find((r) => r.id === role.id);
            const isAssignedAsSubRole = userRoles?.some(
              (ur) => ur.subRoleIds?.some((subRoleId) => subRoleId === role.id)
            );

            const isDisabled = getIsDisabled(
              role.defaultRole,
              hasEditRights && roleGroup.canEdit,
              isNonDeliverItRelevantCompany,
              isExternallyAssignedRole,
              isAssignedAsSubRole ?? false,
              role.isViewOnly
            );

            const getLabel = () => {
              if (isExternallyAssignedRole) {
                return role.abbreviation + " " + role.name + "*";
              } else {
                return role.abbreviation + " " + role.name;
              }
            };

            const getTooltip = () => {
              if (isExternallyAssignedRole) {
                return (
                  <li key={role?.id}>
                    <TooltipText>{t("(*) Externally assigned role")}</TooltipText>
                  </li>
                );
              } else if (isNonDeliverItRelevantCompany) {
                return (
                  <li key={role?.id}>
                    <TooltipText>
                      {t("Cannot assign DeliverIT roles for non-DeliverIT relevant companies")}
                    </TooltipText>
                  </li>
                );
              } else {
                return role?.roleAccessRights?.map((roleAccessRight) => (
                  <li key={roleAccessRight.accessRight.id}>
                    <TooltipText>{roleAccessRight.accessRight?.friendlyName}</TooltipText>
                  </li>
                ));
              }
            };

            return (
              <WithPopup key={role.abbreviation}>
                <div>
                  <Checkbox
                    monochrome={true}
                    key={role.id}
                    label={getLabel()}
                    value={
                      role.defaultRole || correspondingRole !== undefined || isAssignedAsSubRole
                    }
                    disabled={isDisabled}
                    onChange={() => {
                      if (userRoles?.findIndex((r) => r.id === role.id) === -1 && userRoles) {
                        setUserRoles([...userRoles, rolesInGroup[index]]);
                      } else if (userRoles) {
                        setUserRoles([...userRoles.filter((userRole) => userRole.id !== role.id)]);
                      }
                    }}
                  />
                </div>
                <Popup
                  trigger="hover"
                  position={["left top", "right top"]}
                  style={{ zIndex: commonUXTheme.zIndices.tooltip }}
                >
                  <div style={{ maxWidth: "300px" }}>
                    <ul>{getTooltip()}</ul>
                  </div>
                </Popup>
              </WithPopup>
            );
          })}
        </RoleGroupContainer>
      );
    }
  };

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const selectedUser = useSelector(getSelectedUser);
  const userPriceLists = useSelector(getUserPriceLists);
  const userAccessRights = useSelector(getUserAccessRights);

  const canViewUserPriceLists = useMemo(
    () =>
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [AccessRights.ManagePriceLists, AccessRights.ViewAllPriceLists]
      }),
    [userAccessRights]
  );

  useEffect(() => {
    if (selectedUser && isAccessRequestView && canViewUserPriceLists) {
      // Creating a Set to remove duplicates
      const defaultRoleIds =
        [
          ...new Set(
            accessRights
              ?.flatMap((roleGroupDto) => roleGroupDto.accessRights)
              .map((accessRights) => getDefaultRoles(accessRights, roleSelectionType, isInternal, roleGroups, teamType))
              .flatMap((role) => role)
              .map((x) => x.id)
          )
        ];
      dispatch(
        manageUsersSagas.getUserPriceLists.createAction({
          userId: selectedUser.user.id,
          withAddedRoleIds: (userRoles?.map((x) => x.id) ?? []).concat(defaultRoleIds),
          withCompanyId: companyId
        })
      );
    }
  }, [
    dispatch,
    selectedUser,
    userRoles,
    companyId,
    accessRights,
    roleSelectionType,
    isInternal,
    isAccessRequestView,
    canViewUserPriceLists,
    roleGroups,
    teamType
  ]);

  const updateUserDisabledManagedPriceList = useCallback(
    (managedPriceListId: number) => {
      if (selectedUser?.user) {
        dispatch(
          manageUsersSagas.updateUserDisabledManagedPriceList.createAction({
            updateUserDisabledManagedPriceListCommand: {
              userId: selectedUser?.user.id,
              managedPriceListId: managedPriceListId
            }
          })
        );
      }
    },
    [dispatch, selectedUser?.user]
  );

  return (
    <UserRolesContainer isAccessRequestView={isAccessRequestView} isTeamDialog={isTeamDialog}>
      <DialogContentContainer isMobile={isMobile} isAccessRequestView={isAccessRequestView}>
        {userRoles ? (
          <>
            <RolesWrapper>
              {roleGroups?.map((roleGroup) => getRoleGroup(roleGroup))}

              {hasEditRights && saveChanges ? (
                <>
                  <ButtonGroup>
                    <StyledButton
                      text={t("Save")}
                      buttonType="primary"
                      sizeClass="medium"
                      onClick={() => saveChanges(false)}
                      disabled={!isSaveEnabled}
                      isLoading={isLoading}
                    />
                    <StyledButton
                      text={t("Close")}
                      buttonType="secondary"
                      sizeClass="medium"
                      onClick={() => setIsOpen(false)}
                    />
                  </ButtonGroup>
                  <AuthorizedComponent accessRights={[AccessRights.SystemAdmin]}>
                    <ButtonGroup>
                      <StyledAutoWidthButton
                        text={t("Save without notification")}
                        buttonType="primary"
                        sizeClass="medium"
                        onClick={() => saveChanges(true)}
                        disabled={!isSaveEnabled}
                        isLoading={isLoading}
                      />
                    </ButtonGroup>
                  </AuthorizedComponent>
                </>
              ) : null}
            </RolesWrapper>
            <div style={{ width: "500px" }}>
              <Table
                sizeClass={"small"}
                solidBackground={true}
                borderType={"none"}
                zebra={false}
                fitToContent={true}
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "75%" }}>{t("Access right")}</th>
                    <th style={{ textAlign: "right" }}>{t("Role")}</th>
                  </tr>
                </thead>
                <tbody>
                  {accessRights?.map((x) =>
                    x.accessRights.map((accessRight) => {
                      const activeRoles = getActiveRoles(
                        userRoles,
                        accessRight,
                        roleSelectionType,
                        isInternal,
                        roleGroups,
                        teamType,
                        showDefaultRolesAsSelected,
                      );
                      if (activeRoles) {
                        return (
                          <tr key={accessRight.accessRight?.id}>
                            <td>{accessRight.accessRight?.friendlyName}</td>
                            <td style={{ textAlign: "right" }}>{activeRoles}</td>
                          </tr>
                        );
                      }
                      return null;
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </>
        ) : null}
      </DialogContentContainer>
      {canViewUserPriceLists && isAccessRequestView ? (
        <PriceListsContainer isMobile={isMobile} isAccessRequestView={isAccessRequestView}>
          <UserPriceListsGrid
            userPriceList={userPriceLists}
            updateUserDisabledManagedPriceList={updateUserDisabledManagedPriceList}
            isAccessRequestView={isAccessRequestView}
          />
        </PriceListsContainer>
      ) : null}
    </UserRolesContainer>
  );
};

import { Icon, Menu, MenuItem, MenuItemProps, Popup, WithPopup } from "@abb/abb-common-ux-react";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import styled from "styled-components";

const ActionsRendererContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const ActionsPopupContainer = styled(Menu)<{rightPosition?: string}>`
  right: ${(props) => props.rightPosition || '-10px'} !important;
  top: -10px !important;
  .ABB_CommonUX_Menu__menuItemTitle {
    margin-right: 16px;
  }
  a {
    text-decoration: none;
  }
`;

export interface GridActionsRendererParams extends ICellRendererParams {
  menuItems: GridActionsMenuItemProps[];
  target?: string;
  rightPosition?: string;
}
export interface GridActionsMenuItemProps extends MenuItemProps {
  hide?: boolean;
}

export const GridActionsRenderer = (props: GridActionsRendererParams) => {
  return (
    <WithPopup>
      <div>
        <ActionsRendererContainer
          style={{
            height: props.node.rowHeight + "px"
          }}
        >
          <Icon name={"abb/menu"} sizeClass="small" />
        </ActionsRendererContainer>
      </div>
      <Popup trigger="click" position={["right center"]} disablePaddings disableDefaultStyle>
        <base target={props.target ?? "_blank"} />
        <ActionsPopupContainer isOpen={true} alignToParent="right" rightPosition={props.rightPosition}>
          {props.menuItems?.filter((x) => !x.hide).map((x) => <MenuItem {...x} key={x.itemId} />)}
        </ActionsPopupContainer>
      </Popup>
    </WithPopup>
  );
};

import { AppState } from "../../../../setup/appRootReducer";
import { produce } from "immer";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import {
  ApiTeamsChangeMemberActivityPutRequest,
  ApiTeamsChangeMemberRolePutRequest,
  ApiTeamsChangeMemberStatusPutRequest,
  ApiTeamsChangeMemberTeamInUsePutRequest,
  ApiTeamsGetRequest,
  ApiTeamsMembersAcceptTeamInvitationPostRequest,
  ApiTeamsMembersGetRequest,
  ApiTeamsMembersInvitePostRequest,
  ApiTeamsMembersRequestTeamAccessPostRequest,
  ApiTeamsMembersRolesUserIdGetRequest,
  ApiTeamsPostRequest,
  ApiTeamsUserTeamsGetRequest,
  TeamAccessRequestDto,
  TeamDto,
  TeamInvitationDto,
  TeamMemberDto,
  TeamsVm,
  UserTeamDto,
  UserTeamRolesDto
} from "../../../../api";
import { ManageTeamsViewActions, ManageTeamsViewActionTypes } from "../actions/manageTeamsViewActions";
import { manageTeamsSagas } from "../sagas/manageTeamsSagas";

interface Requests {
  getTeams: RequestState<TeamsVm, ApiTeamsGetRequest>;
  getTeam: RequestState<TeamDto, ApiTeamsMembersGetRequest>;
  getUserTeams: RequestState<Array<UserTeamDto>, ApiTeamsUserTeamsGetRequest>;
  getUserTeamRoles: RequestState<Array<UserTeamRolesDto>, ApiTeamsMembersRolesUserIdGetRequest>;
  changeTeamMemberStatus: RequestState<TeamMemberDto, ApiTeamsChangeMemberStatusPutRequest>;
  changeTeamMemberRole: RequestState<TeamMemberDto, ApiTeamsChangeMemberRolePutRequest>;
  changeTeamMemberActivity: RequestState<TeamMemberDto, ApiTeamsChangeMemberActivityPutRequest>;
  changeTeamMemberTeamInUse: RequestState<TeamMemberDto, ApiTeamsChangeMemberTeamInUsePutRequest>;
  createTeam: RequestState<TeamDto, ApiTeamsPostRequest>;
  inviteUserToTeam: RequestState<TeamInvitationDto, ApiTeamsMembersInvitePostRequest>;
  acceptTeamInvitation: RequestState<TeamMemberDto, ApiTeamsMembersAcceptTeamInvitationPostRequest>;
  requestTeamAccess: RequestState<TeamAccessRequestDto, ApiTeamsMembersRequestTeamAccessPostRequest>;
}

export interface ManageTeamsViewState {
  manageTeamsCriteria: ApiTeamsGetRequest;
  requests: Requests;
  teams: TeamsVm | undefined;
  team: TeamDto | undefined;
  userTeams: UserTeamDto[] | undefined;
  userTeamRoles: UserTeamRolesDto[] | undefined;
}

export const defaultTeamsCriteria: ApiTeamsGetRequest = {
  criteriaIsAscendingOrder: true,
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaSortColumn: "name"
};

export const defaultTeamMembersCriteria: ApiTeamsMembersGetRequest = {
  criteriaIsAscendingOrder: true,
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaSortColumn: "lastName"
};

const defaultState: ManageTeamsViewState = {
  manageTeamsCriteria: defaultTeamsCriteria,
  requests: {
    getTeams: createInitialRequest(),
    getTeam: createInitialRequest(),
    getUserTeams: createInitialRequest(),
    getUserTeamRoles: createInitialRequest(),
    changeTeamMemberStatus: createInitialRequest(),
    changeTeamMemberRole: createInitialRequest(),
    changeTeamMemberActivity: createInitialRequest(),
    changeTeamMemberTeamInUse: createInitialRequest(),
    createTeam: createInitialRequest(),
    inviteUserToTeam: createInitialRequest(),
    requestTeamAccess: createInitialRequest(),
    acceptTeamInvitation: createInitialRequest(),
  },
  teams: undefined,
  team: undefined,
  userTeams: undefined,
  userTeamRoles: undefined
};

export function manageTeamsViewReducer(
  state: ManageTeamsViewState = defaultState,
  action: ManageTeamsViewActions
): ManageTeamsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageTeamsSagas.getTeams.actionTypes,
      key: "getTeams"
    },
    {
      actionTypes: manageTeamsSagas.getTeam.actionTypes,
      key: "getTeam"
    },
    {
      actionTypes: manageTeamsSagas.getUserTeams.actionTypes,
      key: "getUserTeams"
    },
    {
      actionTypes: manageTeamsSagas.getUserTeamRoles.actionTypes,
      key: "getUserTeamRoles"
    },
    {
      actionTypes: manageTeamsSagas.changeTeamMemberStatus.actionTypes,
      key: "changeTeamMemberStatus"
    },
    {
      actionTypes: manageTeamsSagas.changeTeamMemberRole.actionTypes,
      key: "changeTeamMemberRole"
    },
    {
      actionTypes: manageTeamsSagas.changeTeamMemberActivity.actionTypes,
      key: "changeTeamMemberActivity"
    },
    {
      actionTypes: manageTeamsSagas.changeTeamMemberTeamInUse.actionTypes,
      key: "changeTeamMemberTeamInUse"
    },
    {
      actionTypes: manageTeamsSagas.createTeam.actionTypes,
      key: "createTeam"
    },
    {
      actionTypes: manageTeamsSagas.inviteUserToTeam.actionTypes,
      key: "inviteUserToTeam"
    },
    {
      actionTypes: manageTeamsSagas.requestTeamAccess.actionTypes,
      key: "requestTeamAccess"
    },
    {
      actionTypes: manageTeamsSagas.acceptTeamInvitation.actionTypes,
      key: "acceptTeamInvitation"
    },
  ]);

  if (manageTeamsSagas.getTeams.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.teams = action.payload;
    });
  } else if (manageTeamsSagas.getTeam.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.team = action.payload;
    });
  } else if (manageTeamsSagas.getUserTeams.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userTeams = action.payload;
    });
  } else if (manageTeamsSagas.getUserTeamRoles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userTeamRoles = action.payload;
    });
  }

  switch (action.type) {
    case ManageTeamsViewActionTypes.ChangeManageTeamsCriteria:
      state = produce(state, (draft) => {
        draft.manageTeamsCriteria = action.criteria;
      });
      break;
  }
  return state;
}

export const getUserAccessibleTeams = (state: AppState): UserTeamDto[] | undefined =>
  state.manageTeamsView.userTeams;

export const getUserTeamRoles = (state: AppState): UserTeamRolesDto[] | undefined =>
  state.manageTeamsView.userTeamRoles;

export const getManageTeamsCriteria = (state: AppState): ApiTeamsGetRequest =>
  state.manageTeamsView.manageTeamsCriteria;

export const getManageTeams = (state: AppState): TeamsVm | undefined =>
  state.manageTeamsView.teams;

export const getManageTeam = (state: AppState): TeamDto | undefined =>
  state.manageTeamsView.team;

export const getChangeMemberStatusRequest = (state: AppState): RequestState<TeamMemberDto, ApiTeamsChangeMemberStatusPutRequest> =>
  state.manageTeamsView.requests.changeTeamMemberStatus;

export const getChangeMemberRoleRequest = (state: AppState): RequestState<TeamMemberDto, ApiTeamsChangeMemberRolePutRequest> =>
  state.manageTeamsView.requests.changeTeamMemberRole;

export const GetChangeMemberActivityRequest = (state: AppState): RequestState<TeamMemberDto, ApiTeamsChangeMemberActivityPutRequest> =>
  state.manageTeamsView.requests.changeTeamMemberActivity;

export const GetChangeMemberTeamInUseRequest = (state: AppState): RequestState<TeamMemberDto, ApiTeamsChangeMemberTeamInUsePutRequest> =>
  state.manageTeamsView.requests.changeTeamMemberTeamInUse;

export const getCreateTeamRequest = (state: AppState): RequestState<TeamDto, ApiTeamsPostRequest> =>
  state.manageTeamsView.requests.createTeam;

export const getInviteUserToTeamRequest = (state: AppState): RequestState<TeamInvitationDto, ApiTeamsMembersInvitePostRequest> =>
  state.manageTeamsView.requests.inviteUserToTeam;

export const getRequestTeamAccessRequest = (state: AppState): RequestState<TeamAccessRequestDto, ApiTeamsMembersRequestTeamAccessPostRequest> =>
  state.manageTeamsView.requests.requestTeamAccess;

export const getAcceptTeamInvitationRequest = (state: AppState): RequestState<TeamMemberDto, ApiTeamsMembersAcceptTeamInvitationPostRequest> =>
  state.manageTeamsView.requests.acceptTeamInvitation;

export const getUserTeamsRequest = (state: AppState): RequestState<Array<UserTeamDto>, ApiTeamsUserTeamsGetRequest> =>
  state.manageTeamsView.requests.getUserTeams;

export const getGetUserTeamRolesRequest = (state: AppState): RequestState<Array<UserTeamRolesDto>, ApiTeamsMembersRolesUserIdGetRequest> =>
  state.manageTeamsView.requests.getUserTeamRoles;
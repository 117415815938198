/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeamMemberStatusDto {
    Requested = 'Requested',
    Rejected = 'Rejected',
    Approved = 'Approved',
    Removed = 'Removed',
    Invited = 'Invited',
    RoleChangeRequested = 'RoleChangeRequested'
}

export function TeamMemberStatusDtoFromJSON(json: any): TeamMemberStatusDto {
    return TeamMemberStatusDtoFromJSONTyped(json, false);
}

export function TeamMemberStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberStatusDto {
    return json as TeamMemberStatusDto;
}

export function TeamMemberStatusDtoToJSON(value?: TeamMemberStatusDto | null): any {
    return value as any;
}


import { userActions } from "applications/common/actions/userActions";
import {
  getIsPrivacyConsentDialogOpen,
  getPrivacyConsentRequest,
  getUser
} from "applications/common/reducers/userReducer";
import { consentSagas } from "applications/common/sagas/consentSagas";
import { getGeneralApplicationSettings } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import { RequestStatus } from "framework/state/requestStatus";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "../../framework/components/Button";
import { Dialog } from "../../framework/components/Dialog";
import { HorizontalElementContainer } from "../../framework/components/HorizontalElementContainer";
import { UserNameForm } from "./UserNameForm";
import { Icon } from "@abb/abb-common-ux-react";

export const privacyConsentDeclinedLocalStorageIdentifier = "privacyConsentDeclined";

const FormRow = styled.div`
  margin-block: ${(props) => props.theme.sizes.sm};
`;

const FormContainer = styled.div`
  margin-block: ${(props) => props.theme.sizes.sm};
  display: flex;
  flex-direction: column;
  max-width: 600px;
  textarea {
    min-height: 200px !important;
    min-width: 400px !important;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.sizes.l};
  gap: ${(props) => props.theme.sizes.s};
`;

const Bold = styled.b`
  display: inline-block;
  text-align: left;
`;

export const PrivacyConsentDialog: FC = () => {
  const dialogIsOpen = useSelector(getIsPrivacyConsentDialogOpen);
  const privacyConsentRequestStatus = useSelector(getPrivacyConsentRequest);
  const applicationSettings = useSelector(getGeneralApplicationSettings);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    localStorage.setItem(privacyConsentDeclinedLocalStorageIdentifier, "true");
    dispatch(userActions.togglePrivacyConsentDialogVisibility(false));
  };

  const onAgree = () => {
    dispatch(
      consentSagas.privacyConsent.createAction({
        handlePrivacyConsentCommand: { consentGiven: true }
      })
    );
  };

  return (
    <Dialog
      title="Create a user account"
      isOpen={dialogIsOpen}
      onClose={onClose}
      closeOnLostFocus={false}
      contentClassName={"dialog-content overflow-auto"}
      showCloseButton={false}
      closeOnEscape={false}
    >
      <FormContainer>
        <FormRow>
          {t(`Create a user account in ${applicationSettings.applicationName}? To request access to a company,
          you must have a user account. Your log in information will be saved in the application database and used to identify you.`)}
        </FormRow>
        {user.formerUser ? (
          <>
            <MessageContainer>
              <Icon name="abb/warning-circle-1" sizeClass="large" color="color-status-warning" />
              <FormRow>
                {t(
                  "We have found an account with the same email address. It will be automatically deactivated with your consent."
                )}
                <br />
                <Bold>{`${user.formerUser.email} (${user.formerUser.firstName} ${user.formerUser.lastName})`}</Bold>
              </FormRow>
            </MessageContainer>
          </>
        ) : null}
        {user && (!user.firstName || !user.lastName) ? (
          <>
            <FormRow>
              {t("Could not find names automatically. Please enter your details below.")}
            </FormRow>

            <FormRow>
              <UserNameForm onClose={onClose} />
            </FormRow>
          </>
        ) : (
          <FormRow style={{ marginLeft: "auto" }}>
            <HorizontalElementContainer>
              <Button
                text={t("Decline")}
                sizeClass="medium"
                buttonType="secondary"
                isLoading={false}
                onClick={onClose}
              />
              <Button
                text={t("Accept")}
                sizeClass="medium"
                buttonType="primary"
                isLoading={privacyConsentRequestStatus.status === RequestStatus.Pending}
                onClick={onAgree}
              />
            </HorizontalElementContainer>
          </FormRow>
        )}
      </FormContainer>
    </Dialog>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamTypes,
    TeamTypesFromJSON,
    TeamTypesFromJSONTyped,
    TeamTypesToJSON,
} from './TeamTypes';

/**
 * 
 * @export
 * @interface CreateTeamCommand
 */
export interface CreateTeamCommand {
    /**
     * 
     * @type {TeamTypes}
     * @memberof CreateTeamCommand
     */
    teamType: TeamTypes;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamCommand
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamCommand
     */
    parentTeamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamCommand
     */
    firstUserId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTeamCommand
     */
    firstUserRoles?: Array<number> | null;
}

export function CreateTeamCommandFromJSON(json: any): CreateTeamCommand {
    return CreateTeamCommandFromJSONTyped(json, false);
}

export function CreateTeamCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTeamCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamType': TeamTypesFromJSON(json['teamType']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parentTeamId': !exists(json, 'parentTeamId') ? undefined : json['parentTeamId'],
        'firstUserId': !exists(json, 'firstUserId') ? undefined : json['firstUserId'],
        'firstUserRoles': !exists(json, 'firstUserRoles') ? undefined : json['firstUserRoles'],
    };
}

export function CreateTeamCommandToJSON(value?: CreateTeamCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamType': TeamTypesToJSON(value.teamType),
        'name': value.name,
        'description': value.description,
        'parentTeamId': value.parentTeamId,
        'firstUserId': value.firstUserId,
        'firstUserRoles': value.firstUserRoles,
    };
}


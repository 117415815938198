import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Button as AbbButton,
  CustomValidationResult,
  WithTooltip,
  Tooltip
} from "@abb/abb-common-ux-react";
import styled, { useTheme } from "styled-components";
import { TFunction } from "i18next";
import { Button } from "framework/components/Button";
import { Dispatch } from "redux";
import { quickPriceCheckSagas } from "../../quickPriceCheck/sagas/quickPriceCheckSagas";
import {
  ApiPricesPriceListPostRequest,
  AddOrUpdateShoppingCartItemsCommand,
  CreateShoppingCartCommand,
  ApiPricesPostRequest,
  ShoppingCartDto
} from "api";
import { useSelector } from "react-redux";
import {
  getPriceProductVm,
  getAddOrUpdateItemsInShoppingCartStatus,
  isSingleProductLoading,
  getSoftwareProductRedirects
} from "../../common/reducers/shoppingCartReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { shoppingCartSagas } from "../../common/sagas/shoppingCartSagas";
import { shoppingCartActions } from "../../common/actions/shoppingCartActions";
import { usePrevious } from "../../../framework/hooks/usePrevious";
import { routes } from "../../../utilities/routes";
import { handlePressEnter } from "../../../utilities/formikUtils";
import { getApiRegistry } from "framework/state/apiRegistry";
import { userActions } from "applications/common/actions/userActions";
import { apiGridErrorHandler } from "utilities/errorUtils";
import { Dialog } from "framework/components/Dialog";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { RedirectDialog } from "framework/components/RedirectDialog";
import { getConfiguratorBaseUrl } from "applications/common/reducers/commonReducer";
import { getConfiguratorLink } from "utilities/urlUtils";
import { infoTypeConstants } from "applications/manage/manageQuotes/containers/QuoteView";

const Container = styled.div`
  width: 100%;
  min-height: 90px;
`;

const FlexContainer = styled.div`
  overflow-wrap: break-word;
  max-width: 240px;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  max-width: 80px;
  max-height: 70px;
  width: auto;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
`;

const BoldText = styled.p`
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold};
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  margin-bottom: ${(props) => props.theme.sizes.xs};
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 25px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.sm};
  min-height: 80px;
`;

const ProductWrapper = styled.div`
  display: flex;
  min-height: 50px;
  height: 100%;
  align-items: flex-start;
  justify-content: space-around;
  gap: ${(props) => props.theme.sizes.lm};
  margin-block: ${(props) => props.theme.sizes.sm};
`;

const Label = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  color: ${(props) => props.theme.colors.grey50};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  text-transform: uppercase;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey20};
`;

// HorizontalElementContainer doesn't work inside the dialog for some reason(?)
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.sm};
  margin-block: ${(props) => props.theme.sizes.m};
`;

const CustomInput = styled(Input)`
  width: 100%;
  max-width: 280px;
`;

const TextArea = styled(Input)`
  margin-top: ${(props) => props.theme.sizes.lm};
  textarea {
    resize: none;
  }
`;

const DividerHeader = styled.p`
  margin: 0;
  text-align: center;
  margin-bottom: ${(props) => props.theme.sizes.lm};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const BottomButtonContainer = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.lm};
  a {
    :hover {
      text-decoration: none;
    }
  }
`;

interface AddProductDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  shoppingCart: ShoppingCartDto | undefined;
  dispatch: Dispatch;
  hasUser: boolean;
  reviseMode: boolean;
  isQuoteView: boolean;
}

export const AddProductDialog = ({
  isOpen,
  t,
  setIsOpen,
  dispatch,
  shoppingCart,
  hasUser,
  reviseMode,
  isQuoteView
}: AddProductDialogProps): JSX.Element => {
  const [orderingCode, setOrderingCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const priceProductVm = useSelector(getPriceProductVm);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<CustomValidationResult>({
    valid: false,
    text: undefined
  });
  const [shoppingCartLoading, setShoppingCartLoading] = useState(false);
  const [redirectingToProductConfigurator, setRedirectingToProductConfigurator] =
    useState<boolean>(false);

  const isProductLoading = useSelector(isSingleProductLoading);
  const addOrUpdateProductLoading = useSelector(getAddOrUpdateItemsInShoppingCartStatus);
  const prevAddOrUpdateProductLoading = usePrevious(addOrUpdateProductLoading);

  const userInformation = useSelector(getUserInformation);

  const configuratorBaseUrl = useSelector(getConfiguratorBaseUrl);
  const softwareProductRedirects = useSelector(getSoftwareProductRedirects);

  const priceProduct = priceProductVm?.products[0];

  const searchForProduct = () => {
    if (shoppingCart?.managedPriceListId ?? userInformation?.selectedManagedPriceListId) {
      const request: ApiPricesPriceListPostRequest = {
        id:
          shoppingCart?.managedPriceListId ??
          userInformation?.selectedManagedPriceListId ??
          undefined,
        pricesRequest: {
          orderingCodes: [orderingCode]
        }
      };
      dispatch(quickPriceCheckSagas.getSinglePriceProductFromOrderingCode.createAction(request));
    } else {
      const request: ApiPricesPostRequest = {
        orderingCodes: [orderingCode]
      };
      dispatch(
        quickPriceCheckSagas.getSinglePriceProductFromOrderingCodeWithoutPriceListId.createAction(
          request
        )
      );
    }
  };

  useEffect(() => {
    if (
      addOrUpdateProductLoading === RequestStatus.Completed &&
      prevAddOrUpdateProductLoading === RequestStatus.Pending
    ) {
      dispatch(shoppingCartActions.setPriceProductVm(undefined));
      setIsOpen(false);
    }
  }, [addOrUpdateProductLoading, setIsOpen, prevAddOrUpdateProductLoading, dispatch]);

  const theme = useTheme();

  const addToCart = async () => {
    if (reviseMode) {
      const existedItem = shoppingCart?.shoppingCartItems.find(
        (i) => i.orderingCode === orderingCode
      );
      if (existedItem) {
        dispatch(
          shoppingCartActions.updateLineItem(
            orderingCode,
            infoTypeConstants.quantity,
            undefined,
            undefined,
            isQuoteView,
            existedItem.quantity + 1,
            description
          )
        );
      } else {
        dispatch(shoppingCartActions.addLineItem(priceProduct, isQuoteView, description));
      }
      setIsOpen(false);
    } else {
      let selectedShoppingCartGuid = shoppingCart?.guid;
      if (
        !selectedShoppingCartGuid &&
        ((shoppingCart?.managedPriceListId || priceProductVm?.priceListId) ??
          userInformation?.selectedManagedPriceListId) &&
        hasUser
      ) {
        const createShoppingCartCommand: CreateShoppingCartCommand = {
          managedPriceListId:
            shoppingCart?.managedPriceListId ??
            priceProductVm?.priceListId ??
            userInformation?.selectedManagedPriceListId
        };
        try {
          setShoppingCartLoading(true);
          const { guid } = await getApiRegistry().shoppingCartsApi.apiShoppingCartsPost({
            createShoppingCartCommand
          });

          await getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidGet({
            guid: guid
          });

          selectedShoppingCartGuid = guid;
          dispatch(userActions.updateSelectedShoppingCartGuid(selectedShoppingCartGuid));
          setShoppingCartLoading(false);
        } catch (e) {
          setShoppingCartLoading(false);
          apiGridErrorHandler(e, dispatch);
        }
      }

      if (selectedShoppingCartGuid) {
        const addOrUpdateShoppingCartItemsCommand: AddOrUpdateShoppingCartItemsCommand = {
          shoppingCartGuid: selectedShoppingCartGuid,
          isAddToQuantity: true,
          shoppingCartItems: [
            {
              quantity: 1,
              orderingCode: priceProduct?.orderingCode ?? orderingCode,
              additionalInformation: description
            }
          ]
        };

        dispatch(
          shoppingCartSagas.addOrUpdateItemsInShoppingCart.createAction({
            addOrUpdateShoppingCartItemsCommand
          })
        );
      }
    }
  };

  useEffect(() => {
    if (priceProduct) {
      if (priceProduct.isSoftwareProduct) {
        setRedirectingToProductConfigurator(true);
      } else {
        const item = shoppingCart?.shoppingCartItems?.find(
          (x) => x.orderingCode === priceProduct.orderingCode
        );
        if (item) {
          setDescription(item.additionalInformation ?? "");
        }
        setValidationResult({
          valid: priceProduct.validationResult,
          text: !priceProduct.validationResult ? t("No product found") : undefined
        });
        setShowValidation(true);
      }
    }
  }, [priceProduct, t, shoppingCart]);

  const isAddToCartDisabled = () => {
    if (priceProduct?.modificationCaseId) {
      return true;
    }

    if (!priceProduct?.validationResult) {
      return true;
    }

    if (priceProduct?.isAddToCartHidden === true) {
      return true;
    }

    if (shoppingCart?.managedPriceListId || priceProductVm?.priceListId) {
      return false;
    }

    return true;
  };

  const hidePrices =
    !(shoppingCart?.managedPriceListId ?? userInformation?.selectedManagedPriceListId) ||
    userInformation?.demoMode;

  const softwareProductOrderingCode = useMemo(
    () => priceProduct?.orderingCode ?? orderingCode,
    [orderingCode, priceProduct]
  );

  return (
    <Dialog
      contentClassName="dialog-content overflow-auto"
      title={t("Add a product by ordering code")}
      isOpen={true}
      onClose={() => {
        // Reset state after closing
        dispatch(shoppingCartActions.setPriceProductVm(undefined));
        setIsOpen(!isOpen);
      }}
    >
      {redirectingToProductConfigurator ? (
        <RedirectDialog
          showDialog={true}
          text={""}
          t={t}
          redirectCallback={() => {
            window.location.href = getConfiguratorLink(
              configuratorBaseUrl,
              softwareProductRedirects[softwareProductOrderingCode] ?? softwareProductOrderingCode,
              false
            );
          }}
          countdownFrom={5}
          title={t("Redirecting to Product configurator...")}
          hideBackground={false}
        />
      ) : null}
      <Container>
        <FlexRow>
          <CustomInput
            dataType="text"
            value={orderingCode}
            onValueChange={(v) => {
              setOrderingCode(v);
              setShowValidation(false);
            }}
            label={t("Enter/paste ordering code")}
            showValidationBarWhenInvalid={showValidation}
            showValidationIconWhenInvalid={showValidation}
            showValidationBarWhenValid={showValidation}
            instantValidation
            validationResult={validationResult}
            showClearIcon
            onKeyUp={(e) =>
              handlePressEnter(e, () => {
                searchForProduct();
              })
            }
          />
          <Button
            buttonType="secondary"
            text={t("Search")}
            style={{ marginTop: theme.sizes.lm }}
            disabled={orderingCode.length <= 1}
            isLoading={isProductLoading}
            sizeClass="small"
            onClick={() => searchForProduct()}
          />
        </FlexRow>
        <>
          {!reviseMode && (
            <>
              <DividerHeader>{t("OR")}</DividerHeader>
              <BottomButtonContainer>
                <Button
                  buttonType={"secondary"}
                  icon={"abb/search"}
                  text={t("Find products in product configurator")}
                  href={routes.products.productSelectionMatrix}
                />
              </BottomButtonContainer>
            </>
          )}
        </>
        {priceProduct?.validationResult ? (
          <>
            <Divider />
            <ProductWrapper>
              <Image src={priceProduct?.imageUrl ?? ""} />
              <FlexContainer>
                <Label>{t("Product")}</Label>
                <BoldText>
                  {priceProduct?.category === "static"
                    ? priceProduct?.orderingCode
                    : priceProduct?.displayName}
                </BoldText>
                <Text>{priceProduct?.orderingCode}</Text>
              </FlexContainer>
              <FlexContainer style={{ minWidth: 130 }}>
                <Label style={{ textAlign: "right" }}>{t("Unit Price")}</Label>
                <BoldText style={{ textAlign: "right" }}>
                  {hidePrices ? t("(Hidden)") : priceProduct.displayPrice}
                </BoldText>
              </FlexContainer>
            </ProductWrapper>
            <TextArea
              value={description}
              onValueChange={(v) => setDescription(v)}
              dataType="textarea"
              label={t("Additional information regarding the product")}
              inputAttributes={{
                rows: "4"
              }}
            />
            <ButtonContainer>
              <AbbButton
                type="discreet-black"
                text={t("Cancel")}
                shape="pill"
                onClick={() => {
                  dispatch(shoppingCartActions.setPriceProductVm(undefined));
                  setIsOpen((open) => !open);
                }}
              />
              <WithTooltip>
                <Button
                  icon="abb/cart"
                  buttonType="primary"
                  text={t("Confirm and add to cart")}
                  disabled={isAddToCartDisabled() || !hasUser}
                  isLoading={
                    shoppingCartLoading || addOrUpdateProductLoading === RequestStatus.Pending
                  }
                  onClick={() => addToCart()}
                />
                <Tooltip disabled={hasUser && !priceProduct?.modificationCaseId}>
                  {!hasUser ? t("Request access to a company to add products to cart") : null}
                  {priceProduct?.modificationCaseId
                    ? t(
                        "Cannot add item to shopping cart because the modification is already in another shopping cart. Please create a new modification in the product configurator"
                      )
                    : null}
                </Tooltip>
              </WithTooltip>
            </ButtonContainer>
          </>
        ) : null}
      </Container>
    </Dialog>
  );
};

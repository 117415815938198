/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectorApplicationFeatureProductValueDto,
    SelectorApplicationFeatureProductValueDtoFromJSON,
    SelectorApplicationFeatureProductValueDtoFromJSONTyped,
    SelectorApplicationFeatureProductValueDtoToJSON,
} from './SelectorApplicationFeatureProductValueDto';
import {
    SelectorProductHighlightDto,
    SelectorProductHighlightDtoFromJSON,
    SelectorProductHighlightDtoFromJSONTyped,
    SelectorProductHighlightDtoToJSON,
} from './SelectorProductHighlightDto';

/**
 * 
 * @export
 * @interface SelectorProductDto
 */
export interface SelectorProductDto {
    /**
     * 
     * @type {number}
     * @memberof SelectorProductDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<SelectorApplicationFeatureProductValueDto>}
     * @memberof SelectorProductDto
     */
    selectorApplicationFeatureProductValues?: Array<SelectorApplicationFeatureProductValueDto> | null;
    /**
     * 
     * @type {Array<SelectorProductHighlightDto>}
     * @memberof SelectorProductDto
     */
    selectorProductHighlights?: Array<SelectorProductHighlightDto> | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    previewImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    presentationImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    pageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    referenceCaseUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    shortText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    longText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SelectorProductDto
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectorProductDto
     */
    isSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    latestMaterialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorProductDto
     */
    cidCode?: string | null;
}

export function SelectorProductDtoFromJSON(json: any): SelectorProductDto {
    return SelectorProductDtoFromJSONTyped(json, false);
}

export function SelectorProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'selectorApplicationFeatureProductValues': !exists(json, 'selectorApplicationFeatureProductValues') ? undefined : (json['selectorApplicationFeatureProductValues'] === null ? null : (json['selectorApplicationFeatureProductValues'] as Array<any>).map(SelectorApplicationFeatureProductValueDtoFromJSON)),
        'selectorProductHighlights': !exists(json, 'selectorProductHighlights') ? undefined : (json['selectorProductHighlights'] === null ? null : (json['selectorProductHighlights'] as Array<any>).map(SelectorProductHighlightDtoFromJSON)),
        'previewImageUrl': !exists(json, 'previewImageUrl') ? undefined : json['previewImageUrl'],
        'presentationImageUrl': !exists(json, 'presentationImageUrl') ? undefined : json['presentationImageUrl'],
        'pageUrl': !exists(json, 'pageUrl') ? undefined : json['pageUrl'],
        'referenceCaseUrl': !exists(json, 'referenceCaseUrl') ? undefined : json['referenceCaseUrl'],
        'shortText': !exists(json, 'shortText') ? undefined : json['shortText'],
        'longText': !exists(json, 'longText') ? undefined : json['longText'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isSelected': !exists(json, 'isSelected') ? undefined : json['isSelected'],
        'latestMaterialCode': !exists(json, 'latestMaterialCode') ? undefined : json['latestMaterialCode'],
        'cidCode': !exists(json, 'cidCode') ? undefined : json['cidCode'],
    };
}

export function SelectorProductDtoToJSON(value?: SelectorProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'selectorApplicationFeatureProductValues': value.selectorApplicationFeatureProductValues === undefined ? undefined : (value.selectorApplicationFeatureProductValues === null ? null : (value.selectorApplicationFeatureProductValues as Array<any>).map(SelectorApplicationFeatureProductValueDtoToJSON)),
        'selectorProductHighlights': value.selectorProductHighlights === undefined ? undefined : (value.selectorProductHighlights === null ? null : (value.selectorProductHighlights as Array<any>).map(SelectorProductHighlightDtoToJSON)),
        'previewImageUrl': value.previewImageUrl,
        'presentationImageUrl': value.presentationImageUrl,
        'pageUrl': value.pageUrl,
        'referenceCaseUrl': value.referenceCaseUrl,
        'shortText': value.shortText,
        'longText': value.longText,
        'sortOrder': value.sortOrder,
        'isSelected': value.isSelected,
        'latestMaterialCode': value.latestMaterialCode,
        'cidCode': value.cidCode,
    };
}


import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StartStep from "./components/StartStep";
import SegmentStep from "./components/SegmentStep";
import { AppContentWrapper } from "framework/components/AppContentWrapper";
import ApplicationStep from "./components/ApplicationStep";
import { FooterNavigationButtons } from "./components/common/FooterNavigationButtons";
import FeaturesStep from "./components/FeaturesStep";
import ComparisonStep from "./components/ComparisonStep";
import RecommendedStep from "./components/RecommendedStep";
import Stepper, { Step } from "framework/components/Stepper";
import { Stack } from "@mui/material";
import { Heading } from "@abb/abb-common-ux-react";
import {
  SelectorMainApplicationDto,
  SelectorProductDto,
  SelectorSegmentDto
} from "api";
import { getApiRegistry } from "framework/state/apiRegistry";
import { SubSegment } from "./components/common/SubSegment";
import { MainApplication } from "./components/common/MainApplication";
import { SelectorApplicationFeatureDto } from "api";
import { commonUXTheme } from "styles/commonUXVariables";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { manageGlobalSettingsSagas } from "applications/manage/globalSettings/sagas/manageGlobalSettingsSagas";
import { SelectorSubSegmentDto } from "api";
import { getImageUrl } from "./components/common/ImageUrlHelper";

const CustomStepper = styled(Stepper)`
  @media print {
    display: none !important;
  }
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

/**
 * React component for CommonUX Headings with word wrapping
 * @returns React component
 */
const StyledHeading = styled(Heading)`
  white-space: break-spaces;
`;

interface UrlParameters {
  application?: number;
  step?: number;
  product?: number;
  features?: number[];
  segment?: number;
}

const SelectorView = (): JSX.Element => {
  const MainApplicationUrlKey = "application";
  const StepUrlKey = "step";
  const ProductUrlKey = "product";
  const FeaturesUrlKey = "features";
  const SegmentUrlKey = "segment";

  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();
  const [segments, setSegments] = useState<SelectorSegmentDto[]>([]);
  const [selectedSegment, setSelectedSegments] = useState<SelectorSegmentDto>();
  const [selectedSubSegment, setSelectedSubSegments] = useState<SelectorSubSegmentDto>();

  const [products, setProducts] = useState<SelectorProductDto[] | null>();
  const [subSegments, setSubSegments] = useState<SubSegment[]>([]);
  const [features, setFeatures] = useState<SelectorApplicationFeatureDto[]>([]);
  const [applications, setApplications] = useState<MainApplication[]>([]);
  const [application, setApplication] = useState<MainApplication>();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [selectedProductId, setSelectedProductId] = useState<number>();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const paramsFromUrl = useMemo(() => {
    const features = searchParams.get(FeaturesUrlKey)
      ? searchParams.get(FeaturesUrlKey)?.split(",").map(Number)
      : undefined;

    return {
      application: Number(searchParams.get(MainApplicationUrlKey)),
      step: Number(searchParams.get(StepUrlKey)),
      features,
      product: Number(searchParams.get(ProductUrlKey)),
      segment: Number(searchParams.get(SegmentUrlKey))
    } as UrlParameters;

    // eslint-disable-next-line
  }, []); // do not change the state when url is changed, only done for initial page load

  const selectedProduct = useMemo(() => {
    return products?.find((p) => p.id == selectedProductId);
  }, [selectedProductId, products]);

  const defaultFeature = useMemo(() => {
    return features?.find((f) => f._default);
  }, [features]);

  const setUrlSearchParams = useCallback(
    (updatedSearchParams: URLSearchParams) => {
      setSearchParams(updatedSearchParams.toString());
    },
    [setSearchParams]
  );

  const setUrlSearchParameter = useCallback(
    (key: string, value?: string) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set(key, value ?? "");
      setUrlSearchParams(updatedSearchParams);
    },
    [searchParams, setUrlSearchParams]
  );

  const createUrlSearchParams = useCallback(
    (
      step?: string,
      segment?: string,
      application?: string,
      features?: string,
      product?: string
    ) => {
      return new URLSearchParams({
        [StepUrlKey]: step ?? "",
        [SegmentUrlKey]: segment ?? "",
        [MainApplicationUrlKey]: application ?? "",
        [FeaturesUrlKey]: features ?? "",
        [ProductUrlKey]: product ?? ""
      });
    },
    []
  );

  const onApplicationSelected = useCallback(
    (application?: SelectorMainApplicationDto) => {
      setApplication(application);
      setFeatures([]);
      setSelectedProductId(undefined);
      setProducts([]);
      setUrlSearchParameter(MainApplicationUrlKey, application?.id?.toString() ?? "");
    },
    // eslint-disable-next-line
    [setUrlSearchParameter, searchParams.toString(), searchParams]
  );

  useEffect(() => {
    dispatch(manageGlobalSettingsSagas.getGlobalSettings.createAction({}));
  }, [dispatch]);

  const loadApplications = useCallback(
    () => {
      return getApiRegistry()
        .selectorApi.apiSelectorApplicationsGet({
          segmentId: selectedSegment?.id,
          subSegmentId: selectedSubSegment?.id
        })
        .then((applications) => {
          setApplications([...applications]);
          if (paramsFromUrl.application && isFirstLoad) {
            onApplicationSelected(applications.find((a) => a.id == paramsFromUrl.application));
            paramsFromUrl.application = undefined;
          }
          setAllApplicationPictogramsAndImages(applications);
        });
    },
    // eslint-disable-next-line
    [
      onApplicationSelected,
      paramsFromUrl.application,
      paramsFromUrl.segment,
      selectedSegment?.id,
      selectedSubSegment?.id
    ]
  );

  const onSegmentsLoaded = useCallback(
    (segments: SelectorSegmentDto[]) => {
      setSegments(segments);
      setSegmentPictograms(segments);
      if (paramsFromUrl.segment) {
        const selectedSegmentFromUrl = segments
          .flatMap((s) => s.selectorSubSegments)
          .find((sub) => sub?.id == paramsFromUrl.segment);

        if (selectedSegmentFromUrl) setSelectedSubSegments(selectedSegmentFromUrl);
      }

      if (paramsFromUrl.step) setActiveStep(paramsFromUrl.step);
    },
    [paramsFromUrl.segment, paramsFromUrl.step]
  );

  useEffect(() => {
    if (!segments.length) {
      getApiRegistry()
        .selectorApi.apiSelectorSegmentsGet()
        .then((segments) => {
          onSegmentsLoaded(segments);
        });
    }
  }, [segments, onSegmentsLoaded]);

  const loadProducts = (
    selectedApplication?: MainApplication,
    selectedFeatures?: SelectorApplicationFeatureDto[]
  ) => {
    if (selectedApplication && selectedFeatures?.length) {
      if (selectedFeatures.findIndex((f) => f.isSelected) >= 0 || defaultFeature?.id) {
        getApiRegistry()
          .selectorApi.apiSelectorProductsGet({
            mainApplicationId: selectedApplication.id,
            additionalFeatureIds: selectedFeatures
              ?.filter((f) => f.isSelected)
              .map((f) => f.id as number)
          })
          .then((response) => {
            setProducts(response);
          });
      }
    }
  };

  useEffect(() => {
    if (products && products.length) {
      let selected = products ? products[0].id : undefined;
      if (isFirstLoad && paramsFromUrl.product) {
        selected = paramsFromUrl.product;
        paramsFromUrl.product = undefined;
      }
      setSelectedProductId(selected);
      setUrlSearchParameter(ProductUrlKey, selected?.toString());
      setIsFirstLoad(false);
    }
    // eslint-disable-next-line
  }, [products, paramsFromUrl]);

  useEffect(() => {
    if (application) {
      getApiRegistry()
        .selectorApi.apiSelectorAdditionalFeaturesGet({
          mainApplicationId: application.id
        })
        .then((response) => {
          if (paramsFromUrl.features && paramsFromUrl.features.length) {
            response.forEach((f) => {
              f.isSelected =
                paramsFromUrl.features && paramsFromUrl.features?.indexOf(f.id as number) >= 0;
            });
          }

          setFeatures(response);
        });
    }
  }, [application, paramsFromUrl]); //,defaultFeature

  useEffect(() => {
    loadProducts(application, features);
    // eslint-disable-next-line
  }, [defaultFeature, features]);

  const setSegmentPictograms = (segments: SelectorSegmentDto[]) => {
    const allSubSegments: SubSegment[] = [];
    segments.forEach((s) => {
      s.selectorSubSegments?.forEach((sub) => {
        if (sub.pictogram) {
          allSubSegments.push({
            ...sub,
            pictogramUrl: getImageUrl(sub.pictogram, true),
            imageUrl: getImageUrl(sub.image)
          });
        }
      });
    });
    setSubSegments(allSubSegments);
  };

  const setAllApplicationPictogramsAndImages = (applications: MainApplication[]) => {
    applications.forEach((s) => {
      if (s.pictogram) s.pictogramUrl = getImageUrl(s.pictogram, true);

      if (s.image) s.imageUrl = getImageUrl(s.image);
    });

    setApplications(applications);
  };

  const onNextClick = () => {
    setActiveStep(activeStep + 1);
  };

  const onPrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const withFooter = (tab: JSX.Element, nextDisabled?: boolean) => {
    return (
      <>
        {tab}
        <FooterNavigationButtons
          onPrevClick={onPrevClick}
          step={activeStep}
          onNextClick={onNextClick}
          nextDisabled={nextDisabled}
          selectedProduct={selectedProduct}
        ></FooterNavigationButtons>
      </>
    );
  };

  const onStepChanged = (step: number) => {
    setActiveStep(step);
    setUrlSearchParameter(StepUrlKey, step.toString());
  };

  const onSegmentSelected = (segment?: SelectorSegmentDto, subSegment?: SelectorSubSegmentDto) => {
    setSelectedSubSegments(subSegment);
    setSelectedSegments(segment);
    if (subSegment) {
      setUrlSearchParams(
        createUrlSearchParams(activeStep?.toString(), subSegment?.id?.toString(), "", "")
      );
    }
  };

  useEffect(() => {
    //if segment parameter is set wait for selected segment is to be set before loading applications
    if ((selectedSubSegment && paramsFromUrl.segment) || !paramsFromUrl.segment) loadApplications();
    // eslint-disable-next-line
  }, [selectedSubSegment, paramsFromUrl.segment]);

  const onAdditionalFeatureSelectionChanged = (features: SelectorApplicationFeatureDto[]) => {
    setFeatures([...features]);
    loadProducts(application, features);
    setSelectedProductId(undefined);
    setUrlSearchParameter(
      FeaturesUrlKey,
      features
        .filter((f) => f.isSelected)
        .map((f) => f.id)
        .join(",")
    );
  };

  const onProductSelected = (product?: SelectorProductDto) => {
    setSelectedProductId(product?.id);
    setUrlSearchParameter(ProductUrlKey, product?.id?.toString());
  };

  return (
    <AppContentWrapper isMainView={true} titleHeadingLevel={3} showFooter={true}>
      {activeStep === 0 && (
        <StyledHeading level={2} text={t("Product selection guide")} style={{ lineHeight: 1.5 }} />
      )}
      <Stack width={"100%"} paddingTop={commonUXTheme.sizes.l}>
        <CustomStepper activeStep={activeStep} handleStep={onStepChanged}>
          <Step title={t("1. Start")}>{withFooter(<StartStep></StartStep>)}</Step>
          <Step title={selectedSubSegment ? `2. ${selectedSubSegment.name}` : t("2. Segment")}>
            {withFooter(
              <SegmentStep
                segments={segments}
                subSegments={subSegments}
                selectedSubSegment={selectedSubSegment}
                selectedSegment={selectedSegment}
                onSegmentSelected={onSegmentSelected}
              ></SegmentStep>
            )}
          </Step>
          <Step title={application ? `3. ${application.name}` : t("3. Application")}>
            {withFooter(
              <ApplicationStep
                applications={applications}
                selectedApplication={application}
                onApplicationSelected={onApplicationSelected}
              ></ApplicationStep>,
              !application
            )}
          </Step>
          <Step title={t("4. Features")} disabled={!application}>
            {!!application &&
              withFooter(
                <FeaturesStep
                  onFeatureSelectionChange={onAdditionalFeatureSelectionChanged}
                  additionalFeatures={features}
                  products={products}
                  key={"4"}
                ></FeaturesStep>,
                !selectedProductId || !products
              )}
          </Step>
          <Step title={t("5. Compare")} disabled={!selectedProductId}>
            {!!products?.length && features && selectedProductId ? (
              withFooter(
                <ComparisonStep
                  defaultApplicationFeatureId={defaultFeature?.id}
                  selectedProductId={selectedProductId}
                  onProductSelected={onProductSelected}
                  products={products}
                  additionalFeatures={features}
                  key={"5"}
                ></ComparisonStep>
              )
            ) : (
              <></>
            )}
          </Step>
          <Step title={t("6. Recommendation")} disabled={!selectedProductId && !selectedProduct}>
            {!!selectedProduct &&
              withFooter(
                <RecommendedStep
                  additionalFeatures={features ?? []}
                  product={selectedProduct}
                ></RecommendedStep>
              )}
          </Step>
        </CustomStepper>
      </Stack>
    </AppContentWrapper>
  );
};

export default SelectorView;

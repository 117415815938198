import React, { useEffect, useState } from "react";
import {
  Grid,
  GridExportParams,
  createHeaderAndHeaderTooltip,
  getDefaultColumnSort
} from "../../../../framework/components/grid/Grid";
import {
  GridApi,
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
  ValueGetterParams
} from "ag-grid-community";
import { CompanyDto, ApiCompaniesOverrideStatusPatchRequest } from "../../../../api";
import moment from "moment";
import { getValidDateFormat } from "../../../../utilities/dateUtils";
import {
  GridCheckboxRenderer,
  GridCheckboxRendererProps
} from "../../../../framework/components/grid/GridCheckboxRenderer";
import styled from "styled-components";
import { CompanyOverrideStatus } from "../../../../enums/grid/companyOverrideStatus";
import { ActiveStatus } from "../../../../enums/grid/activeStatus";
import { CompanyAdminStatus } from "../../../../enums/grid/companyAdminStatus";
import { manageCompaniesDefaultCriteria } from "../containers/ManageCompaniesView";
import { useTranslation } from "react-i18next";
import { Icon } from "@abb/abb-common-ux-react/components/Icon";

export enum ManageCompaniesGridUsage {
  ViewSingleCompany,
  CompanyListing
}

interface ManageCompaniesGridProps {
  gridApi: GridApi | null;
  rowData?: CompanyDto[];
  disableRowSelection?: boolean;
  canEditOverrideStatus?: boolean;
  canSeeAccessAuditDueTo?: boolean;
  usageType: ManageCompaniesGridUsage;
  isLoading?: boolean;
  setGridApi?: (ref: GridApi) => void;
  onSelectCompany?: (companyId: string) => void;
  onUpdateCompanyOverrideStatus?: (
    command: ApiCompaniesOverrideStatusPatchRequest,
    company: CompanyDto
  ) => void;
  onExportCsv?: (params: GridExportParams) => void;
}

const GridContainer = styled.div<{ filtersEnabled?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  .ag-header-cell::after {
    content: ${(props) => (props.filtersEnabled ? "none !important" : "inherit")};
  }
  .ag-header-cell {
    border-top: ${(props) => (props.filtersEnabled ? "0px !important" : "inherit")};
  }
`;
export const IconTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: red;
  gap: ${(props) => props.theme.sizes.s};
`;

export const ManageCompaniesGrid: React.FunctionComponent<ManageCompaniesGridProps> = (props) => {
  const { t } = useTranslation();
  const companyListingMode = props.usageType === ManageCompaniesGridUsage.CompanyListing;
  const colDefs: ColDef[] = [
    { ...createHeaderAndHeaderTooltip(t("Name")), field: "name" },
    { ...createHeaderAndHeaderTooltip(t("Country")), field: "countryCode", maxWidth: 100 },
    { ...createHeaderAndHeaderTooltip(t("City")), field: "addressCity" },
    {
      ...createHeaderAndHeaderTooltip(t("Postal code")),
      field: "addressPostalCode",
      maxWidth: 140,
      hide: companyListingMode
    },
    { ...createHeaderAndHeaderTooltip(t("Street address")), field: "addressStreet" },
    { ...createHeaderAndHeaderTooltip(t("Customer code")), field: "id" },
    {
      ...createHeaderAndHeaderTooltip(t("Global customer number")),
      field: "globalCustomerNumber",
      tooltipValueGetter: (params) =>
        params.value
          ? `${params.value} ${params.data?.isInternal ? t("Internal") : t("External")}`
          : ""
    },
    {
      ...createHeaderAndHeaderTooltip(t("PG Codes")),
      floatingFilter: false,
      sortable: false,
      cellRenderer: (p: any) => {
        // Use a cellRenderer to add bolding to defaultPgCode
        const company = p.data as CompanyDto;
        if (!company) return;
        const pgCodes = company.pgCodes;
        const defaultPgCode = company.defaultPgCode;
        if (defaultPgCode && !pgCodes?.includes(defaultPgCode)) pgCodes?.unshift(defaultPgCode);
        if (!pgCodes) return;

        // Separate cell into 2 parts
        // 1) defaultPgCode (gets bolded) -- defaultPgCode
        // 2) rest of the pg codes -- str2
        const str2 = pgCodes.slice(defaultPgCode ? 1 : 0).join(", ");
        return (
          <>
            {defaultPgCode ? (
              <>
                <b>{defaultPgCode}</b>
                {str2 != "" && ", " + str2}{" "}
              </>
            ) : (
              <>{str2} </>
            )}
          </>
        );
      },
      valueGetter: function pgValueGetter(params: ValueGetterParams) {
        const company = params.data as CompanyDto;
        if (company == undefined) return;
        const pgCodes = company.pgCodes?.map((code) => code); // map into a new array so modifications don't persist
        const defaultPgCode = company.defaultPgCode;
        // Add an * to indicate defaultPgCode
        if (defaultPgCode && !pgCodes?.includes(defaultPgCode))
          pgCodes?.unshift(defaultPgCode + "*");
        else if (defaultPgCode && pgCodes) {
          const i = pgCodes?.indexOf(defaultPgCode);
          pgCodes[i] = defaultPgCode + "*";
        }
        return pgCodes; // ag-grid formats the array
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Company admin")),
      hide: !companyListingMode,
      field: "hasCompanyAdmin",
      maxWidth: 150,
      headerClass: "grid-header-center-with-menu",
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          data: params.value,
          isDisabled: true
        };
        return editorProps;
      },
      minWidth: 48,
      filter: companyListingMode ? "agSetColumnFilter" : undefined,
      floatingFilter: companyListingMode ? true : undefined,
      filterParams: {
        values: [CompanyAdminStatus.Has, CompanyAdminStatus.DoesntHave],
        valueFormatter: (params: any) => t(params.value)
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Last active")),
      field: "lastActive",
      filter: companyListingMode ? "agDateColumnFilter" : undefined,
      floatingFilter: companyListingMode ? true : undefined,
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan"],
        defaultOption: "equals",
        buttons: ["clear"],
        suppressAndOrCondition: true
      },

      valueFormatter: (params: ValueFormatterParams): string => {
        if (!params.value) {
          return " ";
        }
        return moment(params.value).format(getValidDateFormat());
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Active")),
      field: "active",
      minWidth: 48,
      width: 120,
      headerClass: "grid-header-center-with-menu",
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          data: params.value,
          isDisabled: true
        };
        return editorProps;
      },
      filter: companyListingMode ? "agSetColumnFilter" : undefined,
      floatingFilter: companyListingMode ? true : undefined,
      filterParams: {
        values: [ActiveStatus.Active, ActiveStatus.InActive],
        valueFormatter: (params: any) => t(params.value)
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Override as active")),
      minWidth: 48,
      width: 150,
      field: "overrideStatusActive",
      headerClass: "grid-header-center-with-menu",
      filter: companyListingMode ? "agSetColumnFilter" : undefined,
      floatingFilter: companyListingMode ? true : undefined,
      filterParams: {
        values: [CompanyOverrideStatus.Set, CompanyOverrideStatus.NotSet],
        valueFormatter: (params: any) => t(params.value)
      },
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const company = params.data as CompanyDto;
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          data: params.value,
          isDisabled: !props.canEditOverrideStatus,
          onChange: (value: boolean) => {
            const command: ApiCompaniesOverrideStatusPatchRequest = {
              updateCompanyOverrideStatusCommand: { companyId: company.id, overrideStatus: value }
            };
            if (props.onUpdateCompanyOverrideStatus) {
              props.onUpdateCompanyOverrideStatus(command, company);
            }
          }
        };
        return editorProps;
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Access audit due to")),
      field: "accessAuditDueTo",
      hide: !props.canSeeAccessAuditDueTo,
      filter: companyListingMode ? "agSetColumnFilter" : undefined,
      floatingFilter: companyListingMode ? true : undefined,
      filterParams: {
        values: ["Late"],
        defaultToNothingSelected: true,
        suppressSelectAll: true
      },
      cellRenderer: (props: ICellRendererParams) => {
        const [company, setCompany] = useState<CompanyDto>();
        useEffect(() => {
          if (props.data !== company) {
            setCompany(props.data);
          }
        }, [props.data, company]);
        return (
          <>
            {company && company.accessAuditDueTo ? (
              company.hasLateAccessAudit ? (
                <IconTextWrapper>
                  <Icon name="abb/warning-circle-1" sizeClass="small" />
                  {moment(company.accessAuditDueTo).format(getValidDateFormat())}
                </IconTextWrapper>
              ) : (
                moment(company.accessAuditDueTo).format(getValidDateFormat())
              )
            ) : (
              ""
            )}
          </>
        );
      }
    }
  ];

  return (
    <GridContainer filtersEnabled={companyListingMode}>
      <Grid
        onExportCsv={props.onExportCsv}
        t={t}
        defaultSort={getDefaultColumnSort(
          manageCompaniesDefaultCriteria.criteriaIsAscendingOrder,
          manageCompaniesDefaultCriteria.criteriaSortColumn
        )}
        columnDefs={colDefs}
        rowModelType={companyListingMode ? "infinite" : "clientSide"}
        onGridReady={(event) => {
          if (props.setGridApi) {
            props.setGridApi(event.api);
          }
          event.columnApi.applyColumnState({
            state: manageCompaniesDefaultCriteria.criteriaSortColumn
              ? [
                  {
                    colId: manageCompaniesDefaultCriteria.criteriaSortColumn,
                    sort: manageCompaniesDefaultCriteria.criteriaIsAscendingOrder ? "asc" : "desc"
                  }
                ]
              : undefined
          });
        }}
        colDefDefault={
          companyListingMode
            ? {
                filter: "agTextColumnFilter",
                filterParams: {
                  filterOptions: ["contains"],
                  suppressAndOrCondition: true
                },
                floatingFilter: true
              }
            : {
                sortable: false
              }
        }
        statusBarOptions={
          companyListingMode ? { showResetButton: true, showRowCount: true } : undefined
        }
        rowData={props.rowData}
        showPointerOnRowHover={companyListingMode ? true : false}
        rowSelection={props.disableRowSelection ? undefined : "single"}
        cacheBlockSize={100}
        onRowSelected={(event) => {
          if (event.node.isSelected()) {
            const company = event.data as CompanyDto;
            if (props.onSelectCompany) {
              props.onSelectCompany(company.id);
            }
          }
        }}
        onCellClicked={(e) => {
          if (e.column.getColId() !== "overrideStatusActive") {
            const selected = e.node.isSelected();
            e.node.setSelected(!selected);
          }
        }}
        autosizeCols
        disableColumnAutoSize={companyListingMode ? undefined : true}
        domLayout={companyListingMode ? undefined : "autoHeight"}
        blockLoadDebounceMillis={300}
      />
    </GridContainer>
  );
};

import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyDto,
  ManageUserRoleDto,
  RoleDto,
  RoleGroups,
  RoleTypes,
  AccessRights,
  TeamDto,
  TeamTypes,
  UserCompanyRolesDto,
  UserTeamRolesDto
} from "../../api";
import { getIsUpdateUserRolesStatusLoading } from "../../applications/manage/manageUsers/reducers/manageUsersViewReducer";
import { RoleSelectionType } from "../../applications/manage/manageUsers/containers/ManageUserView";
import { accessRightSagas } from "../../applications/common/sagas/accessRightSagas";
import { roleSagas } from "../../applications/common/sagas/roleSagas";
import { manageUsersSagas } from "applications/manage/manageUsers/sagas/manageUsersSagas";
import {
  getRoleGroupAccessRights,
  getRoleGroupRoles
} from "../../applications/common/reducers/commonReducer";
import { RoleGroupAccessRightsDto } from "../../api/models/RoleGroupAccessRightsDto";
import { RoleGroupRolesDto } from "../../api/models/RoleGroupRolesDto";
import { usePrevious } from "./usePrevious";
import {
  getUserAccessRights,
  getUserInformation
} from "../../applications/common/reducers/userReducer";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { useTranslation } from "react-i18next";
import { isEqual, sortBy } from "lodash-es";

export interface Roles {
  id: RoleGroups;
  name?: string | null;
  friendlyName?: string | null;
  description?: string | null;
  roles: RoleDto[];
  canEdit: boolean;
}

interface UserRoles {
  allRoles: RoleGroupRolesDto[] | undefined;
  roles: Roles[] | undefined;
  userRoles: ManageUserRoleDto[] | undefined;
  setUserRoles: React.Dispatch<React.SetStateAction<ManageUserRoleDto[] | undefined>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accessRights: RoleGroupAccessRightsDto[] | undefined;
  title: string;
  isSaveEnabled: boolean;
  isLoading: boolean;
  isOpen: boolean;
  setRoleSelectionType: React.Dispatch<React.SetStateAction<RoleSelectionType | undefined>>;
  roleSelectionType: RoleSelectionType | undefined;
  saveChanges: (disableNotification: boolean) => void;
  changedUserRoles: {
    userRolesToAdd: ManageUserRoleDto[];
    userRolesToRemove: ManageUserRoleDto[];
  };
}

const getFilteredCompanyRoles = (
  isInternal: boolean,
  roleGroupRoles: RoleGroupRolesDto[] | undefined
) => {
  const companyRoleTypeIdToExclude = isInternal
    ? RoleTypes.ExternalCompany
    : RoleTypes.InternalCompany;
  return roleGroupRoles?.map((group) => ({
    ...group,
    companyRoles: group.companyRoles.filter(
      (role) => role.roleTypeId !== companyRoleTypeIdToExclude
    )
  }));
};

const getFilteredTeamRoles = (
  teamType: TeamTypes,
  roleGroupRoles: RoleGroupRolesDto[] | undefined
) => {
  const roleType = teamType === TeamTypes.FunctionalTeam
    ? RoleTypes.FunctionalTeam
    : RoleTypes.BusinessUnitTeam;
  return roleGroupRoles?.map((group) => ({
    ...group,
    teamRoles: group.teamRoles.filter(
      (role) => role.roleTypeId === roleType
    )
  }));
};

export const useUserRoles = (
  selectedUser?: {
    user: {
      id: number | undefined;
    }
    userCompanyRoles?: Array<UserCompanyRolesDto>;
    userGlobalRoles?: Array<ManageUserRoleDto>;
    userTeamRoles?: Array<UserTeamRolesDto>;
  },
  company?: CompanyDto,
  roleGroups: RoleGroups[] | undefined = undefined,
  includeManageOnlyRoles?: boolean,
  team?: TeamDto
): UserRoles => {
  const dispatch = useDispatch();

  const roleGroupRoles = useSelector(getRoleGroupRoles);
  const roleGroupAccessRights = useSelector(getRoleGroupAccessRights);

  const isDialogLoading = useSelector(getIsUpdateUserRolesStatusLoading);
  const userAccessRights = useSelector(getUserAccessRights);

  const currentUser = useSelector(getUserInformation);

  const canEditUserRoles = useMemo(
    () =>
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [
          AccessRights.ManageDeliveriesUsers,
          AccessRights.ManageDeliveriesUsersLimited,
          AccessRights.ManageDeliveriesCompanies,
          AccessRights.ManageConfiguratorUsers,
          AccessRights.ManagePlatformUsers,
          AccessRights.ProcessConfiguratorAccessRequests,
          AccessRights.ViewConfiguratorUsers,
          AccessRights.ViewDeliveriesUsers,
          AccessRights.TeamAccess,
        ]
      }) ||
      (isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [AccessRights.ChangeOwnRoles]
      }) &&
        selectedUser?.user.id === currentUser?.id),
    [userAccessRights, selectedUser, currentUser]
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const prevRoleGroups = usePrevious(roleGroups);
  const prevIncludeManageOnlyRoles = usePrevious(includeManageOnlyRoles);

  const prevUser = usePrevious(selectedUser);

  const [selectedUserRoles, setSelectedUserRoles] = useState<ManageUserRoleDto[] | undefined>(
    undefined
  );

  const [oldUserRoles, setOldUserRoles] = useState<ManageUserRoleDto[] | undefined>(undefined);

  const [roleSelectionType, setRoleSelectionType] = useState<RoleSelectionType | undefined>(
    team ? RoleSelectionType.TEAM : RoleSelectionType.COMPANY
  );

  const [changedUserRoles, setChangedUserRoles] = useState<{
    userRolesToAdd: ManageUserRoleDto[];
    userRolesToRemove: ManageUserRoleDto[];
  }>({ userRolesToAdd: [], userRolesToRemove: [] });

  useEffect(() => {
    // Note. This is just to minimize the sending of requests. When you are fetching your own roles, there are special conditions
    // if you've been assigned with the "ChangeOwnRoles" access right.

    const changeToOrFromEditingOwnRoles =
      (prevUser?.user?.id === currentUser?.id || selectedUser?.user?.id === currentUser?.id) &&
      prevUser?.user?.id !== selectedUser?.user?.id;
    if (
      isEqual(sortBy(prevRoleGroups), sortBy(roleGroups)) &&
      roleGroupRoles &&
      !changeToOrFromEditingOwnRoles &&
      prevIncludeManageOnlyRoles === includeManageOnlyRoles
    ) {
      return;
    }
    if (canEditUserRoles) {
      dispatch(
        roleSagas.getRoles.createAction({
          criteriaRoleGroups: roleGroups,
          criteriaIncludeManageOnlyRoles: includeManageOnlyRoles ?? false,
          criteriaUserIdToEdit: selectedUser?.user.id
        })
      );
    }
  }, [
    roleGroups,
    roleGroupRoles,
    dispatch,
    prevRoleGroups,
    canEditUserRoles,
    includeManageOnlyRoles,
    selectedUser,
    currentUser,
    prevUser,
    prevIncludeManageOnlyRoles
  ]);

  useEffect(() => {
    const changeToOrFromEditingOwnRoles =
      (prevUser?.user?.id === currentUser?.id || selectedUser?.user?.id === currentUser?.id) &&
      prevUser?.user?.id !== selectedUser?.user?.id;

    if (
      isEqual(sortBy(prevRoleGroups), sortBy(roleGroups)) &&
      roleGroupAccessRights &&
      !changeToOrFromEditingOwnRoles
    ) {
      return;
    }
    if (canEditUserRoles) {
      dispatch(
        accessRightSagas.getAccessRights.createAction({
          criteriaRoleGroups: roleGroups,
          criteriaUserIdToEdit: selectedUser?.user.id
        })
      );
    }
  }, [
    roleGroups,
    dispatch,
    roleGroupAccessRights,
    prevRoleGroups,
    canEditUserRoles,
    prevUser,
    selectedUser,
    currentUser
  ]);

  // Keep track of changed user roles before saving
  useEffect(() => {
    let userRolesToAdd: ManageUserRoleDto[] = [];
    let userRolesToRemove: ManageUserRoleDto[] = [];

    if (selectedUserRoles && oldUserRoles) {
      userRolesToAdd = selectedUserRoles.filter(
        (newRole) => !oldUserRoles.some((existingRole) => newRole.id === existingRole.id)
      );

      userRolesToRemove = oldUserRoles.filter(
        (existingRole) => !selectedUserRoles.some((newRole) => existingRole.id === newRole.id)
      );
    }

    setChangedUserRoles({
      userRolesToAdd: [...userRolesToAdd],
      userRolesToRemove: [...userRolesToRemove]
    });
  }, [selectedUserRoles, roleSelectionType, selectedUser, oldUserRoles]);

  // Set global roles as selected user roles to edit
  useEffect(() => {
    if (selectedUser?.userGlobalRoles && roleSelectionType === RoleSelectionType.GLOBAL) {
      setSelectedUserRoles([...selectedUser.userGlobalRoles.filter((role) => !role.defaultRole)]);
      setOldUserRoles([...selectedUser.userGlobalRoles.filter((role) => !role.defaultRole)]);
    } else if (selectedUser?.userTeamRoles && roleSelectionType === RoleSelectionType.TEAM) {
      const defaultTeamRoles = selectedUser.userTeamRoles
        .filter(tr => tr.team.type == team?.type)
        .flatMap(r => r.roles)
        .filter((role) => role.defaultRole);
      setSelectedUserRoles([...defaultTeamRoles]);
      setOldUserRoles([...defaultTeamRoles]);
    } else {
      setSelectedUserRoles([]);
      setOldUserRoles([]);
    }
  }, [selectedUser, roleSelectionType, team]);

  // Set selected companies or teams roles as selected user roles to edit
  useEffect(() => {
    if (company && roleSelectionType === RoleSelectionType.COMPANY) {
      if (!selectedUser?.userCompanyRoles) {
        setSelectedUserRoles([]);
        setOldUserRoles([]);
      } else {
        const selectedUserCompanyRoles = selectedUser?.userCompanyRoles.find(
          (x) => x.company.id === company.id
        );
        if (selectedUserCompanyRoles) {
          // For company roles, all the selections are in the DB.
          setSelectedUserRoles([...selectedUserCompanyRoles.roles]);
          setOldUserRoles([...selectedUserCompanyRoles.roles]);
        }
      }
    } else if (roleSelectionType === RoleSelectionType.TEAM && team && team.id) {
      if (!selectedUser?.userTeamRoles) {
        setSelectedUserRoles([]);
        setOldUserRoles([]);
      } else {
        const selectedUserTeamRoles = selectedUser?.userTeamRoles.find(
          (x) => x.team.id === team.id
        );

        if (selectedUserTeamRoles) {
          // For team roles, all the selections are in the DB.
          setSelectedUserRoles([...selectedUserTeamRoles.roles]);
          setOldUserRoles([...selectedUserTeamRoles.roles]);
        }
      }
    }
  }, [company, team, roleSelectionType, selectedUser]);

  const { t } = useTranslation();

  const saveChanges = (disableNotification: boolean) => {
    if (selectedUser && selectedUser.user.id) {
      const userRolesToAdd: number[] = changedUserRoles?.userRolesToAdd?.map(
        (userRole) => userRole.id
      );

      const userRolesToRemove: number[] = changedUserRoles?.userRolesToRemove?.map(
        (userRole) => userRole.id
      );
      dispatch(
        manageUsersSagas.updateUserRoles.createAction({
          updateUserRolesCommand: {
            userId: selectedUser.user.id,
            companyId: roleSelectionType === RoleSelectionType.COMPANY ? company?.id : null,
            userRolesToAdd: userRolesToAdd,
            userRolesToRemove: userRolesToRemove,
            disableNotification
          }
        })
      );
    }
  };

  const getTitle = (roleType: RoleSelectionType | undefined) => {
    return roleType === RoleSelectionType.GLOBAL
      ? t("Global user roles")
      : roleType === RoleSelectionType.TEAM
        ? t("User roles for <teamName>").replace("<teamName>", team?.name ?? "")
        : t("User roles for <companyName>").replace("<companyName>", company?.companyDisplayName ?? "");
  };

  return {
    allRoles: roleGroupRoles,
    roles:
      roleSelectionType === RoleSelectionType.COMPANY
        ? getFilteredCompanyRoles(!!company?.isInternal, roleGroupRoles)?.map((x) => ({
          id: x.id,
          description: x.description,
          friendlyName: x.friendlyName,
          name: x.name,
          roles: x.companyRoles,
          canEdit: x.canEdit
        }))
        : roleSelectionType === RoleSelectionType.TEAM && team?.type
          ? getFilteredTeamRoles(team?.type, roleGroupRoles)?.map((x) => ({
            id: x.id,
            description: x.description,
            friendlyName: x.friendlyName,
            name: x.name,
            roles: x.teamRoles,
            canEdit: x.canEdit
          }))
          : roleGroupRoles?.map((x) => ({
            id: x.id,
            description: x.description,
            friendlyName: x.friendlyName,
            name: x.name,
            roles: x.globalRoles,
            canEdit: x.canEdit
          })),
    userRoles: selectedUserRoles,
    setUserRoles: setSelectedUserRoles,
    setIsOpen: setIsDialogOpen,
    accessRights: roleGroupAccessRights,
    title: getTitle(roleSelectionType),
    isSaveEnabled:
      changedUserRoles?.userRolesToAdd.length > 0 || changedUserRoles?.userRolesToRemove.length > 0,
    isLoading: isDialogLoading,
    isOpen: isDialogOpen,
    setRoleSelectionType: setRoleSelectionType,
    roleSelectionType,
    saveChanges,
    changedUserRoles
  };
};

import {
  AccessRights,
  CountryDto,
  CurrentUserDto,
  QuoteBaseDto,
  QuoteDto,
  QuoteStateTypes,
  ShoppingCartAttachmentDto,
  ShoppingCartDto,
  ShoppingCartItemDto,
  ShoppingCartStateTypes
} from "api";
import { roleConstants } from "constants/roleConstants";
import { ShoppingCartTabs } from "enums/shoppingCartTabs";
import { FormikProps } from "formik";
import { differenceBy } from "lodash-es";
import { hasAnyAccessRight } from "utilities/authUtils";
import { handleFormikValueChange } from "utilities/formikUtils";
import { ProFormaItem, ReviseMode, ShoppingCartForm } from "./containers/ShoppingCartView";
import moment from "moment";
import { TFunction } from "i18next";

interface DisabledTabs {
  isBuildCartDisabled: boolean;
  isOrderDetailsDisabled: boolean;
  isConfirmOrderDisabled: boolean;
  isOrderInProcessDisabled: boolean;
}

export type DisabledWithTooltip =
  | {
      visibility: "disabled" | "visible";
      tooltip?: string;
    }
  | {
      visibility: "hidden";
    };

export const getActiveTab = (state?: ShoppingCartStateTypes): ShoppingCartTabs => {
  switch (state) {
    case ShoppingCartStateTypes.OrderPlaced:
      return ShoppingCartTabs.OrderInProcess;
    case ShoppingCartStateTypes.OrderPlacedExternally:
      return ShoppingCartTabs.OrderInProcess;
    case ShoppingCartStateTypes.OrderWaitingForApproval:
      return ShoppingCartTabs.ConfirmOrder;
    default:
      return ShoppingCartTabs.BuildCart;
  }
};

interface TabAndButtonState {
  tabs: DisabledTabs;
  isSecondaryButtonDisabled: DisabledWithTooltip;
  isPrimaryButtonDisabled: DisabledWithTooltip;
}

// products with a quoted price should are valid even if no price found or configurator returned false for validation result
const hasInvalidProduct = (shoppingCart: ShoppingCartDto | null | undefined) =>
  shoppingCart?.shoppingCartItems.some(
    (x) => x.quotedPrice == null && (x.isPriceValid === false || x.isValidProduct === false)
  );

const isNotOrderedInvalidProductsCart = (shoppingCart: ShoppingCartDto | null | undefined) => {
  return (
    shoppingCart?.stateId !== ShoppingCartStateTypes.OrderPlaced &&
    shoppingCart?.stateId !== ShoppingCartStateTypes.OrderPlacedExternally &&
    hasInvalidProduct(shoppingCart)
  );
};

export const getTabAndButtonStates = (
  shoppingCart: ShoppingCartDto | undefined,
  userInformation: CurrentUserDto | undefined | null,
  activeTab: ShoppingCartTabs,
  isValidForm: boolean,
  isActiveCart: boolean,
  userAccessRights: { [key: string]: string[] },
  pendingFilesExist: boolean,
  reviseMode: ReviseMode,
  currentUserCanAcknowledgeAndRejectRevision: boolean,
  t: TFunction,
  quote: QuoteDto | undefined
): TabAndButtonState => {
  const hasAccessToPlaceOrderForSelectedCompany = hasAnyAccessRight(
    userInformation?.selectedCompanyId ?? roleConstants.GlobalRoleIdentifier,
    userAccessRights,
    AccessRights.PlaceCompanyOrders
  );

  const state: TabAndButtonState = {
    tabs: {
      isBuildCartDisabled: false,
      isOrderDetailsDisabled: true,
      isConfirmOrderDisabled: true,
      isOrderInProcessDisabled: true
    },
    isSecondaryButtonDisabled: isSecondaryDisabled(
      userInformation,
      shoppingCart,
      activeTab,
      userInformation?.demoMode,
      reviseMode,
      currentUserCanAcknowledgeAndRejectRevision,
      t,
      quote
    ),
    isPrimaryButtonDisabled: isPrimaryDisabled(
      shoppingCart,
      activeTab,
      isActiveCart,
      isValidForm,
      userInformation?.selectedCompanyId,
      hasAccessToPlaceOrderForSelectedCompany,
      userInformation?.demoMode,
      pendingFilesExist,
      reviseMode,
      currentUserCanAcknowledgeAndRejectRevision,
      t
    )
  };

  const hasInvalidProductsButNotOrdered = isNotOrderedInvalidProductsCart(shoppingCart);
  // Don't allow to continue with invalid products
  if (hasInvalidProductsButNotOrdered) {
    if (shoppingCart?.stateId == ShoppingCartStateTypes.OrderWaitingForApproval) {
      // Keep tab in confirm order and delete invalid products there if needed
      return {
        ...state,
        tabs: {
          isBuildCartDisabled: true,
          isOrderDetailsDisabled: true,
          isConfirmOrderDisabled: false,
          isOrderInProcessDisabled: true
        }
      };
    }
    return state;
  }

  switch (shoppingCart?.stateId) {
    case ShoppingCartStateTypes.Draft:
      return {
        ...state,
        tabs: getDraftStateDisabled(
          activeTab,
          !!userInformation?.selectedCompanyId,
          isValidForm,
          shoppingCart?.shoppingCartItems.length > 0,
          hasAccessToPlaceOrderForSelectedCompany
        )
      };
    case ShoppingCartStateTypes.WaitingForQuote:
      return {
        ...state,
        tabs: getWaitingForQuoteDisabled(activeTab)
      };
    case ShoppingCartStateTypes.OrderPlaced:
      return {
        ...state,
        tabs: getOrderPlacedDisabled(activeTab)
      };
    case ShoppingCartStateTypes.OrderPlacedExternally:
      return {
        ...state,
        tabs: getOrderPlacedDisabled(activeTab)
      };
    case ShoppingCartStateTypes.Quote:
      return {
        ...state,
        tabs: getQuoteDisabled(activeTab, isValidForm)
      };
    case ShoppingCartStateTypes.OrderWaitingForApproval:
      return {
        ...state,
        tabs: getOrderWaitingForApprovalDisabled(activeTab)
      };
    default:
      return state;
  }
};

const isSecondaryDisabled = (
  userInformation: CurrentUserDto | undefined | null,
  shoppingCart: ShoppingCartDto | undefined,
  activeTab: ShoppingCartTabs,
  demoMode: boolean | null | undefined,
  reviseMode: ReviseMode,
  currentUserCanAcknowledgeAndRejectRevision: boolean,
  t: TFunction,
  quote: QuoteDto | undefined
): DisabledWithTooltip => {
  if (reviseMode != ReviseMode.None) {
    return {
      visibility: "disabled"
    };
  }

  if (!shoppingCart?.managedPriceListId) {
    return {
      visibility: "disabled",
      tooltip: t("Disabled while prices are hidden")
    };
  }

  if (demoMode) {
    return {
      visibility: "disabled",
      tooltip: t("Disabled while in demo mode")
    };
  }

  if (!userInformation || isNotOrderedInvalidProductsCart(shoppingCart)) {
    return { visibility: "disabled" };
  }

  if (!shoppingCart || shoppingCart.shoppingCartItems.length === 0) {
    return { visibility: "disabled" };
  }

  // disable reject button for user who is no owner(for regular quote/frame agreement),
  // not original owner of shopping cart, not original requester and not PMS(for revisions)
  if (
    shoppingCart?.stateId === ShoppingCartStateTypes.Quote ||
    shoppingCart?.stateId === ShoppingCartStateTypes.FrameAgreement ||
    shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision
  ) {
    if (!!shoppingCart.baseShoppingCartId && !currentUserCanAcknowledgeAndRejectRevision) {
      return {
        visibility: "disabled",
        tooltip: t("Not allowed to reject the revision of this quote")
      };
    }
    if (
      !shoppingCart.baseShoppingCartId &&
      shoppingCart?.ownerEmail !== userInformation.email &&
      !currentUserIsQuoteRequester(quote?.baseQuote, userInformation)
    ) {
      return {
        visibility: "disabled",
        tooltip: t("Not allowed to reject quote for other user's shopping cart")
      };
    }
  }

  if (shoppingCart.stateId === ShoppingCartStateTypes.Draft) {
    if (hasInvalidSoftwarePurchaseEmail(shoppingCart)) {
      return {
        visibility: "disabled",
        tooltip: t(
          "Your cart contains software product(s) that require a purchase email. Please enter a valid email address"
        )
      };
    }
    if (isModificationPending(shoppingCart)) {
      return { visibility: "disabled" };
    }
  }

  if (
    shoppingCart.stateId !== ShoppingCartStateTypes.WaitingForRevision &&
    (shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreement ||
      shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreementApproval ||
      shoppingCart.isBasedOnFrameAgreement)
  ) {
    return { visibility: "hidden" };
  }

  if (activeTab === ShoppingCartTabs.BuildCart) {
    if (
      !shoppingCart ||
      shoppingCart?.shoppingCartItems.length === 0 ||
      !userInformation.selectedCompanyId
    ) {
      return { visibility: "disabled" };
    }

    if (shoppingCart.stateId === ShoppingCartStateTypes.WaitingForQuote) {
      if (userInformation.email !== shoppingCart.ownerEmail) {
        return {
          visibility: "disabled",
          tooltip: t("You cannot cancel this quote request because you are not its owner")
        };
      }
    }
  }

  return { visibility: "visible" };
};

const isPrimaryDisabled = (
  shoppingCart: ShoppingCartDto | undefined,
  activeTab: ShoppingCartTabs,
  isActiveCart: boolean,
  isValidForm: boolean,
  selectedCompanyId: string | null | undefined,
  hasAccessToPlaceOrderForSelectedCompany: boolean,
  demoMode: boolean | null | undefined,
  pendingFilesExist: boolean,
  reviseMode: ReviseMode,
  currentUserCanAcknowledgeRevision: boolean,
  t: TFunction
): DisabledWithTooltip => {
  if (!shoppingCart?.managedPriceListId && hasAccessToPlaceOrderForSelectedCompany) {
    return {
      visibility: "disabled",
      tooltip: t("Disabled while prices are hidden")
    };
  }

  if (demoMode) {
    return {
      visibility: "disabled",
      tooltip: t("Disabled while in demo mode")
    };
  }

  if (pendingFilesExist && reviseMode == ReviseMode.None) {
    return {
      visibility: "disabled",
      tooltip: t("Please finish uploading attachments")
    };
  }

  if (
    !shoppingCart ||
    shoppingCart.shoppingCartItems.length === 0 ||
    isNotOrderedInvalidProductsCart(shoppingCart)
  ) {
    return { visibility: "disabled" };
  }

  if (
    (shoppingCart?.stateId === ShoppingCartStateTypes.OrderPlaced ||
      shoppingCart?.stateId === ShoppingCartStateTypes.OrderPlacedExternally) &&
    reviseMode == ReviseMode.None
  ) {
    return {
      visibility: "disabled"
    };
  }

  if (
    shoppingCart.stateId === ShoppingCartStateTypes.Cancelled ||
    shoppingCart.stateId === ShoppingCartStateTypes.Expired ||
    (shoppingCart.stateId === ShoppingCartStateTypes.Archived && reviseMode == ReviseMode.None) ||
    (shoppingCart.stateId === ShoppingCartStateTypes.Rejected && reviseMode == ReviseMode.None) ||
    shoppingCart.stateId === ShoppingCartStateTypes.QuoteWaitingForApproval ||
    shoppingCart.stateId === ShoppingCartStateTypes.WaitingForQuote ||
    shoppingCart.stateId === ShoppingCartStateTypes.Revised
  ) {
    return { visibility: "disabled" };
  }

  if (shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision) {
    if (!currentUserCanAcknowledgeRevision) {
      return {
        visibility: "disabled",
        tooltip: t("You cannot acknowledge this revision because you are not its owner")
      };
    }
    return { visibility: "visible" };
  }

  if (shoppingCart.stateId === ShoppingCartStateTypes.Draft) {
    if (hasInvalidSoftwarePurchaseEmail(shoppingCart)) {
      return {
        visibility: "disabled",
        tooltip: t(
          "Your cart contains software product(s) that require a purchase email. Please enter a valid email address"
        )
      };
    }

    if (isModificationPending(shoppingCart)) {
      return {
        visibility: "disabled",
        tooltip: t(
          "Your cart contains incomplete modification case(s). Please create these modification cases before proceeding to the next step"
        )
      };
    }

    if (hasInvalidModificationProduct(shoppingCart)) {
      return {
        visibility: "disabled",
        tooltip: t(
          "Your cart contains invalid modification product(s). Please check these modification products"
        )
      };
    }

    if (!isActiveCart) {
      return { visibility: "disabled" };
    }

    if (
      shoppingCart.frameAgreementExpirationDate != undefined &&
      shoppingCart.frameAgreementExpirationDate < new Date()
    ) {
      return {
        visibility: "disabled",
        tooltip: t("Frame agreement has expired")
      };
    }

    const exceedsFrameAgreement = shoppingCart.shoppingCartItems.some((item) => {
      return (
        item.frameAgreementDetails?.limit != undefined &&
        item.quantity + (item.frameAgreementDetails.currentCount ?? 0) >
          item.frameAgreementDetails.limit
      );
    });

    if (exceedsFrameAgreement) {
      return {
        visibility: "disabled",
        tooltip: t("Items must not exceed their frame agreement limit")
      };
    }
  }

  if (
    shoppingCart.isOrderApprover &&
    shoppingCart.stateId === ShoppingCartStateTypes.OrderWaitingForApproval
  ) {
    return { visibility: "visible" };
  }

  const noAccessToPlaceOrder = !hasAccessToPlaceOrderForSelectedCompany
    ? t(
        "You do not have the needed access rights to place orders via Relays-Online. Please contact your closest ABB representative for more information."
      )
    : undefined;

  if (shoppingCart.stateId === ShoppingCartStateTypes.FrameAgreement) {
    if (isQuoteExpired(shoppingCart) && reviseMode == ReviseMode.None) {
      return {
        visibility: "disabled",
        tooltip: t(
          "Frame agreement has expired. Please create new revision to extend the validity date."
        )
      };
    }

    const productsRemaining = shoppingCart.shoppingCartItems.some(
      (item) =>
        item.frameAgreementDetails?.limit != undefined &&
        item.frameAgreementDetails.currentCount != undefined &&
        item.frameAgreementDetails.currentCount < item.frameAgreementDetails.limit
    );

    if (!productsRemaining) {
      return {
        visibility: "disabled",
        tooltip: t("No products remaining")
      };
    }

    if (!hasAccessToPlaceOrderForSelectedCompany) {
      return {
        visibility: "disabled",
        tooltip: noAccessToPlaceOrder
      };
    }

    return {
      visibility: "visible"
    };
  }

  if (
    shoppingCart.stateId === ShoppingCartStateTypes.Archived &&
    !!shoppingCart.activeQuote?.frameAgreement &&
    reviseMode
  ) {
    return { visibility: "visible" };
  }

  if (
    shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreement ||
    shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreementApproval
  ) {
    // You can't perform any actions on non-approved frame agreements
    return {
      visibility: "disabled",
      tooltip: t("Frame agreement is not approved yet")
    };
  }

  if (shoppingCart.stateId === ShoppingCartStateTypes.Quote) {
    if (isQuoteExpired(shoppingCart) && reviseMode == ReviseMode.None) {
      return {
        visibility: "disabled",
        tooltip: t("Quote has expired. Please create new revision to extend the validity date.")
      };
    }
    return {
      visibility: "visible"
    };
  }

  switch (activeTab) {
    case ShoppingCartTabs.OrderDetails:
      return {
        visibility:
          !selectedCompanyId || !hasAccessToPlaceOrderForSelectedCompany ? "disabled" : "visible",
        tooltip: noAccessToPlaceOrder
      };
    case ShoppingCartTabs.ConfirmOrder:
      return {
        visibility:
          !selectedCompanyId ||
          !isValidForm ||
          !hasAccessToPlaceOrderForSelectedCompany ||
          isQuoteExpired(shoppingCart)
            ? "disabled"
            : "visible",
        tooltip: noAccessToPlaceOrder
      };
    case ShoppingCartTabs.OrderInProcess:
      return { visibility: "disabled" };
    case ShoppingCartTabs.BuildCart:
      return {
        visibility:
          !hasAccessToPlaceOrderForSelectedCompany || isQuoteExpired(shoppingCart)
            ? "disabled"
            : "visible",
        tooltip: noAccessToPlaceOrder
      };
    default:
      return { visibility: "visible" };
  }
};

export const hasInvalidSoftwarePurchaseEmail = (
  shoppingCart: ShoppingCartDto | undefined
): boolean => {
  if (!shoppingCart) {
    return false;
  }

  return shoppingCart.shoppingCartItems.some(
    (i) => i.isSoftwareProduct && !i.softwarePurchaseEmail
  );
};

export const isModificationPending = (shoppingCart: ShoppingCartDto | undefined): boolean => {
  if (!shoppingCart) {
    return false;
  }

  return shoppingCart.shoppingCartItems.some((i) => i.hasPendingModification);
};

export const hasInvalidModificationProduct = (
  shoppingCart: ShoppingCartDto | undefined
): boolean => {
  if (!shoppingCart) {
    return false;
  }

  return shoppingCart.shoppingCartItems.some((i) =>
    i.shoppingCartModificationItems.some((i) => i.price == null)
  );
};

export const isQuoteExpired = (shoppingCart: ShoppingCartDto | undefined): boolean => {
  if (!shoppingCart || !shoppingCart.activeQuote) {
    return false;
  }
  return moment(shoppingCart.activeQuote.tenderValidityDate).isBefore(moment());
};

const getQuoteDisabled = (activeTab: ShoppingCartTabs, isValidForm: boolean): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: false,
    isOrderDetailsDisabled: false,
    isConfirmOrderDisabled: !isValidForm,
    isOrderInProcessDisabled: true
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const getDraftStateDisabled = (
  activeTab: ShoppingCartTabs,
  isCompanySelected: boolean,
  isValidForm: boolean,
  hasShoppingCartItems: boolean,
  hasAccessToPlaceOrderForSelectedCompany: boolean
): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: false,
    isOrderDetailsDisabled: !hasShoppingCartItems || !hasAccessToPlaceOrderForSelectedCompany,
    isConfirmOrderDisabled:
      !isCompanySelected ||
      !hasShoppingCartItems ||
      !isValidForm ||
      !hasAccessToPlaceOrderForSelectedCompany,
    isOrderInProcessDisabled: true
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const getOrderPlacedDisabled = (activeTab: ShoppingCartTabs): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: true,
    isOrderDetailsDisabled: true,
    isConfirmOrderDisabled: true,
    isOrderInProcessDisabled: false
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const getWaitingForQuoteDisabled = (activeTab: ShoppingCartTabs): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: false,
    isOrderDetailsDisabled: true,
    isConfirmOrderDisabled: true,
    isOrderInProcessDisabled: true
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const getOrderApprovedDisabled = (activeTab: ShoppingCartTabs): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: true,
    isOrderDetailsDisabled: true,
    isConfirmOrderDisabled: false,
    isOrderInProcessDisabled: true
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const getOrderWaitingForApprovalDisabled = (activeTab: ShoppingCartTabs): DisabledTabs => {
  const defaultValues: DisabledTabs = {
    isBuildCartDisabled: true,
    isOrderDetailsDisabled: true,
    isConfirmOrderDisabled: false,
    isOrderInProcessDisabled: true
  };

  switch (activeTab) {
    default:
      return defaultValues;
  }
};

export const updateProFormaItems = (
  formik: FormikProps<ShoppingCartForm> | undefined,
  shoppingCartItems: ShoppingCartItemDto[] | undefined
): void => {
  const proFormaFromItems: ProFormaItem[] | undefined = shoppingCartItems?.map((x) => ({
    ...x,
    proFormaPrice: undefined,
    shoppingCartItemId: x.id
  }));

  if (proFormaFromItems && formik?.values.proFormaItems) {
    const addedProducts = differenceBy(
      proFormaFromItems,
      formik.values.proFormaItems,
      "orderingCode"
    );
    const removedProducts = differenceBy(
      formik.values.proFormaItems,
      proFormaFromItems,
      (obj) => obj.orderingCode + obj.quantity
    );

    const newItems = [
      ...formik.values.proFormaItems.filter(
        (x) => !removedProducts.some((y) => y.orderingCode === x.orderingCode)
      ),
      ...addedProducts
    ];

    handleFormikValueChange(formik, "proFormaItems", newItems);
  }
};

export const updateAttachments = (
  formik: FormikProps<ShoppingCartForm>,
  riskReviews: ShoppingCartAttachmentDto[] | undefined,
  commercialInvoices: ShoppingCartAttachmentDto[] | undefined
): void => {
  handleFormikValueChange(formik, "riskReviews", riskReviews ?? []);
  handleFormikValueChange(formik, "commercialInvoices", commercialInvoices ?? []);
};

export const isInEu = (
  countries: CountryDto[] | null | undefined,
  countryCode: string | null | undefined
): boolean => {
  if (!countryCode) {
    return false;
  }

  return !!countries?.find((c) => c.code === countryCode)?.isInEu;
};

export const getQuoteStateText = (stateId: QuoteStateTypes | undefined, t: TFunction): string => {
  // QuoteStateType is subset of ShoppingCartStateTypes
  switch (stateId) {
    case QuoteStateTypes.WaitingForQuote:
      return getShoppingCartStateText(ShoppingCartStateTypes.WaitingForQuote, t);
    case QuoteStateTypes.Quote:
      return getShoppingCartStateText(ShoppingCartStateTypes.Quote, t);
    case QuoteStateTypes.OrderPlaced:
      return getShoppingCartStateText(ShoppingCartStateTypes.OrderPlaced, t);
    case QuoteStateTypes.Expired:
      return getShoppingCartStateText(ShoppingCartStateTypes.Expired, t);
    case QuoteStateTypes.Rejected:
      return getShoppingCartStateText(ShoppingCartStateTypes.Rejected, t);
    case QuoteStateTypes.Cancelled:
      return getShoppingCartStateText(ShoppingCartStateTypes.Cancelled, t);
    case QuoteStateTypes.QuoteWaitingForApproval:
      return getShoppingCartStateText(ShoppingCartStateTypes.QuoteWaitingForApproval, t);
    case QuoteStateTypes.Archived:
      return getShoppingCartStateText(ShoppingCartStateTypes.Archived, t);
    case QuoteStateTypes.OrderPlacedExternally:
      return getShoppingCartStateText(ShoppingCartStateTypes.OrderPlacedExternally, t);
    case QuoteStateTypes.FrameAgreement:
      return getShoppingCartStateText(ShoppingCartStateTypes.FrameAgreement, t);
    case QuoteStateTypes.WaitingForFrameAgreementApproval:
      return getShoppingCartStateText(ShoppingCartStateTypes.WaitingForFrameAgreementApproval, t);
    case QuoteStateTypes.WaitingForFrameAgreement:
      return getShoppingCartStateText(ShoppingCartStateTypes.WaitingForFrameAgreement, t);
    case QuoteStateTypes.Draft:
      return getShoppingCartStateText(ShoppingCartStateTypes.Draft, t);
    case QuoteStateTypes.WaitingForRevision:
      return getShoppingCartStateText(ShoppingCartStateTypes.WaitingForRevision, t);
    case QuoteStateTypes.Revised:
      return getShoppingCartStateText(ShoppingCartStateTypes.Revised, t);
    default:
      return "";
  }
};

export const getShoppingCartStateText = (
  stateId: ShoppingCartStateTypes | undefined,
  t: TFunction
): string => {
  switch (stateId) {
    case ShoppingCartStateTypes.WaitingForQuote:
      return t("Request for quote");
    case ShoppingCartStateTypes.Quote:
      return t("Quotation");
    case ShoppingCartStateTypes.OrderWaitingForApproval:
      return t("Order - Awaiting approval");
    case ShoppingCartStateTypes.OrderPlaced:
      return t("Order - Placed");
    case ShoppingCartStateTypes.Expired:
      return t("Quote - Expired");
    case ShoppingCartStateTypes.Rejected:
      return t("Quote - Rejected");
    case ShoppingCartStateTypes.Cancelled:
      return t("Quote request - Cancelled");
    case ShoppingCartStateTypes.QuoteWaitingForApproval:
      return t("Quote - Awaiting approval");
    case ShoppingCartStateTypes.Archived:
      return t("Shopping cart - Archived");
    case ShoppingCartStateTypes.OrderPlacedExternally:
      return t("Order - Placed externally");
    case ShoppingCartStateTypes.FrameAgreement:
      return t("Frame agreement");
    case ShoppingCartStateTypes.WaitingForFrameAgreementApproval:
      return t("Frame agreement - Awaiting approval");
    case ShoppingCartStateTypes.WaitingForFrameAgreement:
      return t("Request for frame agreement");
    case ShoppingCartStateTypes.Draft:
      return t("Draft");
    case ShoppingCartStateTypes.WaitingForRevision:
      return t("Revision - Awaiting for revision");
    case ShoppingCartStateTypes.Revised:
      return t("Quote - Revised");
    default:
      return "";
  }
};

export const currentUserIsQuoteRequester = (
  quote: QuoteDto | QuoteBaseDto | null | undefined,
  userInformation: CurrentUserDto | null | undefined
): boolean => {
  return (
    (quote?.requestedById === userInformation?.id ||
      (userInformation && quote?.additionalQuoteRequesterIds?.includes(userInformation.id))) ??
    false
  );
};

export const quoteCanBeTransferredToMat = (
  isASMOrProcurist: boolean,
  shoppingCart: ShoppingCartDto | undefined | null
) => {
  return (
    isASMOrProcurist &&
    shoppingCart?.quoteIdWaitingToBeProcessed &&
    (shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote ||
      shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreement) &&
    (shoppingCart?.company?.isMaintainedCustomer || shoppingCart?.company?.isInternal)
  );
};

export const showRequestRevisionButton = (
  shoppingCart: ShoppingCartDto | undefined | null,
  isReviseMode: boolean,
  userInformation: CurrentUserDto | undefined | null,
  userAccessRights: { [key: string]: Array<string> }
): boolean => {
  if (
    !shoppingCart ||
    !shoppingCart.activeQuote ||
    !shoppingCart.company ||
    shoppingCart.activeQuote.requestedRevisionById ||
    isReviseMode
  ) {
    return false;
  }
  const companyIsInternalOrMaintained =
    shoppingCart.company?.isMaintainedCustomer === true ||
    shoppingCart.company?.isInternal === true;

  const isASMOrProcurist = hasAnyAccessRight(
    undefined,
    userAccessRights,
    AccessRights.ManageMarginAnalysisToolQuotesAsAsm,
    AccessRights.ManageMarginAnalysisToolQuotesProcurist
  );
  const allowedToHandleOwnRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyId,
    userAccessRights,
    AccessRights.HandlingRevisions
  );
  const allowedToHandleCompanyRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyId,
    userAccessRights,
    AccessRights.HandlingCompanyRevisions
  );
  const allowedToHandleCountryInternalCompanyRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyCountry,
    userAccessRights,
    AccessRights.HandlingCountryInternalCompanyRevisions
  );
  const allowedToRequestRevisionBaseOnAccessRightsAndOwningQuote =
    (currentUserIsQuoteRequester(
      shoppingCart.activeQuote.baseQuote ?? shoppingCart.activeQuote,
      userInformation
    ) &&
      allowedToHandleOwnRevisions) ||
    allowedToHandleCompanyRevisions ||
    (allowedToHandleCountryInternalCompanyRevisions && companyIsInternalOrMaintained);
  return (
    (shoppingCart.stateId === ShoppingCartStateTypes.Quote ||
      shoppingCart.stateId === ShoppingCartStateTypes.FrameAgreement ||
      shoppingCart.stateId === ShoppingCartStateTypes.OrderPlaced ||
      shoppingCart.stateId === ShoppingCartStateTypes.OrderPlacedExternally ||
      (shoppingCart.stateId === ShoppingCartStateTypes.Rejected &&
        !!shoppingCart.baseShoppingCartId)) &&
    !isASMOrProcurist &&
    allowedToRequestRevisionBaseOnAccessRightsAndOwningQuote
  );
};
export const showReviseButton = (
  shoppingCart: ShoppingCartDto | undefined | null,
  quote: QuoteDto | undefined | null,
  userInformation: CurrentUserDto | undefined | null,
  userAccessRights: { [key: string]: Array<string> },
  disableQuoting: boolean,
  activeTab?: ShoppingCartTabs
): boolean => {
  if (!shoppingCart || !quote || disableQuoting || !userInformation) {
    return false;
  }
  const canCreateInternalRevisions = hasAnyAccessRight(
    undefined,
    userAccessRights,
    AccessRights.CreatingInternalRevisions
  );

  const canCreateExternalRevisions = hasAnyAccessRight(
    quote.companyCountry,
    userAccessRights,
    AccessRights.CreatingExternalRevisions
  );

  const companyIsInternalOrMaintained =
    shoppingCart.company?.isMaintainedCustomer === true ||
    shoppingCart.company?.isInternal === true;

  const companyIsExternal = !shoppingCart?.company?.isInternal;

  const allowedToReviseBaseOnAccessRightsAndOwningQuote =
    (quote.baseQuote?.quotedById ?? quote.quotedById) === userInformation.id ||
    (canCreateInternalRevisions && companyIsInternalOrMaintained) ||
    (canCreateExternalRevisions && companyIsExternal);

  return (
    (shoppingCart.stateId === ShoppingCartStateTypes.Quote ||
      shoppingCart.stateId === ShoppingCartStateTypes.FrameAgreement ||
      shoppingCart.stateId === ShoppingCartStateTypes.OrderPlaced ||
      shoppingCart.stateId === ShoppingCartStateTypes.OrderPlacedExternally ||
      shoppingCart.stateId === ShoppingCartStateTypes.Archived ||
      (shoppingCart.stateId === ShoppingCartStateTypes.Rejected &&
        !!shoppingCart.baseShoppingCartId)) &&
    allowedToReviseBaseOnAccessRightsAndOwningQuote &&
    (activeTab === undefined || activeTab === ShoppingCartTabs.BuildCart)
  );
};
export const canAcknowledgeAndRejectRevision = (
  shoppingCart: ShoppingCartDto | undefined | null,
  userInformation: CurrentUserDto | undefined | null,
  userAccessRights: { [key: string]: Array<string> }
): boolean => {
  if (
    !shoppingCart ||
    !shoppingCart.activeQuote ||
    !shoppingCart.company ||
    !shoppingCart.activeQuote.baseQuoteId
  ) {
    return false;
  }
  const companyIsInternalOrMaintained =
    shoppingCart.company?.isMaintainedCustomer === true ||
    shoppingCart.company?.isInternal === true;

  const allowedToHandleOwnRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyId,
    userAccessRights,
    AccessRights.HandlingRevisions
  );
  const allowedToHandleCompanyRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyId,
    userAccessRights,
    AccessRights.HandlingCompanyRevisions
  );

  const allowedToHandleCountryInternalCompanyRevisions = hasAnyAccessRight(
    shoppingCart.activeQuote.companyCountry,
    userAccessRights,
    AccessRights.HandlingCountryInternalCompanyRevisions
  );

  const userOwnsRevision =
    currentUserIsQuoteRequester(shoppingCart.activeQuote.baseQuote, userInformation) ||
    currentUserIsQuoteRequester(shoppingCart.activeQuote, userInformation);
  return (
    allowedToHandleCompanyRevisions ||
    (allowedToHandleCountryInternalCompanyRevisions && companyIsInternalOrMaintained) ||
    (allowedToHandleOwnRevisions && userOwnsRevision)
  );
};

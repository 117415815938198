export function primitiveSort(
  a: string | number | undefined,
  b: string | number | undefined
): number {
  if (a === b) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }

  return a < b ? -1 : 1;
}

import { ArticleDto, NodeDto, NodeFlattenedDto } from "api";
import { Action } from "redux";
export enum TrainingActionType {
  SetVisibleArticle = "Training/SetVisibleArticle",
  SetNodeOpen = "Training/SetNodeOpen",
  SetNodeOpenFlat = "Training/SetNodeOpenFlat",
  SetSearchText = "Training/SetSearchText",
  SetLoadingResults = "Training/SetLoadingResults",
  SetPageNumber = "Training/SetPageNumber",
  SetPageSize = "Training/SetPageSize",
  SetForceScroll = "Training/SetForceScroll",
  ResetArticles = "Training/ResetArticles",
  SetScrollNode = "Training/SetScrollNode"
}

export interface SetVisibleArticle extends Action {
  type: TrainingActionType.SetVisibleArticle;
  article: ArticleDto;
  visible: boolean;
}
export interface SetNodeOpen extends Action {
  type: TrainingActionType.SetNodeOpen;
  node: NodeDto | undefined;
}
export interface SetNodeOpenFlat extends Action {
  type: TrainingActionType.SetNodeOpenFlat;
  node: NodeFlattenedDto | undefined;
}
export interface SetSearchText extends Action {
  type: TrainingActionType.SetSearchText;
  search: string;
}
export interface SetLoadingResults extends Action {
  type: TrainingActionType.SetLoadingResults;
  loading: boolean | undefined;
}
export interface SetPageSize extends Action {
  type: TrainingActionType.SetPageSize;
  size: number;
}
export interface SetPageNumber extends Action {
  type: TrainingActionType.SetPageNumber;
  page: number;
}
export interface SetForceScroll extends Action {
  type: TrainingActionType.SetForceScroll;
  force: boolean;
}
export interface ResetArticles extends Action {
  type: TrainingActionType.ResetArticles;
}
export interface SetScrollNode extends Action {
  type: TrainingActionType.SetScrollNode;
  node: NodeDto | undefined;
}
export const NewTrainingActions = {
  setVisibleArticle: (article: ArticleDto, visible: boolean): SetVisibleArticle => ({
    type: TrainingActionType.SetVisibleArticle,
    article,
    visible
  }),
  setNodeOpen: (node: NodeDto | undefined): SetNodeOpen => ({
    type: TrainingActionType.SetNodeOpen,
    node
  }),
  setNodeOpenFlat: (node: NodeFlattenedDto | undefined): SetNodeOpenFlat => ({
    type: TrainingActionType.SetNodeOpenFlat,
    node
  }),
  setSearchText: (search: string): SetSearchText => ({
    type: TrainingActionType.SetSearchText,
    search
  }),
  setLoading: (loading: boolean | undefined): SetLoadingResults => ({
    type: TrainingActionType.SetLoadingResults,
    loading
  }),
  setPageSize: (size: number): SetPageSize => ({
    type: TrainingActionType.SetPageSize,
    size
  }),
  setPageNumber: (page: number): SetPageNumber => ({
    type: TrainingActionType.SetPageNumber,
    page
  }),
  setForceScroll: (force: boolean): SetForceScroll => ({
    type: TrainingActionType.SetForceScroll,
    force
  }),
  resetArticles: () => ({
    type: TrainingActionType.ResetArticles
  }),
  setScrollNode: (node: NodeDto | undefined): SetScrollNode => ({
    type: TrainingActionType.SetScrollNode,
    node
  })
};

export type NewTrainingActions =
  | SetVisibleArticle
  | SetNodeOpen
  | SetNodeOpenFlat
  | SetSearchText
  | SetLoadingResults
  | SetPageNumber
  | SetPageSize
  | SetForceScroll
  | ResetArticles
  | SetScrollNode;

import { Checkbox } from "@abb/abb-common-ux-react";
import { CountryContactPersonRoleTypes } from "api";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const roleOrder: Record<CountryContactPersonRoleTypes, number> = {
  LocalOrderReceiver: 0,
  LocalQuoteReceiver: 1,
  LocalContactRegionalSales: 2,
  LocalTechnicalSupport: 3,
  AreaSalesManager: 4,
  PMS: 5
};

const getCountryRoleNames = (t: TFunction) => ({
  LocalOrderReceiver: t("Local Order Receiver"),
  LocalQuoteReceiver: t("Local Quote Receiver"),
  LocalContactRegionalSales: t("Local Contact (Regional Sales)"),
  LocalTechnicalSupport: t("Local Technical Support"),
  AreaSalesManager: t("Area Sales Manager"),
  PMS: t("PMS")
});

const UnstyledList = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: ${(props) => props.theme.sizes.sm};
`;

interface CountryContactRoleCheckboxListProps {
  roles: CountryContactPersonRoleTypes[];
  setRoles: (roles: CountryContactPersonRoleTypes[]) => void;
}

export const CountryContactRoleCheckboxList = ({
  roles,
  setRoles
}: CountryContactRoleCheckboxListProps) => {
  const { t } = useTranslation();

  const roleNames = getCountryRoleNames(t);

  return (
    <UnstyledList>
      {Object.entries(CountryContactPersonRoleTypes)
        .map((v) => v[1])
        .sort((a, b) => roleOrder[a] - roleOrder[b])
        .map((role) => (
          <li key={role}>
            <Checkbox
              monochrome
              value={roles.includes(role)}
              label={roleNames[role]}
              onChange={() => {
                if (roles.includes(role)) {
                  setRoles(roles.filter((x) => x !== role));
                } else {
                  setRoles(roles.concat(role));
                }
              }}
            />
          </li>
        ))}
    </UnstyledList>
  );
};

import React from "react";
import { MessageDialog } from "@abb/abb-common-ux-react/components/MessageDialog";
import styled from "styled-components";

export const CustomMessageDialog = styled(MessageDialog)`
  .ABB_CommonUX_Dialog__content {
    padding-top: ${(props) => props.theme.sizes.m};
    padding-bottom: ${(props) => props.theme.sizes.lm};
    padding-left: ${(props) => props.theme.sizes.l};
    padding-right: ${(props) => props.theme.sizes.l};
    max-width: 750px;
  }

  .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__header > .ABB_CommonUX_Dialog__closeButton {
    margin: ${(props) => props.theme.sizes.sm};
  }
`;

export interface ConfirmDialogProps {
  confirmEvents: ConfirmEvent[];
  onRemoveConfirmItem(id: string): void;
}

export interface ConfirmEvent {
  id: string;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?(): void;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = (props) => {
  const activeItem = props.confirmEvents.length > 0 ? props.confirmEvents[0] : undefined;
  return activeItem ? (
    <CustomMessageDialog
      monochromeButtons={true}
      buttons="confirmcancel"
      isOpen={true}
      message={activeItem.message}
      title={activeItem.title}
      onConfirm={() => {
        activeItem.onConfirm();
        props.onRemoveConfirmItem(activeItem.id);
      }}
      onCancel={() => {
        props.onRemoveConfirmItem(activeItem.id);
      }}
      onClose={() => {
        props.onRemoveConfirmItem(activeItem.id);
      }}
    />
  ) : null;
};

export default ConfirmDialog;

import { AppState } from "../../../../setup/appRootReducer";
import {
  ManageUsersViewActions,
  ManageUsersViewActionTypes
} from "../actions/manageUsersViewActions";
import {
  ApiUsersGetRequest,
  ApiUsersManageUserCountryCodePutRequest,
  ApiUsersManageUserRolesPutRequest,
  ApiUsersUpdateUserCountryContactRolesPutRequest,
  ApiUsersUpdateUserDisabledManagedPriceListPutRequest
} from "../../../../api/apis/UsersApi";
import { produce } from "immer";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { ApiUsersIdGetRequest } from "../../../../api/apis/UsersApi";
import { UserDto } from "../../../../api/models/UserDto";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { manageUsersSagas } from "../sagas/manageUsersSagas";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import {
  CompanyDto,
  CompanyVm,
  ApiPricesUserPriceListsGetRequest,
  ManageUserVm,
  PartiallyActiveState,
  UserCountryRolesModel,
  UserDisabledManagedPriceListDto,
  UserPriceListVm,
} from "../../../../api";
import { manageCompaniesSagas } from "../../manageCompanies/sagas/manageCompaniesSagas";
import { userSagas } from "applications/common/sagas/userSagas";

interface Requests {
  getUserById: RequestState<ManageUserVm, ApiUsersIdGetRequest>;
  updateUser: RequestState<UserDto, UserDto>;
  updateUserCountryCode: RequestState<UserDto, ApiUsersManageUserCountryCodePutRequest>;
  updateUserRoles: RequestState<ManageUserVm, ApiUsersManageUserRolesPutRequest>;
  getUserPriceLists: RequestState<UserPriceListVm, ApiPricesUserPriceListsGetRequest>;
  updateUserDisabledManagedPriceList: RequestState<
    UserDisabledManagedPriceListDto,
    ApiUsersUpdateUserDisabledManagedPriceListPutRequest
  >;
  setUserCountryContactRoles: RequestState<
    UserCountryRolesModel[],
    ApiUsersUpdateUserCountryContactRolesPutRequest
  >;
}

export interface ManageUsersViewState {
  manageUsersCriteria: ApiUsersGetRequest;
  selectedUserRowId: number | undefined;
  requests: Requests;
  selectedUser: ManageUserVm | undefined;
  selectedUserHomeCompany: CompanyVm | undefined;
  userPriceLists: UserPriceListVm | undefined;
}

export const defaultUsersCriteria: ApiUsersGetRequest = {
  criteriaIsAscendingOrder: true,
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaSortColumn: "lastName"
};

const defaultState: ManageUsersViewState = {
  manageUsersCriteria: defaultUsersCriteria,
  selectedUserRowId: undefined,
  requests: {
    getUserById: createInitialRequest(),
    updateUser: createInitialRequest(),
    updateUserRoles: createInitialRequest(),
    updateUserCountryCode: createInitialRequest(),
    getUserPriceLists: createInitialRequest(),
    updateUserDisabledManagedPriceList: createInitialRequest(),
    setUserCountryContactRoles: createInitialRequest(),
  },
  selectedUser: undefined,
  userPriceLists: undefined,
  selectedUserHomeCompany: undefined
};

export function manageUsersViewReducer(
  state: ManageUsersViewState = defaultState,
  action: ManageUsersViewActions
): ManageUsersViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageUsersSagas.getUserById.actionTypes,
      key: "getUserById"
    },
    {
      actionTypes: manageUsersSagas.updateUser.actionTypes,
      key: "updateUser"
    },
    {
      actionTypes: manageUsersSagas.updateUserRoles.actionTypes,
      key: "updateUserRoles"
    },
    {
      actionTypes: manageCompaniesSagas.deleteCompanyUser.actionTypes,
      key: "deleteCompanyUser"
    },
    {
      actionTypes: manageCompaniesSagas.updateUserCompanyAccess.actionTypes,
      key: "updateUserCompanyAccess"
    },
    {
      actionTypes: manageUsersSagas.updateUserCountryCode.actionTypes,
      key: "updateUserCountryCode"
    },
    {
      actionTypes: manageUsersSagas.getUserPriceLists.actionTypes,
      key: "getUserPriceLists"
    },
    {
      actionTypes: manageUsersSagas.updateUserDisabledManagedPriceList.actionTypes,
      key: "updateUserDisabledManagedPriceList"
    },
    {
      actionTypes: userSagas.setUserCountryContactRoles.actionTypes,
      key: "setUserCountryContactRoles"
    },
    {
      actionTypes: manageCompaniesSagas.getCompanyToManage.actionTypes,
      key: "getCompanyToManage"
    },
  ]);

  if (manageUsersSagas.getUserById.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.selectedUser = action.payload;
    });
  } else if (manageUsersSagas.getUserById.isRequestAction(action)) {
    state = produce(state, (draft) => {
      if (state.selectedUser?.user.id !== action.payload.id) {
        draft.selectedUser = undefined;
      }
    });
  } else if (manageUsersSagas.getUserById.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.selectedUser = undefined;
    });
  } else if (manageCompaniesSagas.deleteCompanyUser.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const indexToRemove = state.selectedUser?.userCompanyRoles.findIndex(
        (ucr) => ucr.company.id === action.payload.companyId
      );
      if (indexToRemove !== undefined && indexToRemove >= 0 && draft.selectedUser) {
        draft.selectedUser.userCompanyRoles.splice(indexToRemove, 1);
      }
    });
  } else if (manageCompaniesSagas.updateUserCompanyAccess.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const index = state.selectedUser?.userCompanyRoles.findIndex(
        (ucr) =>
          ucr.company.id === action.payload.companyId && ucr.userId === action.payload.user.userId
      );
      if (index !== undefined && index >= 0 && draft.selectedUser) {
        draft.selectedUser.userCompanyRoles[index].active = action.payload.user.active;
      }
    });
  } else if (manageUsersSagas.updateUser.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.selectedUser?.user) {
        draft.selectedUser.user.active = action.payload.active;
        draft.selectedUser.user.firstName = action.payload.firstName;
        draft.selectedUser.user.lastName = action.payload.lastName;
      }
    });
  } else if (manageUsersSagas.updateUserCountryCode.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.selectedUser?.user) {
        draft.selectedUser.user.countryCode = action.payload.countryCode;
      }
    });
  } else if (manageUsersSagas.getUserPriceLists.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userPriceLists = action.payload;
    });
  } else if (manageUsersSagas.getUserPriceLists.isRequestAction(action)) {
    state = produce(state, (draft) => {
      if (state.selectedUser?.user.id !== action.payload.userId) {
        draft.userPriceLists = undefined;
      }
    });
  } else if (manageUsersSagas.updateUserDisabledManagedPriceList.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (
        state.selectedUser?.user.id === action.payload.userId &&
        draft.userPriceLists?.userPriceLists
      ) {
        draft.userPriceLists.userPriceLists = draft.userPriceLists.userPriceLists.map((x) => {
          if (x.id == action.payload.managedPriceListId) {
            return { ...x, isDisabled: action.payload.isDisabled };
          }
          return x;
        });
      }
    });
  } else if (userSagas.setUserCountryContactRoles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.selectedUser) {
        draft.selectedUser.userCountryAccessRights = action.payload.map((x) => ({
          country: {
            code: x.country?.code ?? "",
            isInEu: x.country?.isInEu ?? false,
            name: x.country?.name ?? ""
          },
          roleNames: x.roleList ?? [],
          isActive: x.isActive ?? PartiallyActiveState.Inactive
        }));
      }
    });
  } else if (manageCompaniesSagas.getCompanyToManage.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.selectedUser) {
        draft.selectedUserHomeCompany = action.payload;
      }
    });
  }

  switch (action.type) {
    case ManageUsersViewActionTypes.ChangeManageUsersCriteria:
      state = produce(state, (draft) => {
        draft.manageUsersCriteria = action.criteria;
      });
      break;
    case ManageUsersViewActionTypes.StoreClickedGridIndex:
      state = produce(state, (draft) => {
        draft.selectedUserRowId = action.index;
      });
      break;
    case ManageUsersViewActionTypes.SetSelectedUser:
      state = produce(state, (draft) => {
        draft.selectedUser = action.user;
      });
      break;
  }
  return state;
}

export const getSelectedUserRowId = (state: AppState): number | undefined =>
  state.manageUsersView.selectedUserRowId;

export const getManageUsersCriteria = (state: AppState): ApiUsersGetRequest =>
  state.manageUsersView.manageUsersCriteria;

export const getSelectedUser = (state: AppState): ManageUserVm | undefined =>
  state.manageUsersView.selectedUser;

export const getSelectedUserHomeCompany = (state: AppState): CompanyDto | undefined =>
  state.manageUsersView.selectedUserHomeCompany?.company;

export const getUpdateUserStatus = (state: AppState): RequestStatus =>
  state.manageUsersView.requests.updateUser.status;

export const getGetUserByIdStatus = (state: AppState): RequestStatus =>
  state.manageUsersView.requests.getUserById.status;

export const getUpdateUserRolesStatus = (state: AppState): RequestStatus =>
  state.manageUsersView.requests.updateUserRoles.status;

export const getUserPriceLists = (state: AppState): UserPriceListVm | undefined =>
  state.manageUsersView.userPriceLists;

export const getIsUpdateUserRolesStatusLoading = (state: AppState): boolean => {
  const fetchLoading = state.manageUsersView.requests.getUserById.status;
  const updateLoading = state.manageUsersView.requests.updateUserRoles.status;
  if (updateLoading === RequestStatus.Pending || fetchLoading === RequestStatus.Pending) {
    return true;
  }
  return false;
};

import React from "react";
import {
  Grid,
  createHeaderAndHeaderTooltip
} from "../../../../framework/components/grid/Grid";
import {
  GridApi,
  ICellRendererParams,
  CellClickedEvent,
  GridReadyEvent,
  ColDef,
  ITooltipParams,
  ValueGetterParams
} from "ag-grid-community";
import { AccessRights, ApiTeamsGetRequest, TeamDto, TeamMemberDto, TeamMemberRoleDto, TeamMemberSources, TeamMemberStatusDto, TeamTypes } from "../../../../api";
import { getDefaultColumnSort } from "../../../../framework/components/grid/Grid";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { defaultTeamMembersCriteria } from "../reducers/manageTeamsViewReducer";
import { getTeamMemberGridRowStyle, getTeamMemeberStatusText, isTeamAdminRole, roleAbbreviationToFriendlyName } from "utilities/teamUtils";
import { hasAnyAccessRight } from "utilities/authUtils";
import { GridActionsRenderer, GridActionsRendererParams } from "framework/components/grid/GridActionsRenderer";
import { useSelector } from "react-redux";
import { getUserAccessRights } from "applications/common/reducers/userReducer";
import { GridIconRenderer, GridIconRendererProps } from "framework/components/grid/GridIconRenderer";

interface TeamMembersGridProps {
  t: TFunction;
  onSelectTeam?: (teamId: number) => void;
  setGridApi?: ((ref: GridApi) => void) | null;
  criteria?: React.MutableRefObject<ApiTeamsGetRequest | undefined>;
  actionMethods: TeamMembersGridActionMethods;
}

interface TeamMembersGridActionMethods {
  approve: (teamMember: TeamMemberDto) => void;
  reject: (teamMember: TeamMemberDto) => void;
  remove: (teamMember: TeamMemberDto) => void;
  changeRole: (teamMember: TeamMemberDto) => void;
  addToFunctionalTeam: (teamMember: TeamMemberDto) => void;
}

const getActionItems = (t: TFunction, teamMember: TeamMemberDto, isTeamAdmin: boolean, actionMethods: TeamMembersGridActionMethods) => {
  const isApprovable = isTeamAdmin 
    && (teamMember?.status === TeamMemberStatusDto.Requested
      || teamMember?.status === TeamMemberStatusDto.Rejected
      || teamMember?.status === TeamMemberStatusDto.RoleChangeRequested);

  return [
    {
      text: t("Change role"),
      itemId: teamMember?.userId?.toString(),
      disabled: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Approved),
      hide: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Approved),
      selected: false,
      icon: "abb/edit",
      onSelect: () => actionMethods.changeRole(teamMember),
    },
    {
      text: t("Approve"),
      itemId: teamMember?.userId?.toString(),
      disabled: !isApprovable,
      hide: !isApprovable,
      selected: false,
      icon: "abb/add-user",
      onSelect: () => actionMethods.approve(teamMember)
    },
    {
      text: t("Reject"),
      itemId: teamMember?.userId?.toString(),
      disabled: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Requested || teamMember?.status === TeamMemberStatusDto.RoleChangeRequested),
      hide: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Requested || teamMember?.status === TeamMemberStatusDto.RoleChangeRequested),
      selected: false,
      icon: "abb/not-allowed",
      onSelect: () => actionMethods.reject(teamMember)
    },
    {
      text: t("Remove"),
      itemId: teamMember?.userId?.toString(),
      disabled: !(isTeamAdmin && teamMember?.source !== TeamMemberSources.AzureAd),
      hide: !(isTeamAdmin && teamMember?.source !== TeamMemberSources.AzureAd),
      selected: false,
      icon: "abb/trash",
      onSelect: () => actionMethods.remove(teamMember)
    },
    {
      text: t("Add to functional team"),
      itemId: teamMember?.userId?.toString(),
      disabled: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Approved && teamMember?.team?.type === TeamTypes.BusinessUnit),
      hide: !(isTeamAdmin && teamMember?.status === TeamMemberStatusDto.Approved && teamMember?.team?.type === TeamTypes.BusinessUnit),
      selected: false,
      icon: "abb/plus",
      onSelect: () => actionMethods.addToFunctionalTeam(teamMember),
    },
  ];
}

export const TeamMembersGrid = ({
  t,
  onSelectTeam,
  setGridApi,
  actionMethods
}: TeamMembersGridProps): JSX.Element => {
  const userAccessRights = useSelector(getUserAccessRights);

  const buildTooltip = (teamMember: TeamMemberDto | undefined, baseTooltip?: string | undefined): string => {
    if(!teamMember) return baseTooltip ?? "";

    const tooltips = [];

    if (baseTooltip) {
      tooltips.push(baseTooltip);
    }

    if (teamMember?.team?.type === TeamTypes.BusinessUnit) {
      if (teamMember?.source === TeamMemberSources.AzureAd) {
        tooltips.push(t("Business unit assigned by default. Access can not be removed."));

        const hasTeamAdminRole = teamMember?.roles?.some(role => isTeamAdminRole(role.abbreviation ?? "")) ?? false;

        if (hasTeamAdminRole) {
          tooltips.push(t("User admin role assigned externally to first user or by user admin."));
        }
      }
    }
    
    return tooltips.join("\n");
  };

  const colDefs: ColDef<TeamMemberDto>[] = [
    {
      headerName: t("First name"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "firstName"
    },
    {
      headerName: t("Last name"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "lastName"
    },
    {
      headerName: t("Email"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data, params.data?.email ?? undefined),
      field: "email",
    },
    {
      headerName: t("Roles"),
      field: "roles",
      sortable: false,
      valueGetter: (params: ValueGetterParams<TeamMemberDto, TeamMemberRoleDto[]>) => {
        const roleAbbreviations = params?.data?.roles?.map(role => role?.abbreviation) ?? [];
        return roleAbbreviations.join(", ");
      },
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto, TeamMemberRoleDto[]>): string => {
        const friendlyNames = params?.data?.roles?.map(role => `${role?.abbreviation} ${roleAbbreviationToFriendlyName(role?.abbreviation)}`) ?? [];
        return buildTooltip(params.data, friendlyNames.join("\n"));
      },
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams<TeamMemberDto, string>) => {
        const teamMember = params.data;

        const isTeamAdmin = hasAnyAccessRight(
          params.data?.team?.id?.toString() ?? null,
          userAccessRights,
          AccessRights.ManageTeamMembers, AccessRights.SystemAdmin, AccessRights.ManagePlatformUsers);

        const canEditRoles = isTeamAdmin && !!teamMember && teamMember?.status === TeamMemberStatusDto.Approved;

        const cellRendererParams: GridIconRendererProps = {
          ...params,
          showIcon: canEditRoles,
          text: params?.value ?? "",
          icon: "abb/edit",
          onClick: () => canEditRoles && actionMethods.changeRole(teamMember)
        };

        return cellRendererParams;
      }
    },
    {
      headerName: t("Status"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "status",
      sortable: false,
      valueGetter: (params) => getTeamMemeberStatusText(params?.data?.status, t) ?? "",
    },
    {
      cellRenderer: GridActionsRenderer,
      ...createHeaderAndHeaderTooltip("Action"),
      field: "userId",
      tooltipValueGetter: () => "",
      cellRendererParams: (params: ICellRendererParams<TeamMemberDto>) => {
        const teamMember = params.data;
        if (!teamMember) return;

        const isTeamAdmin = hasAnyAccessRight(
          teamMember?.team?.id?.toString() ?? null,
          userAccessRights,
          AccessRights.ManageTeamMembers, AccessRights.SystemAdmin, AccessRights.ManagePlatformUsers);

        const cellRendererParams: GridActionsRendererParams = {
          ...params,
          menuItems: getActionItems(t, teamMember, isTeamAdmin, actionMethods)
        };
        return cellRendererParams;
      },
      minWidth: 70,
      maxWidth: 70,
      floatingFilter: false,
      sortable: false,
      suppressMenu: true,
      filter: false,
      resizable: false,
    },
  ];

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if(setGridApi) {
        setGridApi(event.api);
      }
    },
    [setGridApi]
  );

  return (
    <Grid
      t={t}
      getRowStyle={(params) => getTeamMemberGridRowStyle(params)}
      statusBarOptions={{ showResetButton: true, showRowCount: true }}
      columnDefs={colDefs}
      rowModelType="infinite"
      isSortPersisted={true}
      defaultSort={getDefaultColumnSort(
        defaultTeamMembersCriteria.criteriaIsAscendingOrder,
        defaultTeamMembersCriteria.criteriaSortColumn
      )}
      
      rowSelection="single"
      cacheBlockSize={100}
      maxBlocksInCache={2}
      onCellClicked={(event: CellClickedEvent) => {
        const team = event.data as TeamDto;
        if(team?.id && onSelectTeam) {
          onSelectTeam(team.id);
        }
      }}
      showPointerOnRowHover={false}
      keepEditingIfFocusIsLost
      colDefDefault={
        {
          sortable: false
        }
      }
      getRowId={(params) => params.data.userId.toString()}
      
      suppressRowClickSelection={true}
      suppressCellFocus={true}
      onGridReady={onGridReady}
      autosizeCols
      disableColumnAutoSize
    />
  );
};
import { TeamDto, ManageUserRoleDto } from "api";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { InputLabel } from "framework/components/InputLabel";
import { getTeamTypeFriendlyName } from "utilities/teamUtils";
import { useDispatch, useSelector } from "react-redux";
import { AddTeamBaseDialog, AddTeamBaseDialogComponent } from "./AddTeamBaseDialog";
import { UserRoles } from "./UserRoles";
import { RoleSelectionType } from "../containers/ManageUserView";
import { useUserRoles } from "framework/hooks/useUserRoles";
import { LoadingIndicator } from "@abb/abb-common-ux-react/components/LoadingIndicator";
import { RequestStatus } from "framework/state/requestStatus";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { getGetUserTeamRolesRequest, getUserTeamRoles } from "applications/manage/manageTeams/reducers/manageTeamsViewReducer";

export enum TeamAccessRightsDialogType {
  AcceptInvitation,
  RequestTeamAccess,
  RequestAccessRights,
  SetUserAccessRights
}

interface TeamAccessRightsDialogProps {
  t: TFunction;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  dialogType: TeamAccessRightsDialogType;
  userId: number | undefined;
  team: TeamDto;
  onSave: (userId: number, team: TeamDto, userRoles: ManageUserRoleDto[] | undefined) => void;
}

export const TeamAccessRightsDialog = ({
  t,
  isOpen,
  setIsOpen,
  isLoading,
  dialogType,
  userId,
  team,
  onSave,
}: TeamAccessRightsDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  
  const userTeamRoles = useSelector(getUserTeamRoles);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);

  const getUserTeamRolesRequest = useSelector(getGetUserTeamRolesRequest);

  useEffect(() => {
    if(userId) {
      dispatch(manageTeamsSagas.getUserTeamRoles.createAction({ userId }));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if(getUserTeamRolesRequest.status == RequestStatus.Pending) {
      setIsLoadingUser(true);
    }
    if(getUserTeamRolesRequest.status == RequestStatus.Completed || getUserTeamRolesRequest.status == RequestStatus.Failed) {
      setIsLoadingUser(false);
    }
  }, [getUserTeamRolesRequest]);

  // Creating a team on the fly without somekind of memoization will cause infinite loop when using in useUserRoles method.
  const teamUser = useMemo(() => {
    return {
      user: {
        id: userId
      },
      userTeamRoles: userTeamRoles
    };
  }, [userId, userTeamRoles]);

  const {
    roles,
    userRoles,
    setUserRoles,
    accessRights,
  } = useUserRoles(teamUser, undefined, undefined, true, team);

  useEffect(() => {
    if(roles && (userRoles?.length ?? 0) === 0) {
      const defaultRoles = roles.map((roleGroup) => {
        return {
          ...roleGroup,
          roles: roleGroup.roles.map((role) => {
            return { ...role, defaultRole: role.defaultRole };
          })
        };
      });

      setUserRoles([...defaultRoles.flatMap((roleGroup) => roleGroup.roles).filter((role) => role.defaultRole)]);
    }
  }, [roles, userRoles, setUserRoles]);

  const getDialogTitle = (): string => {
    switch(dialogType) {
      case TeamAccessRightsDialogType.AcceptInvitation:
        return t("Accept invitation to {{teamName}}", { teamName: team.name });
      case TeamAccessRightsDialogType.RequestTeamAccess:
        return t("Request access to {{teamName}}", { teamName: team.name });
      case TeamAccessRightsDialogType.RequestAccessRights:
        return t("Select requested roles for {{teamName}}", { teamName: team.name });
      case TeamAccessRightsDialogType.SetUserAccessRights:
        return t("Set access rights for {{teamName}}", { teamName: team.name });
      default:
        return "";
    }
  };

  const getRoleTableInstructiveTitle = (): string => {
    switch(dialogType) {
      case TeamAccessRightsDialogType.AcceptInvitation:
        return t("Accept invitation to {{teamType}} {{teamName}} with following roles", { teamType: getTeamTypeFriendlyName(team.type, t).toLowerCase(), teamName: team.name });
      case TeamAccessRightsDialogType.RequestTeamAccess:
        return t("Select requested roles within {{teamType}} and save to send access request to team", { teamType: getTeamTypeFriendlyName(team.type, t).toLowerCase() });
      case TeamAccessRightsDialogType.RequestAccessRights:
        return t("Select roles to request access rights for  {{teamName}}", { teamName: team.name });
      case TeamAccessRightsDialogType.SetUserAccessRights:
        return t("Select roles to set access rights for {{teamName}}", { teamName: team.name });
      default:
        return "";
    }
  }

  return (
    <AddTeamBaseDialog
      t={t}
      isOpen={isOpen}
      isLoading={isLoading}
      width="1012px"
      setIsOpen={setIsOpen}
      title={getDialogTitle()}
      isBusinessUnitTeamGridVisible={false}
      primaryButtonText={t("Save")}
      primaryButtonAction={() => {
        if(userId && team) {
          onSave(userId, team, userRoles);
        }
      }}
      isSecondaryButtonVisible={true}
      secondaryButtonText={t("Cancel")}
      userId={userId}
    >
      <AddTeamBaseDialogComponent.SectionsContainer>
        <AddTeamBaseDialogComponent.TextWrapper>
          <InputLabel
            label={getRoleTableInstructiveTitle()}
          />
        </AddTeamBaseDialogComponent.TextWrapper>
        <AddTeamBaseDialogComponent.InputWrapper>
          { isLoadingUser ?
            <LoadingIndicator determinate={false} style={{ margin: "auto" }} type="radial" />
          : <UserRoles
            isInternal={false}
            isDeliverItRelevantCompany={false}
            roleSelectionType={RoleSelectionType.TEAM}
            roleGroups={roles}
            userRoles={userRoles}
            setUserRoles={setUserRoles}
            accessRights={accessRights}
            saveChanges={undefined}
            setIsOpen={setIsOpen}
            isSaveEnabled={false}
            isLoading={false}
            hasEditRights={dialogType !== TeamAccessRightsDialogType.AcceptInvitation}
            isTeamDialog={true}
            teamType={team.type}
            showDefaultRolesAsSelected={true}
            hideRoleGroupLabel={true}
          />
          }
        </AddTeamBaseDialogComponent.InputWrapper>
      </AddTeamBaseDialogComponent.SectionsContainer>
    </AddTeamBaseDialog>
  );
};

import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { AppContentWrapper } from "../../../../framework/components/AppContentWrapper";
import { ManageCompaniesGrid, ManageCompaniesGridUsage } from "../components/CompaniesGrid";
import { GridApi, IGetRowsParams } from "ag-grid-community";
import {
  ApiCompaniesExportPostRequest,
  ApiCompaniesGetRequest,
  ApiCompaniesOverrideStatusPatchRequest,
  CompanyDto
} from "../../../../api";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";
import {
  AgGridSortModel,
  GridExportParams,
  getSortModel
} from "../../../../framework/components/grid/Grid";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../common/actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";
import { manageCompaniesSagas } from "../sagas/manageCompaniesSagas";
import {
  getUpdateCompanyOverrideStatusRequest,
  getOverrideStatusAuditRequest,
  getOverrideStatusAuditStatus
} from "../reducers/manageCompaniesReducer";
import { usePrevious } from "../../../../framework/hooks/usePrevious";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import { getUserAccessRights } from "../../../common/reducers/userReducer";
import { isAuthorizedToComponent } from "../../../../utilities/authUtils";
import { formCompanyCriteriaFilters } from "../../../../utilities/filters";
import styled from "styled-components";
import { AuditForm } from "../components/AuditForm";
import AuthorizedComponent from "../../../../framework/components/AuthorizedComponent";
import { AuditType } from "../../../../enums/auditType";
import { deliveriesHomeSagas } from "../../../deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { CompanyOverrideStatus } from "../../../../enums/grid/companyOverrideStatus";
import { apiGridErrorHandler } from "../../../../utilities/errorUtils";
import { useTranslation } from "react-i18next";
import { AccessRights } from "../../../../api/models/AccessRights";
import { routes } from "utilities/routes";
import { exportServerSideCsv } from "utilities/gridExportUtils";

const requiredAccessRights: AccessRights[] = [
  AccessRights.CompanyAdmin,
  AccessRights.ManageDeliveriesCompanies,
  AccessRights.ViewCompanies,
  AccessRights.ManageConfiguratorUsers,
  AccessRights.ManagePlatformUsers,
  AccessRights.ViewDeliveriesUsers,
  AccessRights.ViewConfiguratorUsers
];

export const manageCompaniesDefaultCriteria: ApiCompaniesGetRequest = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaGetOnlyActive: false,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "name",
  criteriaRequireAnyOfAccessRight: requiredAccessRights
};

// Negative margin to push the content up as much as is the defined margin-bottom in the header.
const CompanyOverrideStatusAuditContainer = styled.div`
  margin-left: auto;
  margin-top: ${(props) => "-" + props.theme.sizes.xl};
`;

const ManageCompaniesView: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userAccessRights = useSelector(getUserAccessRights);
  const updateCompanyOverrideStatusRequest = useSelector(getUpdateCompanyOverrideStatusRequest);
  const overrideStatusAuditStatus = useSelector(getOverrideStatusAuditStatus);
  const overrideStatusAuditRequest = useSelector(getOverrideStatusAuditRequest);
  const prevUpdateCompanyOverrideStatusRequest = usePrevious(updateCompanyOverrideStatusRequest);
  const locationSearch = useLocation().search;
  const queryParams = useMemo(() => new URLSearchParams(locationSearch), [locationSearch]);

  // Companies grid
  const [manageCompaniesGrid, setManageCompaniesGrid] = useState<GridApi | null>(null);

  const onSetManageCompaniesGridRefSet = useCallback((ref: GridApi) => {
    setManageCompaniesGrid(ref);
  }, []);

  const produceCriteria = useCallback(
    (filterModel: any, sortModel?: AgGridSortModel, page?: number, rowCount?: number) => {
      const filterCriteria = formCompanyCriteriaFilters(filterModel);
      return produce(manageCompaniesDefaultCriteria, (draft) => {
        draft.criteriaSortColumn = sortModel?.sortColumn ?? draft.criteriaSortColumn;
        if (page !== undefined) draft.criteriaPage = page;
        draft.criteriaIsAscendingOrder = sortModel?.isAsc ?? draft.criteriaIsAscendingOrder;
        draft.criteriaName = filterCriteria.criteriaName;
        draft.criteriaAddressCity = filterCriteria.criteriaAddressCity;
        draft.criteriaCountryCode = filterCriteria.criteriaCountryCode;
        draft.criteriaAddressStreet = filterCriteria.criteriaAddressStreet;
        draft.criteriaGetPgCodes = true;
        draft.criteriaGlobalCustomerNumber = filterCriteria.criteriaGlobalCustomerNumber;
        draft.criteriaLastActiveFrom = filterCriteria.criteriaLastActiveFrom;
        draft.criteriaLastActiveTo = filterCriteria.criteriaLastActiveTo;
        draft.criteriaHasLateAccessAudit = filterCriteria.criteriaHasLateAccessAudit;
        draft.criteriaOverrideStatus = filterCriteria.criteriaOverrideStatus;
        draft.criteriaStatus = filterCriteria.criteriaStatus;
        draft.criteriaCustomerCode = filterCriteria.criteriaCustomerCode;
        draft.criteriaHasCompanyAdminStatus = filterCriteria.criteriaHasCompanyAdminStatus;
        draft.criteriaSkipTotalRowCount = page !== 0 && rowCount !== undefined;
      });
    },
    []
  );

  const companiesDataSource = (companiesCriteria: ApiCompaniesGetRequest) => {
    let rowCount: number | undefined = undefined;
    return {
      getRows: (params: IGetRowsParams) => {
        manageCompaniesGrid?.showLoadingOverlay();
        const page = Math.floor(params.startRow / companiesCriteria.criteriaPageSize);
        const api = getApiRegistry().companiesApi;

        const sortModel = getSortModel(params.sortModel);
        const newCriteria = produceCriteria(params.filterModel, sortModel, page, rowCount);

        api
          .apiCompaniesGet(newCriteria)
          .then((res) => {
            if (newCriteria.criteriaSkipTotalRowCount !== true) {
              rowCount = res.criteria.totalCount;
            }

            params.successCallback(res.companies, rowCount);
            if (res.companies.length === 0) {
              manageCompaniesGrid?.showNoRowsOverlay();
            } else {
              manageCompaniesGrid?.hideOverlay();
            }
            manageCompaniesGrid?.sizeColumnsToFit();
          })
          .catch((err) => {
            apiGridErrorHandler(err, dispatch);
            manageCompaniesGrid?.hideOverlay();
          });
      }
    };
  };

  const companiesDataSourceCallBack = useCallback(companiesDataSource, [
    manageCompaniesGrid,
    produceCriteria,
    dispatch
  ]);

  useEffect(() => {
    if (manageCompaniesGrid) {
      manageCompaniesGrid.setDatasource(
        companiesDataSourceCallBack(manageCompaniesDefaultCriteria)
      );
    }
  }, [manageCompaniesGrid, companiesDataSourceCallBack]);

  useEffect(() => {
    if (
      updateCompanyOverrideStatusRequest.status === RequestStatus.Completed &&
      prevUpdateCompanyOverrideStatusRequest?.status === RequestStatus.Pending
    ) {
      manageCompaniesGrid?.redrawRows();
      manageCompaniesGrid?.refreshInfiniteCache();
    }
  }, [
    updateCompanyOverrideStatusRequest,
    prevUpdateCompanyOverrideStatusRequest,
    manageCompaniesGrid
  ]);

  // Get system settings

  useEffect(() => {
    dispatch(deliveriesHomeSagas.getSystemSettings.createAction(undefined));
  }, [dispatch]);

  // Set override status filter if defined
  // As a refactoring task, could think of a more generic solution.
  useEffect(() => {
    if (queryParams && manageCompaniesGrid) {
      const overrideStatusFilter = queryParams.get("overrideStatusActive");
      if (overrideStatusFilter) {
        const currentFilterModel = manageCompaniesGrid.getFilterModel();
        let values: string[] | undefined = undefined;

        if (overrideStatusFilter === "1" || overrideStatusFilter === "true") {
          values = [CompanyOverrideStatus.Set];
        } else if (overrideStatusFilter === "0" || overrideStatusFilter === "false") {
          values = [CompanyOverrideStatus.NotSet];
        }

        manageCompaniesGrid.setFilterModel({
          ...currentFilterModel,
          overrideStatusActive: {
            filterType: "set",
            values: values
          }
        });
      }
    }
  }, [queryParams, manageCompaniesGrid]);

  const handleCompanyChange = (companyId: string) => {
    navigate(routes.manage.company.replace(":companyId", companyId));
  };

  const { t } = useTranslation();

  const handleEditOverrideStatus = (
    command: ApiCompaniesOverrideStatusPatchRequest,
    company: CompanyDto
  ) => {
    const confirmAction = () =>
      dispatch(manageCompaniesSagas.updateCompanyOverrideStatus.createAction(command));

    dispatch(
      userActions.addConfirmEvent(
        confirmAction,
        t("Change company override status"),
        t(
          "Click confirm to change the override status to '<overrideStatus>' for company <companyName> (<companyId>)"
        )
          .replace(
            "<overrideStatus>",
            (command.updateCompanyOverrideStatusCommand?.overrideStatus || false).toString()
          )
          .replace("<companyName>", company.name)
          .replace("<companyId>", company.id.toString())
      )
    );
  };

  const handleOverrideStatusAudit = () => {
    const confirmAction = () =>
      dispatch(manageCompaniesSagas.overrideStatusAudit.createAction(undefined));

    dispatch(
      userActions.addConfirmEvent(
        confirmAction,
        t("Perform override status audit?"),
        t("Make sure, override status has been activated only for the needed companies")
      )
    );
  };

  const handleExport = useCallback(
    (params: GridExportParams) => {
      const api = getApiRegistry().companiesApi;
      const sortModel = getSortModel(params.columnApi.getColumnState());
      const newCriteria = produceCriteria(params.api.getFilterModel(), sortModel);

      exportServerSideCsv<ApiCompaniesExportPostRequest>(
        newCriteria,
        api.apiCompaniesExportPost.bind(api),
        params.api,
        dispatch,
        t
      );
    },
    [dispatch, produceCriteria, t]
  );

  return (
    <AppContentWrapper pageTitle={t("Companies")}>
      <AuthorizedComponent accessRights={[AccessRights.DeliverItAdmin]}>
        <CompanyOverrideStatusAuditContainer>
          <AuditForm
            auditCompleted={overrideStatusAuditStatus?.overrideStatusAuditCompleted}
            nextAuditDue={overrideStatusAuditStatus?.overrideStatusAuditDueTo}
            isLoading={overrideStatusAuditRequest.status === RequestStatus.Pending}
            onMarkAccessAuditDone={handleOverrideStatusAudit}
            auditType={AuditType.OverrideStatusAudit}
          />
        </CompanyOverrideStatusAuditContainer>
      </AuthorizedComponent>
      <ManageCompaniesGrid
        onExportCsv={handleExport}
        setGridApi={onSetManageCompaniesGridRefSet}
        gridApi={manageCompaniesGrid}
        onSelectCompany={handleCompanyChange}
        disableRowSelection
        onUpdateCompanyOverrideStatus={handleEditOverrideStatus}
        canEditOverrideStatus={isAuthorizedToComponent(userAccessRights, {
          operation: "all",
          accessRights: [AccessRights.DeliverItAdmin]
        })}
        canSeeAccessAuditDueTo={isAuthorizedToComponent(userAccessRights, {
          operation: "any",
          accessRights: [AccessRights.DeliverItAdmin, AccessRights.ManageDeliveriesCompanies]
        })}
        usageType={ManageCompaniesGridUsage.CompanyListing}
      />
    </AppContentWrapper>
  );
};

export default ManageCompaniesView;

import React, { useState } from "react";
import { Button } from "framework/components/Button";
import styled from "styled-components";
import { TFunction } from "i18next";
import { Icon, Popup, Tooltip, WithPopup, WithTooltip } from "@abb/abb-common-ux-react";
import { ShoppingCartTabs } from "../../../enums/shoppingCartTabs";
import { QuoteStateTypes, ShoppingCartDto, ShoppingCartStateTypes } from "api";
import { useSelector } from "react-redux";
import {
  getDisableOrdering,
  getDisableQuoting
} from "applications/common/reducers/shoppingCartReducer";
import { PriceContainer, PriceRedDot, PriceText } from "./GridPriceAndActionsRenderer";
import { formatCurrency } from "utilities/currencyUtils";
import { Accordion, AccordionSummary } from "@mui/material";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { DisabledWithTooltip } from "../helpers";

const StickyContainer = styled.div`
  box-shadow: 0 -3px 3px -3px #00000033;
  position: sticky;
  bottom: 0px;
  margin-top: auto;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.shoppingCartStickyContainer};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  width: 100%;
  background-color: ${(props) => props.theme.colors.brandWhite};
  padding-block: ${(props) => props.theme.sizes.m};
`;

const Text = styled.p<{ isBold?: boolean }>`
  font-family: ${(props) =>
    props.isBold
      ? props.theme.fonts.families.fontAbbMedium
      : props.theme.fonts.families.fontAbbRegular};
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  line-height: 25px;
  hyphens: auto;
`;

const CustomIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.sizes.m};
`;

const EndButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.sizes.m};
  row-gap: ${(props) => props.theme.sizes.s};
  margin: 0;
`;

const LinkWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  ${(props) =>
    props.disabled
      ? `
  cursor: not-allowed;
  color: ${props.theme.colors.grey40};
`
      : `
  :hover {
    cursor: pointer;  
    p {
      text-decoration: underline;
    }
  }
`}
`;

const PricesContainer = styled.div`
  justify-content: center;
  align-items: baseline;
`;

const CustomPriceContainer = styled(PriceContainer)`
  display: flex;
`;

const EmptyFlexItem = styled.div`
  display: flex;
  flex: 1;
`;

const TotalPriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${(props) => props.theme.sizes.m};
  row-gap: ${(props) => props.theme.sizes.s};
  justify-content: center;
  align-items: baseline;
`;

const getPrimaryText = (
  activeTab: ShoppingCartTabs,
  t: TFunction,
  shoppingCartStateId: ShoppingCartStateTypes | undefined,
  reviseMode: boolean
) => {
  if (reviseMode) {
    return t("Create revision");
  }
  switch (activeTab) {
    case ShoppingCartTabs.BuildCart:
      if (
        shoppingCartStateId === ShoppingCartStateTypes.FrameAgreement ||
        shoppingCartStateId === ShoppingCartStateTypes.WaitingForFrameAgreement ||
        shoppingCartStateId === ShoppingCartStateTypes.WaitingForFrameAgreementApproval
      ) {
        return t("Make partial order");
      } else if (shoppingCartStateId === ShoppingCartStateTypes.WaitingForRevision) {
        return t("Acknowledge");
      }
      return t("Proceed to order details");
    case ShoppingCartTabs.OrderDetails:
      return t("Confirm order");
    case ShoppingCartTabs.ConfirmOrder:
      if (shoppingCartStateId === ShoppingCartStateTypes.OrderWaitingForApproval) {
        return t("Approve order");
      }
      return t("Request approval");
    default:
      return "";
  }
};

const getSecondaryText = (
  activeTab: ShoppingCartTabs,
  t: TFunction,
  shoppingCartStateId: ShoppingCartStateTypes | undefined,
  isShoppingCartExpired: boolean,
  shoppingCartQuoteStateId: QuoteStateTypes | undefined
) => {
  switch (activeTab) {
    case ShoppingCartTabs.BuildCart:
      if (shoppingCartStateId === ShoppingCartStateTypes.WaitingForQuote) {
        return t("Cancel quote request");
      }
      if (shoppingCartStateId === ShoppingCartStateTypes.Quote) {
        if (isShoppingCartExpired) {
          return t("Request quote");
        } else {
          return t("Reject quote");
        }
      }

      if (shoppingCartStateId === ShoppingCartStateTypes.FrameAgreement) {
        return t("Reject frame agreement");
      }

      if (
        shoppingCartStateId === ShoppingCartStateTypes.WaitingForFrameAgreementApproval ||
        shoppingCartStateId === ShoppingCartStateTypes.WaitingForFrameAgreement
      ) {
        // This should never be returned
        return "";
      }

      if (shoppingCartQuoteStateId === QuoteStateTypes.Draft) {
        return t("Continue quote request");
      }

      if (shoppingCartStateId === ShoppingCartStateTypes.WaitingForRevision) {
        return t("Reject revision");
      }

      return t("Request a new quote");
    case ShoppingCartTabs.OrderDetails:
      return t("Return to cart");
    case ShoppingCartTabs.ConfirmOrder:
      if (shoppingCartStateId === ShoppingCartStateTypes.OrderWaitingForApproval) {
        return t("Reject order");
      }

      return t("Return to order details ");
  }
};

const getTotalPriceComponent = (
  t: TFunction,
  shoppingCart: ShoppingCartDto,
  hidePrices: boolean
) => {
  const hasQuotedPrice = shoppingCart.totalQuotedPrice !== shoppingCart.totalPrice;
  const displayDiscountRate = shoppingCart.totalDiscountRate
    ? shoppingCart.totalDiscountRate >= 0
      ? `(${t("avg.")} -${shoppingCart.totalDiscountRate} %)`
      : `(${t("avg.")} +${-shoppingCart.totalDiscountRate} %)`
    : null;

  return (
    <TotalPriceContainer>
      <Text>{t("Total price")}</Text>
      <PriceContainer>
        <PriceText lineThrough={hasQuotedPrice}>
          {hidePrices
            ? t("(Hidden)")
            : formatCurrency(shoppingCart.totalPrice, shoppingCart.priceListCurrency ?? "")}
        </PriceText>
        {hasQuotedPrice ? <PriceRedDot /> : null}
      </PriceContainer>
      {hasQuotedPrice ? (
        <>
          <PriceContainer>
            <PriceText>
              {hidePrices
                ? t("(Hidden)")
                : formatCurrency(
                    shoppingCart.totalQuotedPrice,
                    shoppingCart.priceListCurrency ?? ""
                  )}
            </PriceText>
          </PriceContainer>
          <PriceContainer>
            <PriceText>{displayDiscountRate}</PriceText>
          </PriceContainer>
        </>
      ) : null}
    </TotalPriceContainer>
  );
};

const getTotalSalesPriceComponent = (
  t: TFunction,
  shoppingCart: ShoppingCartDto,
  hidePrices: boolean
) => {
  return (
    <TotalPriceContainer>
      <Text>{t("Total sales price")}</Text>
      <CustomPriceContainer>
        <PriceText>
          {hidePrices
            ? t("(Hidden)")
            : formatCurrency(
                shoppingCart.totalSalesPrice ?? 0,
                shoppingCart.priceListCurrency ?? ""
              )}
        </PriceText>
      </CustomPriceContainer>
    </TotalPriceContainer>
  );
};

interface BottomSectionPropsShoppingCart {
  t: TFunction;
  shoppingCartDto: ShoppingCartDto | undefined;
  activeTab: ShoppingCartTabs;
  isPrimaryDisabled: DisabledWithTooltip;
  primaryAction: () => void;
  secondaryAction: () => void;
  isPrimaryLoading: boolean;
  setIsProductDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSecondaryDisabled?: DisabledWithTooltip;
  isQuoteExpired: boolean;
  areButtonsHidden: boolean;
  statusBanner: JSX.Element | null;
  reviseMode: boolean;
  isTotalSalesPriceVisible: boolean;
}

interface BottomSectionPropsQuote {
  t: TFunction;
  shoppingCartDto: ShoppingCartDto | undefined;
  isSecondaryButtonVisible: boolean;
  secondaryButtonText: string;
  secondaryButtonAction: () => void;
  isSecondaryButtonDisabled: boolean;
  isPrimaryButtonVisible: boolean;
  isPrimaryButtonDisabled: boolean;
  primaryButtonText: string | undefined;
  primaryButtonAction: () => void;
  isPrimaryButtonLoading: boolean;
  isToOrderButtonVisible: boolean;
  toOrderButtonAction: () => void;
  isToOrderButtonDisabled: boolean;
  isToOrderButtonText: string;
  isTertiaryButtonVisible: boolean;
  tertiaryButtonText: string;
  tertiaryButtonAction: () => void;
  isTertiaryButtonDisabled: boolean;
  statusBanner: JSX.Element;
  setIsProductDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reviseMode: boolean;
  isTotalSalesPriceVisible: boolean;
}

export const BottomSectionShoppingCart = ({
  t,
  shoppingCartDto,
  activeTab,
  isQuoteExpired,
  isPrimaryDisabled,
  primaryAction,
  isPrimaryLoading,
  setIsProductDialogOpen,
  isSecondaryDisabled,
  secondaryAction,
  areButtonsHidden,
  statusBanner,
  reviseMode,
  isTotalSalesPriceVisible
}: BottomSectionPropsShoppingCart): JSX.Element => {
  const disableOrdering = useSelector(getDisableOrdering);
  const disableQuoting = useSelector(getDisableQuoting);
  const userInformation = useSelector(getUserInformation);

  const [bannerExpanded, setBannerExpanded] = useState(true);
  const [bannerLocked, setBannerLocked] = useState(false);

  const hidePrices = !shoppingCartDto?.managedPriceListId || userInformation?.demoMode || false;

  const shoppingCartStateId = shoppingCartDto?.stateId;
  const shoppingCartQuoteStateId = shoppingCartDto?.draftQuote?.stateId;

  return (
    <StickyContainer>
      {statusBanner && (
        <Accordion
          onMouseEnter={() => {
            if (!bannerLocked) setBannerExpanded(true);
          }}
          onMouseLeave={() => {
            if (!bannerLocked) setBannerExpanded(false);
          }}
          expanded={bannerLocked || bannerExpanded}
          sx={{ display: "flex", flexDirection: "column-reverse" }} // Flip accordion direction to up
          disableGutters={true}
        >
          <AccordionSummary
            onClick={() => {
              setBannerLocked(!bannerLocked);
              if (!bannerLocked) setBannerExpanded(false);
            }}
            expandIcon={
              <Icon
                name={bannerLocked ? "abb/lock-closed" : "abb/view"}
                sizeClass="large"
                style={{ transform: "scaleY(-1)" }} // Flip image to normal
              />
            }
          />
          {statusBanner}
        </Accordion>
      )}
      <Container>
        {activeTab === ShoppingCartTabs.BuildCart &&
        (!shoppingCartStateId ||
          shoppingCartStateId === ShoppingCartStateTypes.Draft ||
          reviseMode) ? (
          shoppingCartDto?.isBasedOnFrameAgreement && !reviseMode ? (
            <LinkWrapper disabled aria-disabled="true">
              <WithTooltip>
                <div style={{ display: "flex" }}>
                  <CustomIcon name="abb/plus" sizeClass="medium" />
                  <Text isBold>{t("Add another product")}</Text>
                </div>
                <Tooltip>
                  {t("Can't add products to shopping carts that are based on frame agreements")}
                </Tooltip>
              </WithTooltip>
            </LinkWrapper>
          ) : (
            // TODO: <div> with `onClick` has bad accessibility. Use <button> instead
            <LinkWrapper
              onClick={() =>
                !(shoppingCartDto?.isFrameAgreementWithPartialOrder ?? false)
                  ? setIsProductDialogOpen(true)
                  : undefined
              }
              disabled={shoppingCartDto?.isFrameAgreementWithPartialOrder ?? false}
            >
              <WithTooltip>
                <div style={{ display: "flex" }}>
                  <CustomIcon name="abb/plus" sizeClass="medium" />
                  <Text isBold>{t("Add another product")}</Text>
                </div>
                <Tooltip disabled={!(shoppingCartDto?.isFrameAgreementWithPartialOrder ?? false)}>
                  {t("Can't add products to frame agreement that have partial orders")}
                </Tooltip>
              </WithTooltip>
            </LinkWrapper>
          )
        ) : (
          <EmptyFlexItem />
        )}
        {shoppingCartDto?.totalPrice != undefined ||
        shoppingCartDto?.totalSalesPrice != undefined ? (
          <PricesContainer>
            {shoppingCartDto?.totalPrice != undefined ? (
              getTotalPriceComponent(t, shoppingCartDto, hidePrices)
            ) : (
              <EmptyFlexItem />
            )}
            {isTotalSalesPriceVisible && shoppingCartDto?.totalSalesPrice != undefined ? (
              getTotalSalesPriceComponent(t, shoppingCartDto, hidePrices)
            ) : (
              <EmptyFlexItem />
            )}
          </PricesContainer>
        ) : (
          <EmptyFlexItem />
        )}
        {!areButtonsHidden ? (
          <EndButtonContainer>
            {isSecondaryDisabled &&
              isSecondaryDisabled.visibility !== "hidden" &&
              (isSecondaryDisabled.tooltip ? (
                <WithTooltip>
                  <Button
                    disabled={isSecondaryDisabled.visibility === "disabled" || disableQuoting}
                    buttonType="secondary"
                    text={getSecondaryText(
                      activeTab,
                      t,
                      shoppingCartStateId,
                      isQuoteExpired,
                      shoppingCartQuoteStateId
                    )}
                    onClick={secondaryAction}
                  />
                  <Tooltip>{isSecondaryDisabled.tooltip}</Tooltip>
                </WithTooltip>
              ) : (
                <Button
                  disabled={isSecondaryDisabled?.visibility === "disabled" || disableQuoting}
                  buttonType="secondary"
                  text={getSecondaryText(
                    activeTab,
                    t,
                    shoppingCartStateId,
                    isQuoteExpired,
                    shoppingCartQuoteStateId
                  )}
                  onClick={secondaryAction}
                />
              ))}
            {isPrimaryDisabled &&
              isPrimaryDisabled.visibility !== "hidden" &&
              (isPrimaryDisabled.tooltip ? (
                <WithTooltip>
                  <Button
                    onClick={primaryAction}
                    icon={"abb/right-arrow"}
                    buttonType="primary"
                    text={getPrimaryText(activeTab, t, shoppingCartStateId, reviseMode)}
                    disabled={isPrimaryDisabled.visibility === "disabled" || disableOrdering}
                    isLoading={isPrimaryLoading}
                  />
                  <Tooltip>{isPrimaryDisabled.tooltip}</Tooltip>
                </WithTooltip>
              ) : (
                <Button
                  onClick={primaryAction}
                  icon={"abb/right-arrow"}
                  buttonType="primary"
                  text={getPrimaryText(activeTab, t, shoppingCartStateId, reviseMode)}
                  disabled={isPrimaryDisabled?.visibility === "disabled" || disableOrdering}
                  isLoading={isPrimaryLoading}
                />
              ))}
          </EndButtonContainer>
        ) : (
          <EmptyFlexItem />
        )}
      </Container>
    </StickyContainer>
  );
};

export const BottomSectionQuote = ({
  t,
  shoppingCartDto,
  isSecondaryButtonVisible,
  secondaryButtonText,
  secondaryButtonAction,
  isSecondaryButtonDisabled,
  isPrimaryButtonVisible,
  isPrimaryButtonDisabled,
  primaryButtonText,
  primaryButtonAction,
  isPrimaryButtonLoading,
  isToOrderButtonVisible,
  toOrderButtonAction,
  isToOrderButtonDisabled,
  isToOrderButtonText,
  isTertiaryButtonVisible,
  tertiaryButtonText,
  tertiaryButtonAction,
  isTertiaryButtonDisabled,
  statusBanner,
  setIsProductDialogOpen,
  reviseMode,
  isTotalSalesPriceVisible
}: BottomSectionPropsQuote): JSX.Element => {
  const disableOrdering = useSelector(getDisableOrdering);
  const userInformation = useSelector(getUserInformation);

  const [bannerExpanded, setBannerExpanded] = useState(true);
  const [bannerLocked, setBannerLocked] = useState(false);

  const hidePrices =
    userInformation?.selectedManagedPriceListId === null || userInformation?.demoMode || false;

  return (
    <StickyContainer>
      {statusBanner && (
        <Accordion
          onMouseEnter={() => {
            if (!bannerLocked) setBannerExpanded(true);
          }}
          onMouseLeave={() => {
            if (!bannerLocked) setBannerExpanded(false);
          }}
          expanded={bannerLocked || bannerExpanded}
          sx={{ display: "flex", flexDirection: "column-reverse" }} // Flip accordion direction to up
          disableGutters={true}
        >
          <AccordionSummary
            onClick={() => {
              setBannerLocked(!bannerLocked);
              if (!bannerLocked) setBannerExpanded(false);
            }}
            expandIcon={
              <Icon
                name={bannerLocked ? "abb/lock-closed" : "abb/view"}
                sizeClass="large"
                style={{ transform: "scaleY(-1)" }} // Flip image to normal
              />
            }
          />
          {statusBanner}
        </Accordion>
      )}
      <Container>
        {reviseMode ? (
          <LinkWrapper onClick={() => setIsProductDialogOpen(true)}>
            <CustomIcon name="abb/plus" sizeClass="medium" />
            <Text isBold>{t("Add another product")}</Text>
          </LinkWrapper>
        ) : (
          <EmptyFlexItem />
        )}
        {shoppingCartDto?.totalPrice != undefined ||
        shoppingCartDto?.totalSalesPrice != undefined ? (
          <PricesContainer>
            {shoppingCartDto?.totalPrice != undefined ? (
              getTotalPriceComponent(t, shoppingCartDto, hidePrices)
            ) : (
              <EmptyFlexItem />
            )}
            {isTotalSalesPriceVisible && shoppingCartDto?.totalSalesPrice != undefined ? (
              getTotalSalesPriceComponent(t, shoppingCartDto, hidePrices)
            ) : (
              <EmptyFlexItem />
            )}
          </PricesContainer>
        ) : (
          <EmptyFlexItem />
        )}
        <EndButtonContainer>
          {isTertiaryButtonVisible ? (
            <Button
              buttonType="secondary"
              text={tertiaryButtonText}
              onClick={tertiaryButtonAction}
              disabled={isTertiaryButtonDisabled}
            />
          ) : null}
          {isSecondaryButtonVisible ? (
            <Button
              buttonType="secondary"
              text={secondaryButtonText}
              onClick={secondaryButtonAction}
              disabled={isSecondaryButtonDisabled}
            />
          ) : null}
          {isPrimaryButtonVisible ? (
            <Button
              disabled={isPrimaryButtonDisabled}
              buttonType="primary"
              text={primaryButtonText}
              onClick={primaryButtonAction}
              icon={"abb/right-arrow"}
              isLoading={isPrimaryButtonLoading}
            />
          ) : null}
          {isToOrderButtonVisible ? (
            <WithPopup>
              <div>
                <Button
                  disabled={isToOrderButtonDisabled || disableOrdering}
                  buttonType="primary"
                  text={isToOrderButtonText}
                  onClick={toOrderButtonAction}
                  icon={"abb/right-arrow"}
                />
              </div>
              <Popup
                trigger="hover"
                disabled={!(isToOrderButtonDisabled || disableOrdering)}
                position={["top center", "top left"]}
              >
                <p style={{ margin: "0px" }}>{t("Quote is expired")}</p>
              </Popup>
            </WithPopup>
          ) : null}
        </EndButtonContainer>
      </Container>
    </StickyContainer>
  );
};

import {
  Dropdown,
  DropdownOption,
  Input
} from "@abb/abb-common-ux-react";
import { TeamTypes, UserTeamDto } from "api";
import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { InputLabel } from "framework/components/InputLabel";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { useDispatch, useSelector } from "react-redux";
import { getCreateTeamRequest, getUserAccessibleTeams, getUserTeamsRequest } from "applications/manage/manageTeams/reducers/manageTeamsViewReducer";
import { AddTeamBaseDialog, AddTeamBaseDialogComponent } from "./AddTeamBaseDialog";
import styled from "styled-components";
import { CreateOrAddFunctionalTeamToUserDialog, DialogStates } from "./CreateOrAddFunctionalTeamToUserDialog";
import { RequestStatus } from "framework/state/requestStatus";
import { usePrevious } from "framework/hooks/usePrevious";

const InputContainer = styled.div`
  height: ${(props) => props.theme.sizes.l};

  & > * {
    display: block;
    width: 380px;
  }

  & > div {
    position: fixed;
  }
`;

interface AddUserToFunctionalTeamDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  t: TFunction;
  userId: number | undefined;
  parentTeamId?: number | undefined;
}

export const AddUserToFunctionalTeamDialog = ({
  isOpen,
  t,
  setIsOpen,
  userId,
  parentTeamId,
}: AddUserToFunctionalTeamDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const accessibleTeams = useSelector(getUserAccessibleTeams);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBusinessUnitTeam, setSelectedBusinessUnitTeam] = useState<UserTeamDto | undefined>();
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [selectedFunctionalTeamId, setSelectedFunctionalTeamId] = useState<number | undefined>();
  const [isSelectFuntionalTeamDisabled, setIsSelectFuntionalTeamDisabled] = useState<boolean>(true);
  const [availableFuntionalTeams, setAvailableFuntionalTeams] = useState<UserTeamDto[]>([]);
  const [createOrAddFunctionalTeamToUserDialogProps, setCreateOrAddFunctionalTeamToUserDialogProps] = useState<{isOpen: boolean, state: DialogStates}>({isOpen: false, state: DialogStates.NewTeam});

  const createTeamRequest = useSelector(getCreateTeamRequest);
  const prevCreateTeamRequest = usePrevious(createTeamRequest);
  const userTeamsRequest = useSelector(getUserTeamsRequest);

  useEffect(() => {
    setIsSelectFuntionalTeamDisabled(false);
    setNewTeamName("");
    setSelectedFunctionalTeamId(undefined);
  }, [selectedBusinessUnitTeam]);

  useEffect(() => {
    if(createOrAddFunctionalTeamToUserDialogProps.isOpen || selectedFunctionalTeamId) return;

    const defaultBusinessUnitTeam = parentTeamId
      ? accessibleTeams?.find((team) => team.id === parentTeamId)
      : accessibleTeams?.find((team) => team.type === TeamTypes.BusinessUnit && team.isMember);

    if(!selectedBusinessUnitTeam && defaultBusinessUnitTeam) {
      setSelectedBusinessUnitTeam(defaultBusinessUnitTeam);
    }

    setAvailableFuntionalTeams(accessibleTeams?.filter((team) => team.type === TeamTypes.FunctionalTeam && team.parentId == selectedBusinessUnitTeam?.id) ?? []);
    setIsSelectFuntionalTeamDisabled(false);
  }, [accessibleTeams, selectedBusinessUnitTeam, selectedFunctionalTeamId, createOrAddFunctionalTeamToUserDialogProps, parentTeamId]);

  const reloadUserTeams = useCallback(
    (userId?: number | undefined) => {
      if(userId) {
        dispatch(manageTeamsSagas.getUserTeams.createAction({ criteriaUserId : userId }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
      reloadUserTeams(userId);
  }, [userId, dispatch, reloadUserTeams]);

  useEffect(() => {
    if(createTeamRequest.status === RequestStatus.Completed && prevCreateTeamRequest?.status === RequestStatus.Pending) {
      setIsSelectFuntionalTeamDisabled(true);
      reloadUserTeams(userId);
    }
  }, [createTeamRequest, prevCreateTeamRequest, userId, dispatch, reloadUserTeams]);

  useEffect(() => {
    if(userTeamsRequest.status === RequestStatus.Pending) {
      console.log("Loading user teams");
      setIsLoading(true);
    }
    if(userTeamsRequest.status === RequestStatus.Completed || userTeamsRequest.status === RequestStatus.Failed) {
      console.log("Finished loading user teams");
      setIsLoading(false);
    }
  }, [userTeamsRequest]);

  return (
    <>
      {createOrAddFunctionalTeamToUserDialogProps.isOpen && selectedBusinessUnitTeam ?
        <CreateOrAddFunctionalTeamToUserDialog
          dialogState={createOrAddFunctionalTeamToUserDialogProps.state}
          t={t}
          isOpen={createOrAddFunctionalTeamToUserDialogProps.isOpen}
          setIsOpen={(isOpen) => setCreateOrAddFunctionalTeamToUserDialogProps({isOpen, state: createOrAddFunctionalTeamToUserDialogProps.state})}
          selectedBusinessUnitTeam={selectedBusinessUnitTeam}
          teamName={newTeamName}
          editTeamName={setNewTeamName}
          userId={userId}
          team={selectedFunctionalTeamId && createOrAddFunctionalTeamToUserDialogProps.state == DialogStates.ExistingTeam ? availableFuntionalTeams?.find(ft => ft.id === selectedFunctionalTeamId) : undefined}
          parentTeamId={parentTeamId}
          afterSave={() => {
            setSelectedFunctionalTeamId(undefined);
            setNewTeamName("");
          }}
          />
      : <AddTeamBaseDialog
          t={t}
          isOpen={isOpen}
          isLoading={isLoading}
          setIsOpen={setIsOpen}
          title={t("Add functional team")}
          businessUnitTeamGridLabel={t("Select active business unit")}
          isBusinessUnitTeamGridDisabled={false}
          isBusinessUnitTeamGridVisible={true}
          setSelectedBusinessUnitTeam={setSelectedBusinessUnitTeam}
          selectedBusinessUnitTeam={selectedBusinessUnitTeam}
          primaryButtonText={t("Ok")}
          primaryButtonAction={() => setIsOpen(false)}
          isSecondaryButtonVisible={false}
          userId={userId}
          parentTeamId={parentTeamId}
        >
          <AddTeamBaseDialogComponent.SectionsContainer>
            <AddTeamBaseDialogComponent.TextWrapper>
              <InputLabel label={t("Create functional team within selected business unit")} />
            </AddTeamBaseDialogComponent.TextWrapper>
            <AddTeamBaseDialogComponent.InputWrapper>
              <InputLabel
                  label={`${selectedBusinessUnitTeam?.name ?? ""} - `}
                />
              <InputContainer>
                <Input
                  dataType="text"
                  value={newTeamName}
                  instantValidation
                  placeholder={t("Add new functional team name")}
                  onValueChange={(value) => {
                    setNewTeamName(value);
                  }}
                />
              </InputContainer>
              <Button
                buttonType="secondary"
                text={t("Create")}
                disabled={!(selectedBusinessUnitTeam && newTeamName && newTeamName.length > 0 && !isLoading)}
                isLoading={isLoading}
                onClick={() => {
                  setCreateOrAddFunctionalTeamToUserDialogProps({isOpen: true, state: DialogStates.NewTeam});
                }}
                style={{ alignSelf: "end" }}
                sizeClass="small"
              />
            </AddTeamBaseDialogComponent.InputWrapper>
          </AddTeamBaseDialogComponent.SectionsContainer>
          <AddTeamBaseDialogComponent.SectionsContainer>
            <AddTeamBaseDialogComponent.TextWrapper>
              <InputLabel label={t("Select from existing functional teams")} />
            </AddTeamBaseDialogComponent.TextWrapper>
            <AddTeamBaseDialogComponent.InputWrapper>
              <InputLabel
                  label={`${selectedBusinessUnitTeam?.name ?? ""} - `}
                />
              <InputContainer>
                <Dropdown
                  key={"selectFunctionalTeam"}
                  monochrome
                  searchable
                  placeholder={t("Search functional team by name")}
                  disabled={isSelectFuntionalTeamDisabled}
                  onChange={(
                    selection: Array<{
                      value: any;
                      label: string;
                      isNew: boolean;
                    }>
                  ) => {
                    const value = selection.length > 0 ? selection[0].value : undefined;

                    setSelectedFunctionalTeamId(value > 0 ? value : undefined);
                  }}
                  value={
                    selectedFunctionalTeamId ? [{
                      value: selectedFunctionalTeamId,
                      label: availableFuntionalTeams?.find(ft => ft.id === selectedFunctionalTeamId)?.name ?? ""
                    }] : [/* If team not selected, show placeholder text */]
                  }
                >
                  <DropdownOption value={-1} label={" "} />
                  {availableFuntionalTeams ? (
                    availableFuntionalTeams.map((team) => (
                      <DropdownOption
                        key={team.id}
                        value={team.id}
                        disabled={team.isMember}
                        label={`${(team?.name ?? "")}${team.isMember ? t(" (Already a member)") : ""}`}
                      />
                    ))
                  ) : null}
                </Dropdown>
              </InputContainer>
              <Button
                buttonType="secondary"
                text={t("Select")}
                disabled={!(selectedBusinessUnitTeam && selectedFunctionalTeamId && !isLoading)}
                isLoading={isLoading}
                onClick={() => {
                  setCreateOrAddFunctionalTeamToUserDialogProps({isOpen: true, state: DialogStates.ExistingTeam});
                }}
                style={{ alignSelf: "end" }}
                sizeClass="small"
              />
            </AddTeamBaseDialogComponent.InputWrapper>
          </AddTeamBaseDialogComponent.SectionsContainer>
        </AddTeamBaseDialog>
      }
    </>
  );
};

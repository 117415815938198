import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { TFunction } from "i18next";
import { ICellRendererParams } from "ag-grid-community";
import { Link, Icon } from "@abb/abb-common-ux-react";
import {
  DetailsCustomLink,
  DetailsFlexWrapper
} from "applications/quickPriceCheck/components/GridProductRenderer";
import { SerialNumberEditInput } from "./SerialNumberEditInput";
import { EmailInput } from "./EmailInput";
import { EmailRegistrationStatus, SoftwareSubscriptionEmailRegistrationSentDto } from "api";
import { formatDate } from "utilities/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getGeneralApplicationSettings } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";

const Container = styled.div`
  display: flex;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Image = styled.img`
  max-width: 80px;
  max-height: 70px;
  min-width: 80px;
  width: auto;
  height: auto;
  margin-bottom: auto;
  margin-right: ${(props) => props.theme.sizes.l};
`;

const BoldText = styled.p`
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  line-height: 25px;
`;

const ModificationBoldText = styled.p`
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  line-height: 35px;
  padding-right: ${(props) => props.theme.sizes.s};
  display: inline-block;
`;

const SmallHeader = styled.h5`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 15px;
`;

const CustomLink = styled(Link)<{ isShowLess: boolean }>`
  margin-left: ${(props) => (props.isShowLess ? props.theme.sizes.xs : 0)};
  :hover {
    cursor: pointer;
  }
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.sm};
  height: max-content;
  justify-content: flex-start;
  align-items: center;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: ${(props) => props.theme.sizes.s};
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
`;
const NoteText = styled.p`
  margin: 0;
  margin-top: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 15px;
  color: ${(props) => props.theme.colors.grey60};
`;

export interface GridProductRendererProps extends ICellRendererParams {
  t: TFunction;
  imgUrl?: string | null;
  displayName?: string | null;
  compCodeOrOrderingCode?: string | null;
  additionalInformation?: string | null;
  orderingCode: string | null | undefined;
  vendorOrderingCode?: string | null | undefined;
  serialNumbers?: string[] | null;
  isModification?: boolean | undefined;
  onClick: () => void;
  showDetailsText: string;
  showDetails: boolean;
  icon: string;
  isSimpleRenderer?: boolean;
  softwarePurchaseEmail?: string | null;
  isSoftwareProduct?: boolean;
  isStaticProduct?: boolean;
  isRenewalProduct?: boolean;
  onRemoveSerialNumber?: (id: number, serialNumber: string) => void;
  errorText?: string | null | undefined;
  onlineDeliveryProductEmailHelpText?: string | null;
  emailsSoftwareVerification?: SoftwareSubscriptionEmailRegistrationSentDto[] | null;
  reviseMode?: boolean;
}

export const GridProductRenderer = (props: GridProductRendererProps) => {
  const [showLess, setShowLess] = useState(true);
  const dispatch = useDispatch();
  const applicationSettings = useSelector(getGeneralApplicationSettings);

  const getEmailHeader = () => {
    if (props.isRenewalProduct) {
      return props.t("Subscription owner");
    } else {
      return props.t("Software user email");
    }
  };

  // see logic in usings of this Renderer
  const getOrderingOrCompositionCodesHeader = () => {
    return (
      <SmallHeader>
        {props.t(props.orderingCode ? "Composition code" : "Ordering code")}
      </SmallHeader>
    );
  };

  const getProductDescription = () => {
    if (props.isStaticProduct) {
      if (props.isRenewalProduct) {
        return (
          <>
            <BoldText>{props.vendorOrderingCode}</BoldText>
            {getOrderingOrCompositionCodesHeader()}
            <Text>{props.compCodeOrOrderingCode}</Text>
            <Text>{props.displayName + " - " + props.t("Renewal of subscription")}</Text>
          </>
        );
      } else {
        return (
          <>
            {getOrderingOrCompositionCodesHeader()}
            <BoldText>{props.compCodeOrOrderingCode}</BoldText>
            <Text>{props.displayName}</Text>
          </>
        );
      }
    } else {
      return (
        <>
          <BoldText>{props.displayName}</BoldText>
          {getOrderingOrCompositionCodesHeader()}
          <Text>{props.compCodeOrOrderingCode}</Text>
          {props.orderingCode ? (
            <>
              <SmallHeader>{props.t("Ordering code")}</SmallHeader>
              <Text>{props.orderingCode}</Text>
            </>
          ) : null}
        </>
      );
    }
  };

  const updateShoppingCartItemSoftwarePurchaseEmail = useCallback(
    (email: string) => {
      dispatch(
        shoppingCartSagas.updateShoppingCartItemSoftwarePurchaseEmail.createAction({
          id: props.data.id,
          updateShoppingCartItemSoftwarePurchaseEmailDto: {
            softwarePurchaseEmail: email
          }
        })
      );
    },
    [dispatch, props.data.id]
  );

  return (
    <Container>
      {(((props.isSimpleRenderer && !props.serialNumbers) || props.isModification) &&
        !props.reviseMode) ||
      !props.imgUrl ? null : (
        <Image src={props.imgUrl} />
      )}
      {props.isModification ? (
        <FlexContainer>
          <ModificationBoldText>{props.compCodeOrOrderingCode}</ModificationBoldText>
          <EmailInput
            t={props.t}
            emailHeader={props.t("Email address for software delivery")}
            isSimpleRenderer={props.isSimpleRenderer}
            emailHelpText={props.onlineDeliveryProductEmailHelpText}
            isSoftwareProduct={props.isSoftwareProduct}
            isModification={props.isModification}
            softwarePurchaseEmail={props.softwarePurchaseEmail}
            onUpdateSoftwarePurchaseEmail={updateShoppingCartItemSoftwarePurchaseEmail}
          />
        </FlexContainer>
      ) : (
        <FlexContainer>
          {getProductDescription()}
          {props.errorText ? (
            <ErrorWrapper>
              <Icon name={"abb/error-circle-2"} color="red" sizeClass="large" />
              <BoldText>{props.errorText}</BoldText>
            </ErrorWrapper>
          ) : null}
          {props.serialNumbers && props.serialNumbers.length > 0 ? (
            <>
              <SmallHeader>{props.t("Serial number")}</SmallHeader>
              {props.serialNumbers.map((serialNumber) => {
                return (
                  <ActionsWrapper key={serialNumber}>
                    <SerialNumberEditInput
                      serialNumber={serialNumber}
                      isReadOnly={props.isSimpleRenderer || !props.onRemoveSerialNumber}
                      handleRemoveSerialNumber={() => {
                        if (props.onRemoveSerialNumber) {
                          props.onRemoveSerialNumber(props.data.id, serialNumber);
                        }
                      }}
                    />
                  </ActionsWrapper>
                );
              })}
            </>
          ) : null}
          {props.additionalInformation ? (
            <>
              <SmallHeader>{props.t("Additional information")}:</SmallHeader>
              <Text>
                {showLess && props.additionalInformation.length > 100
                  ? props.additionalInformation.slice(0, 60) + "..."
                  : props.additionalInformation}
                {showLess ? null : <br></br>}
                {props.additionalInformation.length > 100 && (
                  <CustomLink
                    isShowLess={showLess}
                    discreet
                    onClick={() => setShowLess((cur) => !cur)}
                  >
                    {showLess ? props.t("Show more") : props.t("Show less")}
                  </CustomLink>
                )}
              </Text>
            </>
          ) : null}
          {props.isSoftwareProduct ? (
            <>
              <EmailInput
                t={props.t}
                emailHeader={getEmailHeader()}
                isSimpleRenderer={props.isSimpleRenderer || props.isRenewalProduct}
                emailHelpText={props.onlineDeliveryProductEmailHelpText}
                isSoftwareProduct={props.isSoftwareProduct}
                isModification={props.isModification}
                softwarePurchaseEmail={props.softwarePurchaseEmail}
                onUpdateSoftwarePurchaseEmail={(email) => {
                  if (email) {
                    updateShoppingCartItemSoftwarePurchaseEmail(email);
                  }
                }}
                note={
                  props.isRenewalProduct
                    ? props
                        .t("Please contact <Email> if changes are needed")
                        .replace("<Email>", applicationSettings.emailDeliverIT)
                    : null
                }
              />
              {props.emailsSoftwareVerification?.map((x) => (
                <NoteText>
                  {x.requestCreated ? `${formatDate(x.requestCreated)} ` : ""}
                  {props
                    .t("Request to register email sent to <Email> status: <Status>")
                    .replace("<Email>", x.softwarePurchaseEmail ?? "unknown email")
                    .replace(
                      "<Status>",
                      x.statusRegistration === EmailRegistrationStatus.Registered
                        ? props.t("Registered")
                        : props.t("Not registered")
                    )}
                </NoteText>
              ))}
            </>
          ) : null}
          {props.showDetails ? (
            <DetailsFlexWrapper onClick={() => props.onClick()}>
              <DetailsCustomLink discreet>{props.showDetailsText}</DetailsCustomLink>
              <Icon name={props.icon} sizeClass={"medium"} />
            </DetailsFlexWrapper>
          ) : null}
        </FlexContainer>
      )}
    </Container>
  );
};

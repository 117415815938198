import React, { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { AppContainer } from "@abb/abb-common-ux-react/components/AppContainer";
import NormalizedStyles from "../../styles/normalize";
import { commonUXTheme } from "../../styles/commonUXVariables";
import NotificationViewer from "./NotificationViewer";
import { userActions } from "../../applications/common/actions/userActions";
import {
  getNotifications,
  getConfirmEvents,
  getShouldFetchUserInformation,
  getAccessToken,
  getUser,
  getAuthSettings,
  getUserInformation
} from "../../applications/common/reducers/userReducer";
import { userSagas } from "../../applications/common/sagas/userSagas";
import ConfirmDialog from "./ConfirmDialog";
import { usePrevious } from "../hooks/usePrevious";
import { CssStyles } from "../../styles/index";
import { deliveriesHomeViewActions } from "applications/deliveries/deliveriesHome/actions/deliveriesHomeViewActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageProvider } from "applications/common/components/LanguageProvider";
import { MenuBar } from "shared/menubar/containers/MenuBar";
import { PrivacyConsentDialog } from "../../applications/privacyConcent/PrivacyConsentDialog";
import { commonActions } from "applications/common/actions/commonActions";
import { applicationNotificationViewActions } from "applications/applicationNotifications/actions/applicationNotitficationActions";
import {
  ApplicationNotificationOperations,
  ApplicationNotificationTypes,
  CompanyDto,
  NonTransientNotification,
  RoleGroups,
  TransientNotification,
  NotificationSeverity,
  ShoppingCartUpdatedEvent
} from "api";
import { routes } from "utilities/routes";
import {
  getApplicationRoleGroup,
  getIsHotjarInitialized
} from "applications/common/reducers/commonReducer";
import { useMsal } from "@azure/msal-react";
import { loginRequestScopes } from "configuration/authConfig";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider
} from "@mui/material/styles";
import { productInformationActions } from "applications/deliveries/productInformation/actions/productInformationActions";
import { ordersViewActions } from "applications/deliveries/orders/actions/ordersViewActions";
import {
  defaultOrderCriteria,
  orderSearchFormDefaultFields
} from "applications/deliveries/orders/reducers/ordersViewReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { hotjar } from "react-hotjar";
import { productInformationSagas } from "applications/deliveries/productInformation/sagas/productInformationSagas";
import { CookiesDialog } from "applications/cookiesDialog/CookiesDialog";
import { getProducts } from "applications/products/productComparison/reducers/productComparisonReducers";
import { Link } from "./Link";
import { getSerialNumbersPendingOnlineDelivery } from "applications/deliveries/productInformation/reducers/productInformationViewReducer";
const ApplicationContainer = styled(AppContainer)`
  background: ${(props) => props.theme.colors.whitePrimary};
  @media print {
    height: auto;
  }
`;

export const App: FC = (props) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getUser);
  const authSettings = useSelector(getAuthSettings);
  const notifications = useSelector(getNotifications);
  const confirmEvents = useSelector(getConfirmEvents);
  const applicationRoleGroup = useSelector(getApplicationRoleGroup);
  const shouldFetchUserInformation = useSelector(getShouldFetchUserInformation);
  const serialNumbersPendingOnlineDelivery = useSelector(getSerialNumbersPendingOnlineDelivery);
  const userInformation = useSelector(getUserInformation);

  const isHotjarInitialized = useSelector(getIsHotjarInitialized);

  // Using the ref due to issues with keeping the list updated in callback
  const pendingSerialNumbersRef = useRef<string[]>([]);
  const prevShouldFetchUserInformation = usePrevious(shouldFetchUserInformation);
  const accessToken = useSelector(getAccessToken);
  const productsToCompare = useSelector(getProducts);
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_BASEPATH;

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    pendingSerialNumbersRef.current = serialNumbersPendingOnlineDelivery;
  }, [serialNumbersPendingOnlineDelivery]);

  useEffect(() => {
    if (isHotjarInitialized && hotjar.initialized() && currentUser) {
      hotjar.identify(currentUser.userId, {
        firstName: currentUser.firstName,
        lastname: currentUser.lastName,
        email: currentUser.email,
        userPrincipalName: currentUser.userPrincipalName,
        language: currentUser.language,
        timeZone: currentUser.timeZone,
        countryCode: currentUser.countryCode,
        isExternaluser: currentUser.isExternalUser
      });
    }
  }, [currentUser, isHotjarInitialized]);

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(userActions.storeUserInfo(accounts[0]));
    } else {
      dispatch(userActions.changeIsLoggingIn(false));
    }
  }, [accounts, inProgress, dispatch]);

  useEffect(() => {
    if (currentUser.accountInfo && authSettings && currentUser.shouldFetchToken) {
      dispatch(
        userSagas.getToken.createAction({
          request: {
            scopes: [authSettings.resourceScope, "offline_access"],
            account: currentUser.accountInfo,
            forceRefresh: false
          },
          authSettings: authSettings
        })
      );
    }
  }, [currentUser, authSettings, dispatch]);

  useEffect(() => {
    if (shouldFetchUserInformation && prevShouldFetchUserInformation === false) {
      dispatch(userSagas.getUserInformation.createAction(undefined));
    }
  }, [shouldFetchUserInformation, prevShouldFetchUserInformation, dispatch]);

  const handleRemoveConfirmEvent = (id: string) => {
    dispatch(userActions.removeConfirmEvent(id));
  };

  useEffect(() => {
    let groupToSet: RoleGroups | undefined = undefined;
    if (location.pathname.startsWith(routes.orders.home)) {
      groupToSet = RoleGroups.DeliverIt;
    } else if (
      location.pathname.startsWith(routes.quickPriceCheck) ||
      location.pathname.startsWith(routes.shoppingCart)
    ) {
      groupToSet = RoleGroups.Configurator;
    } else {
      groupToSet = undefined;
    }
    if (applicationRoleGroup !== groupToSet) {
      dispatch(commonActions.setApplicationRoleGroup(groupToSet));
    }
  }, [location, dispatch, applicationRoleGroup]);

  // "translation" is the default namespace where the translations are loaded from
  // useSuspense is false, because this component doesn't have any Suspenses as its parents
  const { t, ready } = useTranslation("translation", { useSuspense: false });

  const handleTokenRefresh = () => {
    if (authSettings && currentUser && currentUser.accountInfo) {
      dispatch(
        userSagas.getToken.createAction({
          request: {
            scopes: [authSettings.resourceScope, "offline_access"],
            account: currentUser.accountInfo,
            forceRefresh: false
          },
          authSettings: authSettings,
          redirectToLogInPageWhenRefreshFails: true
        })
      );
    }
  };

  // SignalR handling
  const handleNonTransientNotification = useCallback(
    (message: NonTransientNotification) => {
      dispatch(userActions.addNotification(NotificationSeverity.Success, message.header));
      switch (message.type) {
        case ApplicationNotificationTypes.UserAccessRightsChanged:
          dispatch(userSagas.getUserInformation.createAction(undefined));
          break;
        case ApplicationNotificationTypes.QuoteApprovalRequested:
          dispatch(userSagas.getUserInformation.createAction(undefined));
          break;
      }
    },
    [dispatch]
  );

  const handleTransientNotification = useCallback(
    (message: TransientNotification) => {
      if (
        message.type === ApplicationNotificationTypes.JustInTimeProductAvailableForDownload &&
        message.link
      ) {
        dispatch(
          userActions.addNotification(
            message.severity as NotificationSeverity,
            message.header,
            message.isConfirmation,
            15000,
            undefined,
            undefined,
            false,
            false,
            <div>
              <b>{message.header}</b>
              <p>
                {`${t("If the download doesn't start automatically, please")} `}
                <Link
                  displayInline
                  underlined
                  to={`${routes.orders.productInformation.index}/licenses/${message.link}`}
                >
                  {t("click here")}
                </Link>
                {` ${t("to open the download page.")}`}
              </p>
            </div>
          )
        );

        const isPendingOnlineDeliveryForSerialNumber = pendingSerialNumbersRef.current.some(
          (sn) => sn === message.link
        );

        if (isPendingOnlineDeliveryForSerialNumber) {
          dispatch(
            productInformationSagas.getOrderLicenseFiles.createAction({
              serialNumber: message.link,
              provideAutomaticDownloadInformation: isPendingOnlineDeliveryForSerialNumber
            })
          );
          dispatch(
            productInformationActions.clearSerialNumberFromPendingOnlineDeliveryList(message.link)
          );
        }
      } else if (message.type === ApplicationNotificationTypes.JustInTimeProductInActivated) {
        // When the license gets inactivated, it's enough to just refetch information related to the serial number
        if (message.link) {
          dispatch(
            productInformationSagas.getOrderLicenseFiles.createAction({
              serialNumber: message.link
            })
          );
        }
      } else if (message.type === ApplicationNotificationTypes.UserAccessRightsChanged) {
        dispatch(userSagas.getUserInformation.createAction(undefined));
      } else {
        // When pipeline faults, we need to refetch information related to the serial number
        if (
          message.type === ApplicationNotificationTypes.JustInTimeProductPipelineFaulted &&
          message.link
        ) {
          dispatch(
            productInformationSagas.getOrderLicenseFiles.createAction({
              serialNumber: message.link
            })
          );
          dispatch(productInformationActions.setIsProcessingLicenseFileFailed(true));
        }
        dispatch(
          userActions.addNotification(
            message.severity as NotificationSeverity,
            message.header,
            message.isConfirmation,
            10000
          )
        );
      }
    },
    [dispatch, t]
  );

  const handleOnConfiguratorBaseUrlSet = useCallback(
    (baseUrl?: string) => {
      dispatch(commonActions.setConfiguratorBaseUrl(baseUrl));
    },
    [dispatch]
  );

  const handleOnNotificationStateChanged = useCallback(
    (
      unreadNotificationCount: number,
      operation?: ApplicationNotificationOperations,
      notificationIds?: number[]
    ) => {
      dispatch(
        applicationNotificationViewActions.setNotificationState(
          unreadNotificationCount,
          operation,
          notificationIds
        )
      );
    },
    [dispatch]
  );

  const handleClickNavigationItem = useCallback(
    (internalRoute: string) => {
      if (internalRoute === routes.orders.productInformation.index) {
        dispatch(productInformationActions.setProductInformationSearchCriteria(undefined));
      } else if (
        internalRoute === routes.orders.index ||
        internalRoute === routes.orders.ordersBacklog
      ) {
        dispatch(ordersViewActions.changeOrderCriteria(defaultOrderCriteria));
        dispatch(ordersViewActions.changeSearchFields(orderSearchFormDefaultFields));
      }
    },
    [dispatch]
  );

  const onShoppingCartUpdatedEvent = useCallback(
    (message: ShoppingCartUpdatedEvent) => {
      if (message.shoppingCartGuid && message.shouldRefreshCart) {
        dispatch(
          shoppingCartSagas.getShoppingCartById.createAction({ guid: message.shoppingCartGuid })
        );
      }
    },
    [dispatch]
  );

  const getPixelAmount = (cssString: string) => {
    return Number(cssString.split("px")[0]);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: getPixelAmount(commonUXTheme.breakpoints.xs),
        sm: getPixelAmount(commonUXTheme.breakpoints.sm),
        md: getPixelAmount(commonUXTheme.breakpoints.md),
        lg: getPixelAmount(commonUXTheme.breakpoints.lg),
        xl: getPixelAmount(commonUXTheme.breakpoints.xl),
        xxl: getPixelAmount(commonUXTheme.breakpoints.xxl)
      }
    },
    typography: {
      fontFamily: [
        commonUXTheme.fonts.families.fontAbbRegular,
        commonUXTheme.fonts.families.fontAbbMedium,
        commonUXTheme.fonts.families.fontAbbLight,
        commonUXTheme.fonts.families.fontAbbBold
      ].join(",")
    }
  });

  return (
    <ThemeProvider theme={commonUXTheme}>
      <MuiThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <LanguageProvider>
            <ApplicationContainer>
              <NotificationViewer
                notifications={notifications}
                onRemoveNotification={(id: string) => {
                  dispatch(userActions.removeNotification(id));
                }}
                translateText={ready ? t : undefined}
              />
              <ConfirmDialog
                confirmEvents={confirmEvents}
                onRemoveConfirmItem={handleRemoveConfirmEvent}
              />
              <NormalizedStyles />
              <CssStyles />
              <PrivacyConsentDialog />
              <CookiesDialog />
              <MenuBar
                accessToken={accessToken}
                basePath={basePath ?? ""}
                isSignedIn={accessToken !== undefined && accessToken.length > 0}
                activePath={location.pathname}
                onClickMenuItem={handleClickNavigationItem}
                onSignIn={() => {
                  instance.loginRedirect({
                    scopes: loginRequestScopes,
                    prompt: "select_account"
                  });
                }}
                onSignOut={() => {
                  instance.logoutRedirect();
                }}
                onSelectCompany={(
                  company: CompanyDto | undefined,
                  companyHasApprover: boolean | undefined
                ) => {
                  dispatch(
                    deliveriesHomeViewActions.changeSelectedCompany(company, !!companyHasApprover)
                  );
                }}
                useExternalLinks={false}
                enableReactRouterLinks
                translateText={ready ? t : undefined}
                onConfiguratorBaseUrlSet={handleOnConfiguratorBaseUrlSet}
                onRefreshToken={handleTokenRefresh}
                debug={process.env.NODE_ENV === "development"}
                onNonTransientNotificationReceived={handleNonTransientNotification}
                onTransientNotificationReceived={handleTransientNotification}
                onNotificationStateChanged={handleOnNotificationStateChanged}
                roleGroup={applicationRoleGroup}
                onShoppingCartUpdatedEvent={onShoppingCartUpdatedEvent}
                productsToCompare={productsToCompare}
                navigate={navigate}
                demoMode={userInformation?.demoMode}
              />
              {props.children}
            </ApplicationContainer>
          </LanguageProvider>
        </StyledEngineProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

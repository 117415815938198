import { Action } from "redux";
import { ProductInformationSearchCriteria } from "../reducers/productInformationViewReducer";

export enum ProductInformationActionTypes {
  SetProductInformationSearchCriteria = "ProductInformation/SetProductInformationSearchCriteria",
  ClearSerialNumberFromPendingOnlineDeliveryList = "ProductInformation/ClearSerialNumberFromPendingOnlineDeliveryList",
  SetIsProcessingLicenseFileFailed = "ProductInformation/SetIsProcessingLicenseFileFailed"
}

export interface SetProductInformationSearchCriteria extends Action {
  type: ProductInformationActionTypes.SetProductInformationSearchCriteria;
  productInformationSearchCriteria: ProductInformationSearchCriteria | undefined;
}

export interface ClearSerialNumberFromPendingOnlineDeliveryList extends Action {
  type: ProductInformationActionTypes.ClearSerialNumberFromPendingOnlineDeliveryList;
  serialNumber: string;
}

export interface SetIsProcessingLicenseFileFailed extends Action {
  type: ProductInformationActionTypes.SetIsProcessingLicenseFileFailed;
  value: boolean;
}

export const productInformationActions = {
  setProductInformationSearchCriteria: (
    productInformationSearchCriteria: ProductInformationSearchCriteria | undefined
  ): SetProductInformationSearchCriteria => ({
    type: ProductInformationActionTypes.SetProductInformationSearchCriteria,
    productInformationSearchCriteria
  }),
  clearSerialNumberFromPendingOnlineDeliveryList: (
    serialNumber: string
  ): ClearSerialNumberFromPendingOnlineDeliveryList => ({
    type: ProductInformationActionTypes.ClearSerialNumberFromPendingOnlineDeliveryList,
    serialNumber
  }),
  setIsProcessingLicenseFileFailed: (value: boolean): SetIsProcessingLicenseFileFailed => ({
    type: ProductInformationActionTypes.SetIsProcessingLicenseFileFailed,
    value
  })
};

export type ProductInformationActions =
  | SetProductInformationSearchCriteria
  | ClearSerialNumberFromPendingOnlineDeliveryList
  | SetIsProcessingLicenseFileFailed;

import {
  ApiTrainingNodesFlatGetRequest,
  ApiTrainingNodesRootsGetRequest,
  ApiTrainingSearchArticlePostRequest,
  ArticleDto,
  ArticleDtoFormatted,
  NodeDto,
  NodeFlattenedDto,
  SearchResponseDto
} from "api";
import { produce } from "immer";
import { NewTrainingActions, TrainingActionType } from "../actions/trainingActions";
import { AppState } from "setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { trainingSagas } from "../sagas/trainingSagas";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { RequestStatus } from "framework/state/requestStatus";
import { getTrainingArticleOrder } from "utilities/nodeUtils";
import { unionBy } from "lodash-es";

interface Requests {
  getNodesRoots: RequestState<ApiTrainingNodesRootsGetRequest>;
  getNodesFlat: RequestState<ApiTrainingNodesFlatGetRequest>;
  search: RequestState<SearchResponseDto, ApiTrainingSearchArticlePostRequest>;
}

export interface TrainingState {
  requests: Requests;

  flatNodes: NodeFlattenedDto[];
  nodes: NodeDto[];
  nodesOnScreen: NodeDto[];
  scrollToNode: NodeDto | undefined;

  search: string;
  isSearch: boolean | undefined;
  isLoading: boolean | undefined;
  pageSize: number;
  pageNumber: number;
  forceScroll: boolean;

  articlesHits: ArticleDtoFormatted[];
  articlesOnScreen: ArticleDto[];
  articlesHitsNumber: number | undefined;
  categories:
    | {
        [key: string]: number;
      }
    | undefined;
}
const defaultState: TrainingState = {
  requests: {
    getNodesRoots: createInitialRequest(),
    getNodesFlat: createInitialRequest(),
    search: createInitialRequest()
  },
  flatNodes: [],
  nodes: [],
  nodesOnScreen: [],
  scrollToNode: undefined,
  search: "",
  isSearch: undefined,
  isLoading: undefined,
  pageNumber: 0,
  pageSize: 2,
  forceScroll: false,
  articlesHits: [],
  articlesOnScreen: [],
  articlesHitsNumber: undefined,
  categories: undefined
};

export function trainingReducer(
  state: TrainingState = defaultState,
  action: NewTrainingActions
): TrainingState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: trainingSagas.getNodesFlat.actionTypes,
      key: "getNodesFlat"
    },
    {
      actionTypes: trainingSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: trainingSagas.search.actionTypes,
      key: "search"
    }
  ]);
  if (trainingSagas.getNodesFlat.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.flatNodes = action.payload;
    });

  if (trainingSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload
        .sort((a, b) => a.order - b.order)
        .map((x) => {
          return { ...x, subNodes: x.subNodes?.sort((a, b) => a.order - b.order) };
        });
    });
  if (trainingSagas.search.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // Remove duplicates and sort list
      draft.articlesHits = unionBy(state.articlesHits, action.payload.articles, "id").sort((a, b) =>
        getTrainingArticleOrder(draft.nodes, a, b)
      );
      draft.articlesHitsNumber = action.payload.estimatedTotalHits ?? 0;
      draft.categories = action.payload.facetDistribution ?? undefined;
      draft.isLoading = false;
    });
  }

  switch (action.type) {
    case TrainingActionType.SetVisibleArticle:
      state = produce(state, (draft) => {
        draft.articlesOnScreen = action.visible
          ? unionBy(draft.articlesOnScreen, [action.article], "id")
          : draft.articlesOnScreen.filter((x) => x.id !== action.article.id);
        draft.nodesOnScreen = draft.flatNodes.filter((x) =>
          draft.articlesOnScreen.some(
            (article) => x.articlesId?.some((articleId) => articleId == article.id)
          )
        );
      });
      break;
    case TrainingActionType.SetSearchText:
      state = produce(state, (draft) => {
        if (state.search !== action.search) {
          draft.search = action.search;
          draft.isSearch = !action.search ? false : true;
          draft.isLoading = !action.search ? false : true;
          draft.pageNumber = 0;
          draft.forceScroll = true;
          draft.articlesHits = [];
          draft.articlesHitsNumber = 0;
        }
      });
      break;
    case TrainingActionType.SetLoadingResults:
      state = produce(state, (draft) => {
        draft.isLoading = action.loading;
      });
      break;
    case TrainingActionType.SetPageNumber:
      state = produce(state, (draft) => {
        draft.pageNumber = action.page;
      });
      break;
    case TrainingActionType.SetPageSize:
      state = produce(state, (draft) => {
        draft.pageSize = action.size;
      });
      break;
    case TrainingActionType.SetForceScroll:
      state = produce(state, (draft) => {
        draft.forceScroll = action.force;
      });
      break;
    case TrainingActionType.ResetArticles:
      state = produce(state, (draft) => {
        draft.articlesHits = [];
        draft.articlesHitsNumber = 0;
        draft.articlesOnScreen = [];
      });
      break;
    case TrainingActionType.SetScrollNode:
      state = produce(state, (draft) => {
        draft.scrollToNode = action.node;
      });
      break;
  }
  return state;
}
export const getHits = (state: AppState) => state.training.articlesHits;
export const getHitsCategories = (state: AppState) => state.training.categories;
export const getNodeExpandedTraining = (state: AppState): NodeDto | undefined =>
  state.training.scrollToNode;
export const getFlatNodes = (state: AppState): NodeFlattenedDto[] => state.training.flatNodes;
export const getScrollToNode = (state: AppState) => state.training.scrollToNode;
export const getNodesOnScreen = (state: AppState): NodeDto[] => state.training.nodesOnScreen;
export const getNodesFlatStatus = (state: AppState): RequestStatus =>
  state.training.requests.getNodesFlat.status;
export const getNodes = (state: AppState): NodeDto[] => state.training.nodes;
export const getNodesStatus = (state: AppState): RequestStatus =>
  state.training.requests.getNodesRoots.status;
export const getSearch = (state: AppState): string | undefined => state.training.search;
export const getSearchStatus = (state: AppState): RequestStatus =>
  state.training.requests.search.status;
export const getIsSearch = (state: AppState): boolean | undefined => state.training.isSearch;
export const getIsLoading = (state: AppState): boolean | undefined => state.training.isLoading;
export const getPageSize = (state: AppState): number => state.training.pageSize;
export const getPageNumber = (state: AppState): number => state.training.pageNumber;
export const getForceScroll = (state: AppState): boolean => state.training.forceScroll;

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamTypes,
    TeamTypesFromJSON,
    TeamTypesFromJSONTyped,
    TeamTypesToJSON,
} from './TeamTypes';

/**
 * 
 * @export
 * @interface TeamDto
 */
export interface TeamDto {
    /**
     * 
     * @type {number}
     * @memberof TeamDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    description?: string | null;
    /**
     * 
     * @type {TeamTypes}
     * @memberof TeamDto
     */
    type?: TeamTypes;
    /**
     * 
     * @type {number}
     * @memberof TeamDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    parentName?: string | null;
}

export function TeamDtoFromJSON(json: any): TeamDto {
    return TeamDtoFromJSONTyped(json, false);
}

export function TeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : TeamTypesFromJSON(json['type']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
    };
}

export function TeamDtoToJSON(value?: TeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'type': TeamTypesToJSON(value.type),
        'parentId': value.parentId,
        'parentName': value.parentName,
    };
}


import React, { useState } from "react";
import { AccessRights, CountryDto, ManageUserDto } from "../../../../api";
import styled from "styled-components";
import { Dropdown, DropdownOption, Input } from "@abb/abb-common-ux-react";
import { IconWithTooltip } from "framework/components/IconWithTooltip";
import { SectionHeader as SectionHeading } from "framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";
import AuthorizedComponent from "framework/components/AuthorizedComponent";
import { Button } from "framework/components/Button";
import { HorizontalElementContainer } from "framework/components/HorizontalElementContainer";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.xs};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${(props) => props.theme.sizes.sm};
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.sizes.sm};
`;

const IconText = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

interface ManageUsersFormProps {
  userInformation: ManageUserDto;
  countries: CountryDto[];
  canChangeCountryCode: boolean;
  canEditFirstAndLastName: boolean;
  onChangeCountryCode: (countryCode: string, countryName: string) => void;
  onChangeFirstOrLastName: (firstName: string, lastName: string) => void;
}

const getUserInformationLabels = (t: (text: string) => string) => {
  return {
    azureAdId: t("User ID#"),
    firstName: t("First name"),
    lastName: t("Last name"),
    email: t("Email"),
    userPrincipalName: t("User principal name"),
    userAccountTypeDescription: t("Account type"),
    countryCode: t("Country code")
  };
};

const editableFieldsForAdmin = ["firstName", "lastName"];

const getUserInformationKeys = (t: (text: string) => string) =>
  Object.keys(getUserInformationLabels(t)) as (keyof typeof getUserInformationLabels)[];

const getUserInformationShape = (t: (text: string) => string) =>
  getUserInformationKeys(t).map((prop) => {
    return { property: prop, label: getUserInformationLabels(t)[prop] };
  });

export const ManageUserForm = ({
  userInformation,
  countries,
  canChangeCountryCode,
  onChangeCountryCode,
  onChangeFirstOrLastName
}: ManageUsersFormProps): JSX.Element => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>(userInformation.firstName);
  const [lastName, setLastName] = useState<string>(userInformation.lastName);

  const drawCountryCodeDropdown = (item: { property: never; label: never }) => {
    const userHasSelectedCountryCode =
      userInformation[item.property] !== undefined &&
      userInformation[item.property] !== null &&
      userInformation[item.property] !== "";
    const userCountryCode = userHasSelectedCountryCode
      ? userInformation[item.property]
      : userInformation.azureAdCountryCode ?? "";
    return (
      <div style={{ cursor: "pointer" }} key={"countryCode"}>
        <Dropdown
          monochrome
          label={item.label}
          searchable
          disabled={!canChangeCountryCode}
          onChange={(
            selection: Array<{
              value: any;
              label: string;
              isNew: boolean;
            }>
          ) => {
            if (selection.length > 0) {
              onChangeCountryCode(selection[0].value, selection[0].label);
            }
          }}
          value={[
            {
              value: userCountryCode,
              label: countries.find((c) => c.code === userCountryCode)?.name ?? userCountryCode
            }
          ]}
        >
          {countries.map((country, i) => (
            <DropdownOption
              key={country.code ?? i.toString()}
              value={country.code ?? ""}
              label={country.name ?? ""}
            />
          ))}
        </Dropdown>
      </div>
    );
  };

  const canSaveFirstAndLastName = () => {
    return (
      (userInformation.firstName !== firstName || userInformation.lastName !== lastName) &&
      firstName &&
      lastName
    );
  };

  const handleFormValueChange = (property: never, newValue: string) => {
    if (property === "firstName") {
      setFirstName(newValue);
    } else if (property === "lastName") {
      setLastName(newValue);
    }
  };
  const getFormInputValue = (property: never) => {
    if (property === "firstName") {
      return firstName;
    } else if (property === "lastName") {
      return lastName;
    }
    return userInformation[property];
  };

  return (
    <Form>
      <SectionHeader text={t("User information")} />
      {getUserInformationShape(t).map((item) => {
        if (item.property !== "countryCode") {
          return (
            <Input
              key={item.property}
              dataType="text"
              disabled={!editableFieldsForAdmin.some((s) => s === item.property)}
              label={item.label}
              value={getFormInputValue(item.property)}
              onValueChange={(value: string) => {
                handleFormValueChange(item.property, value);
              }}
            />
          );
        } else {
          return drawCountryCodeDropdown(item);
        }
      })}
      <AuthorizedComponent accessRights={[AccessRights.SystemAdmin]}>
        <ButtonContainer>
          <HorizontalElementContainer isLeft>
            <Button
              buttonType="secondary"
              text={t("Revert")}
              disabled={!canSaveFirstAndLastName()}
              onClick={() => {
                setFirstName(userInformation?.firstName);
                setLastName(userInformation?.lastName);
              }}
            />
            <Button
              buttonType="primary"
              text={t("Save first / last name")}
              disabled={!canSaveFirstAndLastName()}
              onClick={() => {
                onChangeFirstOrLastName(firstName, lastName);
              }}
            />
          </HorizontalElementContainer>
        </ButtonContainer>
      </AuthorizedComponent>
      {userInformation.isGuest ? (
        <FormIconContainer>
          <IconWithTooltip
            icon="abb/information-circle-1"
            tooltipText={t(
              "The user has not been processed by user admin. Guest status will be removed if an access request concerning the user is processed or the user is assigned to a role"
            )}
            style={{ cursor: "pointer" }}
          />
          <IconText>{t("User is a guest user")}</IconText>
        </FormIconContainer>
      ) : null}
    </Form>
  );
};

import React, { useEffect, useState } from "react";
import { createHeaderAndHeaderTooltip, Grid } from "../../../../framework/components/grid/Grid";
import { ColDef, ICellRendererParams, ITooltipParams, ValueGetterParams } from "ag-grid-community";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AccessRights, ManageUserRoleDto, TeamDto, TeamMemberSources, TeamMemberStatusDto, TeamTypes, UserTeamDto, UserTeamRolesDto } from "api";
import { useSelector } from "react-redux";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import {
  GridCheckboxRenderer,
  GridCheckboxRendererProps
} from "framework/components/grid/GridCheckboxRenderer";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import {
  GridRadioButtonRenderer,
  GridRadioButtonRendererProps
} from "framework/components/grid/GridRadioButtonRenderer";
import { Button } from "framework/components/Button";
import { getTeamMemberGridRowStyle, getTeamMemeberStatusText, isTeamAdminRole, roleAbbreviationToFriendlyName } from "utilities/teamUtils";
import { hasAnyAccessRight } from "utilities/authUtils";
import { GridToggleSwitchRenderer } from "framework/components/grid/GridToggleSwitchRenderer";
import { GridActionsMenuItemProps, GridActionsRenderer, GridActionsRendererParams } from "framework/components/grid/GridActionsRenderer";

const InputWrapper = styled.div`
  margin-top: ${(props) => props.theme.sizes.sm};
  display: flex;
  gap: 16px;
  position: relative;
  z-index: 10;

  & > :first-child {
    width: 100%;
  }

  &:has(div[id$="-listbox"][class$="-menu"]) {
    z-index: 11;
  }
`;

const Container = styled.div`
  width: 100%;
`;

interface UserTeamsGridProps {
  userId: number;
  nameColumnTitle: string;
  userTeamRoles: UserTeamRolesDto[];
  availableTeams: UserTeamDto[] | undefined;
  useToggleSwitch?: boolean;
  showActions?: boolean;
  onChangeActiveTeam: (userId: number, team: TeamDto, isInUser: boolean) => void;
  onUpdateMemberActivity: (userId: number, team: TeamDto, isActive: boolean) => void;
  onRequestTeamAccess: (userId: number, team: TeamDto) => void;
  getTeamName?: (team: TeamDto) => string;
  actionMenuItems?: (teamMember: UserTeamRolesDto) => GridActionsMenuItemProps[];
}

export const UserTeamsGrid = ({
  userId,
  nameColumnTitle,
  userTeamRoles,
  availableTeams = [],
  useToggleSwitch = false,
  showActions = false,
  onChangeActiveTeam,
  onUpdateMemberActivity,
  onRequestTeamAccess,
  getTeamName,
  actionMenuItems = () => []
}: UserTeamsGridProps) => {
  const { t } = useTranslation();

  const currentUser = useSelector(getUserInformation);
  const userAccessRights = useSelector(getUserAccessRights);

  const [canDoActions, setCanDoActions] = useState<boolean>(false);
  const [requestingAccessTo, setRequestingAccessTo] = useState<UserTeamDto | undefined>(undefined);

  useEffect(() => {
    setCanDoActions(userId === currentUser?.id || hasAnyAccessRight(null, userAccessRights, AccessRights.SystemAdmin, AccessRights.ManagePlatformUsers));
  }, [userId, currentUser, userAccessRights]);

  const buildTeamName = (team: TeamDto): string => {
    if(getTeamName) {
      return getTeamName(team);
    }

    return team.name && team.description
          ? `${team.name} - ${team.description}`
          : team.name ?? team.description ?? "";
  }

  const buildTooltip = (userTeam: UserTeamRolesDto | undefined, baseTooltip?: string | undefined): string => {
    if (!userTeam) return baseTooltip ?? "";

    const tooltips = [];

    if (baseTooltip) {
      tooltips.push(baseTooltip);
    }

    if (userTeam?.team?.type === TeamTypes.BusinessUnit) {
      if (userTeam?.source === TeamMemberSources.AzureAd) {
        tooltips.push(t("Business unit assigned by default. Access can not be removed."));

        if (userTeam.roles.some(r => isTeamAdminRole(r.abbreviation))) {
          tooltips.push(t("User admin role assigned externally to first user or by user admin."));
        }
      }
    }
    
    return tooltips.join("\n");
  };

  const colDefs: ColDef<UserTeamRolesDto>[] = [
    {
      headerClass: "grid-header-center-with-menu",
      field: "team.id",
      headerName: t("In use"),
      cellRenderer: GridRadioButtonRenderer,
      suppressAutoSize: true,
      minWidth: 92,
      maxWidth: 92,
      width: 92,
      tooltipValueGetter: (params) => 
        buildTooltip(params.data,
          params.data?.status === TeamMemberStatusDto.Invited 
            ? t("You haven't accepted invitation to this team") 
            : !params.data?.isActive 
              ? t("You are set as inactive in this team")
              : ""),
      cellRendererParams: (params: ICellRendererParams<UserTeamRolesDto>) => {
        const isStatusApproved = params.data?.status === TeamMemberStatusDto.Approved || params.data?.status === TeamMemberStatusDto.RoleChangeRequested;
        const cellRendererParams: GridRadioButtonRendererProps = {
          ...params,
          isDisabled: !(params.data?.isActive && isStatusApproved && canDoActions),
          selectionValue: params.data?.team?.id?.toString() ?? "",
          value: params.data?.isInUse ? params.data?.team?.id?.toString() ?? "" : "",
          onChange: () => {
            if(canDoActions && isStatusApproved && userId && params.data?.team) {
              onChangeActiveTeam(userId, params.data?.team, !params.data?.isInUse);
            }
          },
        };
        return cellRendererParams;
      }
    },
    {
      headerName: t(nameColumnTitle),
      tooltipValueGetter: (params) => 
        buildTooltip(params.data),
      field: "team.name",
      valueGetter: (params) => params && params.data ? buildTeamName(params.data.team) : "",
      flex: 1
    },
    {
      headerName: t("User role"),
      field: "roles",
      tooltipValueGetter: (params: ITooltipParams<UserTeamRolesDto, ManageUserRoleDto[]>): string => {
        const friendlyNames = params?.data?.roles?.map(role => `${role?.abbreviation} ${roleAbbreviationToFriendlyName(role?.abbreviation)}`) ?? [];
        return buildTooltip(params.data, friendlyNames.join("\n"));
      },
      valueGetter: (params: ValueGetterParams<UserTeamRolesDto, ManageUserRoleDto[]>) => {
        const roleAbbreviations = params?.data?.roles?.map(role => role?.abbreviation) ?? [];
        return roleAbbreviations.join(", ");
      },
    },
    {
      headerName: t("Status"),
      tooltipValueGetter: (params) => buildTooltip(params.data),
      field: "status",
      maxWidth: 120,
      valueGetter: (params) => getTeamMemeberStatusText(params?.data?.status, t) ?? "",
    },
    {
      headerName: t("Active"),
      tooltipValueGetter: (params) => 
        buildTooltip(params.data),
      field: "isActive",
      maxWidth: 100,
      cellRenderer: useToggleSwitch ? GridToggleSwitchRenderer : GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams<UserTeamRolesDto>) => {
        const isStatusApproved = params.data?.status === TeamMemberStatusDto.Approved || params.data?.status === TeamMemberStatusDto.RoleChangeRequested;
        const checkboxRendererParams: GridCheckboxRendererProps = {
          ...params,
          isDisabled: !(isStatusApproved && canDoActions),
          data: params.data?.isActive,
          onChange: (newValue) => {
            if(canDoActions && isStatusApproved && userId && params.data?.team) {
              onUpdateMemberActivity(userId, params.data?.team, newValue);
            }
          }
        };
        return checkboxRendererParams;
      }
    },
    {
      cellRenderer: GridActionsRenderer,
      ...createHeaderAndHeaderTooltip("Action"),
      field: "team.id",
      hide: !showActions || !canDoActions,
      tooltipValueGetter: () => "",
      cellRendererParams: (params: ICellRendererParams<UserTeamRolesDto>) => {
        const teamMember = params.data;
        if (!teamMember) return;

        const cellRendererParams: GridActionsRendererParams = {
          ...params,
          menuItems: params && params.data ? actionMenuItems(params.data) : []
        };
        return cellRendererParams;
      },
      minWidth: 70,
      maxWidth: 70,
      floatingFilter: false,
      sortable: false,
      suppressMenu: true,
      filter: false,
      resizable: false,
    },
  ];

  const getTeamLabel = (team: UserTeamDto | undefined) => {
    return team ? buildTeamName(team) : "";
  }

  return (
    <Container>
      <Grid
        t={t}
        rowData={userTeamRoles}
        disableColumnAutoSize={true}
        suppressCellFocus={true}
        domLayout="autoHeight"
        columnDefs={colDefs}
        getRowStyle={(params) =>getTeamMemberGridRowStyle(params)}
        colDefDefault={{ sortable: false }}
        onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
      />
      {canDoActions ? (
        <InputWrapper>
          <Dropdown
            key={"teamName"}
            onClose={
              () => {
                console.log("foo");
              }
            }
            monochrome
            label={`+ ${t("Add {{teamType}}", { teamType: nameColumnTitle.toLowerCase() })}`}
            searchable
            disabled={false}
            onChange={(
              selection: Array<{
                value: any;
                label: string;
                isNew: boolean;
              }>
            ) => {
              const value = selection.length > 0 ? selection[0].value : undefined;

              setRequestingAccessTo(availableTeams?.find(t => t.id === value));
            }}
            value={[
              {
                value: requestingAccessTo?.id,
                label: getTeamLabel(requestingAccessTo)
              }
            ]}
          >
            <DropdownOption value={-1} label={" "} />
            {availableTeams ? (
              availableTeams.filter(t => !t.isMember).map((team) => (
                <DropdownOption
                  key={team.id}
                  value={team.id}
                  label={getTeamLabel(team)}
                />
              ))
            ) : null}
          </Dropdown>
          <Button
            buttonType="secondary"
            text={t("Request access")}
            disabled={!requestingAccessTo}
            onClick={() => {
              if(userId && requestingAccessTo) {
                onRequestTeamAccess(userId, requestingAccessTo)
                setRequestingAccessTo(undefined);
              }
            }}
            style={{ alignSelf: "end" }}
            sizeClass="small"
          />
        </InputWrapper>
      ) : null}
    </Container>
  );
};

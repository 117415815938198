/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleTypes {
    Global = 'Global',
    Company = 'Company',
    InternalCompany = 'InternalCompany',
    ExternalCompany = 'ExternalCompany',
    BusinessUnitTeam = 'BusinessUnitTeam',
    FunctionalTeam = 'FunctionalTeam'
}

export function RoleTypesFromJSON(json: any): RoleTypes {
    return RoleTypesFromJSONTyped(json, false);
}

export function RoleTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleTypes {
    return json as RoleTypes;
}

export function RoleTypesToJSON(value?: RoleTypes | null): any {
    return value as any;
}


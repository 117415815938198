import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { LabelWithText } from "framework/components/LabelWithText";
import { getTermsAndConditions } from "../../manage/manageQuotes/reducers/quoteViewReducer";
import {
  CompanyDto,
  QuoteBaseDto,
  QuoteStateTypes,
  ShoppingCartDto,
  ShoppingCartStateTypes
} from "api";
import { TFunction } from "i18next";
import { manageQuotesSagas } from "../../manage/manageQuotes/sagas/ManageQuotesSagas";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { manageTermsConditionsSagas } from "applications/manage/manageTermsConditions/sagas/manageTermsConditionsSagas";
import { usePriceListsDropdown } from "framework/hooks/usePriceListsDropdown";
import TermsAndConditionsLink from "applications/common/components/TermsAndConditionsLink";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { InputLabel } from "framework/components/InputLabel";
import { ContentContainer } from "applications/shoppingCart/components/orderDetails/ContentContainer";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import { routes } from "utilities/routes";
import { Link } from "framework/components/Link";
import { BudgetaryNote } from "../../common/components/BudgetaryNote";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { RedirectDialog } from "framework/components/RedirectDialog";
import { userSagas } from "applications/common/sagas/userSagas";

const FlexColumn = styled.div`
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: ${(props) => props.theme.sizes.m};
`;

const FlexRow = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.sizes.lm};
`;

const LinkContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  margin-top: ${(props) => props.theme.sizes.xs};
  :hover {
    cursor: pointer;
  }
`;

const CustomDropdown = styled(Dropdown)`
  [class$="-menu"] {
    width: max-content;
    min-width: 180px;
    right: 0;
  }
  [class$="-SingleValue"] {
    max-width: 100%;
  }
  min-width: 180px;
`;

const Message = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  word-break: break-word;
  white-space: pre-wrap;
  max-height: 7lh;
  overflow-y: auto;
`;

/**
 * @param isEditable Override internal logic to decide if component should be in edit mode.
 */
interface PriceInfoProps {
  quote: QuoteBaseDto | undefined;
  t: TFunction;
  currency: string | null | undefined;
  shoppingCart: ShoppingCartDto | undefined;
  selectedCompany: CompanyDto | undefined;
  isEditable?: boolean;
}

const PriceInfo = ({
  quote,
  t,
  currency,
  shoppingCart,
  selectedCompany,
  isEditable
}: PriceInfoProps): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [updateSelectedCompanyRedirect, setUpdateSelectedCompanyRedirect] = useState(false);

  // Selector
  const termsAndConditions = useSelector(getTermsAndConditions);
  const userInformation = useSelector(getUserInformation);

  useEffect(() => {
    dispatch(shoppingCartSagas.getTermsOfDeliveries.createAction({ canUse: true }));
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    if (quote?.id) {
      dispatch(
        manageQuotesSagas.getQuoteTermsAndCondition.createAction({
          quoteId: quote?.id
        })
      );
    }
  }, [dispatch, quote?.id]);

  const handleDownloadTermsAndConditions = () => {
    if (quote && quote.id) {
      dispatch(
        manageTermsConditionsSagas.downloadQuoteTermsConditions.createAction({ quoteId: quote.id })
      );
    }
  };

  const { selectedPriceList, userPriceLists, changeSelectedPriceList } = usePriceListsDropdown(
    shoppingCart,
    quote?.stateId !== QuoteStateTypes.WaitingForQuote ||
      shoppingCart?.stateId !== ShoppingCartStateTypes.Draft
  );

  return (
    <ContentContainer title={t("Pricing and currency")} gap={theme.sizes.m}>
      {updateSelectedCompanyRedirect ? (
        <RedirectDialog
          showDialog={true}
          text={t(
            "Your active company will be changed automatically to shopping cart's company in order to open related orders.\nYou will need to press one related order once again. \n"
          )}
          t={t}
          redirectCallback={() => {
            dispatch(
              userSagas.updateUserSelectedCompany.createAction({
                updateUserSelectedCompanyCommand: {
                  selectedCompanyId: shoppingCart?.company?.id
                }
              })
            );
            setUpdateSelectedCompanyRedirect(false);
          }}
          countdownFrom={5}
          icon={"abb/warning-circle-1"}
          title={t("Changing selected company...")}
          hideBackground={false}
        />
      ) : undefined}

      <FlexRow>
        <FlexColumn>
          <div>
            {!!quote && (
              <LabelWithText
                label={t("Price list")}
                text={[
                  quote?.priceListDisplayName,
                  quote?.priceListName && quote?.priceListName !== quote?.priceListDisplayName
                    ? `(${quote.priceListName})`
                    : ""
                ]
                  .filter(Boolean)
                  .join("\n")}
                noMarginBottom
              />
            )}
            {isEditable && !!shoppingCart && (
              <div>
                <CustomDropdown
                  searchable={userPriceLists && userPriceLists.length > 14 ? true : false}
                  monochrome
                  label={t("Select price list")}
                  value={selectedPriceList}
                  onChange={(item) => {
                    if (item && item[0]) {
                      changeSelectedPriceList(item[0].value);
                    }
                  }}
                >
                  <DropdownOption label={t("No prices")} value="null" />
                  {(userPriceLists ?? []).map((u) => (
                    <DropdownOption key={u.value} label={u.label} value={u.value} />
                  ))}
                </CustomDropdown>
              </div>
            )}
            {termsAndConditions ? (
              <LinkContainer>
                <TermsAndConditionsLink
                  onClick={() => handleDownloadTermsAndConditions()}
                ></TermsAndConditionsLink>
              </LinkContainer>
            ) : (
              <></>
            )}
            <BudgetaryNote />
          </div>
        </FlexColumn>
        <FlexColumn>
          <LabelWithText label={t("Currency")} text={currency} />
        </FlexColumn>
      </FlexRow>
      {!!quote &&
        (shoppingCart?.termsOfDeliveryCode != null ||
          shoppingCart?.termsOfDeliveryDescription != null) && (
          <div>
            <InputLabel
              label={t("Terms of delivery")}
              tooltipText={t(
                "Terms of delivery are based on Incoterms 2020. The shown Incoterm is the default used, but another can be requested. Incoterms and destination will be confirmed in Order Acknowledgement during order booking phase."
              )}
            />
            {`${shoppingCart?.termsOfDeliveryCode?.concat(" - ") ?? ""}${
              shoppingCart?.termsOfDeliveryDescription ?? ""
            }`}
          </div>
        )}
      {quote?.reviseMessage && (
        <div>
          <SectionHeader
            text={t("Revision request message")}
            style={{ marginBottom: theme.sizes.m }}
          />
          <Message>{quote.reviseMessage}</Message>
        </div>
      )}
      {shoppingCart?.quotationRequestMessage &&
        quote?.stateId === QuoteStateTypes.WaitingForQuote && (
          <div>
            <SectionHeader
              text={t("Message from quote requester")}
              style={{ marginBottom: theme.sizes.m }}
            />
            <Message>{shoppingCart.quotationRequestMessage}</Message>
          </div>
        )}
      {quote?.stateId === QuoteStateTypes.FrameAgreement && (
        <div>
          <LabelWithText
            label={t("Related orders")}
            text={
              <Link
                iconBefore="abb/link"
                style={{
                  fontSize: theme.fonts.sizes.fontSizeS
                }}
                to={"#"}
                onlyOnClick
                onClick={() => {
                  if (userInformation?.selectedCompanyId !== shoppingCart?.company?.id) {
                    setUpdateSelectedCompanyRedirect(true);
                  } else {
                    navigate(`${routes.orders.index}?orderOrReference=${quote.reference}`);
                  }
                }}
              >
                {t("Open related orders")}
              </Link>
            }
            noMarginBottom
          />
        </div>
      )}
    </ContentContainer>
  );
};

export default PriceInfo;

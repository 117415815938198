/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutomaticDownloadModelForLicenses,
    AutomaticDownloadModelForLicensesFromJSON,
    AutomaticDownloadModelForLicensesFromJSONTyped,
    AutomaticDownloadModelForLicensesToJSON,
} from './AutomaticDownloadModelForLicenses';
import {
    JustInTimeLicenseDto,
    JustInTimeLicenseDtoFromJSON,
    JustInTimeLicenseDtoFromJSONTyped,
    JustInTimeLicenseDtoToJSON,
} from './JustInTimeLicenseDto';
import {
    LicensePackageDto,
    LicensePackageDtoFromJSON,
    LicensePackageDtoFromJSONTyped,
    LicensePackageDtoToJSON,
} from './LicensePackageDto';

/**
 * 
 * @export
 * @interface OrderLicensesVm
 */
export interface OrderLicensesVm {
    /**
     * 
     * @type {string}
     * @memberof OrderLicensesVm
     */
    serialNumber: string;
    /**
     * 
     * @type {Array<LicensePackageDto>}
     * @memberof OrderLicensesVm
     */
    licensePackages: Array<LicensePackageDto>;
    /**
     * 
     * @type {Array<JustInTimeLicenseDto>}
     * @memberof OrderLicensesVm
     */
    justInTimeLicenses: Array<JustInTimeLicenseDto>;
    /**
     * 
     * @type {AutomaticDownloadModelForLicenses}
     * @memberof OrderLicensesVm
     */
    automaticDownloadInformation?: AutomaticDownloadModelForLicenses;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLicensesVm
     */
    userCanRequestSupport?: boolean;
}

export function OrderLicensesVmFromJSON(json: any): OrderLicensesVm {
    return OrderLicensesVmFromJSONTyped(json, false);
}

export function OrderLicensesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLicensesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serialNumber'],
        'licensePackages': ((json['licensePackages'] as Array<any>).map(LicensePackageDtoFromJSON)),
        'justInTimeLicenses': ((json['justInTimeLicenses'] as Array<any>).map(JustInTimeLicenseDtoFromJSON)),
        'automaticDownloadInformation': !exists(json, 'automaticDownloadInformation') ? undefined : AutomaticDownloadModelForLicensesFromJSON(json['automaticDownloadInformation']),
        'userCanRequestSupport': !exists(json, 'userCanRequestSupport') ? undefined : json['userCanRequestSupport'],
    };
}

export function OrderLicensesVmToJSON(value?: OrderLicensesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'licensePackages': ((value.licensePackages as Array<any>).map(LicensePackageDtoToJSON)),
        'justInTimeLicenses': ((value.justInTimeLicenses as Array<any>).map(JustInTimeLicenseDtoToJSON)),
        'automaticDownloadInformation': AutomaticDownloadModelForLicensesToJSON(value.automaticDownloadInformation),
        'userCanRequestSupport': value.userCanRequestSupport,
    };
}


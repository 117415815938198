/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeamAccessRequestTypes {
    AccessRequest = 'AccessRequest',
    RoleRequest = 'RoleRequest'
}

export function TeamAccessRequestTypesFromJSON(json: any): TeamAccessRequestTypes {
    return TeamAccessRequestTypesFromJSONTyped(json, false);
}

export function TeamAccessRequestTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamAccessRequestTypes {
    return json as TeamAccessRequestTypes;
}

export function TeamAccessRequestTypesToJSON(value?: TeamAccessRequestTypes | null): any {
    return value as any;
}


import { RowClassParams, RowStyle } from "ag-grid-community";
import { TeamMemberDto, TeamMemberSources, TeamMemberStatusDto, TeamTypes, UserTeamRolesDto } from "api";
import { TFunction } from "i18next";
import { commonUXTheme } from "styles/commonUXVariables";

const roleAbbreviationFriendlyNameMap: { abbreviation: string, friendlyName: string, teamType: TeamTypes, isAdmin: boolean }[] = [
  { abbreviation: "BU1", friendlyName: "Administrator", teamType: TeamTypes.BusinessUnit, isAdmin: true },
  { abbreviation: "BU2", friendlyName: "User", teamType: TeamTypes.BusinessUnit, isAdmin: false },
  { abbreviation: "FT1", friendlyName: "Administrator", teamType: TeamTypes.FunctionalTeam, isAdmin: true },
  { abbreviation: "FT2", friendlyName: "Viewer", teamType: TeamTypes.FunctionalTeam, isAdmin: false },
  { abbreviation: "FT3", friendlyName: "Contributor", teamType: TeamTypes.FunctionalTeam, isAdmin: false },
  { abbreviation: "FT4", friendlyName: "Approver", teamType: TeamTypes.FunctionalTeam, isAdmin: false },
];

export function roleAbbreviationToFriendlyName(abbreviation: string | undefined | null): string {
  const role = roleAbbreviationFriendlyNameMap.find(role => role.abbreviation === (abbreviation ?? "").toUpperCase());
  return role?.friendlyName || "";
}

export function getRoleAbbreviationsForTeamType(teamType?: TeamTypes): string[] {
  return roleAbbreviationFriendlyNameMap.filter(role => role.teamType === teamType).map(role => role.abbreviation) ?? [];
}

export function getTeamMemberGridRowStyle(params: RowClassParams<UserTeamRolesDto | TeamMemberDto>): RowStyle | undefined {
  if (!params.data) {
    return undefined;
  }

  switch (params.data.source) {
    case TeamMemberSources.AzureAd:
      return { backgroundColor: commonUXTheme.colors.green10 };
    case TeamMemberSources.UserRequested:
    default:
      return undefined;
  }
};

export function isTeamAdminRole(abbreviation: string): boolean {
  return roleAbbreviationFriendlyNameMap.some(role => role.abbreviation === abbreviation.toUpperCase() && role.isAdmin);
}

export function getTeamTypeFriendlyName(teamType: TeamTypes | undefined, t: TFunction): string {
  switch (teamType) {
    case TeamTypes.BusinessUnit:
      return t("Business unit");
    case TeamTypes.FunctionalTeam:
      return t("Functional team");
    default:
      return "";
  }
}

export function getTeamMemeberStatusText(status: TeamMemberStatusDto | undefined, t: TFunction): string {
  switch (status) {
    case TeamMemberStatusDto.Requested:
      return t("Requested");
    case TeamMemberStatusDto.Rejected:
      return t("Rejected");
    case TeamMemberStatusDto.Approved:
      return t("Approved");
    case TeamMemberStatusDto.Removed:
      return t("Removed");
    case TeamMemberStatusDto.Invited:
      return t("Invited");
    case TeamMemberStatusDto.RoleChangeRequested:
      return t("Role change requested");
    default:
      return "";
  }
}
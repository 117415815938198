import {
  TeamsVm,
  ApiTeamsGetRequest,
  TeamDto,
  ApiTeamsIdGetRequest,
  TeamMemberDto,
  ApiTeamsUserTeamsGetRequest,
  ApiTeamsChangeMemberStatusPutRequest,
  ApiTeamsChangeMemberTeamInUsePutRequest,
  ApiTeamsChangeMemberRolePutRequest,
  ApiTeamsChangeMemberActivityPutRequest,
  ApiTeamsPostRequest,
  ApiTeamsMembersInvitePostRequest,
  UserTeamDto,
  TeamInvitationDto,
  ApiTeamsMembersAcceptTeamInvitationPostRequest,
  TeamAccessRequestDto,
  ApiTeamsMembersRequestTeamAccessPostRequest,
  UserTeamRolesDto,
  ApiTeamsMembersRolesUserIdGetRequest
} from "api";
import {
  createGenericSaga,
  CreateSagaOptions,
  defaultSagaErrorHandler,
  GenericSagaOptions,
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

interface ManageTeamsSagas {
  [index: string]: CreateSagaOptions<any, any>;
  getTeams: GenericSagaOptions<TeamsVm, ApiTeamsGetRequest>;
  getTeam: GenericSagaOptions<TeamDto, ApiTeamsIdGetRequest>;
  getUserTeams: GenericSagaOptions<Array<UserTeamDto>, ApiTeamsUserTeamsGetRequest>;
  getUserTeamRoles: GenericSagaOptions<Array<UserTeamRolesDto>, ApiTeamsMembersRolesUserIdGetRequest>;
  changeTeamMemberStatus: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberStatusPutRequest>;
  changeTeamMemberRole: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberRolePutRequest>;
  changeTeamMemberActivity: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberActivityPutRequest>;
  changeTeamMemberTeamInUse: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberTeamInUsePutRequest>;
  createTeam: GenericSagaOptions<TeamDto, ApiTeamsPostRequest>;
  inviteUserToTeam: GenericSagaOptions<TeamInvitationDto, ApiTeamsMembersInvitePostRequest>;
  requestTeamAccess: GenericSagaOptions<TeamAccessRequestDto, ApiTeamsMembersRequestTeamAccessPostRequest>;
  acceptTeamInvitation: GenericSagaOptions<TeamMemberDto, ApiTeamsMembersAcceptTeamInvitationPostRequest>;
}

export const manageTeamsSagas: ManageTeamsSagas = {
  getTeam: createGenericSaga(
    "manageTeamsSagas/getTeam",
    getApiRegistry().teamsApi.apiTeamsIdGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getTeams: createGenericSaga(
    "manageTeamsSagas/getTeams",
    getApiRegistry().teamsApi.apiTeamsGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getUserTeams: createGenericSaga(
    "teamSagas/getUserTeams",
    getApiRegistry().teamsApi.apiTeamsUserTeamsGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getUserTeamRoles: createGenericSaga(
    "teamSagas/getUserTeamRoles",
    getApiRegistry().teamsApi.apiTeamsMembersRolesUserIdGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberStatus: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberStatus",
    getApiRegistry().teamsApi.apiTeamsChangeMemberStatusPut.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberRole: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberRole",
    getApiRegistry().teamsApi.apiTeamsChangeMemberRolePut.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberActivity: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberActivity",
    getApiRegistry().teamsApi.apiTeamsChangeMemberActivityPut.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberTeamInUse: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberTeamInUse",
    getApiRegistry().teamsApi.apiTeamsChangeMemberTeamInUsePut.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  createTeam: createGenericSaga(
    "manageTeamsSagas/createTeam",
    getApiRegistry().teamsApi.apiTeamsPost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  inviteUserToTeam: createGenericSaga(
    "manageTeamsSagas/inviteUserToTeam",
    getApiRegistry().teamsApi.apiTeamsMembersInvitePost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  requestTeamAccess: createGenericSaga(
    "manageTeamsSagas/requestTeamAccess",
    getApiRegistry().teamsApi.apiTeamsMembersRequestTeamAccessPost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  acceptTeamInvitation: createGenericSaga(
    "manageTeamsSagas/acceptTeamInvitation",
    getApiRegistry().teamsApi.apiTeamsMembersAcceptTeamInvitationPost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
};

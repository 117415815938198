import { ArticleDto, NodeDto } from "api";
import { Action } from "redux";
export enum TerminologyBankActionType {
  SetVisibleArticle = "TerminologyBank/SetVisibleArticle",
  SetSearchText = "TerminologyBank/SetSearchText",
  SetLoadingResults = "TerminologyBank/SetLoadingResults",
  SetPageNumber = "TerminologyBank/SetPageNumber",
  SetPageSize = "TerminologyBank/SetPageSize",
  SetForceScroll = "TerminologyBank/SetForceScroll",
  ResetArticles = "TerminologyBank/ResetArticles",
  SetScrollNode = "TerminologyBank/SetScrollNode"
}

export interface SetVisibleArticle extends Action {
  type: TerminologyBankActionType.SetVisibleArticle;
  article: ArticleDto;
  visible: boolean;
}
export interface SetSearchText extends Action {
  type: TerminologyBankActionType.SetSearchText;
  search: string | undefined;
}

export interface SetLoadingResults extends Action {
  type: TerminologyBankActionType.SetLoadingResults;
  loading: boolean | undefined;
}
export interface SetPageSize extends Action {
  type: TerminologyBankActionType.SetPageSize;
  size: number;
}
export interface SetPageNumber extends Action {
  type: TerminologyBankActionType.SetPageNumber;
  page: number;
}
export interface SetForceScroll extends Action {
  type: TerminologyBankActionType.SetForceScroll;
  force: boolean;
}
export interface ResetArticles extends Action {
  type: TerminologyBankActionType.ResetArticles;
}
export interface SetScrollNode extends Action {
  type: TerminologyBankActionType.SetScrollNode;
  node: NodeDto | undefined;
}
export const TerminologyBankAction = {
  setVisibleArticle: (article: ArticleDto, visible: boolean): SetVisibleArticle => ({
    type: TerminologyBankActionType.SetVisibleArticle,
    article,
    visible
  }),
  setSearchText: (search: string | undefined): SetSearchText => ({
    type: TerminologyBankActionType.SetSearchText,
    search
  }),
  setLoading: (loading: boolean | undefined): SetLoadingResults => ({
    type: TerminologyBankActionType.SetLoadingResults,
    loading
  }),
  setPageSize: (size: number): SetPageSize => ({
    type: TerminologyBankActionType.SetPageSize,
    size
  }),
  setPageNumber: (page: number): SetPageNumber => ({
    type: TerminologyBankActionType.SetPageNumber,
    page
  }),
  setForceScroll: (force: boolean): SetForceScroll => ({
    type: TerminologyBankActionType.SetForceScroll,
    force
  }),
  resetArticles: () => ({
    type: TerminologyBankActionType.ResetArticles
  }),
  setScrollNode: (node: NodeDto | undefined): SetScrollNode => ({
    type: TerminologyBankActionType.SetScrollNode,
    node
  })
};

export type TerminologyBankActions =
  | SetVisibleArticle
  | SetSearchText
  | SetLoadingResults
  | SetPageNumber
  | SetPageSize
  | SetForceScroll
  | ResetArticles
  | SetScrollNode;

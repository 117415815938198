/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuoteStateTypes,
    QuoteStateTypesFromJSON,
    QuoteStateTypesFromJSONTyped,
    QuoteStateTypesToJSON,
} from './QuoteStateTypes';
import {
    RevisionQuoteHistoryItem,
    RevisionQuoteHistoryItemFromJSON,
    RevisionQuoteHistoryItemFromJSONTyped,
    RevisionQuoteHistoryItemToJSON,
} from './RevisionQuoteHistoryItem';

/**
 * 
 * @export
 * @interface ManageQuoteDto
 */
export interface ManageQuoteDto {
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    shoppingCartIdentifier: string;
    /**
     * 
     * @type {QuoteStateTypes}
     * @memberof ManageQuoteDto
     */
    stateId: QuoteStateTypes;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    reference: string;
    /**
     * 
     * @type {Date}
     * @memberof ManageQuoteDto
     */
    quoteRequestedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ManageQuoteDto
     */
    tenderValidityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    requestedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    requestedByEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    requestedByRemoved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    quotedByName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    quotedByRemoved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    businessUnitTeamName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    companyDisplayName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    totalQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    totalPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    totalQuotedPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    totalSalesPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    discountPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    readonly totalDisplayPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    readonly totalDisplayQuotedPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    readonly totalDisplaySalesPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    readonly markupPercentage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    isInternalOrMaintained: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManageQuoteDto
     */
    matProjectId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    isOrdered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    showFrameAgreementWarning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuoteDto
     */
    frameAgreement?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ManageQuoteDto
     */
    expectedOrderDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    quoteReceiverEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    rejectMessage?: string | null;
    /**
     * 
     * @type {Array<RevisionQuoteHistoryItem>}
     * @memberof ManageQuoteDto
     */
    history?: Array<RevisionQuoteHistoryItem> | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    accountCustomer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    endCustomer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    endCustomerCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    soldToCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    quotedToChannel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageQuoteDto
     */
    sfdcOpportunityReference?: string | null;
}

export function ManageQuoteDtoFromJSON(json: any): ManageQuoteDto {
    return ManageQuoteDtoFromJSONTyped(json, false);
}

export function ManageQuoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageQuoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectName': json['projectName'],
        'shoppingCartIdentifier': json['shoppingCartIdentifier'],
        'stateId': QuoteStateTypesFromJSON(json['stateId']),
        'state': json['state'],
        'reference': json['reference'],
        'quoteRequestedDate': !exists(json, 'quoteRequestedDate') ? undefined : (json['quoteRequestedDate'] === null ? null : new Date(json['quoteRequestedDate'])),
        'tenderValidityDate': !exists(json, 'tenderValidityDate') ? undefined : (json['tenderValidityDate'] === null ? null : new Date(json['tenderValidityDate'])),
        'requestedBy': json['requestedBy'],
        'requestedByEmail': !exists(json, 'requestedByEmail') ? undefined : json['requestedByEmail'],
        'requestedByRemoved': !exists(json, 'requestedByRemoved') ? undefined : json['requestedByRemoved'],
        'quotedByName': !exists(json, 'quotedByName') ? undefined : json['quotedByName'],
        'quotedByRemoved': !exists(json, 'quotedByRemoved') ? undefined : json['quotedByRemoved'],
        'businessUnitTeamName': !exists(json, 'businessUnitTeamName') ? undefined : json['businessUnitTeamName'],
        'companyDisplayName': !exists(json, 'companyDisplayName') ? undefined : json['companyDisplayName'],
        'totalQuantity': json['totalQuantity'],
        'currency': json['currency'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'totalQuotedPrice': !exists(json, 'totalQuotedPrice') ? undefined : json['totalQuotedPrice'],
        'totalSalesPrice': !exists(json, 'totalSalesPrice') ? undefined : json['totalSalesPrice'],
        'discountPercentage': !exists(json, 'discountPercentage') ? undefined : json['discountPercentage'],
        'totalDisplayPrice': !exists(json, 'totalDisplayPrice') ? undefined : json['totalDisplayPrice'],
        'totalDisplayQuotedPrice': !exists(json, 'totalDisplayQuotedPrice') ? undefined : json['totalDisplayQuotedPrice'],
        'totalDisplaySalesPrice': !exists(json, 'totalDisplaySalesPrice') ? undefined : json['totalDisplaySalesPrice'],
        'markupPercentage': !exists(json, 'markupPercentage') ? undefined : json['markupPercentage'],
        'isInternalOrMaintained': json['isInternalOrMaintained'],
        'matProjectId': !exists(json, 'matProjectId') ? undefined : json['matProjectId'],
        'isOrdered': !exists(json, 'isOrdered') ? undefined : json['isOrdered'],
        'showFrameAgreementWarning': !exists(json, 'showFrameAgreementWarning') ? undefined : json['showFrameAgreementWarning'],
        'frameAgreement': !exists(json, 'frameAgreement') ? undefined : json['frameAgreement'],
        'expectedOrderDate': !exists(json, 'expectedOrderDate') ? undefined : (json['expectedOrderDate'] === null ? null : new Date(json['expectedOrderDate'])),
        'quoteReceiverEmail': !exists(json, 'quoteReceiverEmail') ? undefined : json['quoteReceiverEmail'],
        'rejectMessage': !exists(json, 'rejectMessage') ? undefined : json['rejectMessage'],
        'history': !exists(json, 'history') ? undefined : (json['history'] === null ? null : (json['history'] as Array<any>).map(RevisionQuoteHistoryItemFromJSON)),
        'accountCustomer': !exists(json, 'accountCustomer') ? undefined : json['accountCustomer'],
        'endCustomer': !exists(json, 'endCustomer') ? undefined : json['endCustomer'],
        'endCustomerCountry': !exists(json, 'endCustomerCountry') ? undefined : json['endCustomerCountry'],
        'soldToCountry': !exists(json, 'soldToCountry') ? undefined : json['soldToCountry'],
        'quotedToChannel': !exists(json, 'quotedToChannel') ? undefined : json['quotedToChannel'],
        'sfdcOpportunityReference': !exists(json, 'sfdcOpportunityReference') ? undefined : json['sfdcOpportunityReference'],
    };
}

export function ManageQuoteDtoToJSON(value?: ManageQuoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectName': value.projectName,
        'shoppingCartIdentifier': value.shoppingCartIdentifier,
        'stateId': QuoteStateTypesToJSON(value.stateId),
        'state': value.state,
        'reference': value.reference,
        'quoteRequestedDate': value.quoteRequestedDate === undefined ? undefined : (value.quoteRequestedDate === null ? null : value.quoteRequestedDate.toISOString()),
        'tenderValidityDate': value.tenderValidityDate === undefined ? undefined : (value.tenderValidityDate === null ? null : value.tenderValidityDate.toISOString()),
        'requestedBy': value.requestedBy,
        'requestedByEmail': value.requestedByEmail,
        'requestedByRemoved': value.requestedByRemoved,
        'quotedByName': value.quotedByName,
        'quotedByRemoved': value.quotedByRemoved,
        'businessUnitTeamName': value.businessUnitTeamName,
        'companyDisplayName': value.companyDisplayName,
        'totalQuantity': value.totalQuantity,
        'currency': value.currency,
        'totalPrice': value.totalPrice,
        'totalQuotedPrice': value.totalQuotedPrice,
        'totalSalesPrice': value.totalSalesPrice,
        'discountPercentage': value.discountPercentage,
        'isInternalOrMaintained': value.isInternalOrMaintained,
        'matProjectId': value.matProjectId,
        'isOrdered': value.isOrdered,
        'showFrameAgreementWarning': value.showFrameAgreementWarning,
        'frameAgreement': value.frameAgreement,
        'expectedOrderDate': value.expectedOrderDate === undefined ? undefined : (value.expectedOrderDate === null ? null : value.expectedOrderDate.toISOString()),
        'quoteReceiverEmail': value.quoteReceiverEmail,
        'rejectMessage': value.rejectMessage,
        'history': value.history === undefined ? undefined : (value.history === null ? null : (value.history as Array<any>).map(RevisionQuoteHistoryItemToJSON)),
        'accountCustomer': value.accountCustomer,
        'endCustomer': value.endCustomer,
        'endCustomerCountry': value.endCustomerCountry,
        'soldToCountry': value.soldToCountry,
        'quotedToChannel': value.quotedToChannel,
        'sfdcOpportunityReference': value.sfdcOpportunityReference,
    };
}


import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SectionHeader as SectionHeading } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";
import { ChangeMemberActivityCommand, ChangeMemberTeamInUseCommand, CompanyDto, CurrentUserDto, ManageUserRoleDto, TeamAccessRequestTypes, TeamDto, TeamMemberDto, TeamMemberStatusDto, TeamTypes, UserTeamRolesDto } from "api";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedUser, getSelectedUserHomeCompany } from "../reducers/manageUsersViewReducer";
import { UserTeamsGrid } from "./UserTeamsGrid";
import { getAcceptTeamInvitationRequest, GetChangeMemberActivityRequest, GetChangeMemberTeamInUseRequest, getRequestTeamAccessRequest, getUserAccessibleTeams, getUserTeamRoles } from "applications/manage/manageTeams/reducers/manageTeamsViewReducer";
import { usePrevious } from "framework/hooks/usePrevious";
import { RequestStatus } from "framework/state/requestStatus";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { roleSagas } from "applications/common/sagas/roleSagas";
import { userActions } from "applications/common/actions/userActions";
import { TeamAccessRightsDialog, TeamAccessRightsDialogType } from "./TeamAccessRightsDialog";
import { getUserCompanyRoles } from "applications/common/reducers/userReducer";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.l};
  margin-top: ${(props) => props.theme.sizes.l};

  & ~ div {
    margin-top: ${(props) => props.theme.sizes.l};
  }
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.l};
`;

interface UserTeamGridsProps {
  user?: CurrentUserDto | undefined;
}

export const UserTeamGrids = (props: UserTeamGridsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userId, setUserId] = useState<number | undefined>(undefined);

  const [isRequestTeamRoleChangeDialogOpen, setIsRequestTeamRoleChangeDialogOpen] = useState<boolean>(false);
  const [isRequestTeamRoleChangeDialogLoading, setIsRequestTeamRoleChangeDialogLoading] = useState<boolean>(false);
  const [requestTeamRoleChangeDialogProperies, setRequestTeamRoleChangeDialogProperies] = useState<TeamMemberDto | undefined>(undefined);

  const [isRequestTeamAccessDialogOpen, setIsRequestTeamAccessDialogOpen] = useState<boolean>(false);
  const [isRequestTeamAccessDialogLoading, setIsRequestTeamAccessDialogLoading] = useState<boolean>(false);
  const [requestTeamAccessDialogProperies, setRequestTeamAccessDialogProperies] = useState<{userId: number, team: TeamDto} | undefined>(undefined);

  const [isAcceptInvitationDialogOpen, setIsAcceptInvitationDialogOpen] = useState<boolean>(false);
  const [isAcceptInvitationDialogLoading, setIsAcceptInvitationDialogLoading] = useState<boolean>(false);
  const [currentTeamMember, setCurrentTeamMember] = useState<TeamMemberDto | undefined>(undefined);

  const [isSelectedAbbUser, setIsSelectedAbbUser] = useState<boolean>(false);
  const [inUseBusinessUnitTeam, setInUseBusinessUnitTeam] = useState<UserTeamRolesDto | undefined>(undefined);

  const selectedUserHomeCompany = useSelector(getSelectedUserHomeCompany);
  const userCompanyRoles = useSelector(getUserCompanyRoles);
  const [userHomeCompany, setUserHomeCompany] = useState<CompanyDto | undefined>(undefined);

  const selectedUser = useSelector(getSelectedUser);
  const userTeamRoles = useSelector(getUserTeamRoles);
  const accessibleTeams = useSelector(getUserAccessibleTeams);

  const changeMemberActivityRequest = useSelector(GetChangeMemberActivityRequest);
  const prevChangeMemberActivityRequest = usePrevious(changeMemberActivityRequest);

  const changeMemberTeamInUseRequest = useSelector(GetChangeMemberTeamInUseRequest);
  const prevChangeMemberTeamInUseRequest = usePrevious(changeMemberTeamInUseRequest);

  const requestTeamAccessRequest = useSelector(getRequestTeamAccessRequest);
  const prevRequestTeamAccessRequest = usePrevious(requestTeamAccessRequest);

  const acceptInvitationRequest = useSelector(getAcceptTeamInvitationRequest);
  const prevAcceptInvitationRequest = usePrevious(acceptInvitationRequest);

  const reloadUserRoles = useCallback(
    (userId: number) => {
      dispatch(manageTeamsSagas.getUserTeamRoles.createAction({ userId: userId }));
    },
    [dispatch]
  );

  // If no user previously selected, but one given in parameters e.g. User information page
  useEffect(() => {
    if(props.user && props.user.id && props.user.homeCompanyId && userCompanyRoles) {
      const homeCompany = userCompanyRoles.find((ucr) => ucr.companyUser.companyId === props.user?.homeCompanyId)?.companyUser.company;
      setUserHomeCompany(homeCompany);

      dispatch(roleSagas.getRoles.createAction({}));
    }
  }, [props.user, userCompanyRoles, dispatch, reloadUserRoles]);

  useEffect(() => {
    setUserId(props.user?.id ?? selectedUser?.user.id);
  }, [props.user, selectedUser, dispatch]);

  useEffect(() => {
    if(userId) {
      dispatch(manageTeamsSagas.getUserTeams.createAction({ criteriaUserId : userId }));
      reloadUserRoles(userId);
    }
  }, [userId, dispatch, reloadUserRoles]);

  useEffect(() => {
    if(userTeamRoles && isSelectedAbbUser) {
      setInUseBusinessUnitTeam(userTeamRoles.find((ut) => ut.team.type === TeamTypes.BusinessUnit && ut.isInUse));
    } else {
      setInUseBusinessUnitTeam(undefined);
    }
  }, [userTeamRoles, isSelectedAbbUser]);

  useEffect(() => {
    setIsSelectedAbbUser((selectedUserHomeCompany?.isAbbCompany || userHomeCompany?.isAbbCompany) ?? false);
  }, [selectedUserHomeCompany, userHomeCompany]);

  useEffect(() => {
    if(changeMemberActivityRequest?.status === RequestStatus.Completed && prevChangeMemberActivityRequest?.status === RequestStatus.Pending && userId) {
      reloadUserRoles(userId);
    }
  }, [changeMemberActivityRequest, prevChangeMemberActivityRequest, userId, dispatch, reloadUserRoles]);

  const handleUpdateMemberActivity = (userId: number, team: TeamDto, isActive: boolean) => {
    if(!team?.id) return;

    const command: ChangeMemberActivityCommand = {
      userId: userId,
      teamId: team.id,
      isActive: isActive
    };

    dispatch(
      manageTeamsSagas.changeTeamMemberActivity.createAction({changeMemberActivityCommand: command})
    );
  }

  useEffect(() => {
    if(changeMemberTeamInUseRequest?.status === RequestStatus.Completed && prevChangeMemberTeamInUseRequest?.status === RequestStatus.Pending && userId) {
      reloadUserRoles(userId);
    }
  }, [changeMemberTeamInUseRequest, prevChangeMemberTeamInUseRequest, userId, dispatch, reloadUserRoles]);

  useEffect(() => {
    if(acceptInvitationRequest.status === RequestStatus.Completed || acceptInvitationRequest.status === RequestStatus.Failed) {
      setIsAcceptInvitationDialogLoading(false);
    }

    if(acceptInvitationRequest?.status === RequestStatus.Completed && prevAcceptInvitationRequest?.status === RequestStatus.Pending && userId) {
      reloadUserRoles(userId);
      setIsAcceptInvitationDialogOpen(false);
    }
  }, [acceptInvitationRequest, prevAcceptInvitationRequest, userId, dispatch, reloadUserRoles]);

  const handleActiveTeamChange = (userId: number, team: TeamDto, isInUser: boolean) => {
    if(!team?.id) return;

    const command: ChangeMemberTeamInUseCommand = {
      userId: userId,
      teamId: team.id,
      isInUse: isInUser
    };

    dispatch(
      userActions.addConfirmEvent(
        () =>
          dispatch(manageTeamsSagas.changeTeamMemberTeamInUse.createAction({changeMemberTeamInUseCommand: command})),
        t("Change active team"),
        t("Are you sure you want to change active team?")
      )
    );
  }

  const openRequestTeamRoleChangeDialog = (teamMember: TeamMemberDto) => {
    setRequestTeamRoleChangeDialogProperies(teamMember);
    setIsRequestTeamRoleChangeDialogOpen(true);
  };

  const openRequestTeamAccessDialog = (userId: number, team: TeamDto) => {
    setRequestTeamAccessDialogProperies({userId: userId, team: team});
    setIsRequestTeamAccessDialogOpen(true);
  };

  const openAcceptTeamInviteDialog = (teamMember: TeamMemberDto) => {
    setCurrentTeamMember(teamMember);
    setIsAcceptInvitationDialogOpen(true);
  };

  const requestTeamAccess = (userId: number, team: TeamDto, userRoles: ManageUserRoleDto[] | undefined) => {
    if(userId && team?.id) {
      setIsRequestTeamAccessDialogLoading(true);

      dispatch(
        manageTeamsSagas.requestTeamAccess.createAction({
          requestTeamAccessCommand: {
            userId: userId,
            teamId: team.id,
            requestType: TeamAccessRequestTypes.AccessRequest,
            userRoles: Array.from(new Set(userRoles?.map((role) => role.id) ?? [])) // Remove duplicates
          }
        })
      );
    }
  };

  useEffect(() => {
    if(requestTeamAccessRequest.status === RequestStatus.Completed || requestTeamAccessRequest.status === RequestStatus.Failed) {
      setIsRequestTeamAccessDialogLoading(false);
    }

    if(requestTeamAccessRequest?.status === RequestStatus.Completed && prevRequestTeamAccessRequest?.status === RequestStatus.Pending && userId) {
      reloadUserRoles(userId);

      if(isRequestTeamAccessDialogOpen) {
        setIsRequestTeamAccessDialogOpen(false);
        setRequestTeamAccessDialogProperies(undefined);
      }
      
      if(isRequestTeamRoleChangeDialogOpen) {
        setIsRequestTeamRoleChangeDialogLoading(false);
        setRequestTeamRoleChangeDialogProperies(undefined);
      }
    }
  }, [requestTeamAccessRequest, prevRequestTeamAccessRequest, userId, isRequestTeamAccessDialogOpen, isRequestTeamRoleChangeDialogOpen, dispatch, reloadUserRoles]);

  const acceptTeamInvitation = (userId: number, team: TeamDto) => {
    setIsAcceptInvitationDialogLoading(true);
    dispatch(
      manageTeamsSagas.acceptTeamInvitation.createAction({
        acceptInvitationCommand: {
          userId: userId ?? 0,
          teamId: team.id ?? 0,
        }
      })
    );
  };

  const requestTeamRoleChange = (userId: number, team: TeamDto, userRoles: ManageUserRoleDto[] | undefined) => {
    if(userId && team?.id) {
      setIsRequestTeamRoleChangeDialogLoading(true);

      dispatch(
        manageTeamsSagas.requestTeamAccess.createAction({
          requestTeamAccessCommand: {
            userId: userId,
            teamId: team.id,
            requestType: TeamAccessRequestTypes.RoleRequest,
            userRoles: Array.from(new Set(userRoles?.map((role) => role.id) ?? [])) // Remove duplicates
          }
        })
      );
    }
  }

  return (
    <>
      {isRequestTeamRoleChangeDialogOpen && requestTeamRoleChangeDialogProperies?.userId && requestTeamRoleChangeDialogProperies?.team ? 
        <TeamAccessRightsDialog
          t={t}
          isOpen={isRequestTeamRoleChangeDialogOpen}
          setIsOpen={setIsRequestTeamRoleChangeDialogOpen}
          isLoading={isRequestTeamRoleChangeDialogLoading}
          dialogType={TeamAccessRightsDialogType.RequestAccessRights}
          userId={requestTeamRoleChangeDialogProperies.userId}
          team={requestTeamRoleChangeDialogProperies.team}
          onSave={requestTeamRoleChange}
        />
      : null }
      {isRequestTeamAccessDialogOpen && requestTeamAccessDialogProperies ? 
        <TeamAccessRightsDialog
          t={t}
          isOpen={isRequestTeamAccessDialogOpen}
          setIsOpen={setIsRequestTeamAccessDialogOpen}
          isLoading={isRequestTeamAccessDialogLoading}
          dialogType={TeamAccessRightsDialogType.RequestTeamAccess}
          userId={requestTeamAccessDialogProperies.userId}
          team={requestTeamAccessDialogProperies.team}
          onSave={requestTeamAccess}
        />
      : null }
      {isAcceptInvitationDialogOpen && currentTeamMember?.userId && currentTeamMember?.team ? 
        <TeamAccessRightsDialog
          t={t}
          isOpen={isAcceptInvitationDialogOpen}
          dialogType={TeamAccessRightsDialogType.AcceptInvitation}
          setIsOpen={setIsAcceptInvitationDialogOpen}
          isLoading={isAcceptInvitationDialogLoading}
          userId={currentTeamMember.userId}
          team={currentTeamMember.team}
          onSave={acceptTeamInvitation}
        />
      : null}
      {userId && isSelectedAbbUser ? ( // For now only visible to ABB users
        <Container>
          <SectionHeader text={t("Team user roles")} />

          {userTeamRoles && isSelectedAbbUser ? (
            <>
              <UserTeamsGrid
                userId={userId}
                nameColumnTitle={t("Business units")}
                showActions={true}
                userTeamRoles={userTeamRoles.filter((ut) => ut.team.type === TeamTypes.BusinessUnit)}
                availableTeams={accessibleTeams?.filter((team) => team.type === TeamTypes.BusinessUnit && !team.memberStatus)}
                useToggleSwitch={props.user ? true : false}
                onChangeActiveTeam={handleActiveTeamChange}
                onUpdateMemberActivity={handleUpdateMemberActivity}
                onRequestTeamAccess={openRequestTeamAccessDialog}
                actionMenuItems={(teamMember) => [
                  {
                    text: t("Request role change"),
                    itemId: teamMember?.userId?.toString(),
                    disabled: teamMember.status !== TeamMemberStatusDto.Approved,
                    hide: teamMember.status !== TeamMemberStatusDto.Approved,
                    selected: false,
                    icon: "abb/plus",
                    onSelect: () => openRequestTeamRoleChangeDialog(teamMember)
                  }
                ]}
              />

              <UserTeamsGrid
                userId={userId}
                nameColumnTitle={t("Functional teams")}
                showActions={true}
                userTeamRoles={userTeamRoles.filter((ut) => ut.team.type === TeamTypes.FunctionalTeam && ut.team.parentId === inUseBusinessUnitTeam?.team.id)}
                availableTeams={accessibleTeams?.filter((team) => team.type === TeamTypes.FunctionalTeam && team.parentId === inUseBusinessUnitTeam?.team.id && !team.memberStatus)}
                useToggleSwitch={props.user ? true : false}
                onChangeActiveTeam={handleActiveTeamChange}
                onUpdateMemberActivity={handleUpdateMemberActivity}
                onRequestTeamAccess={openRequestTeamAccessDialog}
                getTeamName={(team) => team.parentName && team.name
                  ? `${team.parentName} - ${team.name}`
                  : team.parentName ?? team.name ?? ""}
                actionMenuItems={(teamMember) => [
                  {
                    text: t("Accept invitation"),
                    itemId: teamMember?.userId?.toString(),
                    disabled: teamMember.status !== TeamMemberStatusDto.Invited,
                    hide: teamMember.status !== TeamMemberStatusDto.Invited,
                    selected: false,
                    icon: "abb/add-user",
                    onSelect: () => openAcceptTeamInviteDialog(teamMember)
                  },
                  {
                    text: t("Request role change"),
                    itemId: teamMember?.userId?.toString(),
                    disabled: teamMember.status !== TeamMemberStatusDto.Approved,
                    hide: teamMember.status !== TeamMemberStatusDto.Approved,
                    selected: false,
                    icon: "abb/plus",
                    onSelect: () => openRequestTeamRoleChangeDialog(teamMember)
                  }
                ]}
              />
            </>
          ) : null}
        </Container>
      ) : null}
    </>
  );
};

import React, { memo } from "react";
import { ToggleSwitch } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";

export interface GridToggleSwitchRendererProps extends ICellRendererParams {
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
}

const GridToggleSwitch = styled(ToggleSwitch)`
  .ABB_CommonUX_ToggleSwitch__control {
    margin-right: 0 !important;
  }
`;

export const GridToggleSwitchRenderer = memo((props: GridToggleSwitchRendererProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <GridToggleSwitch
        monochrome={true}
        value={props.value}
        disabled={props.isDisabled}
        onChange={() => props.onChange && props.onChange(!props.value)}
      />
    </div>
  );
});

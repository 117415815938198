import { ShoppingCartDto } from "api/models/ShoppingCartDto";
import PriceInfo from "applications/shoppingCart/components/PriceInfo";
import ProjectDetailsInfo from "applications/manage/manageQuotes/components/ProjectDetailsInfo";
import QuoteDetailsInfo from "applications/manage/manageQuotes/components/QuoteDetailsInfo";
import { TFunction } from "i18next";
import React from "react";
import { InformationContainer, InformationGridContainer } from "./OrderDetailsSection";
import { CompanyDto, QuoteDto } from "api";
import { FormikProps } from "formik";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";
import QuoteMessagesSection from "applications/manage/manageQuotes/components/QuoteMessagesSection";
import { ReviseMode } from "../containers/ShoppingCartView";

export interface QuoteInformationProps {
  t: TFunction;
  shoppingCart?: ShoppingCartDto | undefined;
  quote?: QuoteDto | null;
  selectedCompany?: CompanyDto;
  formik?: FormikProps<ReviseQuoteFromCart>;
  reviseMode: ReviseMode;
  isEditable?: boolean;
}

export const QuoteInformation = ({
  t,
  shoppingCart,
  quote,
  selectedCompany,
  formik,
  reviseMode,
  isEditable
}: QuoteInformationProps): JSX.Element => {
  return (
    <InformationContainer>
      <InformationGridContainer>
        <QuoteDetailsInfo
          shoppingCart={shoppingCart}
          quote={quote ?? shoppingCart?.activeQuote}
          t={t}
          selectedCompany={selectedCompany}
          formik={formik}
          reviseMode={reviseMode}
          isEditable={isEditable}
        />
        <ProjectDetailsInfo
          quote={quote ?? shoppingCart?.activeQuote}
          t={t}
          formik={formik}
          selectedCompany={selectedCompany}
          shoppingCart={shoppingCart}
          isShoppingCartInfo
          reviseMode={reviseMode == ReviseMode.Complete}
          isEditable={isEditable}
        />
        <PriceInfo
          t={t}
          quote={quote ?? shoppingCart?.activeQuote}
          currency={shoppingCart?.priceListCurrency}
          shoppingCart={shoppingCart}
          selectedCompany={selectedCompany ?? shoppingCart?.company}
          isEditable={isEditable}
        />
        <QuoteMessagesSection t={t} quote={quote ?? shoppingCart?.activeQuote} />
      </InformationGridContainer>
    </InformationContainer>
  );
};

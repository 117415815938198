/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    FirmwareDetailsResponseDto,
    FirmwareDetailsResponseDtoFromJSON,
    FirmwareDetailsResponseDtoToJSON,
} from '../models';

export interface ApiFirmwareUpdatesExportPostRequest {
    codes?: Array<string>;
    fromSerialNumber?: string;
    toSerialNumber?: string;
    onlyLatest?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiFirmwareUpdatesFirmwareDetailsGetRequest {
    codes?: Array<string>;
    fromSerialNumber?: string;
    toSerialNumber?: string;
    onlyLatest?: boolean;
}

export interface ApiFirmwareUpdatesFirmwareDownloadFirmwareIdGetRequest {
    firmwareId: number;
}

/**
 * 
 */
export class FirmwareUpdatesApi extends runtime.BaseAPI {

    /**
     */
    async apiFirmwareUpdatesExportPostRaw(requestParameters: ApiFirmwareUpdatesExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.codes) {
            queryParameters['Codes'] = requestParameters.codes;
        }

        if (requestParameters.fromSerialNumber !== undefined) {
            queryParameters['FromSerialNumber'] = requestParameters.fromSerialNumber;
        }

        if (requestParameters.toSerialNumber !== undefined) {
            queryParameters['ToSerialNumber'] = requestParameters.toSerialNumber;
        }

        if (requestParameters.onlyLatest !== undefined) {
            queryParameters['OnlyLatest'] = requestParameters.onlyLatest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFirmwareUpdatesExportPost(requestParameters: ApiFirmwareUpdatesExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFirmwareUpdatesExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDetailsGetRaw(requestParameters: ApiFirmwareUpdatesFirmwareDetailsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FirmwareDetailsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.codes) {
            queryParameters['Codes'] = requestParameters.codes;
        }

        if (requestParameters.fromSerialNumber !== undefined) {
            queryParameters['FromSerialNumber'] = requestParameters.fromSerialNumber;
        }

        if (requestParameters.toSerialNumber !== undefined) {
            queryParameters['ToSerialNumber'] = requestParameters.toSerialNumber;
        }

        if (requestParameters.onlyLatest !== undefined) {
            queryParameters['OnlyLatest'] = requestParameters.onlyLatest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/firmware-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmwareDetailsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDetailsGet(requestParameters: ApiFirmwareUpdatesFirmwareDetailsGetRequest, initOverrides?: RequestInit): Promise<FirmwareDetailsResponseDto> {
        const response = await this.apiFirmwareUpdatesFirmwareDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDownloadFirmwareIdGetRaw(requestParameters: ApiFirmwareUpdatesFirmwareDownloadFirmwareIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.firmwareId === null || requestParameters.firmwareId === undefined) {
            throw new runtime.RequiredError('firmwareId','Required parameter requestParameters.firmwareId was null or undefined when calling apiFirmwareUpdatesFirmwareDownloadFirmwareIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/firmware-download/{firmwareId}`.replace(`{${"firmwareId"}}`, encodeURIComponent(String(requestParameters.firmwareId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDownloadFirmwareIdGet(requestParameters: ApiFirmwareUpdatesFirmwareDownloadFirmwareIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFirmwareUpdatesFirmwareDownloadFirmwareIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import React, { useCallback } from "react";
import { Grid, createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { ColDef, GetRowIdFunc } from "ag-grid-community";
import styled from "styled-components";
import { SectionHeader as SectionHeading } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";
import { CompanyDto } from "api";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.sm};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

interface UserExternallyAssignedCompanyRolesGridProps {
  externallyAssignedCompanies: CompanyDto[];
}

export const UserExternallyAssignedCompanyRolesGrid = ({
  externallyAssignedCompanies
}: UserExternallyAssignedCompanyRolesGridProps): JSX.Element => {
  const { t } = useTranslation();

  const colDefs: ColDef[] = [
    {
      field: "companyDisplayName",
      ...createHeaderAndHeaderTooltip(t("Company"))
    },
    {
      ...createHeaderAndHeaderTooltip(t("User role category")),
      valueGetter: () => {
        return t("Account manager");
      },
      flex: 1
    }
  ];

  const getRowId: GetRowIdFunc = useCallback((params) => (params.data as CompanyDto).id, []);

  if (externallyAssignedCompanies.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <SectionHeader text={t("Externally assigned user company roles")} />
      <Grid
        t={t}
        rowData={externallyAssignedCompanies}
        getRowId={getRowId}
        suppressCellFocus={true}
        disableColumnAutoSize={false}
        domLayout="autoHeight"
        autosizeCols
        columnDefs={colDefs}
        colDefDefault={{ sortable: false }}
        onGridReady={(params) => {
          params.columnApi.applyColumnState({
            state: [
              {
                colId: "companyDisplayName",
                sort: "asc"
              }
            ]
          });
        }}
      />
    </Container>
  );
};

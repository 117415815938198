import React from "react";
import { Checkbox } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { SectionHeader } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";

const ActiveCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

interface ActiveStatusProps {
  active: boolean;
  onChange: () => void;
  disabled: boolean;
}

export const ActiveStatus = ({ active, onChange, disabled }: ActiveStatusProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SectionHeader text={t("Status")} />
      <ActiveCheckbox
        value={active}
        onChange={() => onChange()}
        label={t("Active")}
        monochrome={true}
        disabled={disabled}
      />
    </Wrapper>
  );
};

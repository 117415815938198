import React from "react";
import { GroupsWithRoleAbbreviations } from "../containers/ManageUserView";
import { createHeaderAndHeaderTooltip, Grid } from "framework/components/grid/Grid";
import { ColDef } from "ag-grid-community";
import styled from "styled-components";
import { SectionHeader } from "../../../../framework/components/styled/SectionHeader";
import { Button } from "framework/components/Button";
import { useTranslation } from "react-i18next";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface GlobalUserRolesProps {
  globalRoleAbbreviations: GroupsWithRoleAbbreviations[] | undefined;
  setIsUserRolesDialogOpen: () => void;
  canOnlyView?: boolean;
}

export const GlobalUserRoles = ({
  globalRoleAbbreviations,
  setIsUserRolesDialogOpen,
  canOnlyView
}: GlobalUserRolesProps): JSX.Element => {
  const { t } = useTranslation();
  const colDefs: ColDef[] = [
    {
      ...createHeaderAndHeaderTooltip(t("Group")),
      field: "name"
    },
    {
      ...createHeaderAndHeaderTooltip(t("Roles")),
      field: "roles"
    }
  ];

  return (
    <div>
      <HeaderWrapper>
        <SectionHeader text={t("Global user roles")} />
        <Button
          icon={canOnlyView ? "abb/view" : "abb/edit"}
          onClick={() => setIsUserRolesDialogOpen()}
          text={canOnlyView ? t("View") : t("Edit")}
          buttonType="secondary"
        />
      </HeaderWrapper>
      <Grid
        t={t}
        rowData={globalRoleAbbreviations}
        columnDefs={colDefs}
        domLayout="autoHeight"
        autosizeCols
        disableColumnAutoSize
      ></Grid>
    </div>
  );
};

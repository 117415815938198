import React, { useCallback, useState } from "react";
import { Grid, createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { ColDef, GetRowIdFunc, ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { ManageUserCountryRolesDto } from "api/models/ManageUserCountryRolesDto";
import { SectionHeader as SectionHeading } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";
import { AccessRights, CountryDto, PartiallyActiveState } from "api";
import {
  GridIconRenderer,
  GridIconRendererProps
} from "framework/components/grid/GridIconRenderer";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedUser } from "../reducers/manageUsersViewReducer";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { commonUXTheme } from "styles/commonUXVariables";
import { Dialog } from "framework/components/Dialog";
import { CountryRoleEditDialog } from "./CountryRoleEditDialog";
import { userSagas } from "applications/common/sagas/userSagas";
import { AddCountryRoleButton } from "./AddCountryRoleButton";
import { getGeneralApplicationSettings } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import { userActions } from "applications/common/actions/userActions";
import {
  GridCheckboxRenderer,
  GridCheckboxRendererProps
} from "framework/components/grid/GridCheckboxRenderer";
import { ThreeStateValue } from "@abb/abb-common-ux-react";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.sm};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

interface UserCountryAccessRightsGridProps {
  userCountryAccessRights: ManageUserCountryRolesDto[];
}

export const UserCountryRolesGrid = ({
  userCountryAccessRights
}: UserCountryAccessRightsGridProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedUser = useSelector(getSelectedUser);
  const userAccessRights = useSelector(getUserAccessRights);
  const currentUser = useSelector(getUserInformation);
  const systemSettings = useSelector(getGeneralApplicationSettings);

  const [editingCountry, setEditingCountry] = useState<string | undefined>(undefined);

  const isCountryContactSimulationEnabled = !systemSettings.disableCountryContactPersonSimulation;

  const isEditingOwnRolesAndHasAccess =
    isAuthorizedToComponent(userAccessRights, {
      operation: "any",
      accessRights: [AccessRights.ChangeOwnRoles]
    }) && selectedUser?.user.id === currentUser?.id;

  const colDefs: ColDef<ManageUserCountryRolesDto>[] = [
    {
      field: "country.name",
      ...createHeaderAndHeaderTooltip(t("Country")),
      valueGetter: (params) => {
        const data = params.data;
        if (!data) return "";
        return data.country && data.country.code && data.country.name
          ? data.country.code + " - " + data.country.name
          : data.country?.code ?? data.country?.name ?? "";
      },
      width: 300
    },
    {
      field: "roleNames",
      ...createHeaderAndHeaderTooltip(t("User role category")),
      valueGetter: (params) => {
        const rightNames = params.data?.roleNames;

        //.replace adds a space after lowercase if followed by uppercase letter.
        return rightNames && rightNames?.length > 0
          ? rightNames.join(", ").replace(/(?<=[a-z])(?=[A-Z])/g, " $&")
          : "";
      },
      flex: 1
    }
  ];

  if (isEditingOwnRolesAndHasAccess && isCountryContactSimulationEnabled) {
    colDefs.push({
      ...createHeaderAndHeaderTooltip(t("Edit")),
      maxWidth: 80,
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams<ManageUserCountryRolesDto>) => {
        const cellRendererParams: GridIconRendererProps = {
          ...params,
          text: params.value,
          color: commonUXTheme.colors.blackPrimary,
          icon: "abb/edit",
          showIcon: true,
          onClick: () => setEditingCountry(params.data?.country.code)
        };
        return cellRendererParams;
      }
    });
    colDefs.push({
      ...createHeaderAndHeaderTooltip(t("Active")),
      maxWidth: 100,
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams<ManageUserCountryRolesDto>) => {
        const state = {
          [PartiallyActiveState.Active]: ThreeStateValue.Checked,
          [PartiallyActiveState.PartiallyActive]: ThreeStateValue.Indeterminate,
          [PartiallyActiveState.Inactive]: ThreeStateValue.Unchecked
        }[params.data?.isActive ?? PartiallyActiveState.Inactive];

        const p: GridCheckboxRendererProps = {
          ...params,
          isDisabled: false,
          value: state,
          data: state,
          onChange: (newValue) => {
            const countryCode = params.data?.country.code;
            const roles = params.data?.roleNames;
            if (countryCode && roles) {
              dispatch(
                userSagas.setUserCountryContactRoles.createAction({
                  updateUserCountryContactRolesCommand: {
                    countryCode,
                    roles: roles,
                    isActive: newValue,
                    userId: selectedUser?.user.id ?? currentUser?.id ?? 0
                  }
                })
              );
            }
          }
        };
        return p;
      }
    });
    colDefs.push({
      ...createHeaderAndHeaderTooltip(t("Delete")),
      maxWidth: 120,
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams<ManageUserCountryRolesDto>) => {
        const cellRendererParams: GridIconRendererProps = {
          ...params,
          text: params.value,
          color: commonUXTheme.colors.blackPrimary,
          icon: "abb/trash",
          showIcon: true,
          onClick: () => {
            dispatch(
              userActions.addConfirmEvent(
                () => {
                  const countryCode = params.data?.country.code;
                  if (countryCode) {
                    dispatch(
                      userSagas.setUserCountryContactRoles.createAction({
                        updateUserCountryContactRolesCommand: {
                          countryCode,
                          roles: [],
                          isActive: true,
                          userId: selectedUser?.user.id ?? currentUser?.id ?? 0
                        }
                      })
                    );
                  }
                },
                t("Remove your country roles from {{country}}?", {
                  country: params.data?.country.name ?? t("Unknown country")
                }),
                t("Click confirm to remove all of your country roles from {{country}}.", {
                  country: params.data?.country.name ?? t("Unknown country")
                })
              )
            );
          }
        };
        return cellRendererParams;
      }
    });
  }

  const getRowId: GetRowIdFunc = useCallback(
    (params) => (params.data.country as CountryDto).code,
    []
  );

  if (!isEditingOwnRolesAndHasAccess && userCountryAccessRights.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <SectionHeader text={t("Country user roles")} />
      <Dialog isOpen={editingCountry !== undefined} onClose={() => setEditingCountry(undefined)}>
        {editingCountry && (
          <CountryRoleEditDialog
            editingCountry={editingCountry}
            roles={userCountryAccessRights}
            onClickCancel={() => setEditingCountry(undefined)}
            onSave={(selectedRoles) => {
              setEditingCountry(undefined);
              dispatch(
                userSagas.setUserCountryContactRoles.createAction({
                  updateUserCountryContactRolesCommand: {
                    countryCode: editingCountry,
                    roles: selectedRoles,
                    isActive: true,
                    userId: selectedUser?.user.id ?? currentUser?.id ?? 0
                  }
                })
              );
            }}
          />
        )}
      </Dialog>
      <Grid
        t={t}
        rowData={userCountryAccessRights}
        getRowId={getRowId}
        disableColumnAutoSize={true}
        suppressCellFocus={true}
        domLayout="autoHeight"
        columnDefs={colDefs}
        colDefDefault={{ sortable: false }}
        onGridReady={(params) => {
          params.columnApi.applyColumnState({
            state: [
              {
                colId: "country.countryName",
                sort: "asc"
              }
            ]
          });
        }}
        onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
      />
      {isEditingOwnRolesAndHasAccess && isCountryContactSimulationEnabled && (
        <AddCountryRoleButton
          existingCountryCodesWithRoles={userCountryAccessRights.map((r) => r.country.code)}
          onSave={(country, roles) => {
            dispatch(
              userSagas.setUserCountryContactRoles.createAction({
                updateUserCountryContactRolesCommand: {
                  countryCode: country,
                  roles: roles,
                  isActive: true,
                  userId: selectedUser?.user.id ?? currentUser?.id ?? 0
                }
              })
            );
          }}
        />
      )}
    </Container>
  );
};

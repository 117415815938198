import {
  Input,
} from "@abb/abb-common-ux-react";
import { TeamDto, RoleGroups } from "api";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { FormikProps, withFormik } from "formik";
import { InputLabel } from "framework/components/InputLabel";
import { Roles, useUserRoles } from "framework/hooks/useUserRoles";
import { RoleSelectionType } from "applications/manage/manageUsers/containers/ManageUserView";
import { AddTeamBaseDialog, AddTeamBaseDialogComponent } from "applications/manage/manageUsers/components/AddTeamBaseDialog";
import { UserRoles } from "applications/manage/manageUsers/components/UserRoles";
import { handleFormValueChange, isFormFieldValid } from "utilities/formikUtils";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { roleSagas } from "applications/common/sagas/roleSagas";
import { getTeamRoles } from "applications/common/reducers/commonReducer";

const CustomInput = styled(Input)`
  & > .ABB_CommonUX_Input__validationMessageContainer {
    position: absolute;
  }
`;

const ColumnContainer = styled.div`
  display: flex;

  & > * {
    flex: 1;
    width: 50%;
  }
`;

interface InviteUserToTeamDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: (isloading: boolean) => void;
  t: TFunction;
  team: TeamDto;
  onSendInvite: (email: string, firstName: string, lastName: string, roleIds: number[]) => void;
}

interface InviteToTeamFields {
  email: string;
  firstName: string;
  lastName: string;
  roleIds: number[];
}

const initialFields: InviteToTeamFields = {
  email: "",
  firstName: "",
  lastName: "",
  roleIds: []
};

const Inner: React.FunctionComponent<InviteUserToTeamDialogProps & FormikProps<InviteToTeamFields>> = 
(props): JSX.Element => {
  const t = props.t;
  const dispatch = useDispatch();

  const [teamRoles, setTeamRoles] = useState<Roles[] | undefined>(undefined);

  // If dialog opened for email invite, it could be that roles are not loaded yet
  const loadedTeamRoles = useSelector(getTeamRoles);
  useEffect(() => {
    if(!loadedTeamRoles || loadedTeamRoles.length === 0) {
      dispatch(roleSagas.getRoles.createAction({}));
    }
  }, [loadedTeamRoles, dispatch]);

  const roleTeam = useMemo(() => {
    return { type: props.team.type };
  }, [props.team.type]);

  const {
    roles,
    userRoles,
    setUserRoles,
    accessRights,
    setRoleSelectionType,
    roleSelectionType,
  } = useUserRoles(undefined, undefined, undefined, true, roleTeam);

  useEffect(() => {
    if(setRoleSelectionType && roleSelectionType !== RoleSelectionType.TEAM) {
      setRoleSelectionType(RoleSelectionType.TEAM);
    }
  }, [setRoleSelectionType, roleSelectionType]);

  useEffect(() => {
    if(roles && (teamRoles?.find((roleGroup) => roleGroup.id === RoleGroups.VirtualTeam)?.roles.length ?? 0) === 0) {
      const defaultRoles = roles.map((roleGroup) => {
        return {
          ...roleGroup,
          roles: roleGroup.roles.map((role) => {
            return { ...role, defaultRole: role.defaultRole };
          })
        };
      });

      setTeamRoles(defaultRoles);
      setUserRoles([...defaultRoles.flatMap((roleGroup) => roleGroup.roles).filter((role) => role.defaultRole)]);
    }
  }, [roles, setUserRoles, teamRoles]);

  const onSave = () => {
    if(userRoles) {
      props.values.roleIds = userRoles.map((role) => role.id);
      props.submitForm();
    }
  };

  return (
    <AddTeamBaseDialog
      t={t}
      isOpen={props.isOpen}
      isLoading={props.isLoading}
      width="1012px"
      setIsOpen={props.setIsOpen}
      title={t("Invite user to a functional team")}
      isBusinessUnitTeamGridVisible={false}
      primaryButtonText={t("Save")}
      primaryButtonAction={onSave}
      isPrimaryButtonDisabled={!props.isValid}
      isSecondaryButtonVisible={true}
      secondaryButtonText={t("Cancel")}
    >
      <AddTeamBaseDialogComponent.SectionsContainer>
        <AddTeamBaseDialogComponent.TextWrapper>
          <InputLabel label={t("Information of the invited user")}
                      tooltipText={t("Invite will be sent to the email address provided. When user is added to correct Business Unit team, user will be able to accept invite to this functional unit team.")}
           />
        </AddTeamBaseDialogComponent.TextWrapper>
        <ColumnContainer>
          <AddTeamBaseDialogComponent.InputWrapper>
            <InputLabel
              label={t("First name")}
            />
            <CustomInput
              dataType="text"
              value={props.values.firstName}
              showValidationIconWhenInvalid
              validator={() => isFormFieldValid(props, "firstName")}
              placeholder={t("First name")}
              onValueChange={(value) => handleFormValueChange(props, "firstName", value)}
            />
          </AddTeamBaseDialogComponent.InputWrapper>
          <AddTeamBaseDialogComponent.InputWrapper>
            <InputLabel
              label={t("Last name")}
            />
            <CustomInput
              dataType="text"
              value={props.values.lastName}
              showValidationIconWhenInvalid
              validator={() => isFormFieldValid(props, "lastName")}
              placeholder={t("Last name")}
              onValueChange={(value) => handleFormValueChange(props, "lastName", value)}
            />
          </AddTeamBaseDialogComponent.InputWrapper>
        </ColumnContainer>
        <AddTeamBaseDialogComponent.InputWrapper>
          <InputLabel
            label={t("Email address")}
          />
          <CustomInput
            dataType="text"
            value={props.values.email}
            showValidationIconWhenInvalid
            validator={() => isFormFieldValid(props, "email")}
            placeholder={t("example@abb.com")}
            onValueChange={(value) => handleFormValueChange(props, "email", value)}
          />
        </AddTeamBaseDialogComponent.InputWrapper>
      </AddTeamBaseDialogComponent.SectionsContainer>
      <AddTeamBaseDialogComponent.SectionsContainer>
        <AddTeamBaseDialogComponent.TextWrapper>
          <InputLabel
            label={t("Select user's role within functional team and save to invite user to team")}
          />
        </AddTeamBaseDialogComponent.TextWrapper>
        <AddTeamBaseDialogComponent.InputWrapper>
          <UserRoles
            isInternal={false}
            isDeliverItRelevantCompany={false}
            roleSelectionType={RoleSelectionType.TEAM}
            roleGroups={teamRoles}
            userRoles={userRoles}
            setUserRoles={setUserRoles}
            accessRights={accessRights}
            saveChanges={undefined}
            setIsOpen={props.setIsOpen}
            isSaveEnabled={false}
            isLoading={false}
            hasEditRights={true}
            isTeamDialog={true}
            showDefaultRolesAsSelected={true}
            hideRoleGroupLabel={true}
          />
        </AddTeamBaseDialogComponent.InputWrapper>
      </AddTeamBaseDialogComponent.SectionsContainer>
    </AddTeamBaseDialog>
  );
};

const InviteToTeamSchema = Yup.object().shape({
  email: Yup.string().email("Provide a email address").required()
});

const InviteUserToTeamDialog = withFormik<InviteUserToTeamDialogProps, InviteToTeamFields>({
  mapPropsToValues: () => {
    return initialFields;
  },
  validateOnMount: true,
  enableReinitialize: true,
  validationSchema: InviteToTeamSchema,
  handleSubmit: (values, props) => {
    props.props.onSendInvite(values.email, values.firstName, values.lastName, values.roleIds);
    props.props.setIsOpen(false);
  }
})(Inner);

export default InviteUserToTeamDialog;
import { CountryContactPersonRoleTypes } from "api";
import { Button } from "framework/components/Button";
import { Dialog } from "framework/components/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { commonUXTheme } from "styles/commonUXVariables";
import { NewCountryDialog } from "./NewCountryDialog";

interface AddCountryRoleButtonProps {
  onSave?: (country: string, roles: CountryContactPersonRoleTypes[]) => void;
  existingCountryCodesWithRoles?: string[];
}

export const AddCountryRoleButton = ({
  onSave,
  existingCountryCodesWithRoles
}: AddCountryRoleButtonProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <NewCountryDialog
          existingCountryCodesWithRoles={existingCountryCodesWithRoles}
          onSave={(countryCode, roles) => {
            if (onSave) {
              onSave(countryCode, roles);
            }
            setIsDialogOpen(false);
          }}
          onClickCancel={() => setIsDialogOpen(false)}
        />
      </Dialog>
      <Button
        buttonType="secondary"
        text={t("Add country")}
        onClick={() => setIsDialogOpen(true)}
        style={{
          marginTop: commonUXTheme.sizes.m
        }}
      />
    </>
  );
};

import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { CountryContactPersonRoleTypes } from "api";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { Button } from "framework/components/Button";
import { StatusBanner } from "framework/components/StatusBanner";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { CountryContactRoleCheckboxList } from "./CountryContactRoleCheckboxList";

const DialogContent = styled.div`
  min-width: 500px;
  gap: ${(props) => props.theme.sizes.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

interface NewCountryDialogProps {
  onClickCancel?: () => void;
  onSave?: (country: string, roles: CountryContactPersonRoleTypes[]) => void;
  existingCountryCodesWithRoles?: string[];
}

export const NewCountryDialog = ({
  existingCountryCodesWithRoles,
  onSave,
  onClickCancel
}: NewCountryDialogProps) => {
  const { t } = useTranslation();
  const countries = useSelector(getCountries);

  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);

  const [roles, setRoles] = useState<CountryContactPersonRoleTypes[]>([]);

  const country = countries?.find((c) => c.code === countryCode);

  return (
    <DialogContent>
      <SectionHeader text={t("New country")} style={{ marginBlock: commonUXTheme.sizes.s }} />
      <Dropdown
        monochrome
        label={t("Country")}
        clearable
        searchable
        onChange={(e) => {
          setCountryCode(e.length ? e[0].value : undefined);
        }}
        value={[{ value: country?.code, label: country?.name }]}
      >
        {countries?.map((c) => {
          return <DropdownOption key={c.code} value={c.code} label={c.name} />;
        })}
      </Dropdown>
      {countryCode && existingCountryCodesWithRoles?.includes(countryCode) && (
        <StatusBanner
          status="warning"
          icon="abb/warning-circle-1"
          headerText={t("Existing roles for {{country}}", { country: country?.name })}
          text={t(
            "You already have country contact roles for {{country}}. Saving will overwrite the existing roles.",
            { country: country?.name }
          )}
        />
      )}
      <CountryContactRoleCheckboxList roles={roles} setRoles={setRoles} />
      <ButtonsContainer>
        <Button buttonType="secondary" text={t("Cancel")} onClick={onClickCancel} />
        <Button
          buttonType="primary"
          text={t("Save")}
          disabled={!countryCode}
          onClick={() => {
            if (onSave) {
              onSave(countryCode ?? "", roles);
            }
          }}
        />
      </ButtonsContainer>
    </DialogContent>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JustInTimeLicenseDto,
    JustInTimeLicenseDtoFromJSON,
    JustInTimeLicenseDtoFromJSONTyped,
    JustInTimeLicenseDtoToJSON,
} from './JustInTimeLicenseDto';

/**
 * 
 * @export
 * @interface OnlineDeliveryVm
 */
export interface OnlineDeliveryVm {
    /**
     * 
     * @type {JustInTimeLicenseDto}
     * @memberof OnlineDeliveryVm
     */
    justInTimeLicense?: JustInTimeLicenseDto;
    /**
     * 
     * @type {string}
     * @memberof OnlineDeliveryVm
     */
    serialNumber: string;
    /**
     * 
     * @type {number}
     * @memberof OnlineDeliveryVm
     */
    estimatedOnlineDeliveryInMinutes?: number;
}

export function OnlineDeliveryVmFromJSON(json: any): OnlineDeliveryVm {
    return OnlineDeliveryVmFromJSONTyped(json, false);
}

export function OnlineDeliveryVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnlineDeliveryVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'justInTimeLicense': !exists(json, 'justInTimeLicense') ? undefined : JustInTimeLicenseDtoFromJSON(json['justInTimeLicense']),
        'serialNumber': json['serialNumber'],
        'estimatedOnlineDeliveryInMinutes': !exists(json, 'estimatedOnlineDeliveryInMinutes') ? undefined : json['estimatedOnlineDeliveryInMinutes'],
    };
}

export function OnlineDeliveryVmToJSON(value?: OnlineDeliveryVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'justInTimeLicense': JustInTimeLicenseDtoToJSON(value.justInTimeLicense),
        'serialNumber': value.serialNumber,
        'estimatedOnlineDeliveryInMinutes': value.estimatedOnlineDeliveryInMinutes,
    };
}


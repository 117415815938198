import { AccessRights, TeamDto, TeamTypes } from "api";
import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import styled from "styled-components";
import { InputLabel } from "framework/components/InputLabel";
import { Dialog } from "framework/components/Dialog";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccessibleTeams } from "applications/manage/manageTeams/reducers/manageTeamsViewReducer";
import { Grid } from "framework/components/grid/Grid";
import { CellClickedEvent, ColDef, ICellRendererParams } from "ag-grid-community";
import { GridRadioButtonRenderer, GridRadioButtonRendererProps } from "framework/components/grid/GridRadioButtonRenderer";
import { getUserAccessRights } from "applications/common/reducers/userReducer";
import { hasAnyAccessRight } from "utilities/authUtils";

export const AddTeamBaseDialogComponent = {
  TextWrapper: styled.div`
    display: flex;
    gap: ${(props) => props.theme.sizes.m};
    margin-top: ${(props) => props.theme.sizes.s};
    width: 100%;
    justify-content: space-between;

    & .ABB_CommonUX_Input__root > .ABB_CommonUX_Input__labelContainer > .ABB_CommonUX_Input__label {
      margin-bottom: 0px;
      font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
    }
  `,

  SectionsContainer: styled.div`
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: ${(props) => props.theme.sizes.l};
  `,

  InputWrapper: styled.div`
    margin-top: ${(props) => props.theme.sizes.sm};
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;

    & > * {
      margin-right: ${(props) => props.theme.sizes.m};
    }

    & > :first-child {
      margin-right: ${(props) => props.theme.sizes.xs};
      line-height: ${(props) => props.theme.sizes.l};
      margin-bottom: 0px;
      width: 110px;

      & .ABB_CommonUX_Input__labelContainer {
        display: flex;
        justify-content: flex-end;
      }

      & .ABB_CommonUX_Input__label {
        margin-bottom: 0px;
      }
    }

    & > :nth-child(2) {
      flex: 1;
    }

    & > :last-child {
      margin-right: 0px;
    }
  `,
};

const Container = styled.div``;

const CustomDialog = styled(Dialog)<{width?: string}>`
  width: ${(props) => props.width || '720px'};
  max-height: 95vh;
  overflow: auto;
`;

const StyledGrid = styled(Grid)`
  .row-not-clickable,
  .row-not-clickable div {
    cursor: auto;
}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

interface AddTeamBaseDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
  width?: string;
  t: TFunction;
  title: string;
  children: React.ReactNode;
  businessUnitTeamGridLabel?: string;
  isBusinessUnitTeamGridVisible?: boolean;
  isBusinessUnitTeamGridDisabled?: boolean;
  setSelectedBusinessUnitTeam?: (team: TeamDto | undefined) => void;
  selectedBusinessUnitTeam?: TeamDto;
  primaryButtonText: string | undefined;
  primaryButtonAction: () => void;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonVisible?: boolean;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  userId?: number | undefined;
  parentTeamId?: number | undefined;
}

export const AddTeamBaseDialog = ({
  isOpen,
  t,
  setIsOpen,
  isLoading = false,
  width,
  title,
  children,
  businessUnitTeamGridLabel = "",
  isBusinessUnitTeamGridVisible = true,
  isBusinessUnitTeamGridDisabled = false,
  setSelectedBusinessUnitTeam,
  selectedBusinessUnitTeam,
  primaryButtonText,
  primaryButtonAction,
  isPrimaryButtonDisabled = false,
  isSecondaryButtonVisible = true,
  secondaryButtonText,
  secondaryButtonAction,
  userId,
  parentTeamId,
}: AddTeamBaseDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const accessibleTeams = useSelector(getUserAccessibleTeams);
  const userAccessRights = useSelector(getUserAccessRights);

  const hasTeamAdminRights = (teamId: number): boolean => {
    return hasAnyAccessRight(
      teamId.toString(),
      userAccessRights,
      AccessRights.ManageTeamMembers, AccessRights.SystemAdmin, AccessRights.ManagePlatformUsers);
  };

  useEffect(() => {
    if(userId && isBusinessUnitTeamGridVisible) {
      dispatch(manageTeamsSagas.getUserTeams.createAction({ criteriaUserId : userId }));
    }
  }, [userId, isBusinessUnitTeamGridVisible, dispatch]);

  const getColumnDefintions = (isDisabled: boolean): ColDef<TeamDto>[] => {
    return [
      {
        headerClass: "grid-header-center-with-menu",
        field: "id",
        filter: false,
        cellRenderer: GridRadioButtonRenderer,
        suppressAutoSize: true,
        minWidth: 92,
        maxWidth: 92,
        width: 92,
        tooltipValueGetter: (params) => `${params.data?.name} - ${params.data?.description}`,
        cellRendererParams: (params: ICellRendererParams<TeamDto>) => {
          const teamId = params.data?.id;
  
          const cellRendererParams: GridRadioButtonRendererProps = {
            ...params,
            isDisabled: isDisabled,
            selectionValue: teamId?.toString() ?? "",
            value: selectedBusinessUnitTeam?.id === teamId ? teamId?.toString() ?? "" : "",
            onChange: () => setSelectedBusinessUnitTeam && setSelectedBusinessUnitTeam(params.data),
          };
          return cellRendererParams;
        }
      },
      {
        field: "name",
        filter: false,
        valueGetter: (params) => {
          return params.data?.name && params.data?.description
            ? `${params.data.name} - ${params.data.description}`
            : params.data?.name ?? params.data?.description ?? "";
        },
        flex: 1
      }
    ];
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      title={title}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
      width={width}
    >
      <Container>
        <>
          {isBusinessUnitTeamGridVisible ? 
            <AddTeamBaseDialogComponent.SectionsContainer>
              <AddTeamBaseDialogComponent.TextWrapper>
                <InputLabel label={businessUnitTeamGridLabel} />
              </AddTeamBaseDialogComponent.TextWrapper>
              <AddTeamBaseDialogComponent.InputWrapper>
                <StyledGrid
                  t={t}
                  rowData={accessibleTeams?.filter((team) => 
                    team.type === TeamTypes.BusinessUnit 
                    && (parentTeamId ? team.id === parentTeamId : true)
                    && team.isMember && team.id && hasTeamAdminRights(team.id))}
                  disableColumnAutoSize={true}
                  headerHeight={0}
                  suppressCellFocus={true}
                  showPointerOnRowHover={!isBusinessUnitTeamGridDisabled}
                  domLayout="autoHeight"
                  columnDefs={getColumnDefintions(isBusinessUnitTeamGridDisabled)}
                  colDefDefault={{ sortable: false }}
                  onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
                  onCellClicked={(event: CellClickedEvent) => !isBusinessUnitTeamGridDisabled && setSelectedBusinessUnitTeam && setSelectedBusinessUnitTeam(event.data)}
                />
              </AddTeamBaseDialogComponent.InputWrapper>
            </AddTeamBaseDialogComponent.SectionsContainer>
          : null}
          {children}
          <ButtonContainer>
            {isSecondaryButtonVisible ?
              <Button
              buttonType={"secondary"}
              text={secondaryButtonText}
              onClick={() => secondaryButtonAction ?? setIsOpen(false)}
            />
            : null}
            <Button
              buttonType={"primary"}
              text={primaryButtonText}
              onClick={primaryButtonAction}
              isLoading={isLoading}
              disabled={isPrimaryButtonDisabled}
            />
          </ButtonContainer>
        </>
      </Container>
    </CustomDialog>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteUserToTeamCommand
 */
export interface InviteUserToTeamCommand {
    /**
     * 
     * @type {number}
     * @memberof InviteUserToTeamCommand
     */
    teamId: number;
    /**
     * 
     * @type {number}
     * @memberof InviteUserToTeamCommand
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToTeamCommand
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToTeamCommand
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToTeamCommand
     */
    lastName?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InviteUserToTeamCommand
     */
    userRoles: Array<number>;
}

export function InviteUserToTeamCommandFromJSON(json: any): InviteUserToTeamCommand {
    return InviteUserToTeamCommandFromJSONTyped(json, false);
}

export function InviteUserToTeamCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteUserToTeamCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamId': json['teamId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'userRoles': json['userRoles'],
    };
}

export function InviteUserToTeamCommandToJSON(value?: InviteUserToTeamCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamId': value.teamId,
        'userId': value.userId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'userRoles': value.userRoles,
    };
}


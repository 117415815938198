import {
  ApiTrainingNodesRootsGetRequest,
  ApiTrainingSearchArticlePostRequest,
  ArticleDto,
  ArticleDtoFormatted,
  NodeDto,
  NodeFlattenedDto,
  SearchResponseDto
} from "api";
import { produce } from "immer";
import {
  TerminologyBankActionType,
  TerminologyBankActions
} from "../actions/terminologyBankActions";
import { AppState } from "setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { RequestStatus } from "framework/state/requestStatus";
import { terminologyBankSagas } from "../sagas/terminologyBankSagas";
import { unionBy } from "lodash-es";
import { getTerminologyBankArticleOrder } from "utilities/nodeUtils";

interface Requests {
  getNodesRoots: RequestState<Array<NodeDto>, ApiTrainingNodesRootsGetRequest>;
  search: RequestState<SearchResponseDto, ApiTrainingSearchArticlePostRequest>;
}

export interface TerminologyBankState {
  requests: Requests;
  nodes: NodeFlattenedDto[];
  subNodes: NodeDto[];
  roots: NodeDto[];
  nodesOnScreen: NodeDto[];
  scrollToNode: NodeDto | undefined;
  search: string | undefined;
  isSearch: boolean | undefined;
  isLoading: boolean | undefined;
  pageSize: number;
  pageNumber: number;
  forceScroll: boolean;

  articlesHits: ArticleDtoFormatted[];
  articlesHitsNumber: number | undefined;
  articlesOnScreen: ArticleDto[];
}
const defaultState: TerminologyBankState = {
  requests: {
    getNodesRoots: createInitialRequest(),
    search: createInitialRequest()
  },
  nodes: [],
  subNodes: [],
  roots: [],
  nodesOnScreen: [],
  scrollToNode: undefined,
  search: undefined,
  isSearch: undefined,
  isLoading: false,
  pageNumber: 0,
  pageSize: 200, // Terminology bank articles are very cheap to load
  forceScroll: false,
  articlesHits: [],
  articlesHitsNumber: undefined,
  articlesOnScreen: []
};

export function terminologyBankReducer(
  state: TerminologyBankState = defaultState,
  action: TerminologyBankActions
): TerminologyBankState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: terminologyBankSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: terminologyBankSagas.search.actionTypes,
      key: "search"
    }
  ]);
  if (terminologyBankSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.roots = action.payload
        .sort((a, b) => a.order - b.order)
        .map((x) => {
          return { ...x, subNodes: x.subNodes?.sort((a, b) => a.order - b.order) };
        });
      draft.subNodes = draft.roots.flatMap((x) => x.subNodes ?? []);
      draft.nodes = draft.roots.concat(draft.subNodes);
    });

  if (terminologyBankSagas.search.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // Remove duplicates and sort list
      draft.articlesHits = unionBy(state.articlesHits, action.payload.articles, "id").sort((a, b) =>
        getTerminologyBankArticleOrder(draft.roots, a, b)
      );
      draft.articlesHitsNumber = action.payload.estimatedTotalHits ?? 0;
      draft.isLoading = false;
    });
  }

  switch (action.type) {
    case TerminologyBankActionType.SetVisibleArticle:
      state = produce(state, (draft) => {
        draft.articlesOnScreen = action.visible
          ? unionBy(draft.articlesOnScreen, [action.article], "id")
          : draft.articlesOnScreen.filter((x) => x.id !== action.article.id);
        draft.nodesOnScreen = draft.nodes.filter((x) =>
          draft.articlesOnScreen.some(
            (article) => x.articlesId?.some((articleId) => articleId == article.id)
          )
        );
      });
      break;
    case TerminologyBankActionType.SetSearchText:
      state = produce(state, (draft) => {
        if (draft.search != action.search) {
          draft.search = action.search;
          draft.isSearch = !action.search ? false : true;
          draft.isLoading = !action.search ? false : true;
          draft.pageNumber = 0;
          draft.forceScroll = true;
          draft.articlesHits = [];
          draft.articlesHitsNumber = 0;
        }
      });
      break;
    case TerminologyBankActionType.SetLoadingResults:
      state = produce(state, (draft) => {
        draft.isLoading = action.loading;
      });
      break;
    case TerminologyBankActionType.SetPageNumber:
      state = produce(state, (draft) => {
        draft.pageNumber = action.page;
        draft.forceScroll = true;
      });
      break;
    case TerminologyBankActionType.SetPageSize:
      state = produce(state, (draft) => {
        draft.pageSize = action.size;
        draft.pageNumber = 0;
        draft.forceScroll = true;
      });
      break;
    case TerminologyBankActionType.SetForceScroll:
      state = produce(state, (draft) => {
        draft.forceScroll = action.force;
      });
      break;
    case TerminologyBankActionType.SetScrollNode:
      state = produce(state, (draft) => {
        draft.scrollToNode = action.node;
      });
      break;
    case TerminologyBankActionType.ResetArticles:
      state = produce(state, (draft) => {
        draft.articlesHits = [];
        draft.articlesHitsNumber = 0;
      });
      break;
  }
  return state;
}

export const getNumberOfHits = (state: AppState): number | undefined =>
  state.terminologyBank.articlesHitsNumber;
export const getHits = (state: AppState): ArticleDtoFormatted[] =>
  state.terminologyBank.articlesHits;
export const getNodesRoots = (state: AppState): NodeDto[] => state.terminologyBank.roots;
export const getSubNodes = (state: AppState): NodeDto[] => state.terminologyBank.subNodes;
export const getScrollToNode = (state: AppState) => state.terminologyBank.scrollToNode;
export const getNodesOnScreen = (state: AppState): NodeDto[] => state.terminologyBank.nodesOnScreen;
export const getSearch = (state: AppState): string | undefined => state.terminologyBank.search;
export const getSearchStatus = (state: AppState): RequestStatus =>
  state.terminologyBank.requests.search.status;
export const getIsSearch = (state: AppState): boolean | undefined => state.terminologyBank.isSearch;
export const getIsLoading = (state: AppState): boolean | undefined =>
  state.terminologyBank.isLoading;
export const getPageSize = (state: AppState): number => state.terminologyBank.pageSize;
export const getPageNumber = (state: AppState): number => state.terminologyBank.pageNumber;
export const getForceScroll = (state: AppState): boolean => state.terminologyBank.forceScroll;

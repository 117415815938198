import { CountryContactPersonRoleTypes, ManageUserCountryRolesDto } from "api";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { Button } from "framework/components/Button";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { CountryContactRoleCheckboxList } from "./CountryContactRoleCheckboxList";

const DialogContent = styled.div`
  max-height: 400px;
  max-width: 600px;
  width: 100%;
  height: 100%;
  gap: ${(props) => props.theme.sizes.s};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

export interface CountryRoleEditDialogProps {
  editingCountry?: string;
  roles?: ManageUserCountryRolesDto[];
  onClickCancel?: () => void;
  onSave?: (roles: CountryContactPersonRoleTypes[]) => void;
}

export const CountryRoleEditDialog = ({
  editingCountry,
  roles,
  onClickCancel,
  onSave
}: CountryRoleEditDialogProps) => {
  const { t } = useTranslation();
  const countries = useSelector(getCountries);

  const singleCountryRoles = roles?.find((r) => r.country.code === editingCountry);
  const initialRoles = singleCountryRoles?.roleNames;

  const [editingRoles, setEditingRoles] = useState(initialRoles ?? []);

  if (!editingCountry) return null;

  const countryLabel = countries?.find((c) => c.code === editingCountry)?.name;
  if (!countryLabel) return null;

  return (
    <DialogContent>
      <SectionHeader
        style={{ marginTop: commonUXTheme.sizes.s }}
        text={t("Country roles for {{country}}", { country: t(countryLabel) })}
      />
      <CountryContactRoleCheckboxList roles={editingRoles} setRoles={setEditingRoles} />
      <ButtonsContainer>
        <Button buttonType="secondary" text={t("Cancel")} onClick={onClickCancel} />
        <Button
          buttonType="primary"
          text={t("Save")}
          onClick={() => {
            if (onSave) {
              onSave(editingRoles);
            }
          }}
        />
      </ButtonsContainer>
    </DialogContent>
  );
};

import React, { useCallback } from "react";
import { Grid, createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { ColDef, GetRowIdFunc, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import {
  GridIconRenderer,
  GridIconRendererProps
} from "../../../../framework/components/grid/GridIconRenderer";
import styled from "styled-components";
import { commonUXTheme } from "../../../../styles/commonUXVariables";
import {
  ApiCompaniesCompanyUsersDeleteRequest,
  ApiCompaniesCompanyUsersPatchRequest,
  CompanyDto,
  RoleDto,
  UpdateCompanyUserActiveStatusCommand,
  UserCompanyRolesDto
} from "api";
import {
  GridCheckboxRenderer,
  GridCheckboxRendererProps
} from "framework/components/grid/GridCheckboxRenderer";
import { Button } from "framework/components/Button";
import { SectionHeader as SectionHeading } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.sm};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

const AddButton = styled(Button)`
  width: fit-content;
  margin-top: ${(props) => props.theme.sizes.lm};
`;

interface UserCompanyAccessRightsGridProps {
  userCompanyRoles: UserCompanyRolesDto[];
  canEditCompanyUsers: boolean;
  canEditActiveStatus: boolean;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUserRolesDialogOpen: (company: CompanyDto) => void;
  onRemoveCompanyUser: (request: ApiCompaniesCompanyUsersDeleteRequest) => void;
  onUpdateCompanyUser: (request: ApiCompaniesCompanyUsersPatchRequest) => void;
  onClickCompanyRow: (companyId: string) => void;
}

export const UserCompanyRolesGrid = ({
  userCompanyRoles,
  openDialog,
  setIsUserRolesDialogOpen,
  onRemoveCompanyUser,
  onUpdateCompanyUser,
  canEditCompanyUsers,
  canEditActiveStatus,
  onClickCompanyRow
}: UserCompanyAccessRightsGridProps): JSX.Element => {
  const { t } = useTranslation();

  const colDefs: ColDef[] = [
    {
      field: "company.companyDisplayName",
      ...createHeaderAndHeaderTooltip(t("Company")),
      tooltipValueGetter: (params) => {
        const company = params.data.company as CompanyDto | undefined;
        if (company?.overrideStatusActive || company?.active) {
          return company.companyDisplayName;
        }
        return company?.companyDisplayName + "\n\n " + t("Company is inactive");
      },
      minWidth: 400,
      flex: 1,
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const company = params.data.company as CompanyDto | undefined;
        const cellRendererParams: GridIconRendererProps = {
          ...params,
          text: params.value,
          isClickable: true,
          onClick: () => {
            if (company) {
              onClickCompanyRow(company.id);
            }
          },
          showIcon: company?.overrideStatusActive || company?.active ? false : true,
          icon: "abb/information-circle-1"
        };
        return cellRendererParams;
      }
    },
    {
      field: "user.roleCategory",
      ...createHeaderAndHeaderTooltip(t("User role category")),
      valueGetter: (params) => {
        const roles = params.data.roles as RoleDto[];
        const abbreviations = roles?.map((r) => r.abbreviation);
        return abbreviations?.length > 0 ? abbreviations.join(", ") : " ";
      },
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const rowData = params.data as UserCompanyRolesDto & { homeCompanyId: number };
        const cellRendererParams: GridIconRendererProps = {
          ...params,
          showIcon: canEditCompanyUsers,
          text: params.value,
          color: commonUXTheme.colors.blackPrimary,
          icon: "abb/edit",
          onClick: canEditCompanyUsers ? () => setIsUserRolesDialogOpen(rowData.company) : undefined
        };
        return cellRendererParams;
      }
    },
    {
      field: "active",
      ...createHeaderAndHeaderTooltip(t("Active"), t("DeliverIT roles active?")),
      headerClass: "grid-header-center-with-menu",
      width: 100,
      suppressSizeToFit: true,
      cellRenderer: GridCheckboxRenderer,
      hide: !canEditActiveStatus,
      cellRendererParams: (params: ICellRendererParams) => {
        const rowData = params.data as UserCompanyRolesDto & { homeCompanyId: number };
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          isDisabled: false,
          data: rowData?.active ?? false,
          onChange: (value: boolean) => {
            if (!rowData.userId || !rowData.company.id) {
              return;
            }
            const command: UpdateCompanyUserActiveStatusCommand = {
              active: value,
              companyId: rowData.company.id,
              userId: rowData.userId
            };
            onUpdateCompanyUser({ updateCompanyUserActiveStatusCommand: command });
          }
        };
        return editorProps;
      }
    },
    {
      field: "id",
      ...createHeaderAndHeaderTooltip(t("Delete")),
      hide: !canEditCompanyUsers,
      width: 120,
      suppressSizeToFit: true,
      headerClass: "grid-header-center-with-menu",
      valueGetter: (params: ValueGetterParams) => {
        return params.data;
      },
      cellRenderer: GridIconRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const rowData = params.data as UserCompanyRolesDto & { homeCompanyId: number };
        const cellRendererParams: GridIconRendererProps = {
          ...params,
          showIcon: true,
          onClick: () => {
            onRemoveCompanyUser({ userId: rowData.userId, companyId: rowData.company.id });
          },
          icon: "abb/trash",
          color: commonUXTheme.colors.blackPrimary
        };
        return cellRendererParams;
      }
    }
  ];

  const getRowId: GetRowIdFunc = useCallback((params) => params.data.company.id, []);

  return (
    <Container>
      <SectionHeader text={t("Company user roles")} />
      <Grid
        t={t}
        rowClassRules={{
          "grid-inactive-row": (params) => {
            const userCompanyRoles = params.data as UserCompanyRolesDto;
            if (userCompanyRoles?.active) {
              return false;
            }
            return true;
          }
        }}
        rowData={userCompanyRoles}
        disableColumnAutoSize={true}
        suppressCellFocus={true}
        domLayout="autoHeight"
        columnDefs={colDefs}
        colDefDefault={{ sortable: false }}
        getRowId={getRowId}
        onGridReady={(params) => {
          params.columnApi.applyColumnState({
            state: [
              {
                colId: "company.companyDisplayName",
                sort: "asc"
              }
            ]
          });
        }}
        onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
      />
      {canEditCompanyUsers ? (
        <AddButton
          text={t("Add company")}
          buttonType="secondary"
          sizeClass="medium"
          onClick={() => openDialog(true)}
        />
      ) : null}
    </Container>
  );
};

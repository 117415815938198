import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { NotificationSeverity, SelectorProductDto } from "api";
import { userActions } from "applications/common/actions/userActions";
import { getConfiguratorBaseUrl } from "applications/common/reducers/commonReducer";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { Button } from "framework/components/Button";
import { getApiRegistry } from "framework/state/apiRegistry";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { routes } from "utilities/routes";
import { getConfiguratorLink } from "utilities/urlUtils";
import { ConnectWithUsDetails, ConnectWithUsDialog } from "./ConnectWithUsForm";
import { ClickableLink, LinkIcon } from "./Containers";
import { SSC600SelectionDialog } from "applications/products/common/SSC600SelectionDialog";

const NavigationContainer = styled(Grid)`
  margin-top: ${(props) => props.theme.sizes.l};
  @media print {
    display: none !important;
  }
`;
interface FooterNavigationButtonsProps {
  step: number;
  onNextClick: () => void;
  onPrevClick?: () => void;
  nextDisabled?: boolean;
  selectedProduct?: SelectorProductDto;
}

export const FooterNavigationButtons = ({
  step,
  onNextClick,
  onPrevClick,
  nextDisabled,
  selectedProduct
}: FooterNavigationButtonsProps): JSX.Element => {
  // Selector
  const currentUser = useSelector(getUserInformation);
  const configuratorBaseUrl = useSelector(getConfiguratorBaseUrl);

  // Const
  const { t } = useTranslation();
  const nextBtnText = step == 0 ? t("Open selection guide") : t("Next");
  const nextBtnTooltip = step == 0 ? t("Let us guide you to the right product") : "";
  const [connectUsOpen, setConnectUsOpen] = useState(false);
  const [connectUsLoading, setConnectUsLoading] = useState(false);
  const [sSC600SelectionDialogOpen, setSSC600SelectionDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const connectUsData = useMemo(() => {
    return {
      additionalInfo: "",
      company: currentUser?.homeCompany?.name,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
      country: currentUser?.homeCompany?.countryCode,
      city: currentUser?.homeCompany?.addressCity,
      consent: false
    } as ConnectWithUsDetails;
  }, [currentUser]);

  const configuratorUrl = useMemo((): string => {
    if (!selectedProduct) {
      return "";
    }

    if (selectedProduct.latestMaterialCode) {
      return getConfiguratorLink(configuratorBaseUrl, selectedProduct.latestMaterialCode, true);
    }

    if (selectedProduct.cidCode) {
      return `${configuratorBaseUrl}?cidCode=${selectedProduct.cidCode}`;
    }
    
    return selectedProduct.pageUrl || "";
  }, [selectedProduct, configuratorBaseUrl]);

  const onConfigureAndBuyClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (selectedProduct?.name && selectedProduct?.name.indexOf("SSC600") >= 0) {
      setSSC600SelectionDialogOpen(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onConnectUsClick = () => {
    setConnectUsOpen(true);
  };

  const submitConnectUs = (formData: ConnectWithUsDetails) => {
    setConnectUsLoading(true);
    getApiRegistry()
      .selectorApi.apiSelectorConnectWithUsPost({
        sendContactUsCommand: {
          additionalInfo: formData.additionalInfo,
          businessType: formData.businessType,
          city: formData.city,
          company: formData.company,
          country: formData.country,
          userEmail: formData.email,
          firstName: formData.firstName,
          industry: formData.industry,
          lastName: formData.lastName,
          userSelectedProductUrl: location.href,
          productType: selectedProduct?.name,
          consent: formData.consent
        }
      })
      .then(() => {
        dispatch(
          userActions.addNotification(
            NotificationSeverity.Success,
            "Contact request is sent!",
            false
          )
        );
        setConnectUsOpen(false);
      })
      .catch(() => {
        dispatch(
          userActions.addNotification(
            NotificationSeverity.Error,
            "Failed to send the contact request!",
            false
          )
        );
      })
      .finally(() => {
        setConnectUsLoading(false);
      });
  };

  const comparisonContent = (
    <>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink
          underlined={false}
          discreet
          target="_blank"
          href={routes.products.productComparator}
        >
          {t("Compare")}
        </ClickableLink>
      </Stack>
      <p>Go to the Relays-Online comparator to see the differences between products in detail.</p>
    </>
  );

  const connectWithUsContent = (
    <>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink underlined={false} discreet onClick={onConnectUsClick}>
          {t("Connect with us")}
        </ClickableLink>
      </Stack>
      <p>
        {t(
          "Connect with us for personalized support regarding your recommendation or if you need help deciding how to continue."
        )}
      </p>
    </>
  );

  const configureAndBuyContent = (
    <>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink
          underlined={false}
          discreet
          target="_blank"
          href={routes.products.productSelectionMatrix}
        >
          {t("Configure and buy")}
        </ClickableLink>
      </Stack>
      <p>Do you know exactly what you want? Go to the Relays-Online configurator.</p>
    </>
  );

  const functionExplorerContent = (
    <>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink
          underlined={false}
          discreet
          target="_blank"
          href={routes.products.productExplorer}
        >
          {t("Explore functionality")}
        </ClickableLink>
      </Stack>
      <p>
        Do you need certain functionality or have ANSI code? Get detailed insights into the features
        and functionality available in the Relays-Online functionality explorer.
      </p>
    </>
  );

  const lastStepConfiguratorLinkContent = (
    <>
      <Button
        target="_blank"
        text={t("Configure and buy")}
        buttonType={"primary"}
        onClick={onConfigureAndBuyClick}
        href={configuratorUrl}
      />
    </>
  );

  return (
    <NavigationContainer container>
      <Grid container marginBottom={commonUXTheme.sizes.m} columnSpacing={15}>
        <Grid item xs={12} md={4}>
          {step != 0 && (
            <Button onClick={onPrevClick} text={t("Previous")} buttonType={"secondary"} />
          )}
        </Grid>
        <Grid item md={4} xs={12}></Grid>
        <Grid item md={4} xs={12}>
          <Stack direction="row" justifyContent="end">
            {step == 5 ? (
              lastStepConfiguratorLinkContent
            ) : (
              <Stack title={nextBtnTooltip}>
                <Button
                  disabled={nextDisabled}
                  onClick={onNextClick}
                  text={nextBtnText}
                  buttonType={"primary"}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      {connectUsOpen && (
        <ConnectWithUsDialog
          formData={connectUsData}
          isOpen={connectUsOpen}
          onClose={() => setConnectUsOpen(false)}
          onFormSubmit={submitConnectUs}
          isLoading={connectUsLoading}
        ></ConnectWithUsDialog>
      )}
      <Grid mt={commonUXTheme.sizes.l} container columnSpacing={15}>
        <Grid item xs={12} md={4}>
          {functionExplorerContent}
        </Grid>
        <Grid item md={4} xs={12}>
          {comparisonContent}
        </Grid>
        <Grid item md={4} xs={12}>
          {step == 5 ? connectWithUsContent : configureAndBuyContent}
        </Grid>
      </Grid>
      {sSC600SelectionDialogOpen && (
        <SSC600SelectionDialog
          isOpen={true}
          modularProductsMode={true}
          onDialogClose={() => setSSC600SelectionDialogOpen(false)}
        ></SSC600SelectionDialog>
      )}
    </NavigationContainer>
  );
};

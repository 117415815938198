import React from "react";
import { Button } from "framework/components/Button";
import styled from "styled-components";
import { GridApi } from "ag-grid-community";
import { HorizontalElementContainer } from "../../../../framework/components/HorizontalElementContainer";
import { CompaniesGrid } from "applications/manage/manageAccessRequests/components/CompaniesGrid";
import { CompanyDto } from "api";
import { useTranslation } from "react-i18next";

const ContentContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.lm};
  min-height: 515px;
  min-width: 800px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
`;

const BottomElementContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.lm};
  margin-bottom: ${(props) => props.theme.sizes.m};
  display: flex;
  position: static;
`;

const GridContainer = styled.div`
  display: flex;
  flex-flow: 1;
  min-height: 500px;
  .ag-header-cell::after {
    content: none !important;
  }
  .ag-header-cell {
    border-top: 0px !important;
  }
`;

interface SelectCompanyDialogProps {
  isDisabled: boolean;
  setSelection: React.Dispatch<React.SetStateAction<CompanyDto | undefined>>;
  submitText: string;
  submit?: () => void;
  setGridApi: (ref: GridApi | null) => void;
  handleClose?: () => void;
}

export const SelectCompany = ({
  handleClose,
  isDisabled,
  submit,
  setSelection,
  submitText,
  setGridApi
}: SelectCompanyDialogProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ContentContainer>
      <GridContainer>
        <CompaniesGrid
          setSelectedCompany={setSelection}
          setGridApi={setGridApi}
          disableResize={true}
        />
      </GridContainer>
      {handleClose && submit ? (
        <BottomElementContainer>
          <ButtonContainer>
            <HorizontalElementContainer>
              {handleClose ? (
                <Button text={t("Close")} buttonType="secondary" onClick={handleClose} />
              ) : null}
              <Button
                text={submitText}
                buttonType="primary"
                disabled={isDisabled}
                onClick={() => submit?.()}
              />
            </HorizontalElementContainer>
          </ButtonContainer>
        </BottomElementContainer>
      ) : null}
    </ContentContainer>
  );
};

import React, { useState, useCallback, useEffect } from "react";
import { AppContentWrapper } from "../../../../framework/components/AppContentWrapper";
import { GridApi, IGetRowsParams } from "ag-grid-community";
import {
  TeamDto,
  ApiTeamsMembersGetRequest,
  TeamMemberDto,
  ManageUserRoleDto,
  TeamMemberStatusDto,
  TeamTypes
} from "../../../../api";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";
import { AgGridSortModel, getSortModel } from "../../../../framework/components/grid/Grid";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import { usePrevious } from "../../../../framework/hooks/usePrevious";
import { apiGridErrorHandler } from "../../../../utilities/errorUtils";
import { SubHeader } from "../../../../framework/components/styled/SubHeader";
import { useTranslation } from "react-i18next";
import { ManageTeamsGridUsage, TeamsGrid } from "../components/TeamsGrid";
import { getManageTeam, getChangeMemberStatusRequest, getChangeMemberRoleRequest, getInviteUserToTeamRequest } from "../reducers/manageTeamsViewReducer";
import { manageTeamsSagas } from "../sagas/manageTeamsSagas";
import { TeamMembersGrid } from "../components/TeamMembersGrid";
import { TeamAccessRightsDialog, TeamAccessRightsDialogType } from "applications/manage/manageUsers/components/TeamAccessRightsDialog";
import { Button } from "framework/components/Button";
import InviteUserToTeamDialog from "../components/InviteUserToFunctionalTeamDialog";
import { AddUserToFunctionalTeamDialog } from "applications/manage/manageUsers/components/AddUserToFunctionalTeamDialog";

const TeamGridContainer = styled.div`
  width: 100%;
`;

const TeamMembersGridContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 240px;
  margin-top: ${(props) => props.theme.sizes.lm};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonAndHeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  & > Button {
    margin-left: ${(props) => props.theme.sizes.sm};
  }
`;

export const defaultAddTeamUsersCriteria = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "lastName",
  criteriaIsInternal: [true]
};

export const manageTeamMembersDefaultCriteria: ApiTeamsMembersGetRequest = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "lastName"
};

const ManageTeamView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { teamId } = useParams<{ teamId?: string }>();

  const [manageTeamMembersGrid, setManageTeamMembersGrid] = useState<GridApi | null>(null);
  const [changeTeamMemberRoleDialogOpen, setChangeTeamMemberRoleDialogOpen] = useState<boolean>(false);
  const [changeTeamMemberRoleDialogLoading, setChangeTeamMemberRoleDialogLoading] = useState<boolean>(false);
  const [editedTeamMember, setEditedTeamMember] = useState<TeamMemberDto | undefined>(undefined);
  const [isInviteUserToFunctionalTeamDialogOpen, setIsInviteUserToFunctionalTeamDialogOpen] = useState<boolean>(false);
  const [isInviteUserToFunctionalTeamDialogLoading, setIsInviteUserToFunctionalTeamDialogLoading] = useState<boolean>(false);
  const [addUserToFunctionalTeamDialogProps, setAddUserToFunctionalTeamDialogProps] = useState<{ userId: number | undefined, isOpen: boolean}>({ userId: undefined, isOpen: false });

  // Selector
  const team: TeamDto | undefined = useSelector(getManageTeam);
  const changeMemberStatusRequest = useSelector(getChangeMemberStatusRequest);
  const changeMemberRoleRequest = useSelector(getChangeMemberRoleRequest);
  const inviteUserToTeamRequest = useSelector(getInviteUserToTeamRequest);

  // Previous
  const prevChangeMemberStatusRequest = usePrevious(changeMemberStatusRequest);
  const prevChangeMemberRoleRequest = usePrevious(changeMemberRoleRequest);
  const prevInviteUserToTeamRequest = usePrevious(inviteUserToTeamRequest);

  useEffect(() => {
    if (teamId) {
      dispatch(
        manageTeamsSagas.getTeam.createAction({
          id: parseInt(teamId, 10)
        })
      );
    }
  }, [teamId, dispatch]);
  

  const onSetManageTeamMembersGridRefSet = useCallback((ref: GridApi) => {
    setManageTeamMembersGrid(ref);
  }, []);

  const produceTeamMembersCriteria = useCallback(
    (filterModel: any, sortModel?: AgGridSortModel, page?: number, rowCount?: number) => {
      return produce(manageTeamMembersDefaultCriteria, (draft) => {
        draft.criteriaSortColumn = sortModel?.sortColumn ?? draft.criteriaSortColumn;
        if (page !== undefined) draft.criteriaPage = page;
        draft.criteriaIsAscendingOrder = sortModel?.isAsc ?? draft.criteriaIsAscendingOrder;
        draft.criteriaTeamId = teamId ? parseInt(teamId, 10) : undefined;
        draft.criteriaSkipTotalRowCount = page !== 0 && rowCount !== undefined;
      });
    },
    [teamId]
  );

  const teamMembersDataSource = (teamMembersCriteria: ApiTeamsMembersGetRequest) => {
    let rowCount: number | undefined = undefined;
    return {
      getRows: (params: IGetRowsParams) => {
        manageTeamMembersGrid?.showLoadingOverlay();
        const page = Math.floor(params.startRow / teamMembersCriteria.criteriaPageSize);
        const api = getApiRegistry().teamsApi;

        const sortModel = getSortModel(params.sortModel);
        const newCriteria = produceTeamMembersCriteria(params.filterModel, sortModel, page, rowCount);
        
        api
          .apiTeamsMembersGet(newCriteria)
          .then((res) => {
            if (newCriteria.criteriaSkipTotalRowCount !== true) {
              rowCount = res.criteria.totalCount;
            }
            
            params.successCallback(res.teamMembers, rowCount);
            if (res.teamMembers.length === 0) {
              manageTeamMembersGrid?.showNoRowsOverlay();
            } else {
              manageTeamMembersGrid?.hideOverlay();
            }
            manageTeamMembersGrid?.sizeColumnsToFit();
          })
          .catch((err) => {
            apiGridErrorHandler(err, dispatch);
            manageTeamMembersGrid?.hideOverlay();
          });
      }
    };
  };

  const teamMembersDataSourceCallBack = useCallback(teamMembersDataSource, [
    manageTeamMembersGrid,
    dispatch,
    produceTeamMembersCriteria
  ]);

  useEffect(() => {
    if (manageTeamMembersGrid && teamId) {
      manageTeamMembersGrid.setDatasource(
        teamMembersDataSourceCallBack(manageTeamMembersDefaultCriteria)
      );
    }
  }, [teamId, manageTeamMembersGrid, teamMembersDataSourceCallBack]);

  useEffect(() => {
    if(changeMemberStatusRequest.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.showLoadingOverlay();
    } else if(changeMemberStatusRequest.status === RequestStatus.Completed && prevChangeMemberStatusRequest?.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.redrawRows();
      manageTeamMembersGrid?.refreshInfiniteCache();
    } else if(changeMemberStatusRequest.status === RequestStatus.Failed) {
      manageTeamMembersGrid?.hideOverlay();
    }
  }, [changeMemberStatusRequest, prevChangeMemberStatusRequest, manageTeamMembersGrid]);

  const changeTeamMemberStatus = (teamMember: TeamMemberDto, status: TeamMemberStatusDto) => {
    dispatch(
      manageTeamsSagas.changeTeamMemberStatus.createAction({
        changeMemberStatusCommand: {
          userId: teamMember.userId,
          email: teamMember.email,
          teamId: teamMember.team?.id ?? 0,
          status: status
        }
      })
    );
  }

  useEffect(() => {
    if(changeMemberRoleRequest.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.showLoadingOverlay();
    } else if(changeMemberRoleRequest.status === RequestStatus.Completed && prevChangeMemberRoleRequest?.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.redrawRows();
      manageTeamMembersGrid?.refreshInfiniteCache();
      setChangeTeamMemberRoleDialogLoading(false);
    } else if(changeMemberRoleRequest.status === RequestStatus.Failed) {
      manageTeamMembersGrid?.hideOverlay();
      setChangeTeamMemberRoleDialogLoading(false);
    }
  }, [changeMemberRoleRequest, prevChangeMemberRoleRequest, manageTeamMembersGrid]);

  const changeTeamMemberRole = (userId: number, team: TeamDto, userRoles: ManageUserRoleDto[] | undefined) => {
    setChangeTeamMemberRoleDialogOpen(false);
    setChangeTeamMemberRoleDialogLoading(true);

    dispatch(
      manageTeamsSagas.changeTeamMemberRole.createAction({
        changeMemberRoleCommand: {
          userId: userId,
          teamId: team.id ?? 0,
          userRoles: userRoles?.map((role) => role.id) ?? []
        }
      })
    );
  }

  const sendTeamInvite = (email: string, firstName: string, lastName: string, roleIds: number[]) => {
    setIsInviteUserToFunctionalTeamDialogLoading(true);

    if(email && team?.id && roleIds) {
      dispatch(
        manageTeamsSagas.inviteUserToTeam.createAction({
          inviteUserToTeamCommand: {
            teamId: team.id,
            email: email,
            firstName: firstName,
            lastName: lastName,
            userRoles: roleIds
          }
        })
      );
    }
  };

  useEffect(() => {
    if(inviteUserToTeamRequest.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.showLoadingOverlay();
    } else if(inviteUserToTeamRequest.status === RequestStatus.Completed && prevInviteUserToTeamRequest?.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.redrawRows();
      manageTeamMembersGrid?.refreshInfiniteCache();
      setIsInviteUserToFunctionalTeamDialogLoading(false);
    } else if(inviteUserToTeamRequest.status === RequestStatus.Failed) {
      manageTeamMembersGrid?.hideOverlay();
      setIsInviteUserToFunctionalTeamDialogLoading(false);
    }
  }, [inviteUserToTeamRequest, prevInviteUserToTeamRequest, manageTeamMembersGrid]);


  const addToFunctionalTeam = (teamMember: TeamMemberDto) => {
    setAddUserToFunctionalTeamDialogProps({ userId: teamMember.userId, isOpen: true });
  }

  return (
    <>
      {isInviteUserToFunctionalTeamDialogOpen && team ? (
        <InviteUserToTeamDialog
          t={t}
          isOpen={isInviteUserToFunctionalTeamDialogOpen}
          setIsOpen={setIsInviteUserToFunctionalTeamDialogOpen}
          isLoading={isInviteUserToFunctionalTeamDialogLoading}
          setIsLoading={setIsInviteUserToFunctionalTeamDialogLoading}
          team={team}
          onSendInvite={sendTeamInvite}
        />
      ) : null}
      {changeTeamMemberRoleDialogOpen && editedTeamMember && team ? (
        <TeamAccessRightsDialog
          t={t}
          isOpen={changeTeamMemberRoleDialogOpen}
          setIsOpen={setChangeTeamMemberRoleDialogOpen}
          isLoading={changeTeamMemberRoleDialogLoading}
          dialogType={TeamAccessRightsDialogType.SetUserAccessRights}
          userId={editedTeamMember.userId}
          team={team}
          onSave={changeTeamMemberRole}
        />
      ) : null}
      {addUserToFunctionalTeamDialogProps.isOpen && addUserToFunctionalTeamDialogProps.userId && team && team.id ?
        <AddUserToFunctionalTeamDialog
          t={t}
          isOpen={addUserToFunctionalTeamDialogProps.isOpen}
          setIsOpen={(isOpen) => setAddUserToFunctionalTeamDialogProps(() => ({ userId: undefined, isOpen }))}
          userId={addUserToFunctionalTeamDialogProps.userId}
          parentTeamId={team.id}
          />
      : null}
      <AppContentWrapper pageTitle={t("Manage team")}>
        <TeamGridContainer>
          <TeamsGrid
            usageType={ManageTeamsGridUsage.ViewSingle}
            t={t}
            setGridApi={null}
            rowData={team ? [team] : undefined}
          />
        </TeamGridContainer>
        <TeamMembersGridContainer>
          {team && team.type == TeamTypes.FunctionalTeam ? 
            <ButtonAndHeaderWrapper>
              <SubHeader text={t("Team members")}></SubHeader>
              <Button
                text={t("Invite user")}
                sizeClass="medium"
                buttonType="secondary"
                onClick={() => setIsInviteUserToFunctionalTeamDialogOpen(true)}
              />
            </ButtonAndHeaderWrapper>
          : null}
          <TeamMembersGrid
            t={t}
            setGridApi={onSetManageTeamMembersGridRefSet}
            actionMethods={{
              approve: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatusDto.Approved),
              reject: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatusDto.Rejected),
              remove: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatusDto.Removed),
              changeRole: (teamMember: TeamMemberDto) => {
                setEditedTeamMember(teamMember);
                setChangeTeamMemberRoleDialogOpen(true);
              },
              addToFunctionalTeam: (teamMember: TeamMemberDto) => addToFunctionalTeam(teamMember)
            }}
          />
        </TeamMembersGridContainer>
      </AppContentWrapper>
    </>
  );
};

export default ManageTeamView;

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContentWrapper } from "../../../../framework/components/AppContentWrapper";
import { useDispatch, useSelector } from "react-redux";
import { manageUsersSagas } from "../sagas/manageUsersSagas";
import {
  getSelectedUser,
  getUpdateUserRolesStatus,
  getUserPriceLists
} from "../reducers/manageUsersViewReducer";
import { ManageUserForm } from "../components/ManageUserForm";
import { getUserAccessRights, getUserInformation } from "../../../common/reducers/userReducer";
import styled from "styled-components";
import { UserCompanyRolesGrid } from "../components/UserCompanyRolesGrid";
import { UserCountryRolesGrid } from "../components/UserCountryRolesGrid";
import { UserPriceListsGrid } from "../components/UserPriceListsGrid";
import { manageCompaniesSagas } from "../../manageCompanies/sagas/manageCompaniesSagas";
import { userActions } from "../../../common/actions/userActions";
import { getAddUserToCompanyRequest } from "../../manageCompanies/reducers/manageCompaniesReducer";
import { usePrevious } from "../../../../framework/hooks/usePrevious";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import { SelectCompany } from "../components/SelectCompany";
import { ActiveStatus } from "../components/ActiveStatus";
import { GridApi } from "ag-grid-community";
import { companiesGridDataSource } from "applications/manage/manageAccessRequests/components/CompaniesGrid";
import { GlobalUserRoles } from "../components/GlobalUserRoles";
import { UserRoles } from "../components/UserRoles";
import AuthorizedComponent from "framework/components/AuthorizedComponent";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { useUserRoles } from "../../../../framework/hooks/useUserRoles";
import { useWindowIsMaxHeight } from "framework/hooks/useWindowIsMaxHeight";
import {
  ApiCompaniesCompanyUsersDeleteRequest,
  ApiCompaniesCompanyUsersPatchRequest,
  CompanyDto,
  AccessRights
} from "api";
import { useTranslation } from "react-i18next";
import { RoleGroups } from "../../../../api/models/RoleGroups";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { accessRequestsSagas } from "applications/accessRequests/sagas/accessRequestsSagas";
import { Dialog } from "framework/components/Dialog";
import { UserExternallyAssignedCompanyRolesGrid } from "../components/UserExternallyAssignedCompanyRolesGrid";
import { routes } from "utilities/routes";
import { CountryContactVisibilityStatus } from "../components/CountryContactVisibilityStatus";
import { supportSagas } from "applications/support/sagas/supportSagas";
import { getSetSupportPersonVisibilityRequest } from "applications/support/reducers/supportReducer";
import { UserTeamGrids } from "../components/UserTeamGrids";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Container = styled.div<{ maxWidth?: number }>`
  display: flex;
  margin-left: ${(props) => props.theme.sizes.xxl};
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  @media (max-width: 960px) {
    margin-left: 0;
  }
`;

export enum RoleSelectionType {
  GLOBAL,
  COMPANY,
  TEAM
}

export interface GroupsWithRoleAbbreviations {
  id: RoleGroups;
  name: string;
  roles: string;
}

const requiredAccessRights: AccessRights[] = [
  AccessRights.CompanyAdmin,
  AccessRights.ManageDeliveriesCompanies,
  AccessRights.ManageConfiguratorCompanyUsers,
  AccessRights.ManageConfiguratorUsers,
  AccessRights.ViewCompanies
];

const ManageUserView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId?: string }>();

  const userIdParam = useMemo(() => (userId ? parseInt(userId, 10) : undefined), [userId]);

  const navigate = useNavigate();

  // Selectors
  const selectedUser = useSelector(getSelectedUser);
  const addUserToCompanyRequest = useSelector(getAddUserToCompanyRequest);
  const userAccessRights = useSelector(getUserAccessRights);
  const currentUser = useSelector(getUserInformation);
  const countries = useSelector(getCountries);
  const userPriceLists = useSelector(getUserPriceLists);
  const updateUserRolesStatus = useSelector(getUpdateUserRolesStatus);
  const addCompanyStatus = useSelector(getAddUserToCompanyRequest);
  const setSupportPersonVisibilityRequest = useSelector(getSetSupportPersonVisibilityRequest);

  // Previous
  const prevAddUserToCompanyRequest = usePrevious(addUserToCompanyRequest);
  const prevUpdateUserRolesStatus = usePrevious(updateUserRolesStatus);

  // State
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState(false);

  // Callback
  const onSetGridApiRefSet = useCallback((ref: GridApi | null) => {
    setGridApi(ref);
  }, []);

  const isEditingOwnRolesAndHasAccess =
    isAuthorizedToComponent(userAccessRights, {
      operation: "any",
      accessRights: [AccessRights.ChangeOwnRoles]
    }) && selectedUser?.user.id === currentUser?.id;

  const isPlatformManager = isAuthorizedToComponent(userAccessRights, {
    operation: "any",
    accessRights: [AccessRights.ManagePlatformUsers]
  });

  const getHasEditRights = (selectionType: RoleSelectionType | undefined) => {
    switch (selectionType) {
      case RoleSelectionType.COMPANY:
        return canEditCompanyRoles();

      case RoleSelectionType.GLOBAL:
        return canEditUserGlobalRoles();

      case RoleSelectionType.TEAM:
        return canEditTeamRoles();

      default:
        return false;
    }
  };

  const canManageUsers = () => {
    return isAuthorizedToComponent(userAccessRights, {
      operation: "any",
      accessRights: [
        AccessRights.ManageDeliveriesUsers,
        AccessRights.ManageDeliveriesUsersLimited,
        AccessRights.ManageConfiguratorUsers,
        AccessRights.ManagePlatformUsers,
        AccessRights.ManageMarginAnalysisToolUsers
      ]
    });
  };

  const canEditUserGlobalRoles = () => {
    return canManageUsers() || isEditingOwnRolesAndHasAccess;
  };

  const canViewUserGlobalRoles = () => {
    return (
      canEditUserGlobalRoles() ||
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [AccessRights.ViewConfiguratorUsers, AccessRights.ViewDeliveriesUsers]
      })
    );
  };

  const canEditCompanyRoles = () => {
    return (
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [
          AccessRights.ManageDeliveriesUsers,
          AccessRights.ManageDeliveriesUsersLimited,
          AccessRights.ManageConfiguratorUsers,
          AccessRights.ManageConfiguratorCompanyUsers
        ]
      }) || isEditingOwnRolesAndHasAccess
    );
  };

  const canEditTeamRoles = () => {
    return (
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [
          AccessRights.ManagePlatformUsers
        ]
      }) || isEditingOwnRolesAndHasAccess
    );
  };

  const canEditUserCompanyActiveStatus = () => {
    return isAuthorizedToComponent(userAccessRights, {
      operation: "any",
      accessRights: [
        AccessRights.ManageDeliveriesUsers,
        AccessRights.CompanyAdmin,
        AccessRights.ManageDeliveriesUsersLimited
      ]
    });
  };

  const canViewUserPriceLists = useMemo(
    () =>
      isAuthorizedToComponent(userAccessRights, {
        operation: "any",
        accessRights: [AccessRights.ManagePriceLists, AccessRights.ViewAllPriceLists]
      }),
    [userAccessRights]
  );

  const {
    allRoles,
    roles,
    userRoles,
    setUserRoles,
    setIsOpen,
    accessRights,
    title,
    isSaveEnabled,
    isLoading,
    isOpen,
    setRoleSelectionType,
    saveChanges,
    roleSelectionType
  } = useUserRoles(selectedUser, selectedCompany, undefined, true);

  useEffect(() => {
    if (userIdParam) {
      dispatch(manageUsersSagas.getUserById.createAction({ id: userIdParam }));
    }
  }, [userIdParam, dispatch]);

  useEffect(() => {
    if (selectedUser && canViewUserPriceLists) {
      dispatch(manageUsersSagas.getUserPriceLists.createAction({ userId: selectedUser.user.id }));
    }
  }, [dispatch, selectedUser, canViewUserPriceLists]);

  useEffect(() => {
    if (!countries) {
      dispatch(accessRequestsSagas.getCountries.createAction({}));
    }
  }, [dispatch, countries]);

  useEffect(() => {
    if (
      addUserToCompanyRequest?.status === RequestStatus.Completed &&
      prevAddUserToCompanyRequest?.status === RequestStatus.Pending &&
      selectedUser
    ) {
      dispatch(manageUsersSagas.getUserById.createAction({ id: selectedUser.user.id }));
    }
  }, [addUserToCompanyRequest, prevAddUserToCompanyRequest, dispatch, selectedUser]);

  const selectedUserId = selectedUser?.user.id;
  useEffect(() => {
    if (
      setSupportPersonVisibilityRequest.status === RequestStatus.Completed &&
      selectedUserId !== undefined
    ) {
      dispatch(manageUsersSagas.getUserById.createAction({ id: selectedUserId }));
    }
  }, [dispatch, setSupportPersonVisibilityRequest.status, selectedUserId]);

  useEffect(() => {
    if (
      prevUpdateUserRolesStatus === RequestStatus.Pending &&
      updateUserRolesStatus === RequestStatus.Completed
    ) {
      setIsOpen(false);
    }
  }, [updateUserRolesStatus, prevUpdateUserRolesStatus, setIsOpen]);

  useEffect(() => {
    if (
      addCompanyStatus?.status === RequestStatus.Completed &&
      prevAddUserToCompanyRequest?.status === RequestStatus.Pending
    ) {
      setIsAddCompanyDialogOpen(false);
    }
  }, [addCompanyStatus, prevAddUserToCompanyRequest]);

  useEffect(() => {
    if (selectedUser?.user?.homeCompanyId) {
      dispatch(
        manageCompaniesSagas.getCompanyToManage.createAction({
          id: selectedUser.user.homeCompanyId
        })
      );
    }
  }, [selectedUser, dispatch]);

  const addUserCompaniesDataSourceCallBack = useCallback(companiesGridDataSource, [gridApi]);
  useEffect(() => {
    if (gridApi && selectedUser) {
      gridApi.setDatasource(
        addUserCompaniesDataSourceCallBack(
          {
            criteriaPage: 0,
            criteriaPageSize: 100,
            criteriaAlreadyInCompanyIds: selectedUser.userCompanyRoles.map((u) => u.company.id),
            criteriaRequireAnyOfAccessRight: requiredAccessRights,
            criteriaUserIdToEdit: selectedUser.user.azureAdId,
            criteriaGetOnlyActive: false
          },
          gridApi,
          dispatch
        )
      );
    }
  }, [addUserCompaniesDataSourceCallBack, gridApi, selectedUser, dispatch]);

  const { t } = useTranslation();

  const handleClose = () => {
    setIsAddCompanyDialogOpen(false);
  };

  const handleOpen = () => {
    setIsAddCompanyDialogOpen(true);
  };

  const handleAddCompanyToUser = () => {
    const onConfirmAction = () => {
      dispatch(
        manageCompaniesSagas.addUserToCompany.createAction({
          addCompanyUserCommand: {
            userId: selectedUser?.user.id,
            companyId: selectedCompany?.id
          }
        })
      );
    };

    dispatch(
      userActions.addConfirmEvent(
        onConfirmAction,
        t("Add company to user?"),
        t("Click confirm to add the company to user")
      )
    );
  };

  const changeUserActiveStatus = () => {
    if (selectedUser?.user) {
      const onConfirmAction = () => {
        dispatch(
          manageUsersSagas.updateUser.createAction({
            adminUpdateUserCommand: {
              id: selectedUser.user.id,
              active: !selectedUser.user.active
            }
          })
        );
      };
      dispatch(
        userActions.addConfirmEvent(
          onConfirmAction,
          t("Active status"),
          t(`Change user's active status to ${!selectedUser.user.active ? "active" : "inactive"}`)
        )
      );
    }
  };

  const changeCountryContactVisibility = (isVisible: boolean) => {
    if (selectedUser?.user) {
      const onConfirmAction = () => {
        dispatch(
          supportSagas.setSupportPersonVisibility.createAction({
            setSupportPersonVisibilityCommand: {
              email: selectedUser.user.email,
              isVisible
            }
          })
        );
      };

      dispatch(
        userActions.addConfirmEvent(
          onConfirmAction,
          t("Country contact person visibility"),
          selectedUser.countryContactInformation.isVisibleInSupportPages
            ? t("Hide country contact person from support pages")
            : t("Show country contact person on support pages")
        )
      );
    }
  };

  const changeFirstOrLastName = (firstName: string, lastName: string) => {
    if (selectedUser?.user) {
      const onConfirmAction = () => {
        dispatch(
          manageUsersSagas.updateUser.createAction({
            adminUpdateUserCommand: {
              id: selectedUser.user.id,
              firstName: firstName,
              lastName: lastName
            }
          })
        );
      };

      dispatch(
        userActions.addConfirmEvent(
          onConfirmAction,
          t(`Change the user's first / last name?`),
          t(`The user cannot change the selection by him/herself`)
        )
      );
    }
  };

  const changeUserCountryCode = (countryCode: string, countryName: string) => {
    if (selectedUser?.user) {
      const onConfirmAction = () => {
        dispatch(
          manageUsersSagas.updateUserCountryCode.createAction({
            adminUpdateUserCountryCodeCommand: {
              id: selectedUser.user.id,
              countryCode: countryCode
            }
          })
        );
      };
      const userHasSelectedCountryCode =
        selectedUser?.user.countryCode !== undefined &&
        selectedUser?.user.countryCode !== null &&
        selectedUser?.user.countryCode !== "";

      const isEditingOwnRoles = selectedUser?.user.id === currentUser?.id;

      if (isEditingOwnRoles) {
        onConfirmAction();
      } else {
        dispatch(
          userActions.addConfirmEvent(
            onConfirmAction,
            t(`Change user's country code to ${countryCode} - ${countryName}?`),
            t(
              `The user cannot change the selection by him/herself.${
                userHasSelectedCountryCode
                  ? ""
                  : " Note! The user hasn't made the initial country selection, and the default value available from user account is used."
              }`
            )
          )
        );
      }
    }
  };

  const globalGroupsAndRoleAbbreviations: GroupsWithRoleAbbreviations[] | undefined =
    useMemo(() => {
      if (selectedUser && allRoles) {
        return allRoles.map((group) => {
          return {
            id: group.id,
            name: group.friendlyName ?? "",
            roles: group.globalRoles
              .filter(
                (role) =>
                  role.defaultRole ||
                  selectedUser.userGlobalRoles.some((r) => r.id === role.id) ||
                  selectedUser.userGlobalRoles.some(
                    (r) => r.subRoleIds?.some((subRoleId) => subRoleId === role.id)
                  )
              )
              .map((r) => r.abbreviation)
              .sort((a, b) => (a < b ? -1 : 1))
              .join(", ")
          };
        });
      }
    }, [selectedUser, allRoles]);

  const handleRemoveUser = (request: ApiCompaniesCompanyUsersDeleteRequest) => {
    const onConfirmAction = () => {
      dispatch(manageCompaniesSagas.deleteCompanyUser.createAction(request));
    };
    dispatch(
      userActions.addConfirmEvent(
        onConfirmAction,
        t("Remove user from company?"),
        t("Click confirm to remove <firstName> <lastName> from the company")
          .replace("<firstName>", selectedUser?.user.firstName || "user")
          .replace("<lastName>", selectedUser?.user.lastName || "")
      )
    );
  };

  const handleUpdateCompanyUser = (request: ApiCompaniesCompanyUsersPatchRequest) =>
    dispatch(manageCompaniesSagas.updateUserCompanyAccess.createAction(request));

  const handleClickCompanyRow = (companyId: string) =>
    navigate(`${routes.manage.companies}/${companyId}`);

  const updateUserDisabledManagedPriceList = useCallback(
    (managedPriceListId: number) => {
      if (selectedUser?.user) {
        dispatch(
          manageUsersSagas.updateUserDisabledManagedPriceList.createAction({
            updateUserDisabledManagedPriceListCommand: {
              userId: selectedUser?.user.id,
              managedPriceListId: managedPriceListId
            }
          })
        );
      }
    },
    [dispatch, selectedUser?.user]
  );

  const isOverflowVisible = useWindowIsMaxHeight(800);

  return (
    <AppContentWrapper>
      <Dialog
        isOpen={isAddCompanyDialogOpen}
        onClose={handleClose}
        contentClassName={
          !isOverflowVisible ? "dialog-content overflow-auto" : "dialog-content overflow-visible"
        }
        title={t("Select company")}
        closeOnLostFocus={true}
      >
        <SelectCompany
          setSelection={setSelectedCompany}
          isDisabled={selectedCompany === undefined}
          setGridApi={onSetGridApiRefSet}
          submit={handleAddCompanyToUser}
          handleClose={handleClose}
          submitText={t("Add company")}
        />
      </Dialog>
      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={title}
        contentClassName="dialog-content overflow-auto"
      >
        <UserRoles
          isInternal={!!selectedCompany?.isInternal}
          isDeliverItRelevantCompany={selectedCompany?.isDeliverITRelevant ?? false}
          roleSelectionType={roleSelectionType}
          roleGroups={roles}
          userRoles={userRoles}
          setUserRoles={setUserRoles}
          accessRights={accessRights}
          saveChanges={saveChanges}
          setIsOpen={setIsOpen}
          isSaveEnabled={isSaveEnabled}
          isLoading={isLoading}
          hasEditRights={getHasEditRights(roleSelectionType)}
        />
      </Dialog>
      {selectedUser ? (
        <FlexContainer>
          <div>
            <ManageUserForm
              userInformation={selectedUser.user}
              countries={countries ?? []}
              onChangeCountryCode={changeUserCountryCode}
              canChangeCountryCode={canManageUsers()}
              canEditFirstAndLastName={isPlatformManager}
              onChangeFirstOrLastName={changeFirstOrLastName}
            />

            <AuthorizedComponent accessRights={[AccessRights.ManagePlatformUsers]}>
              <ActiveStatus
                active={selectedUser.user.active}
                onChange={changeUserActiveStatus}
                disabled={!canEditUserGlobalRoles}
              />
            </AuthorizedComponent>

            <AuthorizedComponent accessRights={[AccessRights.ManageCountryContacts]}>
              <CountryContactVisibilityStatus
                active={
                  typeof selectedUser.countryContactInformation.isVisibleInSupportPages ===
                  "boolean"
                    ? selectedUser.countryContactInformation.isVisibleInSupportPages
                    : false
                }
                onChange={() => {
                  const currentActiveStatus =
                    typeof selectedUser.countryContactInformation.isVisibleInSupportPages ===
                    "boolean"
                      ? selectedUser.countryContactInformation.isVisibleInSupportPages
                      : false;

                  changeCountryContactVisibility(!currentActiveStatus);
                }}
                disabled={!selectedUser.countryContactInformation.isCountryContact}
              />
            </AuthorizedComponent>
            {canViewUserGlobalRoles() ? (
              <GlobalUserRoles
                globalRoleAbbreviations={globalGroupsAndRoleAbbreviations}
                setIsUserRolesDialogOpen={() => {
                  setRoleSelectionType(RoleSelectionType.GLOBAL);
                  setIsOpen(true);
                }}
                canOnlyView={canViewUserGlobalRoles() && !canEditUserGlobalRoles()}
              />
            ) : null}
          </div>
          <Container>
            <UserCompanyRolesGrid
              onRemoveCompanyUser={handleRemoveUser}
              onUpdateCompanyUser={handleUpdateCompanyUser}
              userCompanyRoles={selectedUser.userCompanyRoles}
              openDialog={handleOpen}
              canEditCompanyUsers={canEditCompanyRoles()}
              setIsUserRolesDialogOpen={(company: CompanyDto) => {
                setRoleSelectionType(RoleSelectionType.COMPANY);
                setSelectedCompany(company);
                setIsOpen(true);
              }}
              canEditActiveStatus={canEditUserCompanyActiveStatus()}
              onClickCompanyRow={handleClickCompanyRow}
            />

            <AuthorizedComponent accessRights={[AccessRights.TeamAccess]}>
              <UserTeamGrids></UserTeamGrids>
            </AuthorizedComponent>

            <UserCountryRolesGrid userCountryAccessRights={selectedUser.userCountryAccessRights!} />

            <UserExternallyAssignedCompanyRolesGrid
              externallyAssignedCompanies={selectedUser?.companiesWhereAccountManager ?? []}
            />
            {canViewUserPriceLists ? (
              <UserPriceListsGrid
                userPriceList={userPriceLists}
                updateUserDisabledManagedPriceList={updateUserDisabledManagedPriceList}
              />
            ) : null}
          </Container>
        </FlexContainer>
      ) : null}
    </AppContentWrapper>
  );
};

export default ManageUserView;

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessRights {
    SystemAdmin = 'SystemAdmin',
    AdvancedOrderTracking = 'AdvancedOrderTracking',
    DownloadRoutineTestReports = 'DownloadRoutineTestReports',
    QuickOrderTrack = 'QuickOrderTrack',
    ViewAllOrders = 'ViewAllOrders',
    ViewCompanyOrders = 'ViewCompanyOrders',
    CompanyAdmin = 'CompanyAdmin',
    UserAccount = 'UserAccount',
    ManageDeliveriesUsers = 'ManageDeliveriesUsers',
    ManageDeliveriesCompanies = 'ManageDeliveriesCompanies',
    ViewDeliveriesUsers = 'ViewDeliveriesUsers',
    ViewCompanies = 'ViewCompanies',
    ViewPriceLists = 'ViewPriceLists',
    ViewCompanyPriceLists = 'ViewCompanyPriceLists',
    ManagePriceLists = 'ManagePriceLists',
    ManageConfiguratorUsers = 'ManageConfiguratorUsers',
    DeliverItAdmin = 'DeliverITAdmin',
    ManagePlatformUsers = 'ManagePlatformUsers',
    ViewDirectMaterialCosts = 'ViewDirectMaterialCosts',
    ChangeOwnRoles = 'ChangeOwnRoles',
    ViewOrderNotifications = 'ViewOrderNotifications',
    ViewStatistics = 'ViewStatistics',
    ViewAllPriceLists = 'ViewAllPriceLists',
    ViewAllSoftwareLicenses = 'ViewAllSoftwareLicenses',
    ViewSoftwareLicense = 'ViewSoftwareLicense',
    ManageTrainingPages = 'ManageTrainingPages',
    ProcessConfiguratorAccessRequests = 'ProcessConfiguratorAccessRequests',
    ApproveQuotes = 'ApproveQuotes',
    ApproveOrders = 'ApproveOrders',
    ViewManagePriceLists = 'ViewManagePriceLists',
    RequestedOrderApprover = 'RequestedOrderApprover',
    RequestedQuoteApprover = 'RequestedQuoteApprover',
    LocalQuoteHandler = 'LocalQuoteHandler',
    LocalOrderHandler = 'LocalOrderHandler',
    ManageSupplierTermsAndConditions = 'ManageSupplierTermsAndConditions',
    ManageMarginAnalysisToolQuotesAsAsm = 'ManageMarginAnalysisToolQuotesAsAsm',
    ViewMarginAnalysisToolQuotes = 'ViewMarginAnalysisToolQuotes',
    Pms = 'PMS',
    ViewCartsAndQuotes = 'ViewCartsAndQuotes',
    ViewEmailTemplates = 'ViewEmailTemplates',
    ManageEmailTemplates = 'ManageEmailTemplates',
    ViewCompanyLicenses = 'ViewCompanyLicenses',
    ManageCompanyTermsAndConditions = 'ManageCompanyTermsAndConditions',
    ManageCountryTermsAndConditions = 'ManageCountryTermsAndConditions',
    ManageReleaseNotes = 'ManageReleaseNotes',
    LocalContactRegionalSales = 'LocalContactRegionalSales',
    ViewConfiguratorUsers = 'ViewConfiguratorUsers',
    ManageConfiguratorCompanyUsers = 'ManageConfiguratorCompanyUsers',
    PlaceCompanyOrders = 'PlaceCompanyOrders',
    SwgChannelManager = 'SwgChannelManager',
    ViewDeliveryTimes = 'ViewDeliveryTimes',
    ManageCountryContacts = 'ManageCountryContacts',
    IsSoftwareSubscriptionHolder = 'IsSoftwareSubscriptionHolder',
    ManageTerminologyBank = 'ManageTerminologyBank',
    SupportlineAccess = 'SupportlineAccess',
    ManageSupportlineAccess = 'ManageSupportlineAccess',
    ManageMarginAnalysisToolUsers = 'ManageMarginAnalysisToolUsers',
    ManageDeliveriesUsersLimited = 'ManageDeliveriesUsersLimited',
    ManageMarginAnalysisToolQuotesProcurist = 'ManageMarginAnalysisToolQuotesProcurist',
    ManageTeamMembers = 'ManageTeamMembers',
    TeamMember = 'TeamMember',
    CompanyPurchaserEmailReceiver = 'CompanyPurchaserEmailReceiver',
    ViewMyKnowledgeAi = 'ViewMyKnowledgeAi',
    HandlingRevisions = 'HandlingRevisions',
    HandlingCompanyRevisions = 'HandlingCompanyRevisions',
    TeamAdmin = 'TeamAdmin',
    TeamViewer = 'TeamViewer',
    TeamContributor = 'TeamContributor',
    TeamApprover = 'TeamApprover',
    TeamAccess = 'TeamAccess',
    CreatingInternalRevisions = 'CreatingInternalRevisions',
    CreatingExternalRevisions = 'CreatingExternalRevisions',
    HandlingCountryInternalCompanyRevisions = 'HandlingCountryInternalCompanyRevisions'
}

export function AccessRightsFromJSON(json: any): AccessRights {
    return AccessRightsFromJSONTyped(json, false);
}

export function AccessRightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRights {
    return json as AccessRights;
}

export function AccessRightsToJSON(value?: AccessRights | null): any {
    return value as any;
}


import React from "react";
import { Checkbox } from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${(props) => props.theme.sizes.l};
  gap: ${(props) => props.theme.sizes.s};
`;

export type CountryContactVisibilityStatusProps = {
  active: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export const CountryContactVisibilityStatus = ({
  active,
  onChange,
  disabled
}: CountryContactVisibilityStatusProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionHeader text={t("Visibility")} />
      <Checkbox
        value={active}
        onChange={onChange}
        label={t("Visible in support pages")}
        monochrome
        disabled={disabled}
      />
    </Container>
  );
};

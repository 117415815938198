/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';
import {
    TeamMemberRoleDto,
    TeamMemberRoleDtoFromJSON,
    TeamMemberRoleDtoFromJSONTyped,
    TeamMemberRoleDtoToJSON,
} from './TeamMemberRoleDto';
import {
    TeamMemberSources,
    TeamMemberSourcesFromJSON,
    TeamMemberSourcesFromJSONTyped,
    TeamMemberSourcesToJSON,
} from './TeamMemberSources';
import {
    TeamMemberStatusDto,
    TeamMemberStatusDtoFromJSON,
    TeamMemberStatusDtoFromJSONTyped,
    TeamMemberStatusDtoToJSON,
} from './TeamMemberStatusDto';

/**
 * 
 * @export
 * @interface TeamMemberDto
 */
export interface TeamMemberDto {
    /**
     * 
     * @type {boolean}
     * @memberof TeamMemberDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamMemberDto
     */
    isInUse?: boolean;
    /**
     * 
     * @type {TeamMemberStatusDto}
     * @memberof TeamMemberDto
     */
    status?: TeamMemberStatusDto;
    /**
     * 
     * @type {TeamMemberSources}
     * @memberof TeamMemberDto
     */
    source?: TeamMemberSources;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberDto
     */
    userId?: number;
    /**
     * 
     * @type {TeamDto}
     * @memberof TeamMemberDto
     */
    team?: TeamDto;
    /**
     * 
     * @type {Array<TeamMemberRoleDto>}
     * @memberof TeamMemberDto
     */
    roles?: Array<TeamMemberRoleDto> | null;
}

export function TeamMemberDtoFromJSON(json: any): TeamMemberDto {
    return TeamMemberDtoFromJSONTyped(json, false);
}

export function TeamMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isInUse': !exists(json, 'isInUse') ? undefined : json['isInUse'],
        'status': !exists(json, 'status') ? undefined : TeamMemberStatusDtoFromJSON(json['status']),
        'source': !exists(json, 'source') ? undefined : TeamMemberSourcesFromJSON(json['source']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'team': !exists(json, 'team') ? undefined : TeamDtoFromJSON(json['team']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(TeamMemberRoleDtoFromJSON)),
    };
}

export function TeamMemberDtoToJSON(value?: TeamMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isActive': value.isActive,
        'isInUse': value.isInUse,
        'status': TeamMemberStatusDtoToJSON(value.status),
        'source': TeamMemberSourcesToJSON(value.source),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'userId': value.userId,
        'team': TeamDtoToJSON(value.team),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(TeamMemberRoleDtoToJSON)),
    };
}


import React, { memo } from "react";
import { RadioGroup, RadioOption } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";

export interface GridRadioButtonRendererProps extends ICellRendererParams {
  isDisabled: boolean;
  onChange?: () => void | undefined;
  isInactive?: boolean;
  selectionValue: string | undefined;
}

const CustomRadioGroup = styled(RadioGroup)`
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    margin-top: ${(props) => props.theme.sizes.sm};
  }
`;

export const GridRadioButtonRenderer = memo((props: GridRadioButtonRendererProps) => {
  return (
    <CustomRadioGroup
      monochrome={true}
      value={props.selectionValue}
      disabled={props.isDisabled}
      onChange={() => {
        props.onChange && props.onChange();
      }}
      style={{
        height: props.node.rowHeight + "px"
      }}
    >
      <RadioOption value={props.value} />
    </CustomRadioGroup>
  );
});

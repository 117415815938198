/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleSource,
    ArticleSourceFromJSON,
    ArticleSourceFromJSONTyped,
    ArticleSourceToJSON,
} from './ArticleSource';

/**
 * 
 * @export
 * @interface UpdateArticleCommand
 */
export interface UpdateArticleCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateArticleCommand
     */
    id: number;
    /**
     * 
     * @type {ArticleSource}
     * @memberof UpdateArticleCommand
     */
    articleSourceToUpdate: ArticleSource;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateArticleCommand
     */
    removeFromAssignedNodes?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleCommand
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateArticleCommand
     */
    isTitleVisible?: boolean | null;
}

export function UpdateArticleCommandFromJSON(json: any): UpdateArticleCommand {
    return UpdateArticleCommandFromJSONTyped(json, false);
}

export function UpdateArticleCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateArticleCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleSourceToUpdate': ArticleSourceFromJSON(json['articleSourceToUpdate']),
        'removeFromAssignedNodes': !exists(json, 'removeFromAssignedNodes') ? undefined : json['removeFromAssignedNodes'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isTitleVisible': !exists(json, 'isTitleVisible') ? undefined : json['isTitleVisible'],
    };
}

export function UpdateArticleCommandToJSON(value?: UpdateArticleCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'articleSourceToUpdate': ArticleSourceToJSON(value.articleSourceToUpdate),
        'removeFromAssignedNodes': value.removeFromAssignedNodes,
        'title': value.title,
        'isTitleVisible': value.isTitleVisible,
    };
}


import {
  GridApi,
  ValueFormatterParams,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  ITooltipParams,
  IRowNode
} from "ag-grid-community";
import { ApiCompaniesGetRequest, CompanyDto } from "api";
import { manageCompaniesDefaultCriteria } from "applications/manage/manageCompanies/containers/ManageCompaniesView";
import { ActiveStatus } from "enums/grid/activeStatus";
import { CompanyOverrideStatus } from "enums/grid/companyOverrideStatus";
import {
  createHeaderAndHeaderTooltip,
  getSortModel,
  getDefaultColumnSort,
  Grid
} from "framework/components/grid/Grid";
import {
  GridCheckboxRenderer,
  GridCheckboxRendererProps
} from "framework/components/grid/GridCheckboxRenderer";
import { getApiRegistry } from "framework/state/apiRegistry";
import { produce } from "immer";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { getValidDateFormat } from "utilities/dateUtils";
import { apiGridErrorHandler } from "utilities/errorUtils";
import { formCompanyCriteriaFilters } from "utilities/filters";

interface CompaniesGridProps {
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyDto | undefined>>;
  setGridApi: (ref: GridApi | null) => void;
  disableResize?: boolean;
}

const getColDefs = (t: (text: string) => string) => {
  return [
    {
      ...createHeaderAndHeaderTooltip(t("Name")),
      field: "name"
    },
    {
      ...createHeaderAndHeaderTooltip(t("City")),
      field: "addressCity",
      width: 120
    },
    {
      ...createHeaderAndHeaderTooltip(t("Country")),
      field: "countryCode",
      width: 120
    },
    { field: "id", ...createHeaderAndHeaderTooltip(t("Customer code")), width: 150 },
    {
      ...createHeaderAndHeaderTooltip(t("Global customer number")),
      field: "globalCustomerNumber",
      tooltipValueGetter: (params: ITooltipParams) =>
        params.value
          ? `${params.value} ${params.data?.isInternal ? t("Internal") : t("External")}`
          : ""
    },
    {
      ...createHeaderAndHeaderTooltip(t("Last active date")),
      field: "lastActive",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan"],
        defaultOption: "equals",
        buttons: ["clear"],
        suppressAndOrCondition: true
      },

      valueFormatter: (params: ValueFormatterParams): string => {
        if (!params.value) {
          return " ";
        }
        return moment(params.value).format(getValidDateFormat());
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Active")),
      headerClass: "grid-header-center-with-menu",
      field: "active",
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          isDisabled: true
        };
        return editorProps;
      },
      filter: "agSetColumnFilter",
      floatingFilter: true,
      filterParams: {
        values: [ActiveStatus.Active, ActiveStatus.InActive],
        valueFormatter: (params: any) => t(params.value)
      }
    },
    {
      ...createHeaderAndHeaderTooltip(t("Override as active")),
      width: 230,
      headerClass: "grid-header-center-with-menu",
      field: "overrideStatusActive",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      filterParams: {
        values: [CompanyOverrideStatus.Set, CompanyOverrideStatus.NotSet],
        valueFormatter: (params: any) => t(params.value)
      },
      cellRenderer: GridCheckboxRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const editorProps: GridCheckboxRendererProps = {
          ...params,
          data: params.value,
          isDisabled: true
        };
        return editorProps;
      }
    }
  ];
};

export const companiesGridDataSource = (
  criteria: ApiCompaniesGetRequest,
  gridApi: GridApi,
  dispatch: Dispatch,
  matchedCompanyId?: string
): IDatasource => {
  let nodeToSelect: IRowNode | null = null;
  return {
    getRows: (params: IGetRowsParams) => {
      const page = Math.floor(params.startRow / criteria.criteriaPageSize);
      const api = getApiRegistry().companiesApi;
      const sortModel = getSortModel(params.sortModel);
      const filterCriteria = formCompanyCriteriaFilters(params.filterModel);
      const newCriteria = produce(criteria, (draft) => {
        draft.criteriaSortColumn = sortModel?.sortColumn ?? draft.criteriaSortColumn;
        draft.criteriaPage = page;
        draft.criteriaIsAscendingOrder = sortModel?.isAsc ?? draft.criteriaIsAscendingOrder;
        // filters
        draft.criteriaName = filterCriteria.criteriaName;
        draft.criteriaAddressCity = filterCriteria.criteriaAddressCity;
        draft.criteriaCountryCode = filterCriteria.criteriaCountryCode;
        draft.criteriaGlobalCustomerNumber = filterCriteria.criteriaGlobalCustomerNumber;
        draft.criteriaStatus = filterCriteria.criteriaStatus;
        draft.criteriaLastActiveFrom = filterCriteria.criteriaLastActiveFrom;
        draft.criteriaLastActiveTo = filterCriteria.criteriaLastActiveTo;
        draft.criteriaOverrideStatus = filterCriteria.criteriaOverrideStatus;
        draft.criteriaCustomerCode = filterCriteria.criteriaCustomerCode;
      });

      gridApi?.showLoadingOverlay();
      api
        .apiCompaniesGet(newCriteria)
        .then((res) => {
          gridApi.hideOverlay();
          params.successCallback(res.companies, res.criteria.totalCount);

          if (res.companies.length === 0) {
            gridApi.showNoRowsOverlay();
          }

          if (matchedCompanyId) {
            const selectedNodes = gridApi?.getSelectedNodes();
            if (selectedNodes.length === 0) {
              nodeToSelect = gridApi?.getRowNode(matchedCompanyId) ?? null;
              nodeToSelect?.setSelected(true, true);
            }
          }
        })
        .catch((err) => {
          gridApi?.hideOverlay();
          apiGridErrorHandler(err, dispatch);
        });
    }
  };
};

export const CompaniesGrid = ({
  setSelectedCompany,
  setGridApi,
  disableResize
}: CompaniesGridProps): JSX.Element => {
  useEffect(() => {
    return () => {
      setGridApi(null);
    };
  }, [setGridApi]);

  const { t } = useTranslation();

  return (
    <Grid
      t={t}
      disableResize={disableResize}
      columnDefs={getColDefs(t)}
      colDefDefault={{
        filter: "agTextColumnFilter",
        floatingFilter: true,
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true
        }
      }}
      statusBarOptions={{ showResetButton: true, showRowCount: true }}
      rowModelType={"infinite"}
      paginationPageSize={100}
      cacheOverflowSize={2}
      maxConcurrentDatasourceRequests={2}
      maxBlocksInCache={2}
      suppressCellFocus={true}
      rowSelection="single"
      getRowId={(params) => params.data.id}
      showPointerOnRowHover
      onSelectionChanged={(params) => {
        const selectedRow = params.api.getSelectedRows()[0];
        setSelectedCompany(selectedRow);
      }}
      defaultSort={getDefaultColumnSort(
        manageCompaniesDefaultCriteria.criteriaIsAscendingOrder,
        manageCompaniesDefaultCriteria.criteriaSortColumn
      )}
      onGridReady={(event) => {
        setGridApi(event.api);
        event.columnApi.applyColumnState({
          state: [
            {
              colId: "name",
              sort: "asc"
            }
          ]
        });
      }}
    ></Grid>
  );
};

import React, { memo } from "react";
import { Checkbox, ThreeStateValue } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";

export interface GridCheckboxRendererProps extends ICellRendererParams {
  isDisabled: boolean;
  onChange?: (value: boolean) => void;
}

const GridCheckBox = styled(Checkbox)`
  text-align: center;
`;

export const GridCheckboxRenderer = memo((props: GridCheckboxRendererProps) => {
  return (
    <GridCheckBox
      monochrome={true}
      value={props.value}
      disabled={props.isDisabled}
      onChange={() => {
        if (props.onChange) {
          if (typeof props.data === "boolean") {
            props.onChange(!props.data);
          } else if (typeof props.data === "number") {
            const data = props.data as ThreeStateValue;
            if (data === ThreeStateValue.Checked) {
              // Checked
              props.onChange(false);
            } else {
              // Indeterminate and unchecked
              props.onChange(true);
            }
          }
        }
      }}
    />
  );
});

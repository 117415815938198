import React, { memo, ReactElement, useState } from "react";
import { NumericInput, Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";

export type NumericInputColorStatus = "normal" | "good" | "warning" | "error";

const StyledNumericInput = styled(NumericInput)<{
  status?: Exclude<NumericInputColorStatus, "normal">;
}>`
  width: 80px;
  ${(props) =>
    props.status !== undefined
      ? `border-bottom: 2px solid ${
          {
            good: props.theme.colors.statusGreen,
            warning: props.theme.colors.statusYellow,
            error: props.theme.colors.statusRed
          }[props.status]
        }`
      : ""}
`;

export interface GridNumericInputRendererProps extends ICellRendererParams {
  value: number;
  min?: number;
  max?: number;
  onChange: (orderingCode: string, quantity: number, additionalInformation: string) => void;
  type: "normal" | "compact";
  isLoading?: boolean;
  isSimpleRenderer?: boolean;
  /** Normal/undefined = default input
   *
   *  Good = show green underline
   *
   *  Warning = show yellow underline
   *
   *  Error = show red underline
   */
  status?: NumericInputColorStatus | undefined;
  tooltip?: string | undefined;
}

export const GridNumericInputRenderer = memo((props: GridNumericInputRendererProps) => {
  const [quantity, setQuantity] = useState<number | null | undefined>(props.value);
  const [hasFocus, setHasFocus] = useState(false);

  if (props.isSimpleRenderer) {
    return props.value as unknown as ReactElement<any, any> | null;
  }

  const Component = (
    <StyledNumericInput
      status={props.status === "normal" ? undefined : props.status}
      type={props.type}
      value={props.value}
      min={props.min}
      max={props.max}
      disablePlus={props.isLoading}
      disableMinus={props.isLoading}
      onChange={(newQuantity) => {
        const oldQuantity = quantity;
        setQuantity(newQuantity);
        setTimeout(() => {
          if (
            newQuantity !== undefined &&
            newQuantity >= 0 &&
            newQuantity !== props.value &&
            oldQuantity != null &&
            (newQuantity - oldQuantity === 1 || newQuantity - oldQuantity === -1)
          ) {
            props.onChange(props.data.orderingCode, newQuantity, props.data.additionalInformation);
          }
        });
      }}
      onGotFocus={() => {
        setHasFocus(true);
      }}
      onLostFocus={() => {
        if (!quantity) {
          setQuantity(props.value);
        } else if (quantity !== props.value && hasFocus) {
          props.onChange(props.data.orderingCode, quantity, props.data.additionalInformation);
        }

        setHasFocus(false);
      }}
    />
  );

  if (props.tooltip !== undefined) {
    return (
      <WithTooltip>
        {Component}
        <Tooltip>{props.tooltip}</Tooltip>
      </WithTooltip>
    );
  }

  return Component;
});

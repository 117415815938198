import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { AppContentWrapper } from "framework/components/AppContentWrapper";
import { QuoteStatusBanner } from "../components/QuoteStatusBanner";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import {
  getQuote,
  getApproveOrderOrQuoteStatus,
  getRejectApprovalStatus,
  getShoppingCart,
  getRequestQuoteApprovalRequestState,
  getSendQuoteWithoutApprovalRequestState,
  getDisableQuoting,
  getUploadQuoteAttachmentsStatus,
  getDeleteQuoteAttachmentStatus,
  getDraftQuoteFiles,
  getRejectQuoteStatus,
  getActivateShoppingCartStatus,
  getCreateQuoteRevisionRequestState,
  getRequestAcknowledgeRevisionState,
  getQuoteAttachments,
  getSalesChannels,
  getRequestQuoteRevisionRequestState,
  getDraftQuote,
  getSaveMessageToRequestorStatus,
  getAddAdditionalQuoteRequestersStatus
} from "../../../common/reducers/shoppingCartReducer";
import {
  CreateQuoteItemDto,
  QuoteStateTypes,
  AccessRights,
  ShoppingCartStateTypes,
  SaveQuoteDto,
  CreatedItem,
  ReviseQuoteDto,
  ReviseQuoteItemDto,
  ShoppingCartItemDto,
  SalesChannelTypes,
  ShoppingCartDto
} from "api";
import { userActions } from "applications/common/actions/userActions";
import {
  getUpdateUserSelectedCompanyRequest,
  getUserAccessRights,
  getUserInformation
} from "applications/common/reducers/userReducer";
import { hasAnyAccessRight } from "utilities/authUtils";
import { usePrevious } from "framework/hooks/usePrevious";
import { RequestStatus } from "framework/state/requestStatus";
import { ApprovalDialog } from "applications/shoppingCart/components/ApprovalDialog";
import { shoppingCartActions } from "applications/common/actions/shoppingCartActions";
import { LineItemDetailsGrid } from "../components/LineItemDetailsGrid";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import { routes } from "utilities/routes";
import { Formik, FormikProps } from "formik";
import { getDateTime, getValidDateFormat } from "utilities/dateUtils";
import { useQuery } from "framework/hooks/useQuery";
import { shoppingCartCompanySagas } from "applications/shoppingCart/sagas/shoppingCartCompanySagas";
import {
  getCompanyApprovers,
  getCompanyPgCodes
} from "applications/shoppingCart/reducers/shoppingCartCompanyReducer";
import moment from "moment";
import { deliveriesHomeSagas } from "applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { priceStringToFloatNumber } from "utilities/currencyUtils";
import { BottomSectionQuote } from "applications/shoppingCart/components/BottomSection";
import {
  canAcknowledgeAndRejectRevision,
  currentUserIsQuoteRequester,
  isQuoteExpired,
  quoteCanBeTransferredToMat,
  showRequestRevisionButton,
  showReviseButton
} from "applications/shoppingCart/helpers";
import { roleConstants } from "constants/roleConstants";
import { QuoteInformation } from "applications/shoppingCart/components/QuoteInformation";
import { QuoteAttachmentsUploader } from "../components/QuoteAttachmentsUploader";
import { PrintButton } from "framework/components/PrintButton";
import { RejectQuoteDialog } from "applications/shoppingCart/components/RejectQuoteDialog";
import { Button } from "framework/components/Button";
import { mapQuoteToReviseQuote } from "utilities/mappings";
import { AddProductDialog } from "applications/shoppingCart/components/AddProductDialog";
import { EditProductDialog } from "applications/shoppingCart/components/EditProductDialog";
import { getCreateQuoteValidationSchema } from "applications/shoppingCart/validationSchema";
import { RedirectDialog } from "framework/components/RedirectDialog";
import { userSagas } from "applications/common/sagas/userSagas";
import { ReviseQuotationDialog } from "applications/shoppingCart/components/ReviseQuotationDialog";
import { RequestRevisionDialog } from "applications/shoppingCart/components/RequestRevisionDialog";
import { mapShoppingCartToQuoteViewForm } from "utilities/mappings";
import { ReviseMode } from "applications/shoppingCart/containers/ShoppingCartView";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";

const CustomSectionHeader = styled(SectionHeader)`
  margin-bottom: ${(props) => props.theme.sizes.m};
`;
const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const ButtonsPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const EditButtonContainer = styled.div`
  margin-left: ${(props) => props.theme.sizes.sm};
  margin-right: auto;
  margin-bottom: ${(props) => props.theme.sizes.s};
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButtonContainer = styled.div`
  margin-left: auto;
  margin-right: ${(props) => props.theme.sizes.sm};
  margin-bottom: ${(props) => props.theme.sizes.s};
  @media print {
    display: none;
  }
`;

export const defaultShoppingCartsCriteria = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: false,
  criteriaSortColumn: "created"
};

export interface ReviseQuoteFromCart {
  id: number | undefined;
  quoteReceiverEmail: string | undefined | null;
  projectName: string | undefined | null;
  sfdcReference: string | undefined | null;
  expectedOrderDate: string | undefined | null;
  tenderValidityDate: string | undefined | null;
  endCustomerCompanyName: string | undefined | null;
  endCustomerCompanyStreetAddress: string | undefined | null;
  endCustomerCompanyCity: string | undefined | null;
  endCustomerCompanyCountry: string | undefined | null;
  endCustomerCompanyCountryCode: string | undefined | null;
  endCustomerGuid: string | undefined | null;
  isEndCustomer: boolean | undefined | null;
  accountCustomerCompanyName: string | undefined | null;
  accountCustomerCompanyStreetAddress: string | undefined | null;
  accountCustomerCompanyCity: string | undefined | null;
  accountCustomerCompanyCountry: string | undefined | null;
  accountCustomerCompanyCountryCode: string | undefined | null;
  accountCustomerIcvCode: string | undefined | null;
  isAccountCustomer: boolean | undefined | null;
  salesChannelId?: SalesChannelTypes;
  pgCode?: string | null;
  winningPercentage?: number | null;
  salesChannelDescription: undefined | string | null;
  totalSalesPrice?: number | null;
}

export const infoTypeConstants = {
  discountRate: "discountRate",
  unitQuotedPrice: "unitQuotedPrice",
  quantity: "quantity"
};

export const initialReviseValues: ReviseQuoteFromCart = {
  id: undefined,
  quoteReceiverEmail: undefined,
  projectName: undefined,
  sfdcReference: undefined,
  expectedOrderDate: undefined,
  tenderValidityDate: moment().endOf("day").add(3, "months").format(getValidDateFormat()),
  endCustomerCompanyName: undefined,
  endCustomerCompanyStreetAddress: undefined,
  endCustomerCompanyCity: undefined,
  endCustomerCompanyCountry: undefined,
  endCustomerCompanyCountryCode: undefined,
  endCustomerGuid: undefined,
  isEndCustomer: undefined,
  accountCustomerCompanyName: undefined,
  accountCustomerCompanyStreetAddress: undefined,
  accountCustomerCompanyCity: undefined,
  accountCustomerCompanyCountry: undefined,
  accountCustomerCompanyCountryCode: undefined,
  accountCustomerIcvCode: undefined,
  isAccountCustomer: undefined,
  salesChannelDescription: undefined,
  totalSalesPrice: undefined
};

const QuoteView = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quoteId } = useParams<{ quoteId?: string }>();
  const quoteIdAsNumber = useMemo<number | null>(
    () => (quoteId ? parseInt(quoteId, 10) : null),
    [quoteId]
  );
  const query = useQuery();
  const cartGuid = query.get("cart");

  // Selector
  const quote = useSelector(getQuote);
  const draftQuote = useSelector(getDraftQuote);
  const cart = useSelector(getShoppingCart);
  const userAccessRights = useSelector(getUserAccessRights);
  const userInformation = useSelector(getUserInformation);
  const requestQuoteApprovalRequestState = useSelector(getRequestQuoteApprovalRequestState);
  const approveOrderOrQuoteStatus = useSelector(getApproveOrderOrQuoteStatus);
  const rejectApprovalStatus = useSelector(getRejectApprovalStatus);
  const sendQuoteWithoutApprovalRequestState = useSelector(getSendQuoteWithoutApprovalRequestState);
  const rejectQuoteStatus = useSelector(getRejectQuoteStatus);
  const activateShoppingCartStatus = useSelector(getActivateShoppingCartStatus);
  const requestQuoteRevisionRequestState = useSelector(getRequestQuoteRevisionRequestState);
  const quoteRevisionRequestState = useSelector(getCreateQuoteRevisionRequestState);
  const acknowledgeRevisionState = useSelector(getRequestAcknowledgeRevisionState);
  const updateUserSelectedCompanyRequest = useSelector(getUpdateUserSelectedCompanyRequest);
  const addAdditionalQuoteRequestersStatus = useSelector(getAddAdditionalQuoteRequestersStatus);
  const saveMessageToRequestorStatus = useSelector(getSaveMessageToRequestorStatus);
  const companyApprovers = useSelector(getCompanyApprovers);
  const disableQuoting = useSelector(getDisableQuoting);
  const uploadQuoteAttachmentsStatus = useSelector(getUploadQuoteAttachmentsStatus);
  const deleteQuoteAttachmentStatus = useSelector(getDeleteQuoteAttachmentStatus);
  const draftQuoteFiles = useSelector(getDraftQuoteFiles);
  const quoteAttachments = useSelector(getQuoteAttachments);
  const salesChannels = useSelector(getSalesChannels);
  const pgCodes = useSelector(getCompanyPgCodes);

  // Previous
  const prevRequestQuoteApprovalRequestState = usePrevious(requestQuoteApprovalRequestState);
  const prevApproveOrderOrQuoteStatus = usePrevious(approveOrderOrQuoteStatus);
  const prevRejectApprovalStatus = usePrevious(rejectApprovalStatus);
  const prevSendQuoteWithoutApprovalRequestState = usePrevious(
    sendQuoteWithoutApprovalRequestState
  );
  const prevRejectQuoteStatus = usePrevious(rejectQuoteStatus);
  const prevActivateShoppingCartStatus = usePrevious(activateShoppingCartStatus);
  const prevRequestQuoteRevisionRequestState = usePrevious(requestQuoteRevisionRequestState);
  const prevQuoteRevisionRequestState = usePrevious(quoteRevisionRequestState);
  const prevAcknowledgeRevisionState = usePrevious(acknowledgeRevisionState);
  const prevUploadQuoteAttachmentsStatus = usePrevious(uploadQuoteAttachmentsStatus);
  const prevUpdateUserSelectedCompanyRequest = usePrevious(updateUserSelectedCompanyRequest);
  const prevSaveMessageToRequestorStatus = usePrevious(saveMessageToRequestorStatus);
  const prevAddAdditionalQuoteRequestersStatus = usePrevious(addAdditionalQuoteRequestersStatus);
  const prevSelectedCompanyId = useRef("-1");
  const hasInitializedQuotedPrices = useRef(false);
  const detailsRef = useRef(null);
  const gridContainerRef = useRef(null);
  const attachmentsRef = useRef(null);
  // State
  const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [previousIsReadOnly, setPreviousIsReadOnly] = useState<boolean>(true);
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isRejectQuoteDialogOpen, setIsRejectQuoteDialogOpen] = useState(false);
  const [isReviseMode, setIsReviseMode] = useState(false);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedShoppingCartItem, setSelectedShoppingCartItem] = useState<
    ShoppingCartItemDto | undefined
  >();
  const [updateSelectedCompanyRedirect, setUpdateSelectedCompanyRedirect] = useState(false);
  const [isReviseQuotationDialogOpen, setIsReviseQuotationDialogOpen] = useState(false);
  const [isRequestRevisionDialogOpen, setIsRequestRevisionDialogOpen] = useState(false);

  const shoppingCart = useMemo(() => (quote ? quote.shoppingCart : cart), [quote, cart]);
  const isQuote = useMemo(() => (quote ? true : false), [quote]);

  const company = useMemo(
    () => shoppingCart?.company ?? userInformation?.selectedCompany,
    [shoppingCart, userInformation]
  );

  const countryToCheck = useMemo(() => company?.countryCode, [company]);

  const formikRef = useRef<FormikProps<ReviseQuoteFromCart>>();

  // Access rights
  const hasLocalQuoteOrLocalContanctRegionalSalesHandlerOrPmsRight = hasAnyAccessRight(
    countryToCheck,
    userAccessRights,
    AccessRights.LocalQuoteHandler,
    AccessRights.LocalContactRegionalSales,
    AccessRights.Pms,
    AccessRights.ManagePriceLists,
    AccessRights.ViewAllPriceLists
  );
  const isReadOnlyMemo = useMemo(() => {
    return (
      ((isQuote && quote?.stateId !== QuoteStateTypes.WaitingForQuote) ||
        !hasLocalQuoteOrLocalContanctRegionalSalesHandlerOrPmsRight) &&
      !isReviseMode
    );
  }, [
    isQuote,
    quote?.stateId,
    hasLocalQuoteOrLocalContanctRegionalSalesHandlerOrPmsRight,
    isReviseMode
  ]);

  const isASMOrProcurist = useMemo(
    () =>
      hasAnyAccessRight(
        undefined,
        userAccessRights,
        AccessRights.ManageMarginAnalysisToolQuotesAsAsm,
        AccessRights.ManageMarginAnalysisToolQuotesProcurist
      ),
    [userAccessRights]
  );

  const validationSchema = useMemo(() => getCreateQuoteValidationSchema(t), [t]);

  useEffect(() => {
    return () => {
      // refetch the quote when returning to this view
      dispatch(shoppingCartActions.setQuote(undefined));
      dispatch(shoppingCartActions.setShoppingCart(undefined));
    };
  }, [dispatch]);
  useEffect(() => {
    setIsReadOnly(isReadOnlyMemo);
  }, [isReadOnlyMemo]);
  useEffect(() => {
    if (!isQuote && cart && !hasInitializedQuotedPrices.current) {
      dispatch(shoppingCartActions.initializeQuotedPrices());
      hasInitializedQuotedPrices.current = true;
    }
  }, [dispatch, isQuote, cart]);

  useEffect(() => {
    if (cartGuid && cartGuid !== shoppingCart?.guid) {
      dispatch(shoppingCartSagas.getShoppingCartById.createAction({ guid: cartGuid }));
      // Setting hasInitializedQuotedPrices as false here, so that that happens each time shopping cart is fetched.
      // If not set as false here, this will in quoted price / discount values being null after the initial pressing of "build quote"
      hasInitializedQuotedPrices.current = false;
    }
  }, [cartGuid, shoppingCart, dispatch]);

  useEffect(() => {
    if (
      company &&
      shoppingCart &&
      shoppingCart?.stateId !== ShoppingCartStateTypes.QuoteWaitingForApproval &&
      company.id !== prevSelectedCompanyId.current
    ) {
      prevSelectedCompanyId.current = company.id;
      dispatch(shoppingCartCompanySagas.getCompanyApprovers.createAction({ id: company?.id }));
    }
  }, [company, shoppingCart, dispatch]);

  const hasApproveQuoteRight =
    hasAnyAccessRight(
      quote?.shoppingCart?.company?.id,
      userAccessRights,
      AccessRights.ApproveQuotes
    ) ||
    hasAnyAccessRight(
      quote?.shoppingCart?.guid,
      userAccessRights,
      AccessRights.RequestedQuoteApprover
    );

  const companyIsInternalOrMaintained = useMemo(
    () =>
      shoppingCart?.company?.isMaintainedCustomer === true ||
      shoppingCart?.company?.isInternal === true,
    [shoppingCart]
  );

  useEffect(() => {
    if (
      (prevRejectApprovalStatus === RequestStatus.Pending &&
        rejectApprovalStatus === RequestStatus.Completed) ||
      (prevApproveOrderOrQuoteStatus === RequestStatus.Pending &&
        approveOrderOrQuoteStatus === RequestStatus.Completed) ||
      (prevSendQuoteWithoutApprovalRequestState?.status === RequestStatus.Pending &&
        sendQuoteWithoutApprovalRequestState?.status === RequestStatus.Completed) ||
      (prevRequestQuoteApprovalRequestState?.status === RequestStatus.Pending &&
        requestQuoteApprovalRequestState?.status === RequestStatus.Completed) ||
      (prevAcknowledgeRevisionState?.status === RequestStatus.Pending &&
        acknowledgeRevisionState?.status === RequestStatus.Completed) ||
      (prevRequestQuoteRevisionRequestState?.status === RequestStatus.Pending &&
        requestQuoteRevisionRequestState?.status === RequestStatus.Completed) ||
      (prevSaveMessageToRequestorStatus === RequestStatus.Pending &&
        saveMessageToRequestorStatus === RequestStatus.Completed) ||
      (prevAddAdditionalQuoteRequestersStatus === RequestStatus.Pending &&
        addAdditionalQuoteRequestersStatus === RequestStatus.Completed)
    ) {
      setIsApprovalDialogOpen(false);
      setIsRequestRevisionDialogOpen(false);
      if (quoteIdAsNumber) {
        dispatch(
          shoppingCartSagas.getQuoteById.createAction({
            id: quoteIdAsNumber
          })
        );
      }
    }
  }, [
    dispatch,
    quoteIdAsNumber,
    isApprovalDialogOpen,
    prevApproveOrderOrQuoteStatus,
    approveOrderOrQuoteStatus,
    prevRejectApprovalStatus,
    rejectApprovalStatus,
    prevSendQuoteWithoutApprovalRequestState,
    sendQuoteWithoutApprovalRequestState,
    prevRequestQuoteApprovalRequestState,
    requestQuoteApprovalRequestState,
    prevAcknowledgeRevisionState,
    acknowledgeRevisionState,
    prevRequestQuoteRevisionRequestState,
    requestQuoteRevisionRequestState,
    saveMessageToRequestorStatus,
    prevSaveMessageToRequestorStatus,
    prevAddAdditionalQuoteRequestersStatus,
    addAdditionalQuoteRequestersStatus
  ]);

  useEffect(() => {
    if (
      prevSendQuoteWithoutApprovalRequestState?.status === RequestStatus.Pending &&
      sendQuoteWithoutApprovalRequestState?.status === RequestStatus.Completed
    ) {
      const id = sendQuoteWithoutApprovalRequestState.responsePayload;
      if (id) {
        navigate(routes.manage.quote + "/" + id);
      }
    }
  }, [prevSendQuoteWithoutApprovalRequestState, sendQuoteWithoutApprovalRequestState, navigate]);

  useEffect(() => {
    if (
      prevRequestQuoteApprovalRequestState?.status === RequestStatus.Pending &&
      requestQuoteApprovalRequestState?.status === RequestStatus.Completed
    ) {
      const id = requestQuoteApprovalRequestState.responsePayload;
      if (id) {
        navigate(routes.manage.quote + "/" + id);
      }
    }
  }, [prevRequestQuoteApprovalRequestState, requestQuoteApprovalRequestState, navigate]);

  useEffect(() => {
    if (
      (prevQuoteRevisionRequestState?.status === RequestStatus.Pending &&
        quoteRevisionRequestState?.status === RequestStatus.Completed) ||
      (prevUploadQuoteAttachmentsStatus === RequestStatus.Pending &&
        uploadQuoteAttachmentsStatus === RequestStatus.Completed)
    ) {
      const id = quoteRevisionRequestState.responsePayload;
      if (id) {
        if (draftQuoteFiles.length === 0) {
          setIsReviseMode(false);
          setIsApprovalDialogOpen(false);
          setIsReviseQuotationDialogOpen(false);
          navigate(routes.manage.quote + "/" + id);
        } else {
          dispatch(
            shoppingCartSagas.uploadQuoteAttachments.createAction({
              id: id,
              files: draftQuoteFiles
            })
          );
        }
      }
    }
  }, [
    dispatch,
    draftQuoteFiles,
    navigate,
    prevQuoteRevisionRequestState,
    prevUploadQuoteAttachmentsStatus,
    quoteRevisionRequestState,
    uploadQuoteAttachmentsStatus
  ]);

  // Get quote
  useEffect(() => {
    if (quoteIdAsNumber) {
      dispatch(
        shoppingCartSagas.getQuoteById.createAction({
          id: quoteIdAsNumber
        })
      );
    }
  }, [dispatch, quoteIdAsNumber]);

  // Get system settings
  useEffect(() => {
    dispatch(deliveriesHomeSagas.getSystemSettings.createAction(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (
      prevRejectQuoteStatus?.status === RequestStatus.Pending &&
      rejectQuoteStatus?.status === RequestStatus.Completed &&
      quote
    ) {
      dispatch(
        shoppingCartSagas.getQuoteById.createAction({
          id: quote.id
        })
      );
    }
  }, [dispatch, rejectQuoteStatus, prevRejectQuoteStatus, quote]);

  useEffect(() => {
    if (
      activateShoppingCartStatus === RequestStatus.Completed &&
      prevActivateShoppingCartStatus === RequestStatus.Pending &&
      shoppingCart
    ) {
      navigate(routes.shoppingCart, { relative: "path" });
      dispatch(
        shoppingCartSagas.getShoppingCartById.createAction({
          guid: shoppingCart?.guid
        })
      );
      // trigger to refresh the page to apply changes of current user(changing selected company)
      navigate(0);
    }
  }, [
    activateShoppingCartStatus,
    prevActivateShoppingCartStatus,
    shoppingCart,
    navigate,
    dispatch,
    company
  ]);

  useEffect(() => {
    if (quote && shoppingCart && company && isReviseMode) {
      formikRef.current?.setValues(mapQuoteToReviseQuote(shoppingCart, quote, company));
    }
    // disable because of need to be applied only on enabling revise mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isReviseMode]);

  useEffect(() => {
    if (
      updateUserSelectedCompanyRequest.status === RequestStatus.Completed &&
      prevUpdateUserSelectedCompanyRequest.status === RequestStatus.Pending
    ) {
      navigate(0);
    }
  }, [updateUserSelectedCompanyRequest, prevUpdateUserSelectedCompanyRequest, navigate]);

  useEffect(() => {
    if (!salesChannels) {
      dispatch(shoppingCartSagas.getSalesChannels.createAction(undefined));
    }
  }, [salesChannels, dispatch]);

  useEffect(() => {
    if (userInformation?.selectedCompany && isReviseQuotationDialogOpen) {
      dispatch(
        shoppingCartCompanySagas.getCompanyPgCodes.createAction({
          id: userInformation.selectedCompany.id
        })
      );
    }
  }, [userInformation?.selectedCompany, isReviseQuotationDialogOpen, dispatch]);

  const openEditDialogAndSetSelectedShoppingCartItem = useCallback(
    (shoppingCartItem: ShoppingCartItemDto) => {
      setSelectedShoppingCartItem(shoppingCartItem);
      setIsEditDialogOpen((isOpen) => !isOpen);
    },
    []
  );

  const isSecondaryButtonVisible = () => {
    if (quote) {
      if (quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval && hasApproveQuoteRight) {
        return true;
      }
      if (
        shoppingCart?.stateId === ShoppingCartStateTypes.Quote ||
        shoppingCart?.stateId === ShoppingCartStateTypes.FrameAgreement ||
        shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision
      ) {
        if (!!shoppingCart.baseShoppingCartId && acknowledgementAndRejectionsRevisionsAllowed) {
          return true;
        }
        if (
          !shoppingCart.baseShoppingCartId &&
          userInformation &&
          (shoppingCart?.ownerEmail === userInformation.email ||
            currentUserIsQuoteRequester(quote, userInformation))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isPrimaryButtonVisible = () => {
    if (quote) {
      if (
        (quote.stateId === QuoteStateTypes.WaitingForQuote &&
          hasLocalQuoteOrLocalContanctRegionalSalesHandlerOrPmsRight &&
          !companyIsInternalOrMaintained) ||
        (quote.stateId === QuoteStateTypes.QuoteWaitingForApproval && hasApproveQuoteRight)
      ) {
        return true;
      }
      if (isReviseMode) {
        return true;
      }
      if (
        quote.stateId === QuoteStateTypes.WaitingForRevision &&
        acknowledgementAndRejectionsRevisionsAllowed
      ) {
        return true;
      }
    } else if (
      shoppingCart &&
      shoppingCart.stateId === ShoppingCartStateTypes.Draft &&
      hasLocalQuoteOrLocalContanctRegionalSalesHandlerOrPmsRight
    ) {
      return true;
    }
    return false;
  };

  const isTertiaryButtonVisible = () => {
    return (!quote || (quote && quote.stateId === QuoteStateTypes.Draft)) && !quoteId;
  };

  const isToOrderButtonVisible = () => {
    const hasAccessToPlaceOrderForSelectedCompany = hasAnyAccessRight(
      userInformation?.selectedCompanyId ?? roleConstants.GlobalRoleIdentifier,
      userAccessRights,
      AccessRights.PlaceCompanyOrders
    );

    return (
      !!quote &&
      quote.stateId === QuoteStateTypes.Quote &&
      hasAccessToPlaceOrderForSelectedCompany &&
      !isReviseMode
    );
  };

  const askForApproval = () => {
    if (quote || shoppingCart) {
      setIsApprovalDialogOpen(true);
    }
  };

  const createQuoteRevision = (values: ReviseQuoteFromCart) => {
    requestQuoteApproval(undefined, undefined, values, undefined);
  };

  const requestQuoteRevision = (revisionMessage: string) => {
    const quoteId = quote?.id;
    if (quoteId) {
      dispatch(
        shoppingCartSagas.requestQuoteRevision.createAction({
          requestQuoteRevisionCommand: {
            id: quoteId,
            revisionMessage: revisionMessage
          }
        })
      );
    }
  };

  const requestQuoteApproval = (
    approverEmail?: string,
    isOnlyAddedApprover?: boolean,
    values?: ReviseQuoteFromCart,
    sendQuoteWithoutApproval?: boolean
  ) => {
    if (shoppingCart) {
      const guid = shoppingCart.guid;
      const quoteId = quote?.id;
      const saveQuoteDto = buildQuoteDto(shoppingCart, values, false);

      if (isReviseMode && quoteId) {
        const reviseQuoteItems: ReviseQuoteItemDto[] = [];

        shoppingCart.shoppingCartItems.forEach((item) => {
          const reviseQuoteItemDto: ReviseQuoteItemDto = {
            orderingCode: item.orderingCode,
            quotedPrice: !companyIsInternalOrMaintained
              ? priceStringToFloatNumber(item.unitQuotedPrice)
              : undefined,
            quantity: item.quantity,
            additionalInformation: item.additionalInformation
          };
          reviseQuoteItems.push(reviseQuoteItemDto);
        });

        const reviseQuoteDto: ReviseQuoteDto = {
          ...saveQuoteDto,
          quoteReceiverEmail: undefined,
          winningPercentage: companyIsInternalOrMaintained ? values?.winningPercentage : undefined,
          salesChannelId: companyIsInternalOrMaintained ? values?.salesChannelId : undefined,
          pgCode: companyIsInternalOrMaintained ? values?.pgCode : undefined,
          items: [],
          revisionItems: reviseQuoteItems,
          quoteAttachmentsToBeCopied: quoteAttachments.filter((qa) => qa.identifier !== ""),
          totalSalesPrice: shoppingCart?.company?.isInternalBuyer
            ? values?.totalSalesPrice
            : undefined
        };

        const revisionCanBeSendWithoutApprove = companyApprovers?.length === 0;

        dispatch(
          shoppingCartSagas.createQuoteRevision.createAction({
            id: quoteId,
            reviseQuoteDto,
            approverEmail,
            isOnlyAddedApprover,
            sendingRevisionWithoutApproval:
              sendQuoteWithoutApproval && revisionCanBeSendWithoutApprove,
            redirectToMAT: companyIsInternalOrMaintained
          })
        );
      } else {
        if (companyApprovers?.length || approverEmail) {
          dispatch(
            shoppingCartSagas.requestQuoteApproval.createAction({
              guid,
              saveQuoteDto,
              approverEmail,
              isOnlyAddedApprover
            })
          );
        } else {
          // send quote without approval request
          dispatch(
            shoppingCartSagas.sendQuoteWithoutApproval.createAction({
              guid,
              saveQuoteDto
            })
          );
        }
      }
    }
  };

  const saveQuoteAsDraft = (values?: ReviseQuoteFromCart) => {
    if (shoppingCart) {
      const guid = shoppingCart.guid;
      const saveQuoteDto = buildQuoteDto(shoppingCart, values, true);

      // send quote without approval request
      dispatch(
        shoppingCartSagas.sendQuoteWithoutApproval.createAction({
          guid,
          saveQuoteDto
        })
      );
    }
  };

  const buildQuoteDto = (
    shoppingCart: ShoppingCartDto,
    values?: ReviseQuoteFromCart,
    isDraft?: boolean
  ): SaveQuoteDto => {
    const createQuoteItemDto: CreateQuoteItemDto[] = [];
    shoppingCart.shoppingCartItems.forEach((item) => {
      const modificationItems: CreatedItem[] = [];

      item.shoppingCartModificationItems.forEach((modificationItem) => {
        const mi: CreatedItem = {
          orderingCode: modificationItem.orderingCode,
          quotedPrice: modificationItem.unitPrice
            ? priceStringToFloatNumber(modificationItem.unitPrice)
            : null
        };
        modificationItems.push(mi);
      });

      const i: CreateQuoteItemDto = {
        orderingCode: item.orderingCode,
        quotedPrice: item.unitQuotedPrice ? priceStringToFloatNumber(item.unitQuotedPrice) : null,
        modificationItems
      };
      createQuoteItemDto.push(i);
    });

    const accountCustomerDetails = values?.isAccountCustomer
      ? {
          accountCustomerCompanyName: company?.name,
          accountCustomerCompanyStreetAddress: company?.addressStreet,
          accountCustomerCompanyCity: company?.addressCity,
          accountCustomerCompanyCountry: company?.countryName,
          accountCustomerCompanyCountryCode: company?.countryCode,
          accountCustomerIcvCode: company?.globalCustomerNumber
        }
      : {
          accountCustomerCompanyName: values?.accountCustomerCompanyName,
          accountCustomerCompanyStreetAddress: values?.accountCustomerCompanyStreetAddress,
          accountCustomerCompanyCity: values?.accountCustomerCompanyCity,
          accountCustomerCompanyCountry: values?.accountCustomerCompanyCountry,
          accountCustomerCompanyCountryCode: values?.accountCustomerCompanyCountryCode,
          accountCustomerIcvCode: values?.accountCustomerIcvCode
        };

    const endCustomerDetails = values?.isEndCustomer
      ? {
          endCustomerCompanyName: company?.name,
          endCustomerCompanyStreetAddress: company?.addressStreet,
          endCustomerCompanyCity: company?.addressCity,
          endCustomerCompanyCountry: company?.countryName,
          endCustomerCompanyCountryCode: company?.countryCode,
          endCustomerCompanyGuid: company?.globalCustomerNumber
        }
      : {
          endCustomerCompanyName: values?.endCustomerCompanyName,
          endCustomerCompanyStreetAddress: values?.endCustomerCompanyStreetAddress,
          endCustomerCompanyCity: values?.endCustomerCompanyCity,
          endCustomerCompanyCountry: values?.endCustomerCompanyCountry,
          endCustomerCompanyCountryCode: values?.endCustomerCompanyCountryCode,
          endCustomerCompanyGuid: values?.endCustomerGuid
        };

    const saveQuoteDto: SaveQuoteDto = {
      id: values?.id,
      stateId: isDraft ? QuoteStateTypes.Draft : QuoteStateTypes.Quote,
      items: createQuoteItemDto,
      ...accountCustomerDetails,
      ...endCustomerDetails,
      quoteReceiverEmail: values?.quoteReceiverEmail,
      projectName: values?.projectName,
      sfdcReference: values?.sfdcReference,
      expectedOrderDate: values?.expectedOrderDate
        ? getDateTime(values?.expectedOrderDate, getValidDateFormat())
        : undefined,
      tenderValidityDate: values?.tenderValidityDate
        ? getDateTime(values?.tenderValidityDate, getValidDateFormat())
        : undefined
    };

    return saveQuoteDto;
  };

  const approveQuote = () => {
    if (quote) {
      const guid = quote.guid;
      dispatch(
        shoppingCartSagas.approveOrderOrQuote.createAction({
          approveQuoteCommand: { identifier: guid }
        })
      );
    }
  };

  const rejectQuote = () => {
    if (quote) {
      const guid = quote.guid;
      dispatch(
        userActions.addConfirmEvent(
          () =>
            dispatch(
              shoppingCartSagas.rejectApproval.createAction({
                rejectApprovalCommand: { shoppingCartIdentifier: guid }
              })
            ),
          t("Reject quote"),
          t("Are you sure you want to reject this quote?")
        )
      );
    }
  };

  const reviseQuote = () => {
    if (quote && shoppingCart && isReviseMode) {
      if (companyIsInternalOrMaintained) {
        setIsReviseQuotationDialogOpen(true);
      } else {
        setIsApprovalDialogOpen(true);
      }
    }
  };
  const openRejectQuoteDialog = () => {
    if (shoppingCart && isQuoteExpired(shoppingCart)) {
      dispatch(shoppingCartSagas.renewQuote.createAction({ guid: shoppingCart.guid }));
    } else {
      setIsRejectQuoteDialogOpen(true);
    }
  };

  const removeLineItem = (id: number, orderingCode: string) => {
    dispatch(
      userActions.addConfirmEvent(
        () => dispatch(shoppingCartActions.removeLineItem(orderingCode, true)),
        t("Remove shopping cart item"),
        t("Are you sure you want to delete this shopping cart item?")
      )
    );
  };

  const acknowledgeQuote = () => {
    if (quote) {
      const id = quote.id;
      dispatch(
        shoppingCartSagas.acknowledgeRevision.createAction({
          acknowledgeRevisionCommand: { quoteId: id }
        })
      );
    }
  };

  const getSecondaryText = () => {
    if (
      quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval ||
      quote?.stateId === QuoteStateTypes.Quote
    ) {
      return t("Reject quote");
    }

    if (quote?.stateId === QuoteStateTypes.FrameAgreement) {
      return t("Reject frame agreement");
    }

    if (quote?.stateId === QuoteStateTypes.WaitingForRevision) {
      return t("Reject revision");
    }

    return "";
  };

  const getPrimaryText = () => {
    if (isReviseMode) {
      return t("Create revision");
    }
    if (quote?.stateId === QuoteStateTypes.WaitingForQuote) {
      return t("Proceed");
    }
    if (quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval) {
      return t("Approve quote");
    }
    if (shoppingCart?.stateId === ShoppingCartStateTypes.Draft) {
      return t("Create quote");
    }
    if (quote?.stateId === QuoteStateTypes.WaitingForRevision) {
      return t("Acknowledge");
    }
  };

  const getTertiaryText = () => {
    return t("Save quote as draft");
  };

  const secondaryAction = () => {
    if (quote) {
      if (quote.stateId === QuoteStateTypes.QuoteWaitingForApproval) {
        rejectQuote();
      } else if (
        quote.stateId === QuoteStateTypes.Quote ||
        quote.stateId === QuoteStateTypes.FrameAgreement ||
        quote.stateId === QuoteStateTypes.WaitingForRevision
      ) {
        openRejectQuoteDialog();
      }
    }
  };

  const primaryAction = () => {
    if (
      quote?.stateId === QuoteStateTypes.WaitingForQuote ||
      (!quote && shoppingCart?.stateId === ShoppingCartStateTypes.Draft)
    ) {
      askForApproval();
    } else if (quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval) {
      approveQuote();
    }
    if (
      isReviseMode &&
      (quote?.stateId === QuoteStateTypes.OrderPlacedExternally ||
        quote?.stateId === QuoteStateTypes.OrderPlaced ||
        quote?.stateId === QuoteStateTypes.FrameAgreement ||
        quote?.stateId === QuoteStateTypes.Quote ||
        quote?.stateId === QuoteStateTypes.Rejected ||
        quote?.stateId === QuoteStateTypes.Archived)
    ) {
      reviseQuote();
    }
    if (
      quote?.stateId === QuoteStateTypes.WaitingForRevision &&
      acknowledgementAndRejectionsRevisionsAllowed
    ) {
      acknowledgeQuote();
    }
  };

  const tertiaryAction = (values?: ReviseQuoteFromCart) => {
    if (shoppingCart) {
      saveQuoteAsDraft(values);
    }
  };

  const getEmailsList = () => {
    if (companyApprovers && companyApprovers.length > 0) {
      return companyApprovers.join("\r\n");
    }
    return "";
  };

  const updateLineItem = useCallback(
    (
      orderingCode: string,
      infoType: string,
      discountRate: number | null | undefined,
      unitQuotedPrice: number | null | undefined,
      quantity: number,
      additionalInformation: string
    ) => {
      if (orderingCode) {
        // Validate discount and unit quoted price fields, hide Approval button if either of them is empty
        setIsFieldEmpty(
          (infoType === infoTypeConstants.discountRate && discountRate == null) ||
            (infoType === infoTypeConstants.unitQuotedPrice && unitQuotedPrice == null)
        );
        dispatch(
          shoppingCartActions.updateLineItem(
            orderingCode,
            infoType,
            discountRate,
            unitQuotedPrice,
            isQuote,
            quantity,
            additionalInformation
          )
        );
      }
    },
    [dispatch, isQuote]
  );

  const shouldForceAnotherUserAsApprover = () => {
    return (
      (!companyApprovers || companyApprovers.length === 0) &&
      (quote?.currentUserCanBeQuoteApprover === false || quote === undefined)
    );
  };

  const isUploadingAttachmentsEnabled = useMemo((): boolean => {
    if (quote) {
      if (
        quote.stateId === QuoteStateTypes.WaitingForQuote &&
        (quote.quoteReceiverEmail === userInformation?.email ||
          currentUserIsQuoteRequester(quote, userInformation))
      ) {
        return true;
      }

      if (
        quote.stateId === QuoteStateTypes.QuoteWaitingForApproval &&
        (hasApproveQuoteRight || currentUserIsQuoteRequester(quote, userInformation))
      ) {
        return true;
      }

      if (
        quote.stateId === QuoteStateTypes.Quote &&
        currentUserIsQuoteRequester(quote, userInformation)
      ) {
        return true;
      }

      if (
        (quote.stateId === QuoteStateTypes.Quote ||
          quote.stateId === QuoteStateTypes.FrameAgreement ||
          quote.stateId === QuoteStateTypes.OrderPlaced ||
          quote.stateId === QuoteStateTypes.OrderPlacedExternally ||
          (quote.stateId === QuoteStateTypes.Rejected && quote.baseQuoteId)) &&
        quote.quotedById === userInformation?.id
      ) {
        return true;
      }
    }
    return false;
  }, [hasApproveQuoteRight, quote, userInformation]);

  const isRemovingAttachmentsEnabled = useMemo((): boolean => {
    if (quote) {
      if (
        quote.stateId === QuoteStateTypes.WaitingForQuote &&
        (quote.quoteReceiverEmail === userInformation?.email ||
          currentUserIsQuoteRequester(quote, userInformation))
      ) {
        return true;
      }

      if (
        quote.stateId === QuoteStateTypes.QuoteWaitingForApproval &&
        (hasApproveQuoteRight || currentUserIsQuoteRequester(quote, userInformation))
      ) {
        return true;
      }

      if (
        (quote.stateId === QuoteStateTypes.Quote ||
          quote.stateId === QuoteStateTypes.FrameAgreement ||
          quote.stateId === QuoteStateTypes.OrderPlaced ||
          quote.stateId === QuoteStateTypes.OrderPlacedExternally ||
          (quote.stateId === QuoteStateTypes.Rejected && quote.baseQuoteId)) &&
        quote.quotedById === userInformation?.id
      ) {
        return true;
      }
    }
    return false;
  }, [hasApproveQuoteRight, quote, userInformation]);

  const acknowledgementAndRejectionsRevisionsAllowed = useMemo((): boolean => {
    return canAcknowledgeAndRejectRevision(shoppingCart, userInformation, userAccessRights);
  }, [shoppingCart, userAccessRights, userInformation]);

  return (
    <AppContentWrapper showFooter isMainView pageTitle={t("Build quote")}>
      {quote && (
        <ButtonsPanelContainer>
          <EditButtonContainer>
            {showReviseButton(
              shoppingCart,
              quote,
              userInformation,
              userAccessRights,
              disableQuoting
            ) && (
              <>
                <Button
                  buttonType="secondary"
                  text={t("Edit")}
                  icon="abb/edit"
                  onClick={() => {
                    if (userInformation?.selectedCompanyId !== shoppingCart?.company?.id) {
                      setUpdateSelectedCompanyRedirect(true);
                    } else {
                      setIsReviseMode(true);
                    }
                  }}
                  disabled={isReviseMode}
                />
                {isReviseMode && (
                  <Button
                    style={{ marginLeft: theme.sizes.xs }}
                    buttonType="discreet"
                    text={t("Cancel")}
                    icon="abb/close"
                    onClick={() => {
                      setIsReviseMode(false);
                      if (quoteIdAsNumber) {
                        dispatch(
                          shoppingCartSagas.getQuoteById.createAction({
                            id: quoteIdAsNumber
                          })
                        );
                      }
                    }}
                  />
                )}
              </>
            )}
            {showRequestRevisionButton(
              shoppingCart,
              isReviseMode,
              userInformation,
              userAccessRights
            ) && (
              <>
                <Button
                  style={{ marginLeft: theme.sizes.xs }}
                  buttonType="secondary"
                  text={t("Request revision")}
                  icon="abb/edit"
                  onClick={() => {
                    setIsRequestRevisionDialogOpen(true);
                  }}
                />
              </>
            )}
            {quoteCanBeTransferredToMat(isASMOrProcurist, shoppingCart) &&
            shoppingCart?.quoteIdWaitingToBeProcessed === quote.id ? (
              <WithTooltip>
                <Button
                  text={t("Transfer request to MAT and create quote")}
                  buttonType="primary"
                  href={`${shoppingCart?.matBasePath}?quote=${shoppingCart?.quoteIdWaitingToBeProcessed}`}
                  target="_blank"
                  disabled={draftQuoteFiles.length > 0}
                  style={{ marginLeft: theme.sizes.xs }}
                />
                <Tooltip disabled={draftQuoteFiles.length === 0}>
                  {t("Please finish uploading attachments")}
                </Tooltip>
              </WithTooltip>
            ) : null}
          </EditButtonContainer>
          <PrintButtonContainer>
            <PrintButton
              t={t}
              componentsToPrint={[detailsRef, attachmentsRef, gridContainerRef]}
              documentTitle={`${t("Quote")}-${quote.reference}`}
              isPrintLoading={isPrintLoading}
              setIsPrintLoading={setIsPrintLoading}
              onBeforeGetContent={() => {
                setPreviousIsReadOnly(isReadOnly);
                setIsReadOnly(true);
              }}
              onAfterPrint={() => setIsReadOnly(previousIsReadOnly)}
              timeoutMilliseconds={2000}
              setDetailGridStyles
            />
          </PrintButtonContainer>
        </ButtonsPanelContainer>
      )}
      {shoppingCart ? (
        <Formik
          initialValues={
            isReviseMode
              ? initialReviseValues
              : mapShoppingCartToQuoteViewForm(shoppingCart, company)
          }
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          validateOnMount
          innerRef={(ref) => {
            if (ref) {
              formikRef.current = ref;
            }
          }}
        >
          {(formik) => (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px"
                }}
              >
                <div style={{ display: "contents" }} ref={detailsRef}>
                  <QuoteInformation
                    shoppingCart={shoppingCart}
                    t={t}
                    quote={quote ?? draftQuote}
                    selectedCompany={company}
                    formik={formik}
                    reviseMode={isReviseMode ? ReviseMode.Complete : ReviseMode.None}
                    isEditable={!quoteId}
                  />
                </div>
                <GridContainer ref={gridContainerRef}>
                  <CustomSectionHeader text={t("Line item details")}></CustomSectionHeader>
                  <LineItemDetailsGrid
                    isReadOnly={isReadOnly}
                    t={t}
                    shoppingCart={shoppingCart}
                    updateLineItem={updateLineItem}
                    removeLineItem={removeLineItem}
                    openEditDialogAndSetSelectedShoppingCartItem={
                      openEditDialogAndSetSelectedShoppingCartItem
                    }
                    isPrintLayout={isPrintLoading}
                    quoteReference={quote?.reference}
                    hidePrices={!shoppingCart?.managedPriceListId || userInformation?.demoMode}
                    isFrameAgreement={!!shoppingCart?.activeQuote?.frameAgreement}
                    reviseMode={isReviseMode}
                    companyIsInternalOrMaintained={
                      shoppingCart?.company?.isMaintainedCustomer === true ||
                      shoppingCart?.company?.isInternal === true
                    }
                    quoteId={quote?.id}
                  />
                </GridContainer>
                <GridContainer ref={attachmentsRef}>
                  <QuoteAttachmentsUploader
                    isUploadingEnabled={isUploadingAttachmentsEnabled}
                    isRemovingEnabled={isRemovingAttachmentsEnabled}
                    isPrinting={isPrintLoading}
                    isRequestQuotationDialogMode={false}
                    reviseMode={isReviseMode}
                  />
                </GridContainer>
                {isApprovalDialogOpen ? (
                  <ApprovalDialog
                    submitForm={(approverEmail, onlyAddedApprover, sendQuoteWithoutApproval) => {
                      if (shoppingCart) {
                        requestQuoteApproval(
                          approverEmail,
                          onlyAddedApprover,
                          formik.values,
                          sendQuoteWithoutApproval
                        );
                      }
                    }}
                    isOpen={isApprovalDialogOpen}
                    closeDialog={() => setIsApprovalDialogOpen(false)}
                    t={t}
                    isLoading={
                      approveOrderOrQuoteStatus === RequestStatus.Pending ||
                      rejectApprovalStatus === RequestStatus.Pending ||
                      sendQuoteWithoutApprovalRequestState?.status === RequestStatus.Pending ||
                      requestQuoteApprovalRequestState?.status === RequestStatus.Pending ||
                      quoteRevisionRequestState?.status === RequestStatus.Pending ||
                      uploadQuoteAttachmentsStatus === RequestStatus.Pending
                    }
                    forceApproval={shouldForceAnotherUserAsApprover()}
                    invalidApproverEmails={
                      shouldForceAnotherUserAsApprover() ? [userInformation?.email ?? ""] : []
                    }
                    text={
                      companyApprovers?.length
                        ? {
                            title: "Confirm approver",
                            radioGroupTitle: "Approval will be sent to",
                            firstOptionLabel: "Default",
                            secondOptionLabel: "Other",
                            inputDescription: "Add a new approver",
                            textDescription: getEmailsList(),
                            buttonName1: "Request approval",
                            buttonName2: "Request approval",
                            isReversed: true,
                            checkboxLabel: "Also send to default approver(s)"
                          }
                        : {
                            title: "No quote approver found in company",
                            radioGroupTitle: "Is approval required for this quote?",
                            inputDescription: shouldForceAnotherUserAsApprover()
                              ? "Define another user as quote approver"
                              : "Add an approver for quote",
                            textDescription: "You can proceed to place the quote",
                            buttonName1: "Request approval",
                            buttonName2: isReviseMode ? "Create revision" : "Send the quote"
                          }
                    }
                  />
                ) : null}
                {isRejectQuoteDialogOpen && shoppingCart && (
                  <RejectQuoteDialog
                    t={t}
                    setIsOpen={setIsRejectQuoteDialogOpen}
                    isOpen={isRejectQuoteDialogOpen}
                    shoppingCartGuid={shoppingCart.guid}
                    isFrameAgreement={shoppingCart?.isBasedOnFrameAgreement}
                  />
                )}
                {isProductDialogOpen && (
                  <AddProductDialog
                    t={t}
                    isOpen={isProductDialogOpen}
                    setIsOpen={setIsProductDialogOpen}
                    shoppingCart={shoppingCart}
                    dispatch={dispatch}
                    hasUser={!!userInformation}
                    reviseMode={isReviseMode}
                    isQuoteView={true}
                  />
                )}
                {isEditDialogOpen && (
                  <EditProductDialog
                    selectedShoppingCartItem={selectedShoppingCartItem}
                    dispatch={dispatch}
                    t={t}
                    isOpen={isEditDialogOpen}
                    setIsOpen={setIsEditDialogOpen}
                    reviseMode={isReviseMode}
                    isQuoteView={true}
                  />
                )}
                {updateSelectedCompanyRedirect ? (
                  <RedirectDialog
                    showDialog={true}
                    text={t(
                      "Your active company will be changed automatically to shopping cart's company in order to create a revision.\n\n"
                    )}
                    t={t}
                    redirectCallback={() => {
                      dispatch(
                        userSagas.updateUserSelectedCompany.createAction({
                          updateUserSelectedCompanyCommand: {
                            selectedCompanyId: shoppingCart?.company?.id
                          }
                        })
                      );
                      setUpdateSelectedCompanyRedirect(false);
                    }}
                    countdownFrom={5}
                    icon={"abb/warning-circle-1"}
                    title={t("Changing selected company...")}
                    hideBackground={false}
                  />
                ) : undefined}
                {isReviseQuotationDialogOpen && userInformation?.selectedCompany ? (
                  <ReviseQuotationDialog
                    isOpen={isReviseQuotationDialogOpen}
                    setIsOpen={setIsReviseQuotationDialogOpen}
                    shoppingCart={shoppingCart}
                    isLoading={
                      quoteRevisionRequestState?.status === RequestStatus.Pending ||
                      uploadQuoteAttachmentsStatus === RequestStatus.Pending
                    }
                    t={t}
                    salesChannels={salesChannels}
                    createQuoteRevision={createQuoteRevision}
                    pgCodes={pgCodes}
                    formikRevise={formik}
                    selectedCompany={userInformation?.selectedCompany}
                    reviseMode={ReviseMode.None}
                  />
                ) : null}
                {isRequestRevisionDialogOpen && userInformation?.selectedCompany ? (
                  <RequestRevisionDialog
                    isOpen={isRequestRevisionDialogOpen}
                    setIsOpen={setIsRequestRevisionDialogOpen}
                    isLoading={requestQuoteRevisionRequestState.status === RequestStatus.Pending}
                    t={t}
                    requestQuoteRevision={requestQuoteRevision}
                    onlyDateRevision={false}
                    validityDate={quote?.tenderValidityDate}
                  />
                ) : null}
              </div>
              <BottomSectionQuote
                t={t}
                shoppingCartDto={quote?.shoppingCart}
                isTertiaryButtonVisible={isTertiaryButtonVisible()}
                tertiaryButtonText={getTertiaryText()}
                tertiaryButtonAction={() => tertiaryAction(formik.values)}
                isTertiaryButtonDisabled={disableQuoting}
                isSecondaryButtonVisible={isSecondaryButtonVisible()}
                secondaryButtonText={getSecondaryText()}
                secondaryButtonAction={secondaryAction}
                isSecondaryButtonDisabled={disableQuoting || isReviseMode}
                isPrimaryButtonVisible={isPrimaryButtonVisible()}
                isPrimaryButtonDisabled={
                  isFieldEmpty ||
                  (!quote && !formik.isValid) ||
                  disableQuoting ||
                  (draftQuoteFiles.length > 0 && !isReviseMode)
                }
                primaryButtonText={getPrimaryText()}
                primaryButtonAction={primaryAction}
                isPrimaryButtonLoading={
                  approveOrderOrQuoteStatus === RequestStatus.Pending ||
                  uploadQuoteAttachmentsStatus === RequestStatus.Pending ||
                  deleteQuoteAttachmentStatus === RequestStatus.Pending ||
                  acknowledgeRevisionState?.status === RequestStatus.Pending ||
                  quoteRevisionRequestState?.status === RequestStatus.Pending ||
                  saveMessageToRequestorStatus === RequestStatus.Pending ||
                  addAdditionalQuoteRequestersStatus === RequestStatus.Pending
                }
                isToOrderButtonVisible={isToOrderButtonVisible()}
                isToOrderButtonDisabled={isQuoteExpired(shoppingCart)}
                isToOrderButtonText={t("Proceed to order details")}
                toOrderButtonAction={() => {
                  if (shoppingCart) {
                    dispatch(shoppingCartActions.setRedirectToOrderDetails(true));
                    navigate(routes.shoppingCart + "/" + shoppingCart.guid);
                  }
                }}
                statusBanner={
                  <QuoteStatusBanner
                    t={t}
                    quote={quote}
                    shoppingCart={shoppingCart}
                    dispatch={dispatch}
                  />
                }
                setIsProductDialogOpen={setIsProductDialogOpen}
                reviseMode={isReviseMode}
                isTotalSalesPriceVisible={shoppingCart?.company?.isInternalBuyer ?? false}
              />
            </>
          )}
        </Formik>
      ) : null}
    </AppContentWrapper>
  );
};

export default QuoteView;

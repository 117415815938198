import React, { useState } from "react";
import { TFunction } from "i18next";
import { Dropdown, DropdownOption, Icon, Input } from "@abb/abb-common-ux-react";
import { SmallHeader } from "../../../../framework/components/styled/SmallHeader";
import { CompanyDto } from "../../../../api/models/CompanyDto";
import styled, { useTheme } from "styled-components";
import { AddressInfo } from "../AddressInfo";
import { InformationFormDialog, InformationFormKeys } from "./InformationFormDialog";
import { DetailsRadioGroup } from "./DetailsRadioGroup";
import {
  CountryDto,
  PreviousOrderSuggestion,
  SalesChannelDto,
  ShoppingCartAttachmentTypes
} from "api";
import { LabelWithText } from "../../../../framework/components/LabelWithText";
import { ShoppingCartForm } from "applications/shoppingCart/containers/ShoppingCartView";
import { ContentContainer } from "./ContentContainer";
import { handleFormikValueChange, setNestedTouched } from "utilities/formikUtils";
import { AddressContainer } from "./AddressContainer";
import { DropZone } from "../../../../framework/components/DropZone";
import { ErrorMessage, FormikProps } from "formik";
import { Error } from "framework/components/Error";
import { InputLabel } from "framework/components/InputLabel";
import { commonUXTheme } from "styles/commonUXVariables";
import { IconWithTooltip } from "framework/components/IconWithTooltip";

const FormRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
`;

const CustomSmallHeader = styled(SmallHeader)`
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: ${(props) => props.theme.sizes.xs};
  }
  p {
    font-family: ${(props) => props.theme.fonts.families.fontAbbLight};
  }
`;

const CustomDropdown = styled(Dropdown)`
  .ABB_CommonUX_Dropdown__comp {
    & [class$="-menu"] {
      z-index: ${(props) => props.theme.zIndices.regularDropdown};
    }
  }
`;

interface OrderDetailsProps {
  t: TFunction;
  selectedCompany: CompanyDto | undefined;
  shoppingCartGuid: string | undefined;
  isReadOnly: boolean;
  countries: CountryDto[] | undefined;
  formik: FormikProps<ShoppingCartForm>;
  uploadAttachment: (uploadedFile: Blob, attachmentType: ShoppingCartAttachmentTypes) => void;
  deleteAttachment: (attachmentIdentifier: string) => void;
  salesChannels: SalesChannelDto[] | undefined;
  pgCodes: string[] | undefined;
  isQuote: boolean;
  suggestions?: PreviousOrderSuggestion[] | undefined | null;
  onSetSuggestion?: (suggestionId: number) => void;
}

const endCustomerRequiredFields: InformationFormKeys[] = [
  "name",
  "country",
  "city",
  "streetAddress"
];

const accountCustomerRequiredFields: InformationFormKeys[] = [
  "name",
  "country",
  "city",
  "contactPerson",
  "contactPersonEmail",
  "contactPerson",
  "streetAddress",
  "contactPersonTelephone"
];

export const OrderDetails = ({
  t,
  selectedCompany,
  salesChannels,
  isReadOnly,
  countries,
  formik,
  uploadAttachment,
  deleteAttachment,
  pgCodes,
  isQuote,
  suggestions,
  onSetSuggestion
}: OrderDetailsProps): JSX.Element => {
  const [isEndCustomerDialogOpen, setIsEndCustomerDialogOpen] = useState(false);
  const [isAccountCustomerDialogOpen, setIsAccountCustomerDialogOpen] = useState(false);
  const theme = useTheme();

  return (
    <ContentContainer title={t("Order details")}>
      <InformationFormDialog
        t={t}
        isOpen={isEndCustomerDialogOpen}
        onDialogClose={() => {
          setNestedTouched(formik, "endCustomer");
          setIsEndCustomerDialogOpen(false);
        }}
        title={t("Add end customer information")}
        values={formik.values.endCustomer}
        countries={countries}
        suggestions={suggestions}
        onSetSuggestion={(suggestionId: number) => {
          if (onSetSuggestion) {
            onSetSuggestion(suggestionId);
          }
        }}
        formik={formik}
        parentObjName={"endCustomer"}
        requiredFields={endCustomerRequiredFields}
      />
      <InformationFormDialog
        t={t}
        isOpen={isAccountCustomerDialogOpen}
        onDialogClose={() => {
          setNestedTouched(formik, "accountCustomer");
          setIsAccountCustomerDialogOpen(false);
        }}
        title={t("Add account customer information")}
        values={formik.values.accountCustomer}
        countries={countries}
        formik={formik}
        parentObjName={"accountCustomer"}
        requiredFields={accountCustomerRequiredFields}
      />
      <FormRowsWrapper>
        {isReadOnly ? (
          <LabelWithText text={formik.values.projectName} label={t("Project name")} />
        ) : (
          <Input
            inputAttributes={{
              name: "projectName",
              onChange: formik.handleChange
            }}
            label={t("Project name")}
            dataType="text"
            value={formik.values.projectName}
            validationResult={{
              valid: formik.errors.projectName && formik.touched.projectName ? false : true,
              text: formik.errors.projectName
            }}
            instantValidation={true}
            showValidationIconWhenInvalid={false}
            showValidationBarWhenInvalid={true}
            onLostFocus={formik.handleBlur}
          />
        )}
        {isReadOnly ? (
          <>
            <LabelWithText
              text={formik.values.customerReference}
              label={t("Purchase order reference")}
            />
            <LabelWithText
              text={formik.values.endCustomerReference || "-"}
              label={t("End customer order reference")}
            />
          </>
        ) : (
          <>
            <InputLabel label={t("Purchase order reference")} isRequired />
            <Input
              dataType="text"
              value={formik.values.customerReference}
              inputAttributes={{
                name: "customerReference",
                onChange: formik.handleChange
              }}
              validationResult={{
                valid:
                  formik.errors.customerReference && formik.touched.customerReference
                    ? false
                    : true,
                text: formik.errors.customerReference
              }}
              instantValidation={true}
              showValidationIconWhenInvalid={false}
              showValidationBarWhenInvalid={true}
              onLostFocus={formik.handleBlur}
            />
            <InputLabel label={t("End customer order reference")} />
            <Input
              dataType="text"
              value={formik.values.endCustomerReference}
              inputAttributes={{
                name: "endCustomerReference",
                onChange: formik.handleChange
              }}
              validationResult={{
                valid:
                  formik.errors.endCustomerReference && formik.touched.endCustomerReference
                    ? false
                    : true,
                text: formik.errors.endCustomerReference
              }}
              instantValidation={true}
              showValidationIconWhenInvalid={false}
              showValidationBarWhenInvalid={true}
              onLostFocus={formik.handleBlur}
            />
          </>
        )}
        {selectedCompany?.isInternal ? (
          <>
            {isReadOnly ? (
              <LabelWithText
                text={formik.values.salesChannel?.description}
                label={t("Sales channel")}
              />
            ) : (
              <div>
                <InputLabel label={t("Sales channel")} isRequired />
                <Dropdown
                  value={[
                    {
                      label: formik.values.salesChannel?.description ?? "",
                      value: formik.values.salesChannel?.id
                    }
                  ]}
                  monochrome
                  onChange={(v) => {
                    handleFormikValueChange(formik, "salesChannel.id", v[0].value, true);
                    handleFormikValueChange(formik, "salesChannel.description", v[0].label, true);
                  }}
                  validationState={{
                    valid:
                      !!formik.errors.salesChannel && !!formik.touched.salesChannel ? false : true
                  }}
                  showValidationIconWhenInvalid={false}
                  showValidationBarWhenInvalid={true}
                >
                  {salesChannels?.map((c) => (
                    <DropdownOption key={c.id} value={c.id} label={c.description ?? ""} />
                  ))}
                </Dropdown>
              </div>
            )}
          </>
        ) : null}
      </FormRowsWrapper>
      {selectedCompany?.isInternal || selectedCompany ? (
        <div>
          {isReadOnly ? (
            <LabelWithText
              text={formik.values.hasRiskReviewBeenDone ? t("Yes") : t("No")}
              label={t("Has risk review been done?")}
            />
          ) : (
            <>
              <DetailsRadioGroup
                value={formik.values.hasRiskReviewBeenDone?.toString()}
                t={t}
                setValue={(v) => {
                  handleFormikValueChange(formik, "hasRiskReviewBeenDone", v === "true");
                }}
                title={"Has risk review been done?"}
                isRequired
              />
              <ErrorMessage name={"hasRiskReviewBeenDone"}>
                {(err) => <Error msg={err} />}
              </ErrorMessage>
            </>
          )}
          {formik.values.hasRiskReviewBeenDone ? (
            <div style={{ marginTop: theme.sizes.m }}>
              {isReadOnly ? (
                <>
                  {formik.values.riskReviews.map((x) => (
                    <Text key={x.name}>{x.name}</Text>
                  ))}
                </>
              ) : (
                <>
                  <DropZone
                    t={t}
                    acceptedFileTypes={["application/pdf"]}
                    onDropSuccess={(file) =>
                      file.blob &&
                      uploadAttachment(file.blob, ShoppingCartAttachmentTypes.RiskReview)
                    }
                    onClickDelete={(file) => deleteAttachment(file.identifier)}
                    files={formik.values.riskReviews.map((f) => ({
                      identifier: f.identifier,
                      name: f.name
                    }))}
                  />
                  <ErrorMessage name={"riskReviews"}>
                    {() => <Error msg={t("At least 1 risk review file is required")} />}
                  </ErrorMessage>
                </>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <CustomSmallHeader text={t("Buyer")} />
      {selectedCompany ? (
        <AddressInfo
          name={selectedCompany.name + " (" + selectedCompany.id + ")"}
          postalCode={selectedCompany.addressPostalCode}
          streetAddress={selectedCompany.addressStreet}
          city={selectedCompany.addressCity}
          country={selectedCompany.countryName}
        />
      ) : (
        <div style={{ width: "fit-content" }}>
          <ErrorWrapper>
            <Icon
              name="abb/error-circle-2"
              sizeClass="large"
              color={commonUXTheme.colors.statusRed}
            />
            <p>
              <b>{t("Company selection is required")}</b>
            </p>
            <IconWithTooltip
              icon="abb/information-circle-1"
              sizeClass="medium"
              color={commonUXTheme.colors.brandBlack}
              style={{ marginTop: 0, marginLeft: commonUXTheme.sizes.s }}
              tooltipText={t("Select a company from the menu bar at the top of the page")}
            />
          </ErrorWrapper>
        </div>
      )}
      {selectedCompany?.isInternal ? (
        <>
          {isQuote || isReadOnly ? (
            <LabelWithText label={t("PG code")} text={formik.values.pgCode} />
          ) : (
            <>
              {pgCodes && pgCodes?.length > 0 ? (
                <div>
                  <InputLabel label={t("PG code")} isRequired />
                  <CustomDropdown
                    monochrome
                    value={[{ label: formik.values.pgCode ?? "", value: formik.values.pgCode }]}
                    onChange={(v) => handleFormikValueChange(formik, "pgCode", v[0].value, true)}
                    onClose={() => formik.setFieldTouched("pgCode", true, true)}
                    validationState={{
                      valid: !!formik.errors.pgCode && !!formik.touched.pgCode ? false : true,
                      message: formik.errors.pgCode
                    }}
                    showValidationBarWhenInvalid
                  >
                    {pgCodes.map((x) => (
                      <DropdownOption key={x} value={x} label={x} />
                    ))}
                  </CustomDropdown>
                </div>
              ) : (
                <div>
                  <InputLabel label={t("PG code")} isRequired />
                  <Input
                    value={formik.values.pgCode}
                    dataType={"text"}
                    validationResult={{
                      valid: !!formik.errors.pgCode && !!formik.touched.pgCode ? false : true,
                      text: formik.errors.pgCode
                    }}
                    showValidationBarWhenInvalid
                    showValidationIconWhenInvalid
                    inputAttributes={{
                      name: "pgCode",
                      onChange: formik.handleChange
                    }}
                    onLostFocus={formik.handleBlur}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : null}
      {selectedCompany?.isInternal ? (
        <>
          <CustomSmallHeader text={t("Account customer")} />{" "}
          <AddressContainer
            t={t}
            values={formik.values.accountCustomer}
            objKeyName={"accountCustomer"}
            isReadOnly={isReadOnly}
            buttonText={t("Add account customer")}
            setIsDialogOpen={() => setIsAccountCustomerDialogOpen(true)}
          />
        </>
      ) : null}
      {isReadOnly ? null : (
        <>
          <DetailsRadioGroup
            t={t}
            isRequired
            title={
              selectedCompany?.isInternal
                ? t("Is account customer the end customer?")
                : t("Are you the end customer?")
            }
            value={formik.values.isEndCustomer?.toString()}
            setValue={(v) => handleFormikValueChange(formik, "isEndCustomer", v === "true")}
          />
          <ErrorMessage name={"isEndCustomer"}>{(err) => <Error msg={err} />}</ErrorMessage>
        </>
      )}
      {formik.values.isEndCustomer === false ? (
        <>
          <CustomSmallHeader text={t("End customer")} />
          <AddressContainer
            t={t}
            values={formik.values.endCustomer}
            objKeyName={"endCustomer"}
            isReadOnly={isReadOnly}
            buttonText={t("Add end customer")}
            setIsDialogOpen={() => setIsEndCustomerDialogOpen(true)}
          />
        </>
      ) : null}
    </ContentContainer>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FirmwareDetailsDto
 */
export interface FirmwareDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    hashAlgorithm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    hashValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    sourceUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FirmwareDetailsDto
     */
    firmwareId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    productType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FirmwareDetailsDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FirmwareDetailsDto
     */
    releaseDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FirmwareDetailsDto
     */
    serialNumbers?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FirmwareDetailsDto
     */
    orderingCodesWithSerialNumbers?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FirmwareDetailsDto
     */
    latestForCodes?: Array<string> | null;
}

export function FirmwareDetailsDtoFromJSON(json: any): FirmwareDetailsDto {
    return FirmwareDetailsDtoFromJSONTyped(json, false);
}

export function FirmwareDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmwareDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hashAlgorithm': !exists(json, 'hashAlgorithm') ? undefined : json['hashAlgorithm'],
        'hashValue': !exists(json, 'hashValue') ? undefined : json['hashValue'],
        'sourceUrl': !exists(json, 'sourceUrl') ? undefined : json['sourceUrl'],
        'firmwareId': !exists(json, 'firmwareId') ? undefined : json['firmwareId'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'releaseDate': !exists(json, 'releaseDate') ? undefined : (new Date(json['releaseDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
        'orderingCodesWithSerialNumbers': !exists(json, 'orderingCodesWithSerialNumbers') ? undefined : json['orderingCodesWithSerialNumbers'],
        'latestForCodes': !exists(json, 'latestForCodes') ? undefined : json['latestForCodes'],
    };
}

export function FirmwareDetailsDtoToJSON(value?: FirmwareDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hashAlgorithm': value.hashAlgorithm,
        'hashValue': value.hashValue,
        'sourceUrl': value.sourceUrl,
        'firmwareId': value.firmwareId,
        'productType': value.productType,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate.toISOString()),
        'description': value.description,
        'fileName': value.fileName,
        'serialNumbers': value.serialNumbers,
        'orderingCodesWithSerialNumbers': value.orderingCodesWithSerialNumbers,
        'latestForCodes': value.latestForCodes,
    };
}


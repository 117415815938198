/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface TeamInvitationDto
 */
export interface TeamInvitationDto {
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {TeamDto}
     * @memberof TeamInvitationDto
     */
    team?: TeamDto;
    /**
     * 
     * @type {UserDto}
     * @memberof TeamInvitationDto
     */
    user?: UserDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamInvitationDto
     */
    roleAbbreviations?: Array<string> | null;
}

export function TeamInvitationDtoFromJSON(json: any): TeamInvitationDto {
    return TeamInvitationDtoFromJSONTyped(json, false);
}

export function TeamInvitationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamInvitationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'team': !exists(json, 'team') ? undefined : TeamDtoFromJSON(json['team']),
        'user': !exists(json, 'user') ? undefined : UserDtoFromJSON(json['user']),
        'roleAbbreviations': !exists(json, 'roleAbbreviations') ? undefined : json['roleAbbreviations'],
    };
}

export function TeamInvitationDtoToJSON(value?: TeamInvitationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'isActive': value.isActive,
        'team': TeamDtoToJSON(value.team),
        'user': UserDtoToJSON(value.user),
        'roleAbbreviations': value.roleAbbreviations,
    };
}


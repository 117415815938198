/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptInvitationCommand,
    AcceptInvitationCommandFromJSON,
    AcceptInvitationCommandToJSON,
    ChangeMemberActivityCommand,
    ChangeMemberActivityCommandFromJSON,
    ChangeMemberActivityCommandToJSON,
    ChangeMemberRoleCommand,
    ChangeMemberRoleCommandFromJSON,
    ChangeMemberRoleCommandToJSON,
    ChangeMemberStatusCommand,
    ChangeMemberStatusCommandFromJSON,
    ChangeMemberStatusCommandToJSON,
    ChangeMemberTeamInUseCommand,
    ChangeMemberTeamInUseCommandFromJSON,
    ChangeMemberTeamInUseCommandToJSON,
    CreateTeamCommand,
    CreateTeamCommandFromJSON,
    CreateTeamCommandToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    InviteUserToTeamCommand,
    InviteUserToTeamCommandFromJSON,
    InviteUserToTeamCommandToJSON,
    RequestTeamAccessCommand,
    RequestTeamAccessCommandFromJSON,
    RequestTeamAccessCommandToJSON,
    TeamAccessRequestDto,
    TeamAccessRequestDtoFromJSON,
    TeamAccessRequestDtoToJSON,
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoToJSON,
    TeamInvitationDto,
    TeamInvitationDtoFromJSON,
    TeamInvitationDtoToJSON,
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoToJSON,
    TeamMembersVm,
    TeamMembersVmFromJSON,
    TeamMembersVmToJSON,
    TeamTypes,
    TeamTypesFromJSON,
    TeamTypesToJSON,
    TeamsVm,
    TeamsVmFromJSON,
    TeamsVmToJSON,
    UserTeamDto,
    UserTeamDtoFromJSON,
    UserTeamDtoToJSON,
    UserTeamRolesDto,
    UserTeamRolesDtoFromJSON,
    UserTeamRolesDtoToJSON,
} from '../models';

export interface ApiTeamsChangeMemberActivityPutRequest {
    changeMemberActivityCommand?: ChangeMemberActivityCommand;
}

export interface ApiTeamsChangeMemberRolePutRequest {
    changeMemberRoleCommand?: ChangeMemberRoleCommand;
}

export interface ApiTeamsChangeMemberStatusPutRequest {
    changeMemberStatusCommand?: ChangeMemberStatusCommand;
}

export interface ApiTeamsChangeMemberTeamInUsePutRequest {
    changeMemberTeamInUseCommand?: ChangeMemberTeamInUseCommand;
}

export interface ApiTeamsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaName?: string;
    criteriaDescription?: string;
    criteriaTypes?: Array<TeamTypes>;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiTeamsIdGetRequest {
    id: number;
}

export interface ApiTeamsMembersAcceptTeamInvitationPostRequest {
    acceptInvitationCommand?: AcceptInvitationCommand;
}

export interface ApiTeamsMembersGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaTeamId?: number;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiTeamsMembersInvitePostRequest {
    inviteUserToTeamCommand?: InviteUserToTeamCommand;
}

export interface ApiTeamsMembersRequestTeamAccessPostRequest {
    requestTeamAccessCommand?: RequestTeamAccessCommand;
}

export interface ApiTeamsMembersRolesUserIdGetRequest {
    userId: number;
}

export interface ApiTeamsPostRequest {
    createTeamCommand?: CreateTeamCommand;
}

export interface ApiTeamsUserTeamsGetRequest {
    criteriaUserId?: number;
}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     */
    async apiTeamsChangeMemberActivityPutRaw(requestParameters: ApiTeamsChangeMemberActivityPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberActivity`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberActivityCommandToJSON(requestParameters.changeMemberActivityCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberActivityPut(requestParameters: ApiTeamsChangeMemberActivityPutRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberActivityPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberRolePutRaw(requestParameters: ApiTeamsChangeMemberRolePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberRole`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberRoleCommandToJSON(requestParameters.changeMemberRoleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberRolePut(requestParameters: ApiTeamsChangeMemberRolePutRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberRolePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberStatusPutRaw(requestParameters: ApiTeamsChangeMemberStatusPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberStatus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberStatusCommandToJSON(requestParameters.changeMemberStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberStatusPut(requestParameters: ApiTeamsChangeMemberStatusPutRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberStatusPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberTeamInUsePutRaw(requestParameters: ApiTeamsChangeMemberTeamInUsePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberTeamInUse`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberTeamInUseCommandToJSON(requestParameters.changeMemberTeamInUseCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberTeamInUsePut(requestParameters: ApiTeamsChangeMemberTeamInUsePutRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberTeamInUsePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsGetRaw(requestParameters: ApiTeamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiTeamsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiTeamsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaName !== undefined) {
            queryParameters['Criteria.Name'] = requestParameters.criteriaName;
        }

        if (requestParameters.criteriaDescription !== undefined) {
            queryParameters['Criteria.Description'] = requestParameters.criteriaDescription;
        }

        if (requestParameters.criteriaTypes) {
            queryParameters['Criteria.Types'] = requestParameters.criteriaTypes;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsGet(requestParameters: ApiTeamsGetRequest, initOverrides?: RequestInit): Promise<TeamsVm> {
        const response = await this.apiTeamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsIdGetRaw(requestParameters: ApiTeamsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTeamsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsIdGet(requestParameters: ApiTeamsIdGetRequest, initOverrides?: RequestInit): Promise<TeamDto> {
        const response = await this.apiTeamsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersAcceptTeamInvitationPostRaw(requestParameters: ApiTeamsMembersAcceptTeamInvitationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members/AcceptTeamInvitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInvitationCommandToJSON(requestParameters.acceptInvitationCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsMembersAcceptTeamInvitationPost(requestParameters: ApiTeamsMembersAcceptTeamInvitationPostRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsMembersAcceptTeamInvitationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersGetRaw(requestParameters: ApiTeamsMembersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMembersVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiTeamsMembersGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiTeamsMembersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaTeamId !== undefined) {
            queryParameters['Criteria.TeamId'] = requestParameters.criteriaTeamId;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMembersVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsMembersGet(requestParameters: ApiTeamsMembersGetRequest, initOverrides?: RequestInit): Promise<TeamMembersVm> {
        const response = await this.apiTeamsMembersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersInvitePostRaw(requestParameters: ApiTeamsMembersInvitePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamInvitationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members/Invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteUserToTeamCommandToJSON(requestParameters.inviteUserToTeamCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsMembersInvitePost(requestParameters: ApiTeamsMembersInvitePostRequest, initOverrides?: RequestInit): Promise<TeamInvitationDto> {
        const response = await this.apiTeamsMembersInvitePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersRequestTeamAccessPostRaw(requestParameters: ApiTeamsMembersRequestTeamAccessPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamAccessRequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members/RequestTeamAccess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestTeamAccessCommandToJSON(requestParameters.requestTeamAccessCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamAccessRequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsMembersRequestTeamAccessPost(requestParameters: ApiTeamsMembersRequestTeamAccessPostRequest, initOverrides?: RequestInit): Promise<TeamAccessRequestDto> {
        const response = await this.apiTeamsMembersRequestTeamAccessPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersRolesUserIdGetRaw(requestParameters: ApiTeamsMembersRolesUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserTeamRolesDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiTeamsMembersRolesUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members/Roles/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserTeamRolesDtoFromJSON));
    }

    /**
     */
    async apiTeamsMembersRolesUserIdGet(requestParameters: ApiTeamsMembersRolesUserIdGetRequest, initOverrides?: RequestInit): Promise<Array<UserTeamRolesDto>> {
        const response = await this.apiTeamsMembersRolesUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsPostRaw(requestParameters: ApiTeamsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTeamCommandToJSON(requestParameters.createTeamCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsPost(requestParameters: ApiTeamsPostRequest, initOverrides?: RequestInit): Promise<TeamDto> {
        const response = await this.apiTeamsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsUserTeamsGetRaw(requestParameters: ApiTeamsUserTeamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserTeamDto>>> {
        const queryParameters: any = {};

        if (requestParameters.criteriaUserId !== undefined) {
            queryParameters['Criteria.UserId'] = requestParameters.criteriaUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/UserTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserTeamDtoFromJSON));
    }

    /**
     */
    async apiTeamsUserTeamsGet(requestParameters: ApiTeamsUserTeamsGetRequest, initOverrides?: RequestInit): Promise<Array<UserTeamDto>> {
        const response = await this.apiTeamsUserTeamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

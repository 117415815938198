import { ArticleDtoFormatted, NodeDto, NodeFlattenedDto } from "api";

export const nodeAndSubnodesHitsCount = (node: NodeDto, hits: ArticleDtoFormatted[]): number => {
  return [node, ...getSubnodesRecursively(node)]
    .flatMap((x) => x.articlesId)
    .filter((id) => hits.some((hit) => hit.id == id)).length;
};

export const nodeIsRoot = (node: NodeDto): boolean =>
  (node.subNodes && node.subNodes?.length > 0) ?? false;

export const getParentNodesRecursively = (node: NodeDto, nodes: NodeDto[]): NodeDto[] => {
  const parentNodes: NodeDto[] = [];
  const iterate = (n: NodeDto) => {
    const parent = getParentNode(n, nodes);
    if (parent) {
      parentNodes.push(parent);
      iterate(parent);
    }
  };
  iterate(node);
  return parentNodes;
};

export const getNodeArticles = (
  node: NodeDto,
  articles: ArticleDtoFormatted[]
): ArticleDtoFormatted[] => {
  return articles.filter((x) => node.articlesId?.some((id) => id == x.id));
};

export const getSubnodesRecursively = (node: NodeDto): NodeDto[] => {
  const subNodes: NodeDto[] = [];
  const iterate = (n: NodeDto) => {
    n.subNodes?.forEach((subNode) => {
      subNodes.push(subNode);
      iterate(subNode);
    });
  };
  iterate(node);
  return subNodes;
};

export const getFlattenedSubnodes = (
  node: NodeFlattenedDto,
  nodes: NodeFlattenedDto[]
): NodeFlattenedDto[] => {
  const subNodes: NodeFlattenedDto[] = [];
  const iterate = (n: NodeFlattenedDto) => {
    n.subNodesId?.forEach((subNodeId) => {
      const subnode = nodes.find((x) => x.id == subNodeId);
      if (subnode) {
        subNodes.push(subnode);
        iterate(subnode);
      }
    });
  };
  iterate(node);
  return subNodes;
};

export const nodeHasHits = (node: NodeFlattenedDto, hits: ArticleDtoFormatted[]): boolean =>
  node.articlesId?.some((x) => hits.some((h) => x == h.id)) ?? false;

/**
 * Check if surrounding nodes have hits
 * 1. node has articles and hits -> true | node has articles and no hits -> false
 * 2. next has articles and hits -> true | next has articles and no hits -> false (loop all next nodes until value)
 * 3. previous has articles and hits -> true | previous has articles and no hits -> false (loop all previous nodes until value)
 * @returns
 */
export const adjacentNodesHaveHits = (
  node: NodeFlattenedDto,
  nodes: NodeFlattenedDto[],
  hits: ArticleDtoFormatted[]
): boolean => {
  if (nodeHasArticles(node) && nodeHasHits(node, hits)) return true;

  let value: undefined | boolean = undefined;

  const nextNodes = nodes.slice(nodes.findIndex((x) => x.id == node.id));
  if (nextNodes.length > 1) {
    nextNodes.slice(1).forEach((n) => {
      if (nodeHasArticles(n) && value === undefined) {
        value = nodeHasHits(n, hits);
      }
    });
  }

  const previousNodes = nodes.slice(0, nodes.findIndex((x) => x.id == node.id) + 1);
  if (previousNodes.length > 1) {
    previousNodes.forEach((n) => {
      if (nodeHasArticles(n) && value === undefined) {
        value = nodeHasHits(n, hits);
      }
    });
  }

  return value ?? false;
};

export const lastNodeHasArticles = (
  nodes: NodeFlattenedDto[],
  hits: ArticleDtoFormatted[]
): boolean => {
  if (nodes.length == 0 || hits.length == 0) return false;
  return nodes[nodes.length - 1].articlesId?.some((a) => hits.some((h) => h.id == a)) ?? false;
};

export const firstNodeHashits = (
  nodes: NodeFlattenedDto[],
  hits: ArticleDtoFormatted[]
): boolean => {
  if (nodes.length == 0 || hits.length == 0) return false;
  return nodes[0].articlesId?.some((a) => hits.some((h) => h.id == a)) ?? false;
};

export const lastNodeWithHits = (
  nodes: NodeFlattenedDto[],
  hits: ArticleDtoFormatted[]
): NodeFlattenedDto | undefined =>
  nodes.filter((x) => hits.some((h) => x.articlesId?.some((a) => a == h.id))).pop();

export const firstNodeWithHits = (
  nodes: NodeFlattenedDto[],
  hits: ArticleDtoFormatted[]
): NodeFlattenedDto | undefined =>
  nodes.filter((x) => hits.some((h) => x.articlesId?.some((a) => a == h.id))).shift();

export const firstNodeWithArticles = (nodes: NodeFlattenedDto[]): NodeFlattenedDto | undefined =>
  nodes.find((x) => x.articlesId && x.articlesId.length > 0);

export const nextNodesWithArticles = (
  node: NodeFlattenedDto,
  nodes: NodeFlattenedDto[],
  maxArticles: number
): NodeFlattenedDto[] => {
  const withArticles: NodeFlattenedDto[] = [];

  const nodeIndex = nodes.findIndex((x) => x.id == node.id);
  if (nodeIndex >= nodes.length - 1) return []; // Last node, no next nodes
  const nextNodes = nodes.slice(nodeIndex + 1);

  for (let i = 0; i < nextNodes.length; i++) {
    const nextNode = nextNodes[i];
    if (nodeHasArticles(nextNode)) withArticles.push(nextNode);
    if (withArticles.flatMap((x) => x.articlesId).length >= maxArticles) break;
  }
  return withArticles;
};

export const previousNodesWithArticles = (
  node: NodeFlattenedDto,
  nodes: NodeFlattenedDto[],
  maxArticles: number
): NodeFlattenedDto[] => {
  const withArticles: NodeFlattenedDto[] = [];

  const nodeIndex = nodes.findIndex((x) => x.id == node.id);
  if (nodeIndex == 0) return []; // First node, no previous nodes
  const nextNodes = nodes.slice(0, nodeIndex);

  for (let i = nextNodes.length - 1; i >= 0; i--) {
    const nextNode = nextNodes[i];
    if (nodeHasArticles(nextNode)) withArticles.push(nextNode);
    if (withArticles.flatMap((x) => x.articlesId).length >= maxArticles) break;
  }
  return withArticles;
};

export const getTerminologyBankArticleOrder = (
  roots: NodeDto[],
  a: ArticleDtoFormatted,
  b: ArticleDtoFormatted
) => {
  return (
    getNodeRootOrder(roots, a) - getNodeRootOrder(roots, b) ||
    (a.terminologyBankOrder ?? 0) - (b.terminologyBankOrder ?? 0)
  );
};

export const getTrainingArticleOrder = (
  roots: NodeDto[],
  a: ArticleDtoFormatted,
  b: ArticleDtoFormatted
) => {
  return (
    getNodeRootOrder(roots, a) - getNodeRootOrder(roots, b) ||
    (a.trainingOrder ?? 0) - (b.trainingOrder ?? 0)
  );
};

const getNodeRootOrder = (roots: NodeDto[], article: ArticleDtoFormatted) =>
  roots.find(
    (root) => root.subNodes?.some((subNode) => subNode.articlesId?.some((id) => id == article.id))
  )?.order ?? 0;

const getParentNode = (child: NodeDto, nodes: NodeDto[]): NodeDto | undefined => {
  return [...nodes.flatMap((x) => getSubnodesRecursively(x)), ...nodes].find(
    (x) => x.subNodes?.some((subNode) => subNode.id === child.id)
  );
};
const nodeHasArticles = (node: NodeDto): boolean => (node.articlesId ?? []).length > 0;

import React from "react";
import { Grid, createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { UserManagedPriceListDto, UserPriceListVm } from "api";
import { SectionHeader as SectionHeading } from "../../../../framework/components/styled/SectionHeader";
import { useTranslation } from "react-i18next";
import {
  GridToggleSwitchRenderer,
  GridToggleSwitchRendererProps
} from "framework/components/grid/GridToggleSwitchRenderer";

const SectionHeader = styled(SectionHeading)`
  margin-bottom: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

const GridContainer = styled.div<{ isAccessRequestView?: boolean }>`
  display: flex;
  height: ${(props) => (props.isAccessRequestView ? "calc(100% - 60px)" : "35rem")};
  width: 100%;
`;

const InfoText = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  margin-bottom: ${(props) => props.theme.sizes.l};
`;

interface UserCompanyAccessRightsGridProps {
  userPriceList: UserPriceListVm | undefined;
  updateUserDisabledManagedPriceList: (managedPriceListId: number) => void;
  isAccessRequestView?: boolean;
}

export const UserPriceListsGrid = ({
  userPriceList,
  updateUserDisabledManagedPriceList,
  isAccessRequestView
}: UserCompanyAccessRightsGridProps): JSX.Element => {
  const { t } = useTranslation();

  const colDefs: ColDef[] = [
    {
      ...createHeaderAndHeaderTooltip(t("Price List")),
      field: "displayName"
    },
    {
      ...createHeaderAndHeaderTooltip(t("Company")),
      field: "companyDisplayName"
    },
    {
      ...createHeaderAndHeaderTooltip(t("Country")),
      field: "countryName"
    },
    {
      ...createHeaderAndHeaderTooltip(t("Source")),
      field: "source"
    },
    {
      ...createHeaderAndHeaderTooltip(t("Disabled")),
      field: "isDisabled",
      maxWidth: 100,
      cellRenderer: GridToggleSwitchRenderer,
      cellRendererParams: (params: ICellRendererParams): GridToggleSwitchRendererProps => {
        const userManagedPriceList: UserManagedPriceListDto = params.data;
        return {
          ...params,
          onChange: () => updateUserDisabledManagedPriceList(userManagedPriceList.id)
        };
      }
    }
  ];

  const defaultColDef: ColDef = {
    minWidth: 100,
    suppressSizeToFit: false,
    sortable: false
  };

  return (
    <Container>
      <SectionHeader
        text={
          isAccessRequestView ? t("Price lists after access request approval") : t("Price lists")
        }
      />
      {userPriceList != null ? (
        !userPriceList.hasAccessToAllPriceLists ? (
          <GridContainer isAccessRequestView={isAccessRequestView}>
            <Grid
              t={t}
              rowData={userPriceList != null ? userPriceList.userPriceLists : []}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              columnDefs={colDefs}
              domLayout="normal"
              disableColumnAutoSize
              suppressCellFocus={true}
              colDefDefault={defaultColDef}
              autosizeCols
              disableResize
            />
          </GridContainer>
        ) : (
          <InfoText>{t("User has access to all price lists")}</InfoText>
        )
      ) : null}
    </Container>
  );
};

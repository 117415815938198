/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamAccessRequestTypes,
    TeamAccessRequestTypesFromJSON,
    TeamAccessRequestTypesFromJSONTyped,
    TeamAccessRequestTypesToJSON,
} from './TeamAccessRequestTypes';

/**
 * 
 * @export
 * @interface RequestTeamAccessCommand
 */
export interface RequestTeamAccessCommand {
    /**
     * 
     * @type {number}
     * @memberof RequestTeamAccessCommand
     */
    teamId: number;
    /**
     * 
     * @type {number}
     * @memberof RequestTeamAccessCommand
     */
    userId: number;
    /**
     * 
     * @type {TeamAccessRequestTypes}
     * @memberof RequestTeamAccessCommand
     */
    requestType: TeamAccessRequestTypes;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestTeamAccessCommand
     */
    userRoles: Array<number>;
}

export function RequestTeamAccessCommandFromJSON(json: any): RequestTeamAccessCommand {
    return RequestTeamAccessCommandFromJSONTyped(json, false);
}

export function RequestTeamAccessCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestTeamAccessCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamId': json['teamId'],
        'userId': json['userId'],
        'requestType': TeamAccessRequestTypesFromJSON(json['requestType']),
        'userRoles': json['userRoles'],
    };
}

export function RequestTeamAccessCommandToJSON(value?: RequestTeamAccessCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamId': value.teamId,
        'userId': value.userId,
        'requestType': TeamAccessRequestTypesToJSON(value.requestType),
        'userRoles': value.userRoles,
    };
}


export const getConfiguratorLink = (
  baseUrl: string | undefined,
  materialCode: string,
  isModularProduct: boolean,
  applicationName?: string
): string => {
  const isRex610 = materialCode.startsWith("REX610");

  if (!isModularProduct) {
    return `${baseUrl}/static-product?name=${materialCode}`;
  }

  if (applicationName == "Product modification" && isRex610) {
    return `${baseUrl}/static-product?name=REX610 accessories`;
  }

  return `${baseUrl}/product/${materialCode}`;
};

export const getConfiguratorModularProductLink = (
  baseUrl: string | undefined,
  materialCode: string,
  masks: string[] | null | undefined
): string => {
  const link = getConfiguratorLink(baseUrl, materialCode, true);
  const query = Array.isArray(masks) ? `?masks=${encodeURIComponent(masks.join(","))}` : "";
  return `${link}${query}`;
};

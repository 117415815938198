import React, { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { getValidDateFormat } from "../../../../utilities/dateUtils";
import { Button } from "framework/components/Button";
import { HorizontalElementContainer } from "../../../../framework/components/HorizontalElementContainer";
import { AuditType } from "../../../../enums/auditType";
import { useTranslation } from "react-i18next";
import { IconTextWrapper } from "./CompaniesGrid";
import { Icon } from "@abb/abb-common-ux-react";

interface AccessAuditFormProps {
  auditCompleted: Date | null | undefined;
  nextAuditDue: Date | null | undefined;
  hasLateAccessAudit?: boolean | undefined;
  isLoading: boolean;
  auditType: AuditType;
  warningText?: string;
  onMarkAccessAuditDone: () => void;
}

const LabelValue = styled.dl`
  dt {
    color: ${(props) => props.theme.colors.grey60};
    font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  }
  dd {
    margin-left: 0px;
    color: ${(props) => props.theme.colors.grey90};
    font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  }
`;

const WarningText = styled.div`
  color: ${(props) => props.theme.colors.statusRed};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface LabelValuePairProps {
  valuePairs: { label: string; value: string }[];
  isWarningSet?: boolean;
}

const LabelValuePairList: React.FunctionComponent<LabelValuePairProps> = (props) => {
  return (
    <LabelValue>
      {props.valuePairs.map((valuePair) => {
        return (
          <Fragment key={"fragment_" + valuePair.label}>
            <dt>{valuePair.label}</dt>
            {props.isWarningSet ? (
              <IconTextWrapper>
                <Icon name="abb/warning-circle-1" sizeClass="small" />
                <dd>{valuePair.value}</dd>
              </IconTextWrapper>
            ) : (
              <dd>{valuePair.value}</dd>
            )}
          </Fragment>
        );
      })}
    </LabelValue>
  );
};

export const AuditForm: React.FunctionComponent<AccessAuditFormProps> = (props) => {
  const { t } = useTranslation();

  const getButtonText = () => {
    switch (props.auditType) {
      case AuditType.CompanyAccessAudit:
        return t("Mark access audit done");
      case AuditType.OverrideStatusAudit:
        return t("Mark override status audit done");
    }
  };
  return (
    <VerticalContainer>
      {props.warningText ? <WarningText>{props.warningText}</WarningText> : null}
      <HorizontalElementContainer marginSizeBetweenElements={"lm"}>
        <LabelValuePairList
          valuePairs={[
            {
              label: t("Last audit completed"),
              value: props.auditCompleted
                ? moment(props.auditCompleted).format(getValidDateFormat())
                : "-"
            }
          ]}
        />
        <LabelValuePairList
          valuePairs={[
            {
              label: t("Next audit deadline"),
              value: props.nextAuditDue
                ? moment(props.nextAuditDue).format(getValidDateFormat())
                : "-"
            }
          ]}
          isWarningSet={props.hasLateAccessAudit}
        />
        <Button
          buttonType={"primary"}
          sizeClass="medium"
          text={getButtonText()}
          isLoading={props.isLoading}
          onClick={props.onMarkAccessAuditDone}
        />
      </HorizontalElementContainer>
    </VerticalContainer>
  );
};

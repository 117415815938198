import React, { useState } from "react";
import useEventListener from "./useEventListener";

const getHeight = (
  e: any,
  isOverMaxHeight: boolean,
  setIsOverMaxHeight: React.Dispatch<React.SetStateAction<boolean>>,
  maxHeight: number
) => {
  if (e?.target?.innerHeight >= maxHeight && !isOverMaxHeight) {
    setIsOverMaxHeight(true);
  } else if (e?.target?.innerHeight < maxHeight && isOverMaxHeight) {
    setIsOverMaxHeight(false);
  }
};

export const useWindowIsMaxHeight = (maxHeight: number): boolean => {
  const [isOverMaxHeight, setIsOverMaxHeight] = useState(
    window.innerHeight > maxHeight ? true : false
  );

  useEventListener(window, "resize", (e) =>
    getHeight(e, isOverMaxHeight, setIsOverMaxHeight, maxHeight)
  );
  return isOverMaxHeight;
};

import {
  Icon,
  Input,
} from "@abb/abb-common-ux-react";
import { TeamDto, TeamTypes, RoleGroups } from "api";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { InputLabel } from "framework/components/InputLabel";
import { isTeamAdminRole } from "utilities/teamUtils";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { useDispatch, useSelector } from "react-redux";
import { getCreateTeamRequest, getInviteUserToTeamRequest } from "applications/manage/manageTeams/reducers/manageTeamsViewReducer";
import { AddTeamBaseDialog, AddTeamBaseDialogComponent } from "./AddTeamBaseDialog";
import { handlePressEnter } from "utilities/formikUtils";
import { UserRoles } from "./UserRoles";
import { RoleSelectionType } from "../containers/ManageUserView";
import { getSelectedUser } from "../reducers/manageUsersViewReducer";
import { Roles, useUserRoles } from "framework/hooks/useUserRoles";
import { usePrevious } from "framework/hooks/usePrevious";
import { RequestStatus } from "framework/state/requestStatus";

const CustomIcon = styled(Icon)`
  cursor: pointer;
  align-items: center;
  margin-left: ${(props) => props.theme.sizes.xs};
`;

export enum DialogStates {
  NewTeam,
  ExistingTeam,
}

interface CreateOrAddFunctionalTeamToUserDialogProps {
  dialogState: DialogStates;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  t: TFunction;
  selectedBusinessUnitTeam: TeamDto;
  teamName?: string;
  editTeamName?: ((teamName: string) => void);
  userId: number | undefined;
  team?: TeamDto;
  parentTeamId?: number | undefined;
  afterSave?: () => void;
}

export const CreateOrAddFunctionalTeamToUserDialog = ({
  dialogState,
  isOpen,
  t,
  setIsOpen,
  selectedBusinessUnitTeam,
  teamName,
  editTeamName,
  userId,
  team,
  parentTeamId,
  afterSave,
}: CreateOrAddFunctionalTeamToUserDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const selectedUser = useSelector(getSelectedUser);
  const createTeamRequest = useSelector(getCreateTeamRequest);
  const prevCreateTeamRequest = usePrevious(createTeamRequest);
  const inviteUserToTeamRequest = useSelector(getInviteUserToTeamRequest);
  const prevInviteUserToTeamRequest = usePrevious(inviteUserToTeamRequest);
  const [isTeamNameEditable, setIsTeamNameEditable] = useState<boolean>(false);
  const [teamRoles, setTeamRoles] = useState<Roles[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  // Creating a team on the fly without somekind of memoization will cause infinite loop when using in useUserRoles method.
  const roleTeam = useMemo(() => {
    return { type: TeamTypes.FunctionalTeam };
  }, []);

  const {
    roles,
    userRoles,
    setUserRoles,
    accessRights,
    setRoleSelectionType,
    roleSelectionType,
  } = useUserRoles(selectedUser, undefined, undefined, true, roleTeam);

  useEffect(() => {
    if(setRoleSelectionType && roleSelectionType !== RoleSelectionType.TEAM) {
      setRoleSelectionType(RoleSelectionType.TEAM);
    }
  }, [setRoleSelectionType, roleSelectionType]);

  useEffect(() => {
    if(roles && (teamRoles?.find((roleGroup) => roleGroup.id === RoleGroups.VirtualTeam)?.roles.length ?? 0) === 0) {
      const defaultRoles = roles.map((roleGroup) => {
        return {
          ...roleGroup,
          roles: roleGroup.roles.map((role) => {
            return { ...role, defaultRole: role.defaultRole || dialogState == DialogStates.NewTeam && isTeamAdminRole(role.abbreviation) };
          })
        };
      });

      setTeamRoles(defaultRoles);
      setUserRoles([...defaultRoles.flatMap((roleGroup) => roleGroup.roles).filter((role) => role.defaultRole)]);
    }
  }, [roles, dialogState, setUserRoles, teamRoles]);

  const onSave = () => {
    const roleIds = userRoles?.map((role) => role.id);

    if(dialogState === DialogStates.ExistingTeam && team && team.id) {
      setIsLoading(true);
      saveTeamUserInvite(team?.id);
    } else if(teamName && userId && roleIds) {
      setIsLoading(true);
      dispatch(
        manageTeamsSagas.createTeam.createAction({
          createTeamCommand: {
            name: teamName,
            teamType: TeamTypes.FunctionalTeam,
            parentTeamId: selectedBusinessUnitTeam.id,
            firstUserId: userId,
            firstUserRoles: roleIds
          }
        })
      );
    }
  };

  const saveTeamUserInvite = (teamId: number) => {
    const roleIds = userRoles?.map((role) => role.id);

    if(teamId && userId && roleIds) {
      dispatch(
        manageTeamsSagas.inviteUserToTeam.createAction({
          inviteUserToTeamCommand: {
            teamId: teamId,
            userId: userId,
            userRoles: roleIds
          }
        })
      );
    }
  }

  useEffect(() => {
    if(createTeamRequest.status === RequestStatus.Completed || createTeamRequest.status === RequestStatus.Failed) {
      setIsLoading(false);
    }

    if(createTeamRequest.status === RequestStatus.Completed && prevCreateTeamRequest?.status === RequestStatus.Pending) {
      setIsOpen(false);
      if(afterSave) {
        afterSave();
      }
    }
  }, [createTeamRequest, prevCreateTeamRequest, afterSave, setIsOpen]);

  useEffect(() => {
    if(inviteUserToTeamRequest.status === RequestStatus.Completed || inviteUserToTeamRequest.status === RequestStatus.Failed) {
      setIsLoading(false);
    }

    if(inviteUserToTeamRequest.status === RequestStatus.Completed && prevInviteUserToTeamRequest?.status === RequestStatus.Pending) {
      setIsOpen(false);
      if(afterSave) {
        afterSave();
      }
    }
  }, [inviteUserToTeamRequest, prevInviteUserToTeamRequest, afterSave, setIsOpen]);

  return (
    <AddTeamBaseDialog
      t={t}
      isOpen={isOpen}
      isLoading={isLoading}
      width="1012px"
      setIsOpen={setIsOpen}
      title={t("Create functional team")}
      businessUnitTeamGridLabel={t("Selected business unit")}
      isBusinessUnitTeamGridDisabled={true}
      isBusinessUnitTeamGridVisible={true}
      selectedBusinessUnitTeam={selectedBusinessUnitTeam}
      primaryButtonText={t("Save")}
      primaryButtonAction={onSave}
      isSecondaryButtonVisible={true}
      secondaryButtonText={t("Cancel")}
      userId={userId}
      parentTeamId={parentTeamId}
    >
      <AddTeamBaseDialogComponent.SectionsContainer>
        <AddTeamBaseDialogComponent.TextWrapper>
          <InputLabel label={t("Created functional team within selected business unit")} />
        </AddTeamBaseDialogComponent.TextWrapper>
        <AddTeamBaseDialogComponent.InputWrapper>
          <InputLabel
            label={`${selectedBusinessUnitTeam?.name ?? ""} - `}
          />
          {isTeamNameEditable && editTeamName
            ? <Input
                ref={(ref) => ref?.focus()}
                dataType="text"
                value={teamName}
                instantValidation
                placeholder={t("Add new functional team name")}
                onValueChange={(value) => editTeamName(value)}
                onLostFocus={() => setIsTeamNameEditable(false)}
                onKeyUp={(event) => handlePressEnter(event, () => setIsTeamNameEditable(false))}
              />
            : <div>
              <span>{dialogState === DialogStates.NewTeam ? teamName : team?.name}</span>
              { editTeamName && dialogState === DialogStates.NewTeam ?
                <CustomIcon
                  name={"abb/edit"}
                  onClick={() => setIsTeamNameEditable(true)}
                />
              : null}
            </div>
          }
        </AddTeamBaseDialogComponent.InputWrapper>
      </AddTeamBaseDialogComponent.SectionsContainer>
      <AddTeamBaseDialogComponent.SectionsContainer>
        <AddTeamBaseDialogComponent.TextWrapper>
          <InputLabel
            label={t("Select user's role within functional team and save to add user to team")}
            tooltipText={t("First member of team is addigned these roles by default - it can be trasferred later by team user admin.")}
          />
        </AddTeamBaseDialogComponent.TextWrapper>
        <AddTeamBaseDialogComponent.InputWrapper>
          <UserRoles
            isInternal={false}
            isDeliverItRelevantCompany={false}
            roleSelectionType={RoleSelectionType.TEAM}
            roleGroups={teamRoles}
            userRoles={userRoles}
            setUserRoles={setUserRoles}
            accessRights={accessRights}
            saveChanges={undefined}
            setIsOpen={setIsOpen}
            isSaveEnabled={false}
            isLoading={false}
            hasEditRights={true}
            isTeamDialog={true}
            showDefaultRolesAsSelected={true}
            hideRoleGroupLabel={true}
          />
        </AddTeamBaseDialogComponent.InputWrapper>
      </AddTeamBaseDialogComponent.SectionsContainer>
    </AddTeamBaseDialog>
  );
};
